import { selectDashboardData } from 'app/store/userSlice';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import history from '@history';
import { useParams } from 'react-router-dom';
import DoneIcon from '@mui/icons-material/Done';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import quickSocialQuery from '../../query/quickSocial';
import SelectPlatformBox from './SelectPlatformBox';
import {
  handleApiRequest,
  handleFBLogin,
  fetchSocialConnections,
  handleSocialResponse,
} from '../../common/common';

const SocialConnects = () => {
  const params = useParams();
  const [socialData, setSocialData] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedAccount, setSelectedAccount] = useState([]);
  const [accessToken, setAccessToken] = useState();
  const [connectionData, setConnectionData] = useState([]);
  const [openDialog, setOpenDialog] = useState(true);
  const [facebookUserId, setFacebookUserId] = useState();
  const [selectedPage, setSelectedPage] = useState([]);
  const [socialPlatformId, setSocialPlatformId] = useState(null);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [loading, setLoading] = useState(false);
  const appKey = process.env.REACT_APP_WABA_APP_ID;
  const fbConfigKey = process.env.REACT_APP_FB_CONFIG_ID;
  const inConfigKey = process.env.REACT_APP_IN_CONFIG_ID;
  const fetchAgentSupportStatus = useCallback(async () => {
    try {
      setLoading(true);
      if (userCurrentBusinessDetails?.id) {
        const payloadData = {
          query: quickSocialQuery.getQuickSocialChannelWiseData,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const resultData = await handleApiRequest(payloadData);
        setLoading(false);
        if (resultData?.getQuickSocialChannelWiseData?.status === 200) {
          setSocialData(resultData?.getQuickSocialChannelWiseData?.data);
        }
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    fetchAgentSupportStatus();
  }, [fetchAgentSupportStatus]);

  const fetchProfileData = useCallback(
    async (data) => {
      try {
        setLoading(true);
        if (userCurrentBusinessDetails?.id && data?.reviewPlatformId) {
          const payloadData = {
            query: quickSocialQuery.getAllQuickSocialProfileDetails,
            variables: {
              userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
              mediaChannelId: parseInt(data?.reviewPlatformId, 10),
            },
          };
          const resultData = await handleApiRequest(payloadData);
          setLoading(false);
          if (resultData?.getAllQuickSocialProfileDetails?.status === 200) {
            setProfileData(resultData?.getAllQuickSocialProfileDetails?.data);
          }
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    },
    [userCurrentBusinessDetails?.id]
  );

  const handleSocialConnection = async (social) => {
    setLoading(true);
    try {
      let authResponse;
      if (social?.reviewPlatformId === 9 || social?.reviewPlatformId === 10) {
        authResponse = await handleFBLogin(
          appKey,
          fbConfigKey,
          inConfigKey,
          social?.reviewPlatformId
        );
        if (authResponse) {
          const fbAccessToken = authResponse?.accessToken;
          setFacebookUserId(authResponse?.userID);

          if (fbAccessToken && userCurrentBusinessDetails?.id) {
            await fetchSocialConnections(
              social?.reviewPlatformId,
              userCurrentBusinessDetails,
              setConnectionData,
              setAccessToken,
              setOpenDialog,
              setSelectedPage,
              setLoading,
              fbAccessToken
            );
          } else {
            console.log('Missing Facebook access token or business details.');
          }
        } else {
          console.log('Facebook login failed or was cancelled.');
        }
      } else if (social?.reviewPlatformId === 11) {
        // handleXLogin();
      } else if (social?.reviewPlatformId === 12) {
        // handleLinkedInLogin();
      }
    } catch (error) {
      console.error('Error during social connection:', error);
    } finally {
      setLoading(false);
    }

    setSocialPlatformId(social?.reviewPlatformId);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (profileData && Object.keys(profileData)?.length > 0 && selectedAccount) {
      history.push(`quick-social/social-reviews/profile/${params?.id ? params?.id : ''}`, {
        selectedAccount,
        profileData,
      });
    }
  }, [params.id, profileData, selectedAccount]);

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <>
      <div className="p-20 lg:p-32">
        <p className="text-20 sm:text-28 font-600">{t('navigation.socialConnects')}</p>
        <div className="bg-white mt-20 p-20 rounded-md">
          <p className="text-16 sm:text-24 font-600">{t('quickSocial.connectSocial')}</p>
          <div className="flex flex-col mt-14 gap-14">
            {socialData &&
              socialData?.length > 0 &&
              socialData?.map((social, i) => (
                <div className="border-1 rounded-md p-14 border-grey-400 flex gap-20 sm:gap-0 items-start sm:flex-row flex-col justify-between">
                  <div className="flex items-center gap-20">
                    <img
                      className="mx-0 sm:mx-auto rounded-full w-40 h-40 sm:w-48 sm:h-48 "
                      src={social?.logo}
                      alt={social?.name}
                    />
                    <h3 className="text-16 sm:text-20 font-500">{social?.name}</h3>
                  </div>
                  <div>
                    {social?.isConnected ? (
                      <div className="flex gap-10 flex-row">
                        <Button
                          className="rounded-md text-12 sm:text-14 font-500"
                          size="medium"
                          variant="contained"
                          color="secondary"
                        >
                          <DoneIcon className="text-12 sm:text-14 mr-5" />
                          {t('quickSocial.connected')}
                        </Button>
                        <Button
                          className="rounded-md text-12 sm:text-14 font-500"
                          size="medium"
                          variant="outlined"
                          color="quickSocial"
                          onClick={() => {
                            setSelectedAccount(social);
                            fetchProfileData(social);
                          }}
                        >
                          {t('menuItems.viewDetails')}
                        </Button>
                      </div>
                    ) : (
                      <Button
                        className="rounded-md text-12 px-0 sm:px-28 sm:text-14 w-full max-w-160"
                        size="medium"
                        variant="contained"
                        color="quickSocial"
                        onClick={() => handleSocialConnection(social)}
                      >
                        {t('quickSocial.connect')}
                      </Button>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
        {connectionData?.length > 0 && openDialog && (
          <SelectPlatformBox
            onClose={handleClose}
            openDialog={openDialog}
            socialPlatformId={socialPlatformId}
            connectionData={connectionData}
            setSelectedPage={setSelectedPage}
            selectedPage={selectedPage}
            setOpenDialog={setOpenDialog}
            handleSocialResponse={() =>
              handleSocialResponse(
                socialPlatformId,
                accessToken,
                facebookUserId,
                selectedPage,
                userCurrentBusinessDetails,
                setLoading,
                enqueueSnackbar,
                fetchAgentSupportStatus,
                setConnectionData,
                setOpenDialog
              )
            }
            handleClose={handleClose}
          />
        )}
      </div>
    </>
  );
};

export default SocialConnects;
