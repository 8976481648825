import { useCallback, useEffect, useState } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import EditIcon from '@mui/icons-material/Edit';
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
  renderTimeViewClock,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Icon,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { handleApiRequest, imageSendToServer } from '../../../common/common';
import manageBusinessData from '../../../query/manageBusiness';
import { selectDashboardData } from '../../../../store/userSlice';
import ConnectYourBusiness from '../../businessCompetitor/businessCompetitors/ConnectYourBusiness';

const PostSection = () => {
  const postTypes = ['Offer', "What's New", 'Event'];
  const actionTypes = ['Call', 'Book', 'Order', 'Shop', 'Sign Up', 'Learn More'];
  const { enqueueSnackbar } = useSnackbar();
  const [isBusinessConnect, setIsBusinessConnect] = useState(false);
  const [googleBusinessData, setGoogleBusinessData] = useState();
  const [loading, setLoading] = useState(true);
  const [openDialogue, setOpenDialogue] = useState(false);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [fileName, setFileName] = useState();
  const [uploadFile, setUploadFile] = useState();
  const [selectPostType, setSelectPostType] = useState(1);
  const [postType, setPostType] = useState('Offer');
  const [isEdit, setIsEdit] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [postTypeData, setPostTypeData] = useState();
  const [postData, setPostData] = useState({
    offerPost: [],
    eventPost: [],
    actionPost: [],
  });
  const [postDetails, setPostDetails] = useState({
    title: '',
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    couponCode: '',
    redeemLink: '',
    termAndCondition: '',
    actionType: '',
    details: '',
    name: '',
  });

  const typeOfPosts = [
    { id: 1, value: 'Offer Post' },
    { id: 2, value: 'Event Post' },
    { id: 3, value: 'Action Post' },
  ];

  useEffect(() => {
    const fetchBusinessDetails = async () => {
      if (userCurrentBusinessDetails?.id) {
        setLoading(true);
        try {
          const payload = {
            query: manageBusinessData.getUserBusinessesGoogleDetails,
            variables: {
              businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            },
          };
          const result = await handleApiRequest(payload);
          setLoading(false);
          if (result?.getUserBusinessesGoogleDetails?.status === 200) {
            const data = result.getUserBusinessesGoogleDetails?.data;
            const parsedData = JSON.parse(data);
            setGoogleBusinessData(parsedData);
          } else {
            enqueueSnackbar(result?.getUserBusinessesGoogleDetails?.message, {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
        } catch (error) {
          setLoading(false);
          console.error('Error fetching business details:', error);
        }
      }
    };
    fetchBusinessDetails();
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar]);

  const fetchGooglePosts = useCallback(async () => {
    try {
      const payload = {
        query: manageBusinessData.getGoogleBusinessPosts,
        variables: {
          userBusinessesGoogleDetailId: parseInt(
            googleBusinessData?.userBusinessesGoogleDetailId,
            10
          ),
        },
      };
      const res = await handleApiRequest(payload);
      if (res?.getGoogleBusinessPosts?.status === 200) {
        setPostData({
          offerPost:
            res?.getGoogleBusinessPosts?.data?.offerPost &&
            JSON.parse(res?.getGoogleBusinessPosts?.data?.offerPost),
          actionPost:
            res?.getGoogleBusinessPosts?.data?.callToActionPost &&
            JSON.parse(res?.getGoogleBusinessPosts?.data?.callToActionPost),
          eventPost:
            res?.getGoogleBusinessPosts?.data?.eventPost &&
            JSON.parse(res?.getGoogleBusinessPosts?.data?.eventPost),
        });
        setPostTypeData(
          res?.getGoogleBusinessPosts?.data?.offerPost &&
            JSON.parse(res?.getGoogleBusinessPosts?.data?.offerPost)
        );
      }
    } catch (error) {
      console.error('Error fetching Google posts:', error);
    }
  }, [googleBusinessData?.userBusinessesGoogleDetailId]);

  useEffect(() => {
    fetchGooglePosts();
  }, [fetchGooglePosts]);

  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  const handleSelectStartTime = (data) => {
    setPostDetails({
      ...postDetails,
      startTime: data,
    });
  };

  const handleSelectCloseTime = (data) => {
    setPostDetails({
      ...postDetails,
      endTime: data,
    });
  };

  const handleSelectStartDate = (data) => {
    setPostDetails({
      ...postDetails,
      startDate: data,
    });
  };

  const handleSelectEndDate = (data) => {
    setPostDetails({
      ...postDetails,
      endDate: data,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file?.type?.startsWith('image/')) {
      enqueueSnackbar(`${t('tabletVideos.tabletVideoDetail.fileTypeError')}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    const reader = new FileReader();
    reader?.readAsBinaryString(file);

    reader.onload = async () => {
      setUploadFile(file);
      setFileName(file?.name);
    };
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (!file.type.startsWith('image/')) {
      enqueueSnackbar(`${t('tabletVideos.tabletVideoDetail.fileTypeError')}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    const reader = new FileReader();

    reader.readAsBinaryString(file);

    reader.onload = async () => {
      setUploadFile(file);
      setFileName(file?.name);
    };
  };

  const handleClose = () => {
    setOpenDialogue(false);
    setPostDetails({
      title: '',
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      couponCode: '',
      redeemLink: '',
      termAndCondition: '',
      actionType: '',
      details: '',
    });
  };

  const onSubmit = async () => {
    setRefreshIcon(true);
    try {
      const payload = {
        query: isEdit
          ? manageBusinessData?.updateGooglePostByType
          : manageBusinessData.createGooglePostByType,
        variables: {
          postData: {
            type: postType,
            title: postDetails?.title,
            summary: postDetails?.details,
            startDate: formatDate(postDetails?.startDate),
            endDate: formatDate(postDetails?.endDate),
            startTime: new Date(postDetails?.startTime).toTimeString().slice(0, 5),
            endTime: new Date(postDetails?.endTime).toTimeString().slice(0, 5),
            couponCode: postDetails?.couponCode,
            redeemLink: postDetails?.redeemLink,
            termAndCondition: postDetails?.termAndCondition,
            actionType: 'ORDER',
            userBusinessesGoogleDetailId: parseInt(
              googleBusinessData?.userBusinessesGoogleDetailId,
              10
            ),
          },
          image: uploadFile,
          ...(isEdit && {
            postName: postDetails?.name,
          }),
        },
      };
      const result = await imageSendToServer(payload);
      setRefreshIcon(false);
      const res = isEdit ? result?.updateGooglePostByType : result?.createGooglePostByType;
      if (res?.status === 200) {
        fetchGooglePosts();
        handleClose();
        console.log('Post successfully created:', result);
      } else {
        enqueueSnackbar(res?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error creating Google post:', error);
    }
  };

  const handleSelectProduct = (post) => {
    setIsEdit(true);
    setPostDetails({
      title: post?.event?.title,
      startDate: new Date(
        post.event.schedule.startDate.year,
        post.event.schedule.startDate.month - 1,
        post?.event.schedule.startDate.day
      ),
      endDate: new Date(
        post.event.schedule.endDate.year,
        post.event.schedule.endDate.month - 1,
        post?.event.schedule.endDate.day
      ),
      startTime: new Date(
        new Date().setHours(
          post.event.schedule.startTime.hours,
          post.event.schedule.startTime.minutes || 0,
          0,
          0
        )
      ),
      endTime: new Date(
        new Date().setHours(
          post.event.schedule.endTime.hours,
          post.event.schedule.endTime.minutes || 0,
          0,
          0
        )
      ),
      couponCode: post?.offer?.couponCode,
      redeemLink: post?.offer?.redeemOnlineUrl,
      termAndCondition: post?.offer?.termsConditions,
      actionType: '',
      details: post?.summary,
      name: post?.name,
    });
    setOpenDialogue(true);
    if (post?.topicType === 'setPostType') {
      setPostType('Offer');
    }
  };

  const handleSelectPostType = (post) => {
    setSelectPostType(post);
    switch (post) {
      case 1:
        setPostTypeData(postData.offerPost);
        break;
      case 2:
        setPostTypeData(postData.eventPost);
        break;
      case 3:
        setPostTypeData(postData.actionPost);
        break;
      default:
        console.warn('Invalid post type selected');
        break;
    }
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-24">
      {isBusinessConnect ? (
        <div className="bg-white rounded-md px-28 py-28">
          <div className="flex justify-between mb-16">
            <Typography className="font-bold text-20">{t('listManagement.posts')}</Typography>
            <div className="flex gap-8">
              <Select
                className="min-w-160"
                size="small"
                value={selectPostType || 0}
                onChange={(e) => handleSelectPostType(e.target.value)}
              >
                <MenuItem value="0">{t('listManagement.selectPostType')}</MenuItem>
                {typeOfPosts?.map((item, i) => {
                  return (
                    <MenuItem key={i} value={item?.id}>
                      {item?.value}
                    </MenuItem>
                  );
                })}
              </Select>
              <Button
                className="bg-quick-review hover:bg-quick-review rounded px-24 text-white font-semibold"
                onClick={() => setOpenDialogue(true)}
              >
                {t('listManagement.createNewPost')}
              </Button>
            </div>
          </div>
          {postTypeData?.length > 0 ? (
            <div className="grid grid-cols-3 col-span-1 gap-16">
              {postTypeData.map((item, index) => {
                return (
                  <div className="border rounded-md max-h-484 min-h-484" key={index}>
                    <div className="p-4 flex justify-between items-center">
                      <Typography className="font-bold text-20 pl-14 pb-6">
                        {item?.event?.title}
                      </Typography>
                      <Button
                        className="w-40 max-w-40 min-w-40 text-gray hover:bg-transparent"
                        onClick={() => handleSelectProduct(item)}
                      >
                        <EditIcon />
                      </Button>
                    </div>
                    <img
                      className="max-w-384 min-w-384 m-auto h-256 w-full"
                      src={item?.media?.[0]?.googleUrl || 'assets/images/business/nopost.png'}
                      alt=""
                    />
                    <div className="px-16 py-8">
                      <div>
                        <Typography className="font-semibold">{item?.summary}</Typography>
                      </div>
                      <div className="flex justify-between my-8">
                        <div>
                          <Typography className="font-semibold">Post Type </Typography>
                          <Typography className="font-semibold text-gray">
                            {item?.topicType}
                          </Typography>
                        </div>
                        {item?.offer?.couponCode && (
                          <div>
                            <Typography className="font-semibold">
                              {t('listManagement.couponCode')}
                            </Typography>
                            <Typography className="font-semibold text-gray">
                              {item?.offer?.couponCode}{' '}
                            </Typography>
                          </div>
                        )}
                      </div>
                      <Typography className="font-semibold mb-6">
                        {t('listManagement.scheduleDateAndTime')}
                      </Typography>
                      <div className="flex justify-between">
                        <div>
                          <Typography className="font-semibold">Start Date and time</Typography>
                          <Typography className="font-semibold text-gray">
                            {`${String(item?.event?.schedule?.startDate?.day)?.padStart(2, '0')} -
                              ${String(item?.event?.schedule?.startDate?.month)?.padStart(2, '0')} -
                              ${String(item?.event?.schedule?.startDate?.year)?.padStart(2, '0')}`}
                            ,{' '}
                            {item?.event?.schedule?.startTime?.hours &&
                              `${String(item?.event?.schedule?.startTime?.hours)?.padStart(
                                2,
                                '0'
                              )} :
                              ${
                                item?.event?.schedule?.startTime?.minutes
                                  ? String(item?.event?.schedule?.startTime?.minutes)?.padStart(
                                      2,
                                      '0'
                                    )
                                  : '00'
                              }`}
                          </Typography>
                        </div>
                        <div>
                          <Typography className="font-semibold">
                            {t('listManagement.endDateAndTime')}
                          </Typography>
                          <Typography className="font-semibold text-gray">
                            {`${String(item?.event?.schedule?.endDate?.day)?.padStart(2, '0')} -
                              ${String(item?.event?.schedule?.endDate?.month)?.padStart(2, '0')} -
                              ${String(item?.event?.schedule?.endDate?.year)?.padStart(2, '0')}`}
                            ,{' '}
                            {item?.event?.schedule?.startTime?.hours &&
                              `${String(item?.event?.schedule?.endTime?.hours)?.padStart(2, '0')} :
                              ${
                                item?.event?.schedule?.endTime?.minutes
                                  ? String(item?.event?.schedule?.endTime?.minutes)?.padStart(
                                      2,
                                      '0'
                                    )
                                  : '00'
                              }`}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="text-center py-256">
              <Typography className="font-bold text-20">
                {t('listManagement.noPostAvailable')}
              </Typography>
              <Typography className="font-normal text-14 my-8">
                {t('listManagement.createPostMessage')}
              </Typography>
              <Button
                className="bg-quick-review hover:bg-quick-review rounded px-24 text-white font-semibold"
                onClick={() => setOpenDialogue(true)}
              >
                {t('listManagement.createNewPost')}
              </Button>
            </div>
          )}
        </div>
      ) : (
        <ConnectYourBusiness setIsBusinessConnect={setIsBusinessConnect} />
      )}
      <Dialog
        className="business-type-dropdown overflow-scroll"
        open={openDialogue}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent className="p-28 mb-8 relative">
          <Icon
            onClick={() => handleClose()}
            className="text-24 text-grey-400 absolute right-20 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
          <div className="border-b-2 border-solid border-quick-review-100 pb-24">
            <Typography className="font-bold text-20">{googleBusinessData?.title}</Typography>
            <Typography className="text-gray text-16 font-semibold pb-8">
              {t('listManagement.address')} :{' '}
              {googleBusinessData?.storefrontAddress?.addressLines?.[0]}{' '}
              {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
              {googleBusinessData?.storefrontAddress?.locality}{' '}
              {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
              {googleBusinessData?.storefrontAddress?.postalCode}
            </Typography>
          </div>
          <Typography className="font-semibold mt-24 text-16">
            {t('listManagement.selectPostType')}
          </Typography>
          <div className="flex gap-16 mt-8">
            {postTypes?.map((item, index) => {
              return (
                <div key={index}>
                  <Button
                    className={`${
                      postType === item
                        ? 'bg-quick-review hover:bg-quick-review text-white'
                        : 'bg-gray-300 hover:bg-gray-300'
                    } px-40 font-semibold text-16`}
                    onClick={() => setPostType(item)}
                  >
                    {item}
                  </Button>
                </div>
              );
            })}
          </div>
          <Typography className="font-semibold text-16 mb-8 mt-40">
            {postType} {t('listManagement.title')}
          </Typography>
          <TextField
            className="w-full"
            size="small"
            value={postDetails?.title}
            onChange={(e) =>
              setPostDetails({
                ...postDetails,
                title: e.target.value,
              })
            }
          />
          <div className="flex w-full mt-24 mb-48 gap-40">
            <div className="w-full">
              <Typography className="font-semibold text-16 pb-16">
                {t('listManagement.addPostImage')}
              </Typography>
              <Paper
                className="relative w-full h-full rounded-8 shadow-none border-1 border-dashed border-quick-review p-40 bg-quick-review-100 text-center"
                role="button"
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
              >
                <span className="block text-center">
                  <img
                    src="/assets/images/admin-icon/cloud_upload.png"
                    className="mx-auto mb-12"
                    width="61"
                    height="64"
                    alt="..."
                  />
                </span>
                <div className="block font-semibold text-16">
                  {fileName || 'Please select a valid image file'}
                </div>
                <span className="block font-semibold text-16">{t('listManagement.or')}</span>
                <div className="inline-block">
                  <label htmlFor="button-file">
                    <input
                      accept="image/png, image/jpg, image/jpeg"
                      className="hidden"
                      id="button-file"
                      type="file"
                      onChange={handleFileChange}
                    />
                    <Button
                      size="small"
                      className="rounded-xxl block text-14 font-medium px-24 py-5 w-auto"
                      variant="contained"
                      color="secondary"
                      component="span"
                    >
                      Browser file
                    </Button>
                  </label>
                </div>
              </Paper>
            </div>
            {postType !== "What's New" && (
              <div className="w-full">
                <Typography className="font-semibold text-16 pb-16">
                  {t('listManagement.offersStateDateAndDate')}
                </Typography>
                <div className="border border-solid !border-gray rounded px-40 py-40 h-full">
                  <div className="grid grid-cols-2 col-span-1 gap-12 justify-between w-full">
                    <div>
                      <Typography className="text-14 font-medium">
                        {t('listManagement.startDate')}
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          className="h-40 w-full"
                          id="note"
                          variant="outlined"
                          required
                          fullWidth
                          disablePast
                          format="MMM dd, yyyy"
                          value={postDetails?.startDate}
                          onChange={(data) => handleSelectStartDate(data)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{
                                shrink: false,
                              }}
                              label={t('listManagement.selectDate')}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    <div>
                      <Typography className="text-14 font-medium">
                        {t('listManagement.endDate')}
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          className="h-24 w-full"
                          id="note"
                          variant="outlined"
                          required
                          fullWidth
                          disablePast
                          format="MMM dd, yyyy"
                          value={postDetails?.endDate}
                          onChange={(data) => handleSelectEndDate(data)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{
                                shrink: false,
                              }}
                              label={t('listManagement.selectDate')}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    <div>
                      <Typography className="text-14 font-medium">
                        {t('listManagement.startTime')}
                      </Typography>
                      <TimePicker
                        className="h-40 w-full"
                        required
                        value={postDetails?.startTime}
                        onChange={(data) => handleSelectStartTime(data)}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                      />
                    </div>
                    <div>
                      <Typography className="text-14 font-medium">
                        {t('listManagement.endTime')}
                      </Typography>
                      <TimePicker
                        className="h-40 w-full"
                        required
                        value={postDetails?.endTime}
                        onChange={(data) => handleSelectCloseTime(data)}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex gap-16 mt-16">
            {postType !== 'Offer' &&
              actionTypes?.map((item, index) => {
                return (
                  <div key={index}>
                    <Button
                      className={`${
                        postDetails?.actionType === item
                          ? 'bg-quick-review hover:bg-quick-review text-white'
                          : 'bg-gray-300 hover:bg-gray-300'
                      } px-40 font-semibold text-16`}
                      onClick={() => setPostDetails({ ...postDetails, actionType: item })}
                    >
                      {item}
                    </Button>
                  </div>
                );
              })}
          </div>
          <div>
            <Typography className="font-semibold text-16 pt-24 mb-8">
              {postType} {t('listManagement.details')}
            </Typography>
            <TextField
              className="w-full"
              onChange={(e) =>
                setPostDetails({
                  ...postDetails,
                  details: e.target.value,
                })
              }
              rows={5}
              multiline
              size="small"
            />
          </div>
          {postType === 'Offer' ? (
            <>
              <div className="flex justify-between w-full gap-32">
                <div className="w-full">
                  <Typography className="font-semibold text-16 pt-24 mb-8">
                    {t('listManagement.couponCode')}
                  </Typography>
                  <TextField
                    className="w-full"
                    value={postDetails?.couponCode}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        couponCode: e.target.value,
                      })
                    }
                    size="small"
                  />
                </div>
                <div className="w-full">
                  <Typography className="font-semibold text-16 pt-24 mb-8">
                    {t('listManagement.redeemLink')}
                  </Typography>
                  <TextField
                    className="w-full"
                    value={postDetails?.redeemLink}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        redeemLink: e.target.value,
                      })
                    }
                    size="small"
                  />
                </div>
              </div>
              <Typography className="font-semibold text-16 pt-24 mb-8">
                {t('listManagement.termsAndCondition')}
              </Typography>
              <TextField
                className="w-full"
                value={postDetails?.termAndCondition}
                onChange={(e) =>
                  setPostDetails({
                    ...postDetails,
                    termAndCondition: e.target.value,
                  })
                }
                size="small"
              />
            </>
          ) : (
            <div>
              <Typography className="font-semibold text-16 pt-24 mb-8">
                {t('listManagement.website')} {t('listManagement.link')}
              </Typography>
              <TextField className="w-full" size="small" />
            </div>
          )}
          <div className="text-center">
            <Button
              className="bg-quick-review w-1/2 text-white rounded-full mt-16 px-40 disabled:text-white font-bold text-18 disabled:bg-gray hover:bg-quick-review"
              onClick={() => onSubmit()}
              disabled={
                !postDetails?.title ||
                !postDetails?.details ||
                !postDetails?.couponCode ||
                !postDetails?.termAndCondition ||
                !postDetails?.redeemLink ||
                !uploadFile ||
                !postDetails?.startDate ||
                !postDetails?.endDate ||
                !postDetails?.startTime ||
                !postDetails?.endTime ||
                refreshIcon
              }
            >
              {t('listManagement.save')}
              {refreshIcon && (
                <CircularProgress className="text-quick-review absolute mx-auto" size={24} />
              )}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PostSection;
