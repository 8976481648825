import { useEffect, useRef, useState } from 'react';
import { GoogleMap, LoadScript, Circle, OverlayView, InfoWindow } from '@react-google-maps/api';
import { t } from 'i18next';
import '../geoGrid.css';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import manageBusinessData from '../../../query/manageBusiness';
import { handleApiRequest } from '../../../common/common';
import Loading from '../../manage-business/Loading';

const CompetitorBusiness = (props) => {
  const { currentLocation, businessId, reviewPlatformId } = props;
  const [businessRankData, setBusinessRankData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [zoomLevel, setZoomLevel] = useState(12);
  const [showSelectedCircleData, setShowSelectedCircleData] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const lib = ['places'];
  const mapRef = useRef(null);
  const initialRadius = 600;

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i += 1) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  useEffect(() => {
    const fetchCompetitorData = async () => {
      setLoading(true);
      const payload = {
        query: manageBusinessData.getUserBusinessRanker,
        variables: {
          userBusinessId: parseInt(businessId, 10),
          reviewPlatformId: parseInt(reviewPlatformId, 10),
        },
      };
      const result = await handleApiRequest(payload);
      setLoading(false);
      if (result?.getUserBusinessRanker?.status === 200) {
        const data = result?.getUserBusinessRanker?.data?.rankerJson;
        const addColor = data?.map((item) => ({ ...item, color: getRandomColor() }));
        setBusinessRankData(addColor);
      } else {
        enqueueSnackbar(result?.getUserBusinessRanker?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    };
    if (businessId && reviewPlatformId) {
      fetchCompetitorData();
    }
  }, [businessId, reviewPlatformId, enqueueSnackbar]);

  const handleLoad = (map) => {
    mapRef.current = map;
    if (window.google && window.google.maps) {
      const bounds = new window.google.maps.LatLngBounds();
      businessRankData?.forEach((circle) => {
        bounds.extend(new window.google.maps.LatLng(circle.lat, circle.long));
      });
      map.fitBounds(bounds);

      map.addListener('zoom_changed', () => {
        setZoomLevel(map.getZoom());
      });
    }
  };

  if (loading) {
    return <Loading />;
  }

  const createOverlayContent = (rank) => (
    <div className="text-white text-16 text-center flex items-center justify-center h-full font-semibold">
      {rank}
    </div>
  );

  const calculateRadius = () => {
    const factor = 2 ** (zoomLevel - 12);
    return initialRadius / factor;
  };

  const clickOnCircle = (i, data) => {
    setShowSelectedCircleData(i);
  };

  function formatDistance(value) {
    const distanceInMeters = value * 1000;
    if (distanceInMeters >= 1000) {
      return `${value.toFixed(1)} km`;
    }
    return `${Math.round(distanceInMeters)} m`;
  }

  return (
    <div>
      <LoadScript
        id="script-loader"
        googleMapsApiKey={key}
        libraries={lib}
        loadingElement={<div className="text-center">Loading...</div>}
      >
        <GoogleMap
          mapContainerStyle={{
            height: '600px',
            width: '100%',
          }}
          zoom={12}
          center={currentLocation}
          options={{
            disableDefaultUI: false,
            draggable: true,
            scrollwheel: false,
          }}
          onLoad={handleLoad}
        >
          {businessRankData?.map((circle, index) => {
            return (
              <div key={index} className="flex item-center justify-center">
                <Circle
                  center={{ lat: circle.lat, lng: circle.long }}
                  radius={calculateRadius()}
                  onClick={() => clickOnCircle(index, circle)}
                  options={{
                    fillColor: circle.color,
                    fillOpacity: 1,
                    strokeColor: circle.color,
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    clickable: true,
                  }}
                />
                <OverlayView
                  position={{ lat: circle.lat, lng: circle.long }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  {createOverlayContent(circle.rank)}
                </OverlayView>
                {showSelectedCircleData === index && (
                  <InfoWindow
                    position={{ lat: circle.lat, lng: circle.long }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  >
                    <div className="w-384">
                      <Typography className="font-bold text-20">{circle?.name}</Typography>
                      <Typography className="text-14">{circle?.address}</Typography>
                      <div className="flex justify-between mt-16">
                        <Typography className="font-bold text-gray-600 text-14">
                          {t('businessCompetitor.distanceFromYou')}
                        </Typography>
                        <Typography className="font-bold text-14">
                          {formatDistance(circle.distance_of_point_from_center)}
                        </Typography>
                      </div>
                      <div className="flex justify-between">
                        <Typography className="font-bold text-gray-600 text-14">
                          {t('businessCompetitor.primaryCategory')}
                        </Typography>
                        <Typography className="font-bold text-14">
                          {circle?.primary_category}
                        </Typography>
                      </div>
                      <div className="flex justify-between">
                        <Typography className="font-bold text-gray-600 text-14">Rating</Typography>
                        <Typography className="font-bold text-14">{circle?.rating}</Typography>
                      </div>
                      <div className="flex justify-between">
                        <Typography className="font-bold text-gray-600 text-14">
                          {t('businessCompetitor.noOfReviews')}
                        </Typography>
                        <Typography className="font-bold text-14">
                          {circle?.total_reviews}
                        </Typography>
                      </div>
                    </div>
                  </InfoWindow>
                )}
              </div>
            );
          })}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default CompetitorBusiness;
