import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Slider from 'react-slick';
import moment from 'moment';
import { getAllUserBusinessSubscription, selectDashboardData } from 'src/app/store/userSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import { useParams } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import history from '@history';
import { findExpireDay, handleApiRequest, handleWabaApiRequest } from '../../common/common';
import businessData from '../../query/business';
import DashboardAlerts from './DashboardAlerts';
import wabaQuery from '../../query/waba';
import SharePost from '../quick-post/SharePost';
import quickChatQuery from '../../query/quickChat';
import BotStatus from './BotStatus';

function Dashboards() {
  const { t } = useTranslation();
  const params = useParams();
  const [quickChatDetails, setQuickChatDetails] = useState({});
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const [loading, setLoading] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [getChatRoom, setGetChatRoom] = useState([]);
  const [quickChatActiveData, setQuickChatActiveData] = useState({});
  const [shareCompOpen, setShareCompOpen] = useState(false);
  const [dashboardData, setDashboardData] = useState({
    quickChatData: null,
    appSetupCard: [],
    chatRoomWithLastMessage: [],
    quickChatCountAndInsightsMatrix: [],
  });
  const botLink = `${process.env.REACT_APP_PORTAL_URL}/quickchat/${quickChatDetails?.domain}`;
  const isSingleItem = dashboardData?.appSetupCard?.length === 1;
  const redirect = (type, platformType) => {
    const idPath = params?.id ? `/${params?.id}` : '/';

    switch (type) {
      case 'pending-task':
        history.push(`/pending-task${idPath}`, { platformType, botLink });
        break;
      case 'matrices':
        history.push(`quick-chat/matrices${idPath}`);
        break;
      case 'waba':
        history.push(`quick-chat/waba${idPath}`);
        break;
      case 'web-chatBot':
        history.push(`quick-chat/chat${idPath}`);
        break;
      case 'whatsapp-bot':
        history.push(`quick-chat/waba-inbox${idPath}`);
        break;
      default:
        console.warn('Unknown redirect type:', type);
    }
  };

  const settings = {
    dots: false,
    infinite: isSingleItem,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const fetchDashboardData = async (id) => {
    const fetchPayload = (query, variables) => ({
      query,
      variables: { ...variables },
    });

    const requests = [
      handleApiRequest(
        fetchPayload(quickChatQuery.getQuickChat, { businessId: parseInt(id, 10) })
      ).catch((err) => ({
        error: err,
      })),
      handleApiRequest(
        fetchPayload(businessData.getAppSetupCard, {
          businessId: parseInt(id, 10),
          type: 'quick_chat',
        })
      ).catch((err) => ({ error: err })),
      handleWabaApiRequest(
        fetchPayload(wabaQuery.getChatRoomWithLastMessage, { isBroadcast: false, isUnRead: false })
      ).catch((err) => ({
        error: err,
      })),
      handleApiRequest(
        fetchPayload(quickChatQuery.getQuickChatCountAndInsightsMatrix, {
          businessId: parseInt(id, 10),
          botTypeId: 1,
        })
      ).catch((err) => ({
        error: err,
      })),
    ];

    try {
      setLoading(true);
      const results = await Promise.all(requests);

      const [
        quickChatData,
        appSetupCard,
        chatRoomWithLastMessage,
        quickChatCountAndInsightsMatrix,
      ] = results.map((result) => (result.error ? null : result));

      setDashboardData({
        quickChatData: quickChatData?.getQuickChat?.data?.id || null,
        appSetupCard: appSetupCard?.getAppSetupCard || [],
        chatRoomWithLastMessage: chatRoomWithLastMessage?.getChatRoomWithLastMessage?.data || [],
        quickChatCountAndInsightsMatrix:
          quickChatCountAndInsightsMatrix?.getQuickChatCountAndInsightsMatrix?.data || [],
      });
    } catch (error) {
      console.error('Error fetching dashboard data', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      fetchDashboardData(userCurrentBusinessDetails?.id);
    }
  }, [userCurrentBusinessDetails?.id]);
  const { quickChatData, appSetupCard, chatRoomWithLastMessage, quickChatCountAndInsightsMatrix } =
    dashboardData;

  const fetchAppSetupCard = async (id) => {
    try {
      const response = await handleApiRequest({
        query: businessData.getAppSetupCard,
        variables: {
          businessId: parseInt(id, 10),
          type: 'quick_chat',
        },
      });
      setDashboardData((prevData) => ({
        ...prevData,
        appSetupCard: response?.getAppSetupCard || [],
      }));
    } catch (error) {
      console.error('Error fetching app setup card data:', error);
    }
  };

  const fetchAgentSupportStatus = useCallback(async () => {
    try {
      setLoading(true);
      if (userCurrentBusinessDetails?.id && !userCurrentBusinessDetails?.isOnlineBusiness) {
        const payloadData = {
          query: quickChatQuery.getQuickChatDetails,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const resultData = await handleApiRequest(payloadData);
        setLoading(false);
        if (resultData?.getQuickChatDetails?.status === 200) {
          setQuickChatDetails(resultData?.getQuickChatDetails?.data);
        }
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails?.id, userCurrentBusinessDetails?.isOnlineBusiness]);

  useEffect(() => {
    fetchAgentSupportStatus();
  }, [fetchAgentSupportStatus]);

  useEffect(() => {
    setLoading(true);

    const whatAppUserChatRoom = async () => {
      try {
        const payload = {
          query: quickChatQuery.getAllQuickChatWithLastMessage,
          variables: {
            quickChatId: quickChatData,
          },
        };
        const res = await handleApiRequest(payload);
        setLoading(false);
        if (res?.getAllQuickChatWithLastMessage?.status === 200) {
          setGetChatRoom(res?.getAllQuickChatWithLastMessage?.data);
        } else {
          setGetChatRoom([]);
        }
      } catch (error) {
        console.error('An error occurred:', error);
        setLoading(false);
      }
    };

    if (quickChatData && !userCurrentBusinessDetails?.isOnlineBusiness) {
      whatAppUserChatRoom();
    }
  }, [quickChatData, userCurrentBusinessDetails?.isOnlineBusiness]);

  const handleRedirectToChatBot = async (id, cardName) => {
    try {
      const payload = {
        query: quickChatQuery.updateAppSetUpCard,
        variables: {
          appSetUpCardId: parseInt(appSetupCard?.appSetUpCardId, 10),
          cardType: cardName,
          productType: 'quick_chat',
        },
      };

      const res = await handleApiRequest(payload);
      if (res?.updateAppSetUpCard?.status === 200) {
        fetchAppSetupCard(userCurrentBusinessDetails?.id);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const openVideoUrl = (url) => {
    if (url) {
      window.open(url, '_blank');
    } else {
      console.error('No URL provided');
    }
  };

  const handleContactClick = (contact) => {
    setSelectedContact(contact);
  };

  const handleCloseComp = () => {
    setShareCompOpen(false);
  };

  const formatTimestamp = (timestamp, isUnix = true) => {
    const date = isUnix ? moment.unix(timestamp / 1000) : moment(timestamp);
    const today = moment();
    const yesterday = moment().subtract(1, 'days');
    if (date.isSame(today, 'day')) {
      return date.format('h:mm A');
    }
    if (date.isSame(yesterday, 'day')) {
      return 'Yesterday';
    }
    return date.format('DD/MM/YYYY');
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-32 h-full l-padding">
      <div className="flex flex-col gap-32">
        {userSubscriptionData &&
          userSubscriptionData
            .filter((subscriptionData) =>
              subscriptionData?.comboDetails?.length > 0
                ? subscriptionData.comboDetails.some((item) => item?.name === 'Quick Chat')
                : subscriptionData?.name === 'Quick Chat'
            )
            .map(
              (subscriptionData, i) =>
                subscriptionData?.userBusinessId === userCurrentBusinessDetails?.id &&
                subscriptionData?.isFreeTrial === true && (
                  <div key={i} className="bg-[#FFE4E1] text-16 rounded-6 font-600 p-16">
                    <div>
                      {findExpireDay(subscriptionData?.expireDate) > 0 && (
                        <div className="w-full flex justify-center">
                          <div className="w-384 sm:w-586 items-center flex">
                            <img
                              className="block rounded max-w-28 sm:max-w-32"
                              name="logo"
                              src="/assets/images/icon/reviewNotificationIcon.png"
                              alt="bell notification icon"
                            />
                            <p className="ml-10 mr-16 sm:ml-16 sm:mr-32 text-14 sm:text-16 ">
                              {t('quickHubDashboard.your')}{' '}
                              {subscriptionData?.comboDetails?.length > 0
                                ? 'Quick Chat'
                                : subscriptionData?.name}{' '}
                              {t('quickHubDashboard.freeTrialExpire')}{' '}
                              {findExpireDay(subscriptionData?.expireDate)}{' '}
                              {t('business.searchBusiness.labels.days')}.
                            </p>
                            <Button
                              className="text-12 md:text-14 font-semibold bg-[#D24811] text-white hover:bg-red-500 rounded-12"
                              variant="contained"
                              size="small"
                              type="submit"
                              onClick={() => {
                                history.push(
                                  params?.id ? `/selectProduct/${params.id}` : '/selectProduct/',
                                  {
                                    packageBundle: [9],
                                    intervalType: subscriptionData?.packageInterval,
                                    isBusinessAdded: true,
                                  }
                                );
                              }}
                            >
                              {t('quickHubDashboard.upgradeNow')}
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
            )}

        <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-x-0 sm:gap-x-32 gap-y-28">
          <div className="col-span-2">
            <div className="flex flex-col items-center gap-32">
              {appSetupCard?.data?.filter((item) => !item?.isAdded)?.length > 0 && (
                <div className="p-10 sm:p-16 md:px-0 w-full flex gap-10 md:gap-0 flex-col md:flex-row justify-center rounded-md bg-quick-chat">
                  <div className="w-full md:w-1/6 flex flex-col justify-center">
                    <div className="w-full flex flex-col gap-5 items-start">
                      <div className="mx-auto bg-white rounded-full flex items-center justify-center w-60 h-60 lg:w-88 lg:h-88">
                        <h1 className="text-quick-chat text-48 sm:text-64 font-500">
                          {appSetupCard?.data?.filter((item) => !item?.isAdded)?.length}
                        </h1>
                      </div>
                      <span className="mx-auto text-16 lg:text-20 font-bold text-white">
                        {t('quickHubDashboard.pendingTask')}
                      </span>
                    </div>
                  </div>
                  <div className="w-full md:w-5/6 mr-0 md:mr-16">
                    <div className=" bg-white rounded-md p-10 sm:p-20">
                      <div className="flex items-center mb-10 justify-between">
                        <p className="text-14 sm:text-16 font-semibold">
                          {t('quickHubDashboard.nextTask')}
                        </p>
                        <Button
                          className="text-quick-chat h-0 font-semibold cursor-pointer hover:bg-transparent text-14"
                          size="small"
                          onClick={() => redirect('pending-task', 'quick_chat')}
                        >
                          {t('businessDashboard.seeAll')}
                        </Button>
                      </div>
                      <div className="border-1 border-solid !border-quick-chat w-full rounded-md p-6 sm:p-10">
                        <Slider {...settings}>
                          {appSetupCard?.data
                            ?.filter((task) => !task?.isAdded)
                            .map((item) => (
                              <div className="flex">
                                <div className="flex flex-col gap-10 sm:gap-0 sm:flex-row justify-between items-center">
                                  <p className="text-14 sm:text-16 font-medium">{item?.title}</p>
                                  <div className="flex gap-14 items-center">
                                    {item?.videoUrl && (
                                      <Button
                                        className="text-12 sm:text-16 font-medium !border-quick-chat disabled:bg-gray text-quick-chat disabled:text-black rounded-md"
                                        variant="outlined"
                                        color="quickChat"
                                        type="button"
                                        size="small"
                                        onClick={() => openVideoUrl(item?.videoUrl)}
                                      >
                                        <img
                                          src="/assets/images/icon/playVideoIcon-chat.svg"
                                          alt="good"
                                          className="max-w-60 sm:max-w-80 mr-10"
                                        />
                                        {t('quickHubDashboard.watchVideo')}
                                      </Button>
                                    )}
                                    {item?.buttonColor && item?.backgroundColor && (
                                      <Button
                                        style={{
                                          backgroundColor: item?.buttonColor,
                                        }}
                                        className="text-12 sm:text-16 font-medium disabled:bg-gray disabled:text-black rounded-md"
                                        variant="contained"
                                        color="quickChat"
                                        type="button"
                                        size="small"
                                        onClick={() => {
                                          if (item?.cardName === 'testYourChatBots') {
                                            window.open(botLink, '_blank');
                                            handleRedirectToChatBot(item?.id, item?.cardName);
                                          } else if (
                                            item?.cardName === 'configureTheBotResponses'
                                          ) {
                                            if (params?.id) {
                                              history.push(
                                                `/quick-chat/${item?.portalURL}/${params.id}`,
                                                {
                                                  item,
                                                }
                                              );
                                            } else {
                                              history.push(`/quick-chat/${item?.portalURL}`, {
                                                item,
                                              });
                                            }
                                          } else {
                                            history.push({
                                              pathname: `quick-chat/${item?.portalURL}/${
                                                params?.id ? params.id : ''
                                              }`,
                                            });
                                          }
                                        }}
                                      >
                                        {item?.buttonAction}
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {quickChatCountAndInsightsMatrix &&
                Object?.keys(quickChatCountAndInsightsMatrix)?.length > 0 && (
                  <div className="p-10 sm:p-16 w-full rounded-md bg-white">
                    <div className="flex flex-col gap-14">
                      <div className="w-full flex items-center justify-between">
                        <h1 className="text-20 md:text-24 font-bold">
                          {t('quickHubDashboard.matrices')}
                        </h1>
                        <Button
                          className="text-quick-review h-0 font-semibold cursor-pointer hover:bg-transparent text-16 md:text-20"
                          size="small"
                          onClick={() => redirect('matrices')}
                        >
                          {t('dashboard.dashboardSales.viewAll')}
                        </Button>
                      </div>
                      <div className="flex md:flex-row flex-col gap-20">
                        {quickChatCountAndInsightsMatrix
                          ?.filter((item) => item?.name !== `Total Query`)
                          ?.sort((a, b) => {
                            const order = {
                              "Today's Conversations": 1,
                              'Total Conversations': 2,
                              'Success Rate': 3,
                            };
                            return order[a?.name] - order[b?.name];
                          })
                          ?.map((itemData) => {
                            let imageSrc = '';
                            if (itemData?.name === "Today's Conversations") {
                              imageSrc = '/assets/images/icon/todayConversation.svg';
                            } else if (itemData?.name === 'Total Conversations') {
                              imageSrc = '/assets/images/icon/totalConversations.svg';
                            } else if (itemData?.name === 'Success Rate') {
                              imageSrc = '/assets/images/icon/successRate.svg';
                            }

                            return (
                              <div
                                key={itemData?.name}
                                className="w-full md:w-360 p-14 flex rounded-6 items-center border-2 border-grey-300 h-144"
                              >
                                <div className="rounded-full mr-10 flex items-center justify-center">
                                  <img
                                    src={imageSrc}
                                    alt={itemData?.name}
                                    className="w-60 h-60 sm:w-80 sm:h-80"
                                  />
                                </div>
                                <div>
                                  <h1 className="text-28 sm:text-36 font-semibold">
                                    {itemData?.name === 'Success Rate'
                                      ? `${itemData?.count}%`
                                      : itemData?.count}
                                  </h1>
                                  <p className="text-14 sm:text-16 font-medium">{itemData?.name}</p>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                )}
              <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-28 w-full">
                {!userCurrentBusinessDetails?.isOnlineBusiness && (
                  <div className="bg-white rounded-md p-10 sm:p-20 w-full">
                    <p className="text-16 font-bold">{t('quickChat.chatbotDetail.webchatBot')}</p>
                    <div className="bg-white min-h-320 max-h-512 relative mt-16 w-full">
                      {getChatRoom?.length > 0 ? (
                        <>
                          {getChatRoom?.slice(0, 5).map((contact, index) => (
                            <div
                              key={index}
                              className={`mb-10 cursor-pointer hover:bg-quick-chat-100 border-1 border-solid  rounded-md p-10 ${
                                selectedContact?.id === contact?.id
                                  ? 'bg-quick-chat-100 border-quick-chat-100 border-l-3 border-l-solid border-l-quick-chat'
                                  : 'bg-white border-grey-300 pl-12'
                              }`}
                              aria-hidden
                              onClick={() => handleContactClick(contact)}
                            >
                              <div className="flex items-center">
                                <img
                                  src="/assets/images/business/profile.png"
                                  className="img-fluid w-full max-w-48 mr-10"
                                  alt="..."
                                />

                                <div className="overflow-hidden w-full">
                                  <div className="flex items-center justify-between">
                                    <h3 className="text-16 font-semibold w-full overflow-hidden whitespace-nowrap text-ellipsis">
                                      {contact?.ipAddress}
                                    </h3>
                                    <span className="text-12 font-semibold w-68 text-right">
                                      {formatTimestamp(contact?.quickChatRoomMessages?.uts, false)}
                                    </span>
                                  </div>
                                  <div className="flex items-center justify-between">
                                    <span
                                      className="font-medium text-14 whitespace-nowrap overflow-hidden text-ellipsis mt-4 mb-4 block text-grey-600"
                                      dangerouslySetInnerHTML={{
                                        __html: contact?.quickChatRoomMessages?.message,
                                      }}
                                    />

                                    {contact?.status !== 'close' && (
                                      <span
                                        className={`text-12 font-semibold w-68 text-right ${
                                          contact?.status === 'live' ? 'text-grey-800' : 'text-red'
                                        }`}
                                      >
                                        <span
                                          className={`inline-block w-7 h-7 rounded-full mr-3 ${
                                            contact?.status === 'live' ? 'bg-green' : 'bg-red'
                                          }`}
                                        />
                                        <span>{contact?.status}</span>
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="w-full flex justify-center items-center">
                            <Button
                              className="h-20 font-semibold cursor-pointer hover:bg-transparent text-16 md:text-20"
                              size="small"
                              onClick={() => redirect('web-chatBot')}
                            >
                              <p className="text-quick-chat text-center font-600 text-16 sm:text-20">
                                {t('quickChat.chatbotDetail.seeAllChats')}
                              </p>
                              <ChevronRightIcon className="text-quick-chat" />
                            </Button>
                          </div>
                        </>
                      ) : (
                        <div className="w-full h-400 flex gap-20 flex-col px-10 sm:px-40 justify-center items-center">
                          <img
                            src="/assets/images/icon/noConversationsAvailableImage.svg"
                            className="img-fluid"
                            alt="..."
                          />
                          <Typography className="text-center text-20 font-600">
                            {t('quickChat.createBot.noConversationsAvailableTitle')}
                          </Typography>
                          <Typography className="text-center text-16 font-500">
                            {t('quickChat.createBot.noConversationsAvailableDesc')}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="bg-white rounded-md p-10 sm:p-20 w-full">
                  <p className="text-16 font-bold">{t('quickChat.chatbotDetail.whatsappBot')}</p>
                  <div className="bg-white min-h-320 max-h-512 relative mt-16 w-full">
                    {chatRoomWithLastMessage?.length > 0 ? (
                      <>
                        {chatRoomWithLastMessage?.slice(0, 5).map((contact, index) => (
                          <div
                            key={index}
                            className={`mb-10 cursor-pointer hover:bg-quick-chat-100 border-1 border-solid  rounded-md p-10 ${
                              selectedContact?.id === contact?.id
                                ? 'bg-quick-chat-100 border-quick-chat-100 border-l-3 border-l-solid border-l-quick-chat'
                                : 'bg-white border-grey-300 pl-12'
                            }`}
                            aria-hidden
                            onClick={() => handleContactClick(contact)}
                          >
                            <div className="flex items-center">
                              <img
                                src="/assets/images/business/profile.png"
                                className="img-fluid w-full max-w-48 mr-10"
                                alt="..."
                              />

                              <div className="overflow-hidden w-full">
                                <div className="flex items-center justify-between">
                                  <h3 className="text-16 font-semibold w-full overflow-hidden whitespace-nowrap text-ellipsis">
                                    {contact?.name}
                                  </h3>
                                  <span className="text-12 font-semibold w-68 text-right">
                                    {formatTimestamp(contact?.cts) ||
                                      formatTimestamp(Number(contact?.timestamp))}
                                  </span>
                                </div>
                                <div className="flex items-center justify-between">
                                  <span className="font-medium text-14 whitespace-nowrap overflow-hidden text-ellipsis mt-4 mb-4 block text-grey-600">
                                    {contact?.message}
                                  </span>
                                  <span
                                    className={`text-12 font-semibold w-68 text-right ${
                                      contact?.status === 'sent' ||
                                      contact?.status === 'accepted' ||
                                      contact?.status === 'delivered'
                                        ? 'text-green'
                                        : contact?.status === 'read'
                                        ? 'text-grey-600'
                                        : 'text-red'
                                    }`}
                                  >
                                    <span
                                      className={`inline-block w-7 h-7 rounded-full mr-3 ${
                                        contact?.status === 'sent' ||
                                        contact?.status === 'accepted' ||
                                        contact?.status === 'delivered'
                                          ? 'bg-green'
                                          : contact?.status === 'read'
                                          ? 'bg-grey-600'
                                          : 'bg-red'
                                      }`}
                                    />
                                    <span>{contact?.status}</span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="w-full flex justify-center items-center">
                          <Button
                            className="h-20 font-semibold cursor-pointer hover:bg-transparent text-16 md:text-20"
                            size="small"
                            onClick={() => redirect('whatsapp-bot')}
                          >
                            <p className="text-quick-chat text-center font-600 text-16 sm:text-20">
                              {t('quickChat.chatbotDetail.seeAllChats')}
                            </p>
                            <ChevronRightIcon className="text-quick-chat" />
                          </Button>
                        </div>
                      </>
                    ) : (
                      <div className="w-full h-400 flex gap-20 flex-col  sm:px-40 justify-center items-center">
                        <img
                          src="/assets/images/icon/noConversationsAvailableImage.svg"
                          className="img-fluid"
                          alt="..."
                        />
                        <Typography className="text-center text-20 font-600">
                          {t('quickChat.createBot.noConversationsAvailableTitle')}
                        </Typography>
                        <Typography className="text-center text-16 font-500">
                          {t('quickChat.createBot.noConversationsAvailableDesc')}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-32">
            <DashboardAlerts />
            <BotStatus
              quickChatActiveData={quickChatActiveData}
              setQuickChatActiveData={setQuickChatActiveData}
            />
          </div>
        </div>
        {shareCompOpen && (
          <SharePost image={botLink} open={shareCompOpen} onClose={handleCloseComp} />
        )}
      </div>
    </div>
  );
}
export default Dashboards;
