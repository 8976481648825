import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon, Tooltip, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import { selectDashboardData } from 'src/app/store/userSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import './style.css';
import SocialPlatformTab from './socialPlatformTab';
import { handleApiRequest } from '../../common/common';
import metricsData from '../../query/metrics';
import quickSocialQuery from '../../query/quickSocial';

function Analytics() {
  const { t } = useTranslation();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [loading, setLoading] = useState(false);
  const [brandHealth, setBrandHealth] = useState([]);
  const [engagement, setEngagement] = useState([]);
  const [followersGrowth, setFollowersGrowth] = useState({});
  const [metricsIconInfo, setMetricsIconInfo] = useState(null);
  const [activeStep, setActiveStep] = useState();

  useEffect(() => {
    try {
      const fetchData = async () => {
        if (activeStep) {
          const payload = {
            query: quickSocialQuery.getQuickSocialBrandHealthMatrices,
            variables: {
              userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
              mediaChannelId: activeStep,
            },
          };
          const result = await handleApiRequest(payload);
          if (result?.getQuickSocialBrandHealthMatrices?.status === 200) {
            if (result?.getQuickSocialBrandHealthMatrices?.data?.brandHealthData) {
              setBrandHealth(
                JSON.parse(result?.getQuickSocialBrandHealthMatrices?.data?.brandHealthData)
              );
            }
          }
          const payloadEngagement = {
            query: quickSocialQuery.getQuickSocialEngagementMetrics,
            variables: {
              userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
              mediaChannelId: activeStep,
            },
          };
          const resultEngagement = await handleApiRequest(payloadEngagement);
          if (resultEngagement?.getQuickSocialEngagementMetrics?.status === 200) {
            if (resultEngagement?.getQuickSocialEngagementMetrics?.data?.engagementData) {
              setEngagement(
                JSON.parse(resultEngagement?.getQuickSocialEngagementMetrics?.data?.engagementData)
              );
            }
          }
          const payloadMatrices = {
            query: quickSocialQuery.getQuickSocialMatrices,
            variables: {
              userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
              mediaChannelId: activeStep,
            },
          };
          const resultMatrices = await handleApiRequest(payloadMatrices);
          if (resultMatrices?.getQuickSocialMatrices?.status === 200) {
            if (resultMatrices?.getQuickSocialMatrices?.data) {
              setFollowersGrowth(resultMatrices?.getQuickSocialMatrices?.data);
            }
          }
        }
      };
      fetchData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails?.id, activeStep]);

  useEffect(() => {
    try {
      const getMetricsInfoIconData = async () => {
        const payload = {
          query: metricsData.getMetricsInfoIconData,
        };
        const res = await handleApiRequest(payload);
        if (res?.getMetricsInfoIconData?.status === 200) {
          setMetricsIconInfo(res?.getMetricsInfoIconData?.data?.quick_social_info ?? null);
        }
      };
      getMetricsInfoIconData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, []);

  if (loading) {
    return <FuseLoading />;
  }

  const getChartSimple = (data, categories, index, color, types) => {
    const seriesData = {
      likes: [],
      comments: [],
      shares: [],
      engagement: [],
    };

    data.forEach((period) => {
      const likesValue = period.find((p) => p?.key === 'likes')?.value || 0;
      const commentsValue = period.find((p) => p?.key === 'comments')?.value || 0;
      const sharesValue = period.find((p) => p?.key === 'shares')?.value || 0;
      const engagementValue = period.find((p) => p?.key === 'engagement')?.value || 0;

      seriesData?.likes.push(likesValue);
      seriesData?.comments.push(commentsValue);
      seriesData?.shares.push(sharesValue);
      seriesData?.engagement.push(engagementValue);
    });

    const reversedCategories = categories
      ?.map((category) => moment.unix(category).format('MMM DD, YYYY'))
      .reverse();
    const reversedLikes = seriesData?.likes?.reverse();
    const reversedComments = seriesData?.comments?.reverse();
    const reversedShares = seriesData?.shares?.reverse();
    const reversedEngagement = seriesData?.engagement?.reverse();

    const chart = {
      series: [
        {
          name: 'Likes',
          data: reversedLikes,
        },
        {
          name: 'Comments',
          data: reversedComments,
        },
        {
          name: 'Shares',
          data: reversedShares,
        },
        {
          name: 'Engagement',
          data: reversedEngagement,
        },
      ],
      options: {
        grid: {
          strokeDashArray: 3,
          show: true,
        },
        chart: {
          events: {
            mounted: (charts) => {
              charts.windowResizeHandler();
            },
          },
          type: types,
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            columnWidth: '30px',
          },
        },
        legend: {
          onItemClick: {
            toggleDataSeries: false,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            radius: 12,
            shape: 'circle',
          },
        },
        colors: color,
        fill: {
          colors: color,
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          axisTicks: {
            show: false,
          },
          categories: reversedCategories,
          labels: {
            offsetY: 0,
          },
        },
        yaxis: {
          labels: {
            align: 'left',
            maxWidth: 350,
            position: 'left',
            offsetX: -15,
            formatter(val) {
              return val;
            },
            style: {
              fontSize: '14px',
            },
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: (val) => val,
          },
        },
      },
    };

    return (
      <div id={`chart${index}`} className="flex flex-col flex-auto w-full">
        <ReactApexChart
          options={chart.options}
          series={chart.series}
          type={chart.options.chart.type}
          height="300px"
          width="100%"
        />
      </div>
    );
  };

  const getChartArea = (data, categories, i, color, types) => {
    const seriesData = data?.map((item) => {
      const timeStamp = parseInt(item?.find((p) => p?.key === 'timeStamp')?.value, 10);
      const count = parseInt(item?.find((p) => p?.key === 'count')?.value, 10) || 0;

      return {
        x: moment.unix(timeStamp).format('MMM DD, YYYY'),
        y: count,
        originalTimestamp: timeStamp,
      };
    });

    const reversedSeriesData = seriesData?.reverse();
    const reversedCategories = categories
      ?.map((category) => moment.unix(category).format('MMM DD, YYYY'))
      ?.reverse();

    const chart = {
      series: [
        {
          name: '',
          data: reversedSeriesData,
        },
      ],
      options: {
        grid: {
          strokeDashArray: 3,
          show: true,
        },
        chart: {
          events: {
            mounted: (charts) => {
              charts.windowResizeHandler();
            },
          },
          type: types,
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            columnWidth: '30px',
          },
        },
        legend: {
          onItemClick: {
            toggleDataSeries: false,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            radius: 12,
            shape: 'circle',
          },
        },
        colors: color,
        fill: {
          colors: color,
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          axisTicks: {
            show: false,
          },
          categories: reversedCategories,
          labels: {
            offsetY: 0,
            formatter: (val, index) => {
              if (index === 0 || index === categories.length - 1) {
                return val;
              }
              return '';
            },
            style: {
              fontSize: '14px',
            },
          },
        },
        yaxis: {
          labels: {
            show: false,
            align: 'left',
            maxWidth: 350,
            position: 'left',
            offsetX: -15,
            formatter(val) {
              return val;
            },
            style: {
              fontSize: '14px',
            },
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: (val, { seriesIndex, dataPointIndex }) => {
              const date = seriesData[dataPointIndex]?.originalTimestamp;
              return `Date: ${moment.unix(date).format('MMM DD, YYYY')}<br>Count: ${val}`;
            },
          },
        },
      },
    };

    return (
      <div id={`chart${i}`} className="flex flex-col flex-auto w-full">
        <ReactApexChart
          options={chart.options}
          series={chart.series}
          type={chart.options.chart.type}
          height="300px"
          width="100%"
        />
      </div>
    );
  };

  return (
    <div className="p-20 lg:p-32 h-full l-padding">
      <div className="flex w-full items-center justify-between mb-24">
        <div className="items-center justify-between">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-20 md:text-28 font-bold"
          >
            {t('quickSocial.analytics')}
          </Typography>
        </div>
      </div>
      <SocialPlatformTab
        onSelectTab={(step) => {
          setActiveStep(step);
        }}
      />
      {brandHealth && brandHealth?.length > 0 && (
        <>
          <div className="w-full flex items-center justify-between">
            <h1 className="text-20 md:text-24 font-bold mb-16">{t('quickSocial.brandHealth')}</h1>
          </div>
          <ul className="grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-x-24 mb-24 md-gap-y-0 gap-y-16">
            {brandHealth?.map((item, index) => (
              <li className="bg-white rounded-md md:p-32 p-16" key={index}>
                <div className="flex items-center">
                  <img
                    className="mr-16 md:w-auto md:h-auto w-32 h-32"
                    src={
                      item.key === 'Total Followers'
                        ? 'assets/images/quick-hub/quick-social/followers.png'
                        : item.key === 'Total Posts'
                        ? 'assets/images/quick-hub/quick-social/posts.png'
                        : item.key === 'Post Reach'
                        ? 'assets/images/quick-hub/quick-social/reach.png'
                        : 'assets/images/quick-hub/quick-social/engagement.png'
                    }
                    alt={item?.key}
                  />
                  <div>
                    <span className="md:text-32 text-20 font-bold block md:mb-8">
                      {item?.value}
                    </span>
                    <span className="md:text-20 text-16 font-bold block">{item?.key}</span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
      <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-x-24 gap-y-16 sm:gap-y-0">
        {followersGrowth?.followerGrowthData && followersGrowth?.followerGrowthData?.length > 0 && (
          <div className="bg-white rounded-md p-16">
            <div className="w-full flex items-center justify-between">
              <h1 className="text-20 md:text-24 font-bold mb-16">
                {t('quickSocial.followersGrowth')}{' '}
                <Tooltip
                  title={metricsIconInfo?.followersGrowth}
                  arrow
                  classes={{
                    tooltip: 'bg-quick-chat',
                    arrow: 'text-quick-chat',
                  }}
                >
                  <Icon className="text-quick-chat text-20 align-middle ml-4">info</Icon>
                </Tooltip>
              </h1>
            </div>
            <div>
              <>
                <>
                  <div>
                    {getChartArea(
                      followersGrowth?.followerGrowthData,
                      followersGrowth?.followerGrowthData?.map(
                        (period) => period?.find((p) => p?.key === 'timeStamp')?.value
                      ),
                      6,
                      ['#0BA861'],
                      'area'
                    )}
                  </div>

                  <div>
                    {followersGrowth?.summary && followersGrowth?.summary?.length > 0 && (
                      <div className="bg-gray-A500 p-12 rounded-md flex items-center">
                        <img
                          className="mr-16 w-52 h-52"
                          src="assets/images/quick-hub/quick-social/followers.png"
                          alt="..."
                        />
                        <div>
                          <span className="block text-20 font-semibold">
                            {
                              followersGrowth?.summary?.find((sum) => sum?.key === 'difference')
                                ?.value
                            }{' '}
                            (
                            {
                              followersGrowth?.summary?.find((sum) => sum?.key === 'growthRate')
                                ?.value
                            }
                            )
                          </span>
                          <span className="block text-16 font-medium">
                            {followersGrowth?.summary?.find((sum) => sum?.key === 'text')?.value}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              </>
              {/* )} */}
            </div>
          </div>
        )}
        {engagement && engagement?.length > 0 && (
          <div className="bg-white rounded-md p-16">
            <div className="w-full flex items-center justify-between">
              <h1 className="text-20 md:text-24 font-bold mb-16">
                {t('quickSocial.engagement')}
                <Tooltip
                  title={metricsIconInfo?.engagement}
                  arrow
                  classes={{
                    tooltip: 'bg-quick-chat',
                    arrow: 'text-quick-chat',
                  }}
                >
                  <Icon className="text-quick-chat text-20 align-middle ml-4">info</Icon>
                </Tooltip>
              </h1>
            </div>
            <div>
              <>
                <>
                  <div>
                    {getChartSimple(
                      engagement,
                      engagement?.map(
                        (period) => period?.find((p) => p?.key === 'timeStamp')?.value
                      ),
                      6,
                      ['#46FF6B', '#F7E438', '#2E56F8', '#34D1F9'],
                      'bar'
                    )}
                  </div>
                </>
              </>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default Analytics;
