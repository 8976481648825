import { Button } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { selectDashboardData } from 'app/store/userSlice';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import ConformationDialogBox from 'src/app/main/common/ConformationDialogBox';
import { useSelector } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import quickSocialQuery from '../../query/quickSocial';
import { handleApiRequest } from '../../common/common';
import businessData from '../../query/business';

const Scheduled = () => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [openDialogueForDelete, setOpenDialogueForDelete] = useState(false);
  const [scheduledData, setScheduledData] = useState([]);

  const getScheduledData = useCallback(async () => {
    const payloadData = {
      query: quickSocialQuery.getQuickSocialSchedulePost,
      variables: {
        userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
      },
    };
    const resultData = await handleApiRequest(payloadData);
    if (resultData?.getQuickSocialSchedulePost?.status === 200) {
      const data = resultData?.getQuickSocialSchedulePost?.data;
      if (Array.isArray(data)) {
        setScheduledData(data);
      } else {
        setScheduledData([]);
      }
    }
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      getScheduledData();
    }
  }, [getScheduledData, userCurrentBusinessDetails?.id]);

  const deleteScheduledPost = useCallback(
    async (id) => {
      setLoading(true);
      const payloadData = {
        query: businessData.deleteQuickSocialSchedulePost,
        variables: {
          schedulePostId: id,
        },
      };
      const resultData = await handleApiRequest(payloadData);
      setLoading(false);
      if (resultData?.deleteQuickSocialSchedulePost?.status === 200) {
        enqueueSnackbar(resultData?.deleteQuickSocialSchedulePost?.message, {
          variant: 'success',
          autoHideDuration: 2000,
        });
        getScheduledData();
        setOpenDialogueForDelete(false);
      } else {
        enqueueSnackbar(resultData?.deleteQuickSocialSchedulePost?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    },
    [enqueueSnackbar, getScheduledData, setLoading]
  );

  const handleCloseDeleteDialog = () => setOpenDialogueForDelete(false);

  const formatTimeFromDate = (dateString) => {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${hours}:${formattedMinutes} ${ampm}`;
  };

  if (loading) {
    <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-32">
      {scheduledData?.length > 0 && (
        <div className="max-w-3xl">
          <p className="text-16 font-600">Scheduled</p>
          {scheduledData?.map((item) => (
            <div className="flex flex-col gap-20 bg-white p-10 mt-10 sm:p-16">
              <div className="flex justify-between items-center">
                <div className="flex gap-10 flex-row items-center">
                  <img
                    src={userCurrentBusinessDetails?.profileURL}
                    alt="Preview"
                    className="md:w-52 md:h-52 w-40 h-40 object-cover rounded-full"
                  />
                  <p className="text-14 sm:text-16 font-600 text-grey-600">
                    {userCurrentBusinessDetails?.name}
                  </p>
                </div>
                <div className="flex gap-10">
                  <Button className="!min-w-0 bg-indigo-50 bg-opacity-30">
                    <ModeEditIcon className="text-20 sm:text-24 text-quick-social" />
                  </Button>
                  <Button
                    className="!min-w-0 bg-indigo-50 bg-opacity-30"
                    onClick={() => {
                      setOpenDialogueForDelete(true);
                      setSelectedContactId(item?.id);
                    }}
                  >
                    <DeleteOutlineIcon className="text-20 sm:text-24 text-quick-social" />
                  </Button>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="max-w-320">
                  <p>{item?.postData?.caption}</p>
                </div>

                <img
                  src={item?.postData?.imageUrl}
                  alt="Preview"
                  className="sm:w-92 w-76 h-76 sm:h-92"
                />
              </div>
              <div className="border-1 flex items-center w-full p-10 rounded-md border-grey-200">
                <div className="w-full sm:w-1/3 flex items-center gap-10">
                  <img
                    src="/assets/images/icon/DateIcon.svg"
                    alt="Preview"
                    className="sm:w-32 sm:h-32 w-24 h-24 object-cover rounded"
                  />
                  <p className="text-12 sm:text-14 font-500">
                    {new Date(item?.scheduledDate).toLocaleDateString('en-US', {
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                  </p>
                </div>
                <div className="w-full sm:w-2/3">
                  <p className="text-12 sm:text-14 font-500">
                    {formatTimeFromDate(item?.scheduledDate)}
                  </p>
                </div>
              </div>
              <div className="flex flex-row gap-10">
                {item?.sharedChannels?.length > 0 &&
                  item?.sharedChannels?.map((sharedData, sharedIndex) => (
                    <div key={sharedIndex}>
                      <img
                        src={sharedData?.logo}
                        alt="Preview"
                        className="sm:w-32 sm:h-32 w-24 h-24 object-cover rounded-full"
                      />
                    </div>
                  ))}
              </div>
            </div>
          ))}
          <ConformationDialogBox
            open={openDialogueForDelete}
            onClose={handleCloseDeleteDialog}
            onDelete={() => deleteScheduledPost(selectedContactId)}
            confirmationText="Are You Sure?"
            additionalInfo={t('quickSocial.conformationMsg')}
            deleteImage="/assets/images/icon/DeleteClient.svg"
            backgroundColor="red"
            textColor="red"
            buttonText={t('manageBusiness.button.delete')}
          />
        </div>
      )}
    </div>
  );
};

export default Scheduled;
