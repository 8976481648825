import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Slider from 'react-slick';
import moment from 'moment';
import './Dashboards.css';
import { getAllUserBusinessSubscription, selectDashboardData } from 'src/app/store/userSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import { useParams } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import history from '../../../../../@history/@history';
import { handleApiRequest, findExpireDay } from '../../../common/common';
import DashboardAlerts from './DashboardAlerts';
import BusinessPlateForm from './BusinessPlatForm';
import metricsData from '../../../query/metrics';
import ReviewDistribution from '../../matrices/reviewDistribution';
import ReceivedReviews from '../../matrices/receivedReviews';
import quickHub from '../../../query/quickHub';
import businessData from '../../../query/business';
import RepliedVsNotReplied from '../../matrices/RepliedVsNotReplied';
import TextVsNoText from '../../matrices/TextVsNoText';

function Dashboards() {
  const { t } = useTranslation();
  const params = useParams();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const [selectPlateForm, setSelectPlateForm] = useState();
  const [reviewPlatformInfos, setReviewPlatformInfos] = useState({});
  const [dateDetails, setDateDetails] = useState([]);
  const [reviewPlaceId, setReviewPlaceId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [dashboardData, setDashboardData] = useState({
    reviewSentimentPeriodsData: [],
    overallRatingAndReview: {},
    appSetupCard: [],
    metricsInfoIconData: null,
  });
  const isSingleItem = dashboardData?.appSetupCard?.length === 1;
  const redirect = (type, platformType) => {
    try {
      if (type === 'matrices') {
        if (params?.id) {
          history.push(`/matrices/${params?.id}`);
        } else {
          history.push(`/matrices/`);
        }
      }
      if (type === 'pending-task') {
        if (params?.id) {
          history.push(`/pending-task/${params?.id}`, { platformType });
        } else {
          history.push(`/pending-task/`, { platformType });
        }
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const settings = {
    dots: false,
    infinite: isSingleItem,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const fetchDashboardData = async (id) => {
    const fetchPayload = (query, variables) => ({
      query,
      variables: { ...variables },
    });

    const requests = [
      handleApiRequest(fetchPayload(metricsData.getReviewSentimentPeriodsData)).catch((err) => ({
        error: err,
      })),
      handleApiRequest(fetchPayload(quickHub.getOverallRatingAndReview, { businessId: id })).catch(
        (err) => ({ error: err })
      ),
      handleApiRequest(
        fetchPayload(businessData.getAppSetupCard, {
          businessId: parseInt(id, 10),
          type: 'quick_reviews',
        })
      ).catch((err) => ({ error: err })),
      handleApiRequest(fetchPayload(metricsData.getMetricsInfoIconData)).catch((err) => ({
        error: err,
      })),
    ];

    try {
      setLoading(true);
      const results = await Promise.all(requests);

      const [
        reviewSentimentPeriodsData,
        overallRatingAndReview,
        appSetupCard,
        metricsInfoIconData,
      ] = results.map((result) => (result.error ? null : result));

      setDashboardData({
        reviewSentimentPeriodsData:
          reviewSentimentPeriodsData?.getReviewSentimentPeriodsData?.data || [],
        overallRatingAndReview: overallRatingAndReview?.getOverallRatingAndReview?.data || {},
        appSetupCard: appSetupCard?.getAppSetupCard?.data || [],
        metricsInfoIconData: metricsInfoIconData?.getMetricsInfoIconData?.data?.info || null,
      });
    } catch (error) {
      console.error('Error fetching dashboard data', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      fetchDashboardData(userCurrentBusinessDetails?.id);
    }
  }, [userCurrentBusinessDetails?.id]);
  const { reviewSentimentPeriodsData, overallRatingAndReview, appSetupCard, metricsInfoIconData } =
    dashboardData;

  useEffect(() => {
    if (!selectPlateForm) {
      const reviewPlatformInfo = userCurrentBusinessDetails?.reviewplatform?.find((item) =>
        userCurrentBusinessDetails?.isOnlineBusiness
          ? item?.name === 'website'
          : item.defaultReview === true
      );
      setSelectPlateForm(reviewPlatformInfo?.reviewPlatformId);
      setReviewPlaceId(reviewPlatformInfo?.placeId);
    }
  }, [selectPlateForm, userCurrentBusinessDetails]);

  useEffect(() => {
    setLoading(true);
    if (!userCurrentBusinessDetails?.isOnlineBusiness) {
      if (!selectPlateForm) {
        const reviewPlatformValue = userCurrentBusinessDetails?.reviewplatform?.find(
          (item) => item.defaultReview === true
        );
        setReviewPlatformInfos(reviewPlatformValue);
        setSelectPlateForm(reviewPlatformValue?.reviewPlatformId);
        setReviewPlaceId(reviewPlatformValue?.placeId);
      } else {
        const reviewPlatformValue = userCurrentBusinessDetails?.reviewplatform?.find(
          (item) => item.reviewPlatformId === selectPlateForm
        );
        setReviewPlatformInfos(reviewPlatformValue);
        setReviewPlaceId(reviewPlatformValue?.placeId);
      }
      setLoading(false);
    }
  }, [
    selectPlateForm,
    userCurrentBusinessDetails,
    reviewPlatformInfos,
    userCurrentBusinessDetails?.isOnlineBusiness,
  ]);

  function handlePlatformChange(event) {
    const reviewPlatformInfo = userCurrentBusinessDetails?.reviewplatform?.find(
      (item) => item.reviewPlatformId === event?.target?.value
    );
    setSelectPlateForm(event?.target?.value);
    setReviewPlaceId(reviewPlatformInfo?.placeId);
  }

  const openVideoUrl = (url) => {
    if (url) {
      window.open(url, '_blank');
    } else {
      console.error('No URL provided');
    }
  };

  const formatNumber = (num) => {
    if (typeof num !== 'number') {
      return 0;
    }
    if (num >= 1000) {
      return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}k`;
    }
    if (num % 1 !== 0) {
      return parseFloat(num.toFixed(2)).toString();
    }
    return num.toString();
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-32 h-full l-padding">
      <div className="flex flex-col gap-32">
        {userSubscriptionData &&
          userSubscriptionData
            .filter((subscriptionData) =>
              subscriptionData?.comboDetails?.length > 0
                ? subscriptionData.comboDetails.some((item) => item?.name === 'Quick Reviews')
                : subscriptionData?.name === 'Quick Reviews'
            )
            .map(
              (subscriptionData, i) =>
                subscriptionData?.userBusinessId === userCurrentBusinessDetails?.id &&
                subscriptionData?.isFreeTrial === true && (
                  <div key={i} className="bg-[#FFE4E1] text-16 rounded-6 font-600 p-16">
                    <div>
                      {findExpireDay(subscriptionData?.expireDate) > 0 && (
                        <div className="w-full flex justify-center">
                          <div className="w-384 sm:w-586 items-center flex">
                            <img
                              className="block rounded max-w-28 sm:max-w-32"
                              name="logo"
                              src="/assets/images/icon/reviewNotificationIcon.png"
                              alt="bell notification icon"
                            />
                            <p className="ml-10 mr-16 sm:ml-16 sm:mr-32 text-14 sm:text-16 ">
                              {t('quickHubDashboard.your')}
                              {subscriptionData?.comboDetails?.length > 0
                                ? 'Quick Reviews'
                                : subscriptionData?.name}{' '}
                              {t('quickHubDashboard.freeTrialExpire')}{' '}
                              {findExpireDay(subscriptionData?.expireDate)}{' '}
                              {t('business.searchBusiness.labels.days')}.
                            </p>
                            <Button
                              className="text-12 md:text-14 font-semibold bg-[#D24811] text-white hover:bg-red-500 rounded-12"
                              variant="contained"
                              size="small"
                              type="submit"
                              onClick={() => {
                                history.push(
                                  params?.id ? `/selectProduct/${params.id}` : '/selectProduct/',
                                  {
                                    packageBundle: [7],
                                    intervalType: subscriptionData?.packageInterval,
                                    isBusinessAdded: true,
                                  }
                                );
                              }}
                            >
                              {t('quickHubDashboard.upgradeNow')}
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
            )}
        <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-0 sm:gap-x-32 gap-y-28">
          <div className="col-span-2">
            <div className="flex flex-col mb-32 items-center gap-32">
              {appSetupCard?.filter((item) => !item?.isAdded)?.length > 0 && (
                <div className="p-10 w-full flex flex-col lg:flex-row justify-between gap-10 md:gap-6 rounded-md bg-quick-review">
                  <div className="w-full lg:w-200 flex flex-col justify-center">
                    <div className="w-full flex flex-col gap-6 items-center">
                      <div className="mx-auto bg-white rounded-full flex items-center justify-center w-48 h-48 sm:w-60 sm:h-60 lg:w-80 lg:h-80">
                        <h1 className="text-quick-review text-36 sm:text-48 lg:text-56 font-500">
                          {appSetupCard?.filter((item) => !item?.isAdded)?.length}
                        </h1>
                      </div>
                      <span className="text-14 sm:text-16 lg:text-16 text-center font-bold text-white">
                        {t('quickHubDashboard.pendingTask')}
                      </span>
                    </div>
                  </div>
                  <div className="w-full lg:w-10/12 flex flex-col">
                    <div className="bg-white rounded-md p-10 sm:p-20">
                      <div className="flex items-center justify-between mb-10">
                        <p className="text-14 sm:text-16 font-semibold">
                          {t('quickHubDashboard.nextTask')}
                        </p>
                        <Button
                          className="text-quick-review h-0 font-semibold cursor-pointer hover:bg-transparent text-14"
                          size="small"
                          onClick={() => redirect('pending-task', 'quick_reviews')}
                        >
                          {t('businessDashboard.seeAll')}
                        </Button>
                      </div>
                      <div className="border border-solid border-quick-review w-full rounded-md p-6 sm:p-10">
                        <Slider {...settings}>
                          {appSetupCard
                            ?.filter((item) => !item?.isAdded)
                            .map((task, i) => (
                              <div key={i} className="flex">
                                <div className="flex flex-col sm:flex-row justify-between items-center gap-10 sm:gap-0">
                                  <p className="text-14 sm:text-16 lg:text-14 xl:text-16 font-medium">
                                    {task?.title}
                                  </p>
                                  <div className="flex gap-10 items-center">
                                    {task?.videoUrl && (
                                      <Button
                                        className="text-12 sm:text-16 lg:text-12 xl:text-16 font-medium rounded-md"
                                        variant="outlined"
                                        color="quickReview"
                                        type="button"
                                        size="small"
                                        onClick={() => openVideoUrl(task?.videoUrl)}
                                      >
                                        <img
                                          src="/assets/images/icon/playVideoIcon-review.svg"
                                          alt="play"
                                          className="max-w-10 sm:max-w-14 lg:max-w-12 mr-2"
                                        />
                                        {t('quickHubDashboard.watchVideo')}
                                      </Button>
                                    )}
                                    {task?.buttonColor && task?.backgroundColor && (
                                      <Button
                                        style={{ backgroundColor: task?.buttonColor }}
                                        className="text-12 sm:text-16 lg:text-12 xl:text-16 font-medium disabled:bg-gray disabled:text-black rounded-md"
                                        variant="contained"
                                        color="quickReview"
                                        type="button"
                                        size="small"
                                        onClick={() =>
                                          history.push(
                                            `quick-reviews/${task?.portalURL}/${
                                              params?.id ? params.id : ''
                                            }`
                                          )
                                        }
                                      >
                                        {task?.buttonAction}
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {overallRatingAndReview && Object?.keys(overallRatingAndReview)?.length > 0 && (
                <div className="p-10 sm:p-16 w-full rounded-md bg-white">
                  <div className="flex flex-col gap-14">
                    <div className="w-full flex items-center justify-between">
                      <h1 className="text-20 md:text-24 font-bold">
                        {t('quickHubDashboard.matrices')}
                      </h1>
                      <Button
                        className="text-quick-review h-0 font-semibold cursor-pointer hover:bg-transparent text-16 md:text-20"
                        size="small"
                        onClick={() => redirect('matrices')}
                      >
                        {t('dashboard.dashboardSales.viewAll')}
                      </Button>
                    </div>
                    <div className="flex md:flex-row flex-col gap-20">
                      {[
                        {
                          title: overallRatingAndReview?.review,
                          text: overallRatingAndReview?.reviewText,
                          backgroundColor: overallRatingAndReview?.reviewBackgroundColor,
                          iconSrc: '/assets/images/icon/messageicon.svg',
                          altText: 'good',
                        },
                        {
                          title: overallRatingAndReview?.rating,
                          text: overallRatingAndReview?.ratingText,
                          backgroundColor: overallRatingAndReview?.ratingBackgroundColor,
                          iconSrc: '/assets/images/icon/star.svg',
                          altText: 'good',
                        },
                        {
                          title: overallRatingAndReview?.todayReviews,
                          text: overallRatingAndReview?.todaysReviewText,
                          backgroundColor: overallRatingAndReview?.todaysReviewBackgroundColor,
                          iconSrc: '/assets/images/icon/messageicon.svg',
                          altText: 'good',
                        },
                      ].map((item, index) => (
                        <div
                          key={index}
                          className="w-full md:w-360 p-14 flex rounded-12 items-center justify-between h-200"
                          style={{ backgroundColor: item.backgroundColor }}
                        >
                          <div>
                            <h1 className="text-28 md:text-28 lg:text-40 sm:text-40 font-semibold">
                              {formatNumber(item.title)}
                            </h1>
                            <p className="text-14 md:text-12 lg:text-16 sm:text-16 font-medium">
                              {item.text}
                            </p>
                          </div>
                          <div className="bg-white rounded-full flex items-center justify-center w-60 h-60 md:w-60 md:h-60 lg:w-80 lg:h-80 sm:w-80 sm:h-80">
                            <img
                              src={item.iconSrc}
                              alt={item.altText}
                              className="max-w-28 md:max-w-28 lg:max-w-48 sm:max-w-48"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-28">
              <div className="bg-white rounded-md relative shadow p-20 w-full">
                <div className="flex items-center mb-16">
                  <h3 className="text-18 font-bold items-center">
                    {t('metrics.receivedReview')}{' '}
                    {dateDetails?.[0]?.reviewPeriod &&
                    dateDetails?.[dateDetails.length - 1]?.reviewPeriod
                      ? `(${
                          moment.unix(dateDetails[0].reviewPeriod).format('ll').split(',')[0]
                        } - ${
                          moment
                            .unix(dateDetails[dateDetails.length - 1].reviewPeriod)
                            .format('ll')
                            .split(',')[0]
                        })`
                      : ''}
                  </h3>
                  <Tooltip
                    title={metricsInfoIconData?.receivedReview}
                    arrow
                    classes={{
                      tooltip: 'bg-darkgreen',
                      arrow: 'text-darkgreen',
                    }}
                  >
                    <img src="/assets/images/icon/tooltipicon.svg" alt="good" className="ml-6" />
                  </Tooltip>
                </div>
                <ReceivedReviews
                  reviewPlatformId={selectPlateForm}
                  reviewSentiment={reviewSentimentPeriodsData?.filter((item) => item?.id !== 7)}
                  useArea="dashboard"
                  setDateDetails={setDateDetails}
                />
              </div>
              <div className="bg-white rounded-md relative shadow p-20 w-full">
                <div className="flex items-center mb-16">
                  <h3 className="text-18 font-bold">{t('metrics.reviewDistribution')}</h3>
                  <Tooltip
                    title={metricsInfoIconData?.reviewDistribution}
                    arrow
                    classes={{
                      tooltip: 'bg-darkgreen',
                      arrow: 'text-darkgreen',
                    }}
                  >
                    <img src="/assets/images/icon/tooltipicon.svg" alt="good" className="ml-6" />
                  </Tooltip>
                </div>
                <ReviewDistribution
                  reviewPlatformId={selectPlateForm}
                  totalReview={
                    !userCurrentBusinessDetails?.isOnlineBusiness ||
                    parseInt(reviewPlatformInfos?.reviewPlatformId, 10) === 7
                      ? reviewPlatformInfos?.reviewPlatformId === 3
                        ? reviewPlatformInfos?.aggregateData?.aggregateTotalReview
                        : reviewPlatformInfos?.totalReview
                      : selectedProduct?.totalReviews
                  }
                  averageRating={
                    !userCurrentBusinessDetails?.isOnlineBusiness ||
                    parseInt(reviewPlatformInfos?.reviewPlatformId, 10) === 7
                      ? reviewPlatformInfos?.reviewPlatformId === 3
                        ? reviewPlatformInfos?.aggregateData?.aggregateAverageRating
                        : reviewPlatformInfos?.totalRatingStars
                      : selectedProduct?.rating
                  }
                  color={['#029C00', '#95B001', '#F59001', '#F45604', '#D12022']}
                  categories={['5 ★', '4 ★', '3 ★', '2 ★', '1 ★']}
                  useArea="dashboard"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-32 w-full items-center">
            <DashboardAlerts />
            <BusinessPlateForm />
            {parseInt(reviewPlatformInfos?.reviewPlatformId, 10) !== 8 && (
              <div className="bg-white rounded-md relative shadow p-10 sm:p-16 w-full">
                <div className="flex items-center mb-16">
                  <h3 className="text-16 font-bold">{t('metrics.repliedVsNoReplied')}</h3>
                  <Tooltip
                    title={metricsInfoIconData?.repliedVsNotReplied}
                    arrow
                    classes={{
                      tooltip: 'bg-darkgreen',
                      arrow: 'text-darkgreen',
                    }}
                  >
                    <img src="/assets/images/icon/tooltipicon.svg" alt="good" className="ml-6" />
                  </Tooltip>
                </div>
                <RepliedVsNotReplied
                  reviewSentiment={reviewSentimentPeriodsData}
                  reviewPlatformId={selectPlateForm}
                  placeId={reviewPlaceId}
                  totalReview={
                    !userCurrentBusinessDetails?.isOnlineBusiness ||
                    parseInt(reviewPlatformInfos?.reviewPlatformId, 10) === 7
                      ? reviewPlatformInfos?.reviewPlatformId === 3
                        ? reviewPlatformInfos?.aggregateData?.aggregateTotalReview
                        : reviewPlatformInfos?.totalReview
                      : selectedProduct?.totalReviews
                  }
                  sizes="91%"
                  dataLabel="false"
                  fromDashboard="Dashboard"
                />
              </div>
            )}
            {parseInt(reviewPlatformInfos?.reviewPlatformId, 10) !== 8 &&
              !userCurrentBusinessDetails?.isOnlineBusiness && (
                <div className="bg-white rounded-md relative shadow p-10 sm:p-16 w-full">
                  <div className="flex items-center mb-16">
                    <h3 className="text-16 font-bold">{t('metrics.textVsNoText')}</h3>
                    <Tooltip
                      title={metricsInfoIconData?.textVsNoText}
                      arrow
                      classes={{
                        tooltip: 'bg-darkgreen',
                        arrow: 'text-darkgreen',
                      }}
                    >
                      <img src="/assets/images/icon/tooltipicon.svg" alt="good" className="ml-6" />
                    </Tooltip>
                  </div>
                  <TextVsNoText
                    reviewSentiment={reviewSentimentPeriodsData}
                    reviewPlatformId={selectPlateForm}
                    placeId={reviewPlaceId}
                    totalReview={
                      !userCurrentBusinessDetails?.isOnlineBusiness ||
                      parseInt(reviewPlatformInfos?.reviewPlatformId, 10) === 7
                        ? reviewPlatformInfos?.reviewPlatformId === 3
                          ? reviewPlatformInfos?.aggregateData?.aggregateTotalReview
                          : reviewPlatformInfos?.totalReview
                        : selectedProduct?.totalReviews
                    }
                    sizes="91%"
                    dataLabel="false"
                    fromDashboard="Dashboard"
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Dashboards;
