import { Button, Typography } from '@mui/material';
import { selectDashboardData } from 'app/store/userSlice';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import history from '@history';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { getParamsId, capitalizeFirstLetter, reviewFormate } from '../../../common/common';

const BusinessPlateForm = () => {
  const [platformData, setPlatformData] = useState([]);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      setPlatformData(userCurrentBusinessDetails?.reviewplatform);
    }
  }, [userCurrentBusinessDetails?.id, userCurrentBusinessDetails?.reviewplatform]);

  const physicalPlateFormName = ['google', 'zomato', 'yelp', 'trustpilot'];

  const redirect = (type, platformsId) => {
    const id = getParamsId();
    const selectedData = platformData.filter((data) => data?.reviewPlatformId === platformsId);
    const ratingTypeForRedirect = selectedData[0]?.name === 'zomato' ? 'dining' : '';
    const placeIdForRedirect = selectedData[0]?.placeId;
    if (type === 'reviews') {
      if (id) {
        history.push(`quick-reviews/reviews/${id}`, {
          placeIdForRedirect,
          ratingTypeForRedirect,
          platformsId,
        });
      } else {
        history.push(`quick-reviews/reviews/`, {
          placeIdForRedirect,
          ratingTypeForRedirect,
          platformsId,
        });
      }
    } else if (type === 'inviteReviews') {
      if (id) {
        history.push(`/invite-reviews/${id}`);
      } else {
        history.push('/invite-reviews/');
      }
    } else if (type === 'addProducts') {
      history.push(`quick-reviews/product/${id}`, { platformsId });
    } else {
      history.push(`quick-reviews/product/`, { platformsId });
    }
  };

  const handleAddPlatForm = () => {
    const allPlatformsPresent = physicalPlateFormName.every((platformName) =>
      userCurrentBusinessDetails?.reviewplatform.some(
        (item) => item?.name.toLowerCase() === platformName
      )
    );
    history.push('/editBusinessDetails', { businessId: userCurrentBusinessDetails?.id });
  };

  return (
    <div className="bg-white rounded-md relative shadow p-10 sm:p-16 w-full">
      <div className="flex justify-between items-center">
        <Typography className="font-bold text-20">
          {t('businessDashboard.businessPlateForm')}
        </Typography>
        {!userCurrentBusinessDetails?.isOnlineBusiness &&
          !physicalPlateFormName.every((platformName) =>
            userCurrentBusinessDetails?.reviewplatform.some(
              (item) => item?.name.toLowerCase() === platformName
            )
          ) && (
            <Button
              className="text-quick-review font-semibold text-16 hover:bg-white p-0"
              onClick={() => handleAddPlatForm()}
            >
              <AddIcon /> {t('businessDashboard.AddPlateForm')}
            </Button>
          )}
      </div>
      {userCurrentBusinessDetails?.reviewplatform?.length > 0 &&
        userCurrentBusinessDetails?.reviewplatform?.map((item, index) => {
          const isOnlineBusiness = userCurrentBusinessDetails?.isOnlineBusiness;
          return (
            <div
              className={`flex items-center justify-between mt-16 p-12 rounded-lg min-h-112 ${
                (parseInt(item?.reviewPlatformId, 10) === 2 ||
                  parseInt(item?.reviewPlatformId, 10) === 5) &&
                'bg-blue-A300'
              } ${
                (parseInt(item?.reviewPlatformId, 10) === 3 ||
                  parseInt(item?.reviewPlatformId, 10) === 7) &&
                'bg-orange-A300'
              } ${
                (parseInt(item?.reviewPlatformId, 10) === 4 ||
                  parseInt(item?.reviewPlatformId, 10) === 6) &&
                'bg-purple-A300'
              }`}
              key={index}
            >
              <div className="flex gap-16 items-center">
                <div className="bg-white p-12 rounded">
                  <img className="w-32 m-auto" src={item?.logo} alt="" />
                </div>
                <div>
                  <Typography className="text-16 font-semibold">
                    {item?.name && capitalizeFirstLetter(item?.name)}
                  </Typography>
                  {(parseInt(item?.reviewPlatformId, 10) === 3
                    ? item?.aggregateData?.aggregateTotalReview !== 0
                    : item?.totalReview !== 0) && (
                    <>
                      <Typography className="text-14">
                        Based on{' '}
                        {parseInt(item?.reviewPlatformId, 10) === 3
                          ? item?.aggregateData?.aggregateTotalReview
                          : item?.totalReview}{' '}
                        Reviews
                      </Typography>
                      <Typography className="pr-8">
                        {reviewFormate(
                          parseInt(item?.reviewPlatformId, 10) === 3
                            ? item?.aggregateData?.aggregateAverageRating
                            : item?.totalRatingStars
                        )}{' '}
                        <span className="font-semibold">
                          {parseInt(item?.reviewPlatformId, 10) === 3
                            ? item?.aggregateData?.aggregateAverageRating
                            : item?.totalRatingStars}{' '}
                          / 5
                        </span>
                      </Typography>
                    </>
                  )}
                </div>
              </div>
              <div>
                {!isOnlineBusiness || (isOnlineBusiness && item?.totalReview > 0) ? (
                  <Button
                    className="bg-white hover:bg-white rounded max-w-40 min-w-40 w-40"
                    onClick={() => redirect('reviews', item?.reviewPlatformId)}
                  >
                    <ChevronRightIcon />
                  </Button>
                ) : (
                  <>
                    {parseInt(item?.reviewPlatformId, 10) !== 7 && (
                      <Button
                        variant="contained"
                        className="rounded-md px-20 mx-auto"
                        color="secondary"
                        size="small"
                        type="button"
                        onClick={() =>
                          redirect(
                            parseInt(item?.reviewPlatformId, 10) === 7
                              ? 'inviteReviews'
                              : 'addProducts',
                            item?.reviewPlatformId
                          )
                        }
                      >
                        {t('businessDashboard.addProducts')}
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default BusinessPlateForm;
