import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';
import { Icon, Button, Typography } from '@mui/material';
import { publicIpv4 } from 'public-ip';
import FuseLoading from '@fuse/core/FuseLoading';
import { useTranslation } from 'react-i18next';
import { getDecryptData, getEncryptedData, handleApiRequest } from '../common/common';
import quickHub from '../query/quickHub';
import queryData from '../query/common';

const QuickProducts = ({ onStartTrial }) => {
  const { t } = useTranslation();

  const [ipBasedData, setIpBasedData] = useState(
    localStorage.getItem('ipData') ? JSON.parse(getDecryptData(localStorage.getItem('ipData'))) : ''
  );
  const [quickHubProductList, setQuickHubProductList] = useState([]);
  const [checkedState, setCheckedState] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);

  useEffect(() => {
    if (!ipBasedData?.countryId) return;
    const fetchData = async () => {
      const payload = {
        query: quickHub.getQuickHubProducts,
        variables: {
          countryId: ipBasedData?.countryId,
          packageInterval: 'month',
        },
      };
      try {
        const result = await handleApiRequest(payload);
        const products = result?.getQuickHubProducts?.data || [];
        setQuickHubProductList(products);
        setCheckedState(new Array(products.length).fill(false));
      } catch (error) {
        console.error('Failed to fetch QuickHub products:', error);
      }
    };

    fetchData();
  }, [ipBasedData?.countryId]);

  useEffect(() => {
    if (!ipBasedData) {
      const ipAddress = publicIpv4();
      const ipAddressData = async () => {
        const payload = {
          query: queryData.getLocation,
          variables: { data: getEncryptedData(`{"ipAddress": "${await ipAddress}"}`) },
        };
        const locationResponse = await handleApiRequest(payload);
        if (locationResponse?.payload?.data?.getLocation?.data) {
          const locationData = locationResponse?.payload?.data?.getLocation?.data;
          if (locationData) {
            localStorage.setItem('ipData', locationData);
            setIpBasedData(JSON.parse(getDecryptData(locationData)));
          }
        }
      };
      ipAddressData();
    }
  }, [ipBasedData]);

  const handleOnChange = (position, product) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);

    if (updatedCheckedState[position]) {
      setSelectedProduct((prevSelected) => [...prevSelected, product]);
    } else {
      setSelectedProduct((prevSelected) => prevSelected.filter((pro) => pro.id !== product.id));
    }
  };

  const handleRemoveProduct = (product) => {
    const productIndex = quickHubProductList.findIndex((p) => p.id === product.id);

    setSelectedProduct((prevSelected) => prevSelected.filter((pro) => pro.id !== product.id));

    const updatedCheckedState = checkedState.map((item, index) =>
      index === productIndex ? false : item
    );
    setCheckedState(updatedCheckedState);
  };

  const handleStartTrial = () => {
    onStartTrial(selectedProduct);
  };

  return (
    <div className="bg-white mx-auto w-full">
      <div className="text-center">
        <div className="inline-block align-top">
          <Typography className="mt-10 mb-2 text-20 font-bold text-left text-black">
            {t('selectProduct.quickProducts')}
          </Typography>
          <Typography className="mb-20 text-12 font-normal text-black">
            {t('selectProduct.unlockMessage')}
          </Typography>
          <Typography className="text-12 font-normal text-right text-black">
            <span className="bg-darkgreen p-4 mr-4 rounded-md text-white">
              {t('selectProduct.recommended')}
            </span>
          </Typography>
          {quickHubProductList &&
            quickHubProductList?.length > 0 &&
            quickHubProductList?.map((product, index) => (
              <div
                key={index}
                className={`border rounded-lg p-4 mb-20 last:mb-0 md:min-w-480 mx-auto px-20 py-12  ${
                  checkedState[index] ? 'bg-darkgreen-100' : 'bg-white'
                }`}
              >
                <div className="block">
                  <div className="flex w-full items-center">
                    <div className="mr-12 w-60">
                      <img src={product?.image} alt={product?.name} className="h-48 w-48" />
                    </div>
                    <div className="w-full">
                      <div className="flex items-center justify-between mb-8">
                        <h3 className="font-bold text-16">{product?.name}</h3>
                        <span className="inline-block text-16 font-bold capitalize">
                          <span>
                            {product?.currency === 'INR' ? '₹' : '$'}
                            <span>{product?.amount}</span>
                          </span>
                          /{product?.packageInterval}
                        </span>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-12 font-semibold text-gray-500">
                          {product?.description}
                        </p>
                        <Checkbox
                          className="p-0"
                          checked={checkedState[index]}
                          onChange={() => handleOnChange(index, product)}
                          sx={{
                            color: '#BEBEBE',
                            borderRadius: '10px',
                            '&.Mui-checked': {
                              color: 'darkgreen',
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {selectedProduct?.length > 0 && (
          <div className="md:min-w-360 sm:min-w-fit min-w-320 mt-20 sm:mt-0 mx-auto border-1 rounded-lg p-12 sm:p-12 md:p-24 inline-block sm:ml-28 md:ml-60">
            <h2 className="md:text-20 text-16 font-bold mb-24 text-left mt-0">
              {selectedProduct?.length}{' '}
              {selectedProduct?.length === 1 ? 'Product selected' : 'Products selected'}
            </h2>
            {selectedProduct &&
              selectedProduct?.length > 0 &&
              selectedProduct?.map((product, index) => (
                <div
                  key={index}
                  className="bg bg-darkgreen-100 rounded-lg p-4 mb-16 last:mb-0 max-w-320 mx-auto md:px-20 sm:px-10 py-8"
                >
                  <div className="flex w-full items-center">
                    <div className="mr-12 w-40">
                      <img src={product?.image} alt={product?.name} className="h-36 w-36" />
                    </div>
                    <div className="w-full">
                      <div className="flex items-center justify-between">
                        <h3 className="font-semibold text-14">{product?.name}</h3>
                        <span>
                          <Icon
                            className="text-16 cursor-pointer"
                            onClick={() => handleRemoveProduct(product)}
                          >
                            close
                          </Icon>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            <span className="bg-[#FFF8DD] p-12 block mt-80">{t('selectProduct.note')}</span>
            <Button
              onClick={handleStartTrial}
              className="block rounded-md w-full mt-16"
              size="medium"
              variant="contained"
              color="secondary"
            >
              {t('selectProduct.startFreeTrial')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuickProducts;
