import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

const SelectPlatformBox = (props) => {
  const {
    onClose,
    openDialog,
    socialPlatformId,
    connectionData,
    setSelectedPage,
    selectedPage,
    setOpenDialog,
    handleSocialResponse,
    handleClose,
  } = props;
  return (
    <Dialog
      classes={{
        paper: 'border-t-8 border-solid border-t-quick-social border-quick-social rounded-md',
      }}
      open={openDialog}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        <Typography className="text-20 font-semibold text-black border-b-1 border-dashed border-grey-500 w-full pb-10">
          {socialPlatformId === 9
            ? t('quickSocial.selectFbPage')
            : socialPlatformId === 10
            ? t('quickSocial.selectInPage')
            : null}
        </Typography>
      </DialogTitle>
      <Icon
        onClick={() => onClose()}
        className="text-24 absolute top-12 right-10 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
      >
        cancel
      </Icon>

      <div className=" gap-20 p-4 mx-20 rounded-8 flex items-center flex-col">
        {connectionData?.map((item) => (
          <div
            key={item.id}
            role="button"
            tabIndex={0}
            onClick={() => setSelectedPage(item)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                setSelectedPage(item);
              }
            }}
            className={`flex gap-5 border-1 border-grey-200 rounded-md px-20 py-10 flex-col w-full cursor-pointer ${
              selectedPage?.id === item?.id
                ? 'bg-quick-social bg-opacity-70 text-white'
                : 'bg-white'
            }`}
          >
            <p className="text-20 font-600">{item?.name}</p>
            <p className="text-14 font-500">{item?.id}</p>
          </div>
        ))}
      </div>

      <DialogActions className="flex justify-center my-5">
        <Button
          className="text-12 md:text-14 px-32 py-5 font-semibold rounded-6"
          variant="contained"
          color="quickSocial"
          size="small"
          type="submit"
          disabled={selectedPage.length === 0}
          onClick={() => {
            if (selectedPage) {
              setOpenDialog(false);
              handleSocialResponse();
              handleClose();
            }
          }}
        >
          {t('waba.inbox.select')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectPlatformBox;
