import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { selectDashboardData } from 'src/app/store/userSlice';
import metricsData from '../../query/metrics';
import ReviewSentiment from './reviewSentiment';
import { getDataToServer } from '../../common/common';
import NoData from './noDataScreen';

function RatingTrendTracker(props) {
  const { reviewSentiment, reviewPlatformId } = props;
  const [selectedReviewSentimentId, setSelectedReviewSentimentId] = useState(7);
  const [overAll, setOverAll] = useState([]);

  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  const [newGraphData, setNewGraphData] = useState([
    {
      name: 'Rating',
      data: [],
    },
  ]);

  useEffect(() => {
    try {
      const fetchData = async () => {
        if (userCurrentBusinessDetails?.id && selectedReviewSentimentId && reviewPlatformId) {
          const payload = {
            query: metricsData.getReviewsTrendsV1,
            variables: {
              businessId: parseInt(userCurrentBusinessDetails?.id, 10),
              reviewSentimentPeriodsId: parseInt(selectedReviewSentimentId, 10),
              reviewPlatformId: parseInt(reviewPlatformId, 10),
            },
          };
          const result = await getDataToServer(payload);
          const data = result?.getReviewsTrendsV1?.data || [];
          const keyValueObject = data?.trendJsonV1?.map((item) => {
            const obj = {};
            item.forEach(({ key, value }) => {
              obj[key] = value;
            });
            return obj;
          });
          const sortData = keyValueObject?.sort((a, b) => b.timestampV1 - a.timestampV1);
          if (data) {
            setNewGraphData([
              {
                name: 'Rating',
                data: sortData?.map((item) => item.rating),
              },
            ]);
            setOverAll(
              sortData?.map((item) => moment.unix(item.timestampV1).format('MMM DD YYYY'))
            );
          } else {
            setNewGraphData([]);
            setOverAll([]);
          }
        }
      };
      fetchData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails?.id, selectedReviewSentimentId, reviewPlatformId]);

  const getChartSimple = (data, categories, index, types) => {
    const chart = {
      series: data,
      options: {
        grid: {
          strokeDashArray: 3,
          show: true,
        },
        chart: {
          events: {
            mounted: (charts) => {
              charts.windowResizeHandler();
            },
          },
          type: types,
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            columnWidth: '60px',
          },
        },
        stroke: {
          curve: 'straight',
          width: 2,
        },
        markers: {
          size: 4,
          shape: 'circle',
        },
        legend: {
          markers: {
            radius: 12,
          },
        },
        colors: ['#0BA861'],
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          axisTicks: {
            show: false,
          },
          categories,
          labels: {
            offsetY: 0,
            offsetX: 5,
          },
          min: 0,
          max: categories.length > 7 ? 7 : categories.length - 1,
        },
        yaxis: {
          min: 0,
          max: 5,
          tickAmount: 4,
          labels: {
            align: 'left',
            maxWidth: 350,
            position: 'left',
            offsetX: -10,
            formatter(val) {
              return val.toFixed(1);
            },
            style: {
              fontSize: '14px',
            },
          },
        },
      },
    };

    return (
      <div
        id={`chart${index}`}
        className="flex flex-col flex-auto w-full"
        style={{ overflowX: 'auto', overflowY: 'hidden' }}
      >
        <ReactApexChart
          options={chart.options}
          series={chart.series}
          type={chart.options.chart.type}
          height="300px"
          width={categories.length > 7 ? `${categories.length * 60}px` : '100%'}
        />
      </div>
    );
  };

  return (
    <div className="relative">
      {overAll ? (
        <>
          <ReviewSentiment
            reviewSentiment={reviewSentiment}
            sentimentId={[7, 2, 4, 5]}
            onSelectSentiment={setSelectedReviewSentimentId}
          />
          <div>{getChartSimple(newGraphData, overAll, 5, 'line')}</div>
        </>
      ) : (
        <NoData />
      )}
    </div>
  );
}

export default RatingTrendTracker;
