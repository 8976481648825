import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  Icon,
  Typography,
  Input,
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  Checkbox,
  Select,
  MenuItem,
} from '@mui/material';
import { useEffect, useState, useCallback, useRef } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
  renderTimeViewClock,
} from '@mui/x-date-pickers';
import { t } from 'i18next';
import InputLabel from '@mui/material/InputLabel';
import _ from '@lodash';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import { useSnackbar } from 'notistack';
import RadioGroup from '@mui/material/RadioGroup';
import ReactSelect from 'react-select';
import { FixedSizeList as List } from 'react-window';
import wabaQuery from '../../query/waba';
import businessData from '../../query/business';
import {
  handleWabaApiRequest,
  handleApiRequest,
  storeCsvFileDataToServer,
  convertTemplateToHtml,
} from '../../common/common';

const CreateBroadcastPopUp = (props) => {
  const { openValue, cancelCall, popUpMessage, popUpButtonName } = props;

  const [refreshIcon, setRefreshIcon] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(openValue);
  const [templateList, setTemplateList] = useState();
  const [selectTemplate, setSelectTemplate] = useState();
  const [selectPreviewTemplate, setSelectPreviewTemplate] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [selectedOption, setSelectedOption] = useState('sendNow');
  const [totalSelectedCustomers, setTotalSelectedCustomers] = useState();
  const [broadcastName, setBroadcastName] = useState();
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [csvFileData, setCsvFileData] = useState([]);
  const [selectCsvFile, setSelectCsvFile] = useState(0);
  const [customerData, setCustomerData] = useState([]);
  const [csvFileAllList, setCsvFileAllList] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const fileRefCrateBroadcast = useRef();
  const templatePreview = selectPreviewTemplate?.find(
    (template) => template.id === selectTemplate?.value
  );

  const CustomMenuList = (propsList) => {
    const itemHeight = 35;
    const { options, children, maxHeight, getValue } = propsList;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * itemHeight;
    if (options.length > 0) {
      return (
        <div>
          <List
            height={maxHeight}
            itemCount={children.length}
            itemSize={itemHeight}
            initialScrollOffset={initialOffset}
          >
            {({ index, style }) => (
              <div style={style} className="bg-white relative">
                {children[index]}
              </div>
            )}
          </List>
        </div>
      );
    }
    return <div className="p-16">Loading....</div>;
  };

  const handleTemplate = useCallback(() => {
    try {
      const whatAppUserChatRoom = async () => {
        const payload = {
          query: wabaQuery.getWhatsappTemplate,
        };
        const res = await handleWabaApiRequest(payload);
        if (res?.getWhatsappTemplate?.status === 200) {
          const templateListData = res?.getWhatsappTemplate?.data?.map((idx) => ({
            value: idx.id,
            label: idx.displayName,
          }));
          setTemplateList(templateListData);
          setSelectPreviewTemplate(res?.getWhatsappTemplate?.data);
        }
      };
      whatAppUserChatRoom();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, []);

  useEffect(() => {
    handleTemplate();
  }, [handleTemplate]);

  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        query: businessData.getUserCSV,
        variables: {
          callFrom: 'whatsapp',
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getUserCSV?.status === 200) {
        const data = result.getUserCSV?.data;
        setCsvFileAllList(data);
        const sortData = data?.userCSVData?.sort((a, b) => a.id - b.id);
        setCsvFileData(sortData);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectCsvFile === 0) {
          const uniqCustomerId = csvFileAllList?.allContact?.map((item, i) => ({
            ...item,
            uniqueId: Math.random() * 1000,
          }));
          setCustomerData(uniqCustomerId);
        } else {
          const payload = {
            query: businessData.getUserCSVOnCsvId,
            variables: {
              csvId: selectCsvFile,
            },
          };
          const res = await handleApiRequest(payload);
          if (res?.getUserCSVOnCsvId?.status === 200) {
            const uniqCustomerId = res?.getUserCSVOnCsvId?.data?.map((item, i) => ({
              ...item,
              uniqueId: Math.random() * 1000,
            }));
            setCustomerData(uniqCustomerId);
          }
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };
    fetchData();
  }, [selectCsvFile, csvFileAllList?.allContact]);

  useEffect(() => {
    setTotalSelectedCustomers(selectedCustomers?.length);
  }, [selectedCustomers]);

  const handleSelectAllCustomer = () => {
    const allCustomersSelected = customerData?.every((customer) =>
      selectedCustomers?.some((selectedCustomer) => selectedCustomer.uniqueId === customer.uniqueId)
    );
    if (allCustomersSelected) {
      setSelectedCustomers([]);
      setSelectAll(false);
    } else {
      setSelectedCustomers(customerData);
      setSelectAll(true);
    }
  };
  const handleSelectCustomer = (customer) => {
    const existingCustomer = selectedCustomers?.find((item) => item.uniqueId === customer.uniqueId);
    if (existingCustomer) {
      const removeCustomer = selectedCustomers?.filter(
        (item) => item.uniqueId !== customer.uniqueId
      );
      setSelectedCustomers(removeCustomer);
    } else {
      setSelectedCustomers([...selectedCustomers, customer]);
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
    cancelCall();
  };

  function handleSearchEvent(event) {
    setSearchValue(event.target.value);
  }

  const handleClearSearch = async () => {
    await setSearchValue('');
  };

  const handleFileInputChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile?.type === 'text/csv' || selectedFile?.name?.endsWith('.csv')) {
      const payload = {
        query: businessData.saveUserCSV,
        variables: {
          file: selectedFile,
          variables: {
            callFrom: 'whatsapp',
          },
        },
      };
      const result = await storeCsvFileDataToServer(payload);
      if (result?.saveUserCSV?.status === 200) {
        const obj = {
          query: businessData.getUserCSV,
          variables: {
            callFrom: 'whatsapp',
          },
        };
        const res = await handleApiRequest(obj);
        if (res?.getUserCSV?.status === 200) {
          enqueueSnackbar(t(res?.getUserCSV?.message), {
            variant: 'success',
            autoHideDuration: 3000,
          });
          const data = res?.getUserCSV?.data?.userCSVData;
          setCsvFileData(data);
        }
      } else {
        enqueueSnackbar(result?.saveUserCSV?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } else {
      enqueueSnackbar(t('sendMessage.onlyCscFileSelect'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
    fileRefCrateBroadcast.current.value = '';
  };

  const handleSelectCsvFile = async (e) => {
    setSelectCsvFile(e?.target?.value);
  };

  const handleDownloadCsvFile = async () => {
    const payload = {
      query: businessData.getCampaignPlatformDemoCsv,
    };
    const result = await handleApiRequest(payload);
    if (result?.getCampaignPlatformDemoCsv?.status === 200) {
      const demoCsv = result?.getCampaignPlatformDemoCsv?.data?.demoCsv;
      const downloadLink = document.createElement('a');
      downloadLink.href = demoCsv;
      downloadLink.target = '_blank';
      downloadLink.download = 'demo.csv';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };
  const handleSelectDate = (data) => {
    const originalDate = new Date(data);
    const formattedDate = originalDate?.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const [month, day, year] = formattedDate.split('/');
    const desiredFormat = `${year}-${month}-${day}`;
    setSelectedDate(desiredFormat);
  };

  const handleSelectTime = (data) => {
    setSelectedTime(data.toLocaleTimeString());
  };

  const handleCreateBroadCast = async () => {
    setRefreshIcon(true);
    const result = selectedCustomers?.flatMap(({ name, phoneNumber, countryCode }) => ({
      customerName: name,
      phoneNumber: `${countryCode.replace('+', '')}${phoneNumber}`,
    }));
    const dateTimeString = `${selectedDate} ${selectedTime}`;
    const parsedDate = moment(dateTimeString, 'YYYY-MM-DD h:mm:ss A');
    let isoDateTimeString;
    if (parsedDate.isValid()) {
      isoDateTimeString = parsedDate.toISOString();
    } else {
      console.error('Invalid date or time value');
    }
    try {
      const payload = {
        query: wabaQuery.createBroadcast,
        variables: {
          contact: result,
          isScheduled: selectedOption === 'schedule',
          name: broadcastName,
          templateId: selectTemplate?.value,
        },
      };
      if (selectedOption === 'schedule') {
        payload.variables.scheduledDate = isoDateTimeString;
      }
      const res = await handleWabaApiRequest(payload);
      setRefreshIcon(false);
      if (res.createBroadcast?.status === 200) {
        handleClose();
        setBroadcastName('');
      } else {
        enqueueSnackbar(res?.createBroadcast?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  return (
    <Dialog
      classes={{
        paper:
          'm-24 max-w-screen-lg width-full border-t-8 border-solid border-t-darkgreen border-darkgreen rounded-md overflow-x-hidden',
      }}
      open={openValue}
      onKeyDown={(event) => {
        if (event.keyCode === 27) {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle className="p-0 py-16 px-20 border-b-1 border-solid border-grey-300">
        <Icon
          onClick={() => handleClose()}
          className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
        >
          cancel
        </Icon>
        <Typography className="text-20 font-semibold text-black w-full mb-0">
          {popUpMessage}
        </Typography>
      </DialogTitle>
      <div className="flex">
        <div className="w-3/4 p-20 create-broadcast-height overflow-y-auto">
          <div className="text-16 font-semibold">{t('waba.broadcast.newBroadcast.details')}</div>
          <Typography className="mt-16 font-medium">
            {t('waba.broadcast.newBroadcast.detailsDesc')}
          </Typography>
          <div className="bg-grey-100 rounded-md mt-20 p-16 mb-28">
            <div className="block mb-20">
              <InputLabel
                id="broadcastName"
                className="text-16 font-medium mb-4 text-grey-900 mb-10"
              >
                {t('waba.broadcast.newBroadcast.name')}
                <span className="text-red">*</span>
              </InputLabel>
              <TextField
                name="broadcastName"
                type="text"
                placeholder={t('waba.broadcast.newBroadcast.placeholder')}
                onChange={(e) => setBroadcastName(e?.target?.value)}
                value={broadcastName}
                className="bg-white"
                variant="outlined"
                size="small"
                fullWidth
              />
            </div>
            <div>
              <InputLabel
                id="broadcastName"
                className="text-16 font-medium mb-4 text-grey-900 mb-10"
              >
                {t('waba.broadcast.newBroadcast.template')}
              </InputLabel>
              <ReactSelect
                labelId="broadcastTemplate"
                placeholder={t('waba.broadcast.newBroadcast.templatePlaceholder')}
                id="broadcastTemplate"
                name="broadcastTemplate"
                minMenuHeight="45px"
                onChange={(e) => {
                  setSelectTemplate(e);
                }}
                value={selectTemplate}
                options={
                  templateList?.length > 0
                    ? templateList
                    : [{ value: null, label: 'No Template Available' }]
                }
                components={{
                  MenuList: CustomMenuList,
                }}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    minHeight: '42px',
                  }),
                }}
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="max-w-512">
              <div className="text-16 font-semibold">
                {t('waba.broadcast.newBroadcast.contact')}
              </div>
              <Typography className="mt-16 font-medium contents">
                {t('waba.broadcast.newBroadcast.contactDesc')}
              </Typography>
              <span
                aria-hidden
                className="text-darkgreen font-semibold inline-block cursor-pointer ml-5"
                onClick={() => handleDownloadCsvFile()}
              >
                {t('sendMessage.downloadCsvFile')}
              </span>
            </div>
            <div className="md:col-span-2 width_set sm:col-span-4 text-center col-span-6">
              <div className="relative">
                <label htmlFor="button-file">
                  <input
                    className="hidden w-full"
                    id="button-file"
                    type="file"
                    ref={fileRefCrateBroadcast}
                    onChange={handleFileInputChange}
                    aria-labelledby="upload-label"
                  />
                  <Button
                    className="border rounded-md"
                    variant="outlined"
                    color="secondary"
                    component="span"
                    id="upload-label"
                  >
                    {t('waba.broadcast.newBroadcast.importContact')}
                  </Button>
                </label>
              </div>
            </div>
          </div>
          <div className="bg-grey-100 rounded-md mt-20 p-16 mb-28">
            <div className="flex items-center justify-between">
              <div className="inline-block w-full rounded-8 shadow-none border-1 border-solid border-grey-400 rounded-md relative align-middle">
                <Input
                  placeholder={t('appPromotionSlider.search')}
                  className="text-black text-16 h-40  px-12"
                  disableUnderline
                  name="searchText"
                  fullWidth
                  onChange={handleSearchEvent}
                  value={searchValue}
                  inputProps={{
                    'aria-label': 'Search',
                  }}
                />
                <span className="absolute right-8 top-10">
                  {searchValue ? (
                    <Icon
                      color="secondary"
                      className="cursor-pointer text-20"
                      onClick={() => handleClearSearch()}
                    >
                      close
                    </Icon>
                  ) : (
                    <Icon color="secondary" className="text-20">
                      search
                    </Icon>
                  )}
                </span>
              </div>
              <Select
                className="w-full max-w-224 ml-16"
                id="ratingFormate"
                sx={{ '& .MuiSelect-select': { display: 'flex', alignItems: 'center' } }}
                size="small"
                value={selectCsvFile}
                displayEmpty
                onChange={(e) => {
                  handleSelectCsvFile(e);
                }}
              >
                <MenuItem value={0}>{t('waba.broadcast.newBroadcast.AllContact')}</MenuItem>
                {csvFileData?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.fileName}
                  </MenuItem>
                ))}
              </Select>
            </div>

            {/* {customerData?.length > 0 && ( */}
            <div className="mt-20">
              <div className="flex justify-between items-center">
                <div className="flex justify-between items-center gap-20">
                  <Typography className="text-16 text-black font-medium">
                    {t('sendMessage.selected')}:{' '}
                    <span className="text-darkgreen">
                      {totalSelectedCustomers} {t('waba.broadcast.newBroadcast.selectContact')}
                    </span>
                  </Typography>
                </div>
              </div>
              <div className="bg-white rounded-md relative shadow px-4 mt-20">
                <TableContainer>
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                    <TableHead>
                      <TableCell
                        className="text-16 color-black pl-0 font-semibold w-1/12"
                        align="center"
                      >
                        <Checkbox
                          className="p-0 hover:bg-white"
                          indeterminate={
                            selectedCustomers?.length > 0 &&
                            selectedCustomers?.length < customerData?.length
                          }
                          checked={selectAll}
                          onChange={handleSelectAllCustomer}
                        />
                      </TableCell>
                      <TableCell
                        className="text-16 color-black pl-0 font-semibold w-2/4"
                        align="left"
                      >
                        {t('waba.broadcast.newBroadcast.customerName')}
                      </TableCell>
                      <TableCell
                        className="text-16 color-black pl-0 font-semibold w-2/5"
                        align="left"
                      >
                        {t('sendMessage.customerData.column.phoneNo')}
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {customerData?.length > 0 &&
                        customerData?.map((customer, i) => {
                          return (
                            <TableRow
                              hover
                              key={i}
                              className="border-t-2 border-t-[#eeeeee]"
                              role="checkbox"
                              tabIndex={-1}
                            >
                              <TableCell
                                className="text-16 color-black pl-0 font-semibold w-[4%]"
                                align="center"
                              >
                                <Checkbox
                                  className="p-0 hover:bg-white"
                                  checked={selectedCustomers?.includes(customer)}
                                  onChange={() => handleSelectCustomer(customer)}
                                />
                              </TableCell>
                              <TableCell
                                className="text-16 color-black py-4 pl-0 font-semibold w-[23%]"
                                align="left"
                              >
                                {customer.name}
                              </TableCell>
                              <TableCell
                                className="text-16 color-black py-4 pl-0 font-semibold w-[24%]"
                                align="left"
                              >
                                {customer.countryCode} {customer.phoneNumber}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
            {/* )} */}
          </div>

          <div className="text-16 font-semibold">
            {t('waba.broadcast.newBroadcast.deliveryOption')}
          </div>
          <Typography className="mt-16 font-medium">
            {t('waba.broadcast.newBroadcast.deliveryOptionDesc')}
          </Typography>
          <div className="bg-grey-100 rounded-md mt-20 p-16 mb-28">
            <div className="mt-4">
              <FormControl>
                <RadioGroup
                  aria-labelledby="delivery-option"
                  className="block"
                  name="delivery-option"
                  value={selectedOption}
                  onChange={(e) => setSelectedOption(e?.target?.value)}
                >
                  <FormControlLabel
                    value="sendNow"
                    control={<Radio />}
                    className="inline-block"
                    label={t('sendMessage.sendNow')}
                  />
                  <FormControlLabel
                    value="schedule"
                    control={<Radio />}
                    className="inline-block"
                    label={t('sendMessage.schedule')}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            {selectedOption === 'schedule' && (
              <div className="flex items-center justify-between gap-16">
                <div className="w-1/2">
                  <Typography className="text-14 text-black mt-4 font-medium">
                    {t('sendMessage.selectDate')}
                  </Typography>
                  <div className="mt-4">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        className="w-full"
                        id="note"
                        variant="outlined"
                        sx={{
                          '.MuiInputBase-root': {
                            height: '30px',
                          },
                        }}
                        fullWidth
                        disablePast
                        format="MMM dd, yyyy"
                        value={selectedTime}
                        onChange={handleSelectDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{
                              shrink: false,
                            }}
                            label="Select a date"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="w-1/2">
                  <Typography className="text-14 text-black mt-4 font-medium">
                    {t('sendMessage.selectTime')}
                  </Typography>
                  <div className="mt-4">
                    <TimePicker
                      className="w-full"
                      id="time"
                      onChange={handleSelectTime}
                      sx={{
                        '.MuiInputBase-root': {
                          height: '30px',
                        },
                      }}
                      size="small"
                      required
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <DialogActions className="p-0 mt-20 justify-start">
            <Button
              className="md:text-16 font-medium disabled:text-black rounded-md md:min-w-192 mx-10"
              variant="contained"
              color="secondary"
              onClick={() => handleCreateBroadCast()}
              type="button"
              disabled={!broadcastName || !selectTemplate?.value}
            >
              {popUpButtonName}
              {refreshIcon && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          </DialogActions>
        </div>
        <div className="w-2/4 bg-gray-A300 text-center create-broadcast-height sticky top-0">
          <h3 className="text-20 font-semibold text-center py-48">
            {t('waba.broadcast.newBroadcast.preview')}
          </h3>
          <div className="relative inline-block mx-auto">
            <img
              src="/assets/images/business/mobile_frame_waba.png"
              className="img-fluid mx-auto"
              alt="..."
            />
            {templatePreview && (
              <div className="absolute top-144 left-0 right-0 bg-white rounded-md px-8 py-8 max-w-256 mx-auto">
                {templatePreview &&
                  JSON.parse(templatePreview?.templateData)?.components?.map((templateValue, i) => (
                    <div className="block max-w-320 text-left bg-white" key={i}>
                      <div>
                        {templateValue?.type === 'HEADER' && (
                          <>
                            {templateValue?.format === 'TEXT' && (
                              <Typography className="mb-10 font-bold">
                                {templateValue?.text}
                              </Typography>
                            )}
                            {templateValue?.format === 'IMAGE' && (
                              <>
                                {JSON.parse(templatePreview?.dynamicData)?.header?.map(
                                  (dynamicValue) => (
                                    <>
                                      {dynamicValue?.type === 'image' && (
                                        <img
                                          src={dynamicValue?.value}
                                          className="mb-10 max-h-128 rounded-md"
                                          alt="..."
                                        />
                                      )}
                                    </>
                                  )
                                )}
                              </>
                            )}
                          </>
                        )}
                        {templateValue?.type === 'BODY' && (
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: convertTemplateToHtml(templateValue?.text),
                              }}
                            />
                          </>
                        )}
                        {templateValue?.type === 'FOOTER' && (
                          <>
                            <Typography className="mt-6 text-grey-700">
                              {templateValue?.text}
                            </Typography>
                          </>
                        )}
                        {templateValue?.type === 'BUTTONS' &&
                          templateValue?.buttons?.map((buttonValue, b) => (
                            <div
                              className="text-center border-t-1 border-solid border-grey-300 py-4 mt-12"
                              key={b}
                            >
                              <a
                                className="font-bold text-darkgreen text-16"
                                href={`${
                                  buttonValue?.phone_number
                                    ? `tel:${buttonValue?.phone_number}`
                                    : ''
                                }`}
                                rel="noreferrer"
                                target="_blank"
                                role="button"
                              >
                                <img
                                  src={
                                    buttonValue?.phone_number
                                      ? '/assets/images/business/telephone.png'
                                      : '/assets/images/business/view.png'
                                  }
                                  className="w-14 h-14 align-middle mr-4 inline-block"
                                  alt="..."
                                />
                                {buttonValue?.text}
                              </a>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CreateBroadcastPopUp;
