const suggestionPostV2 = ` 
query($userBusinessId: Int!, $rating: Int!, $pageNumber: Int, $pageSize: Int){
	suggestionPostV2 (userBusinessId: $userBusinessId, rating: $rating, pageNumber: $pageNumber, pageSize: $pageSize) {
    message
    status
    totalCount
    data{
      postJsonData
      htmlTemplate
      rating
      reviewId
      sharePostImage
    }      
	}
}  
`;

const getViewedPosts = ` 
query($businessId: Int,$domain: String, $pageNumber: Int, $pageSize: Int){
	getViewedPosts (businessId: $businessId,domain: $domain, pageNumber: $pageNumber, pageSize: $pageSize) {
    message
    status
    totalCount
    data{
      businessName
      cts
      htmlTemplate
      id
      platformId
      review
      reviewRating
      reviewerName
      sharePostTemplateId
      share_post_template{
      cts
      id
      tag
      template
      templateImage
      }
      templateImage
      text{
      review
      reviewPlatformTypeId
      reviewRating
      reviewerName
      }
      userId
    }      
	}
}  
`;

const getSharePostTemplate = ` 
query($sharePostTemplateId: Int,$pageNumber: Int,$pageSize: Int,$tag: String){
	getSharePostTemplate (sharePostTemplateId: $sharePostTemplateId, pageNumber: $pageNumber,pageSize:$pageSize,tag:$tag) {
    message
    status
     totalCount
    data {
      cts
      default
      id
      isActive
      tag
      template
      uts
      templateImage
    }     
	}
}  
`;

const getFavoriteTemplate = ` 
query($businessId: Int!, $pageNumber: Int, $pageSize: Int,$domain: String){
	getFavoriteTemplate (businessId: $businessId, pageNumber: $pageNumber, pageSize: $pageSize,domain:$domain) {
    message
    status
     totalCount 
    data {
      cts
      id
      sharePostTemplateId
      share_post_template{
        template
        templateImage
      }
      userId
    }     
	}
}  
`;

const getConvertedSharePostImage = ` 
query($businessName: String!, $htmlTemplate: String, $review: String!, $reviewPlatformTypeId: Int!, $reviewRating: Int!, $reviewerName: String!){
	getConvertedSharePostImage  (businessName: $businessName, htmlTemplate: $htmlTemplate, review: $review, reviewPlatformTypeId: $reviewPlatformTypeId, reviewRating: $reviewRating, reviewerName: $reviewerName) {
    message
    status
    data {
     socialPostUrl
    }     
	}
}  
`;

const getPostSuggestionsSetting = ` 
query{
	getPostSuggestionsSetting{
    message
    status
     data {
      id
      cts
      text
      isActive
      default
    }   
	}
}  
`;

const getPostSuggestionSettingDefault = ` 
query($userBusinessId: Int!){
	getPostSuggestionSettingDefault  (userBusinessId: $userBusinessId) {
    message
    status
    data {
      id
      text
    }
	}
}  
`;

const sharePostGenerateSummarizeReviews = ` 
query($review: String!, $tokens: String!){
	sharePostGenerateSummarizeReviews  (review: $review, tokens: $tokens) {
    message
    status
    data{
      summarizeReviews
    }
	}
}  
`;
const addFavoriteTemplate = `mutation addFavoriteTemplate($sharePostTemplateId: Int!,$userBusinessId: Int!){
  addFavoriteTemplate(sharePostTemplateId:$sharePostTemplateId, userBusinessId:$userBusinessId){
    status
    message
  }
}`;

const deleteFavoriteTemplate = `mutation deleteFavoriteTemplate($sharePostTemplateId: Int!,$userBusinessId: Int!){
  deleteFavoriteTemplate(sharePostTemplateId:$sharePostTemplateId,userBusinessId:$userBusinessId){
    status
    message
  }
}`;

const saveUserPostSuggestionSetting = `mutation saveUserPostSuggestionSetting($userBusinessId: Int!,
$maximumPostCount: Int!,
$postSuggestionsSettingId: Int!
) {
  saveUserPostSuggestionSetting(userBusinessId:$userBusinessId,duration:$duration,
maximumPostCount: $maximumPostCount,
postSuggestionsSettingId:$postSuggestionsSettingId){
    message
    status
  }
}`;

const enableAndDisablePostSuggestionsSetting = `mutation enableAndDisablePostSuggestionsSetting($userBusinessId: Int!,$isEnable: Boolean!,$userPostSuggestionsReEnableTypeId: Int!) {
  enableAndDisablePostSuggestionsSetting(userBusinessId:$userBusinessId,isEnable: $isEnable,userPostSuggestionsReEnableTypeId:$userPostSuggestionsReEnableTypeId){
    message
    status
  }
}`;

const getUserPostSuggestionSetting = `query getUserPostSuggestionSetting($userBusinessId:Int!) {
  getUserPostSuggestionSetting(userBusinessId:$userBusinessId) {
    data {
      cts
      maximumPostCount
      postSuggestionsSettingId
      duration
      isEnable,
      userPostSuggestionsReEnableTypeId
    }
    message
    status
  }
}`;

const saveViewedPosts = `mutation saveViewedPosts($userBusinessesId:Int!, $sharePostTemplateId: Int!, $reviewText: ReviewTextJson!) {
  saveViewedPosts(userBusinessesId:$userBusinessesId, sharePostTemplateId: $sharePostTemplateId , reviewText: $reviewText) {
    message
    status
  }
}`;

const getAllReviewForFeatureExplore = `query getAllReviewForFeatureExplore($businessId: Int!){
  getAllReviewForFeatureExplore(businessId: $businessId){
    message
    status
    data{
      id
      review
      reviewCts
      reviewRating
      reviewerName
      reviewerProfileUrl
    }
  }
}`;

const updateSurpriseMeCardData = `mutation updateSurpriseMeCardData($quickHubSurpriseCardId: Int!, $planType: String!) {
  updateSurpriseMeCardData(quickHubSurpriseCardId: $quickHubSurpriseCardId, planType: $planType) {
    status
    message
  }
}`;

const getSurpriseMeCard = `query getSurpriseMeCard($isHomeScreen: Boolean!, $userBusinessId: Int!) {
  getSurpriseMeCard(isHomeScreen: $isHomeScreen, userBusinessId: $userBusinessId) {
    status
    message
    data {
      id
      chat {
        count
        isTrialDone
      }
      social {
        count
        isTrialDone
      }
      campaign {
        count
        isTrialDone
      }
    }
  }
}`;

const quickPostQuery = {
  suggestionPostV2,
  getViewedPosts,
  getSharePostTemplate,
  getFavoriteTemplate,
  getConvertedSharePostImage,
  getPostSuggestionsSetting,
  getPostSuggestionSettingDefault,
  sharePostGenerateSummarizeReviews,
  addFavoriteTemplate,
  deleteFavoriteTemplate,
  saveUserPostSuggestionSetting,
  enableAndDisablePostSuggestionsSetting,
  getUserPostSuggestionSetting,
  saveViewedPosts,
  getAllReviewForFeatureExplore,
  updateSurpriseMeCardData,
  getSurpriseMeCard,
};

export default quickPostQuery;
