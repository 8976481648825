import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  Icon,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import businessData from 'src/app/main/query/business';

const CreateTemplate = (props) => {
  const { openDialog, setOpenDialog, category } = props;
  const [selectCategory, setSelectCategory] = useState();
  const [createTemplateDetails, setCreateTemplateDetails] = useState({
    templateName: '',
    message: '',
  });

  const handleCreateTemplate = () => {
    const payload = {
      query: businessData.createCampaignServicesStandardMessages,
      variables: {},
    };
  };
  return (
    <div>
      <Dialog
        classes={{
          paper: 'm-24 sm:p-28 pt-0 rounded-md',
        }}
        open={openDialog}
        fullWidth
        maxWidth="sm"
      >
        <div className="flex items-center justify-between">
          <Typography className="font-bold text-16">
            {t('quickCampaign.template.createTemplate')}
          </Typography>
        </div>
        <Icon
          onClick={() => {
            setOpenDialog(false);
            setSelectCategory('');
          }}
          className="text-24 absolute right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
        >
          cancel
        </Icon>
        <Typography className="mt-16 font-medium text-14">
          {t('quickCampaign.template.templateCategory')}
        </Typography>
        <Select
          className="mt-8"
          size="small"
          value={selectCategory || 0}
          onChange={(e) => setSelectCategory(e.target.value)}
        >
          <MenuItem value="0" disabled>
            {t('quickCampaign.template.selectCategory')}
          </MenuItem>
          {category?.length > 0 &&
            category?.map((item, i) => {
              return (
                <MenuItem key={i} value={item?.id}>
                  {item?.type}
                </MenuItem>
              );
            })}
        </Select>
        <div className="mb-8">
          <Typography className="mt-16 mb-8 font-medium text-14">
            {t('quickCampaign.template.templateDetails')}
          </Typography>
          <div className="bg-gray-100 rounded-md">
            <div className="p-16">
              <Typography className="font-normal mb-12 text-14">
                {t('quickCampaign.template.templateName')}
              </Typography>
              <TextField
                className="w-full bg-white"
                size="small"
                onChange={(e) =>
                  setCreateTemplateDetails({ ...createTemplateDetails, name: e.target.value })
                }
                placeholder={t('quickCampaign.template.enterTemplateNameHere')}
              />
              <div className="flex justify-between">
                <Typography className="font-normal text-14 my-12">
                  {t('quickCampaign.template.messageContent')}
                </Typography>
                <Button className="font-semibold text-quick-campaign my-12 p-0 h-20 min-h-20 hover:bg-gray-100">
                  {t('quickCampaign.template.addVariable')}
                </Button>
              </div>
              <TextField
                className="w-full bg-white"
                size="small"
                multiline
                rows={5}
                onChange={(e) =>
                  setCreateTemplateDetails({ ...createTemplateDetails, message: e.target.value })
                }
                placeholder={t('quickCampaign.template.typeMessage')}
              />
            </div>
          </div>
        </div>
        <DialogActions className="p-0 justify-end">
          <Button
            variant="contained"
            color="quickCampaign"
            autoFocus
            onClick={() => setOpenDialog(true)}
            size="medium"
            disabled={!selectCategory}
            className="rounded-md gap-10 md:px-20 md:py-12 disabled:opacity-40 disabled:bg-quick-campaign disabled:text-white"
          >
            <p className="text-16 font-medium"> {t('quickCampaign.campaign.createCampaign')}</p>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateTemplate;
