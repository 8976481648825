import {
  Button,
  CircularProgress,
  Icon,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import history from '@history';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { setUserData } from 'app/store/userSlice';
import userService from 'src/app/services';
import { useDispatch } from 'react-redux';
import queryData from '../query/common';
import {
  getDataToServerForAuth,
  getEncryptedData,
  sendOTPOnEmailVerification,
  sendOTPOnPhoneNumberVerification,
  verifyEmailOtp,
  verifyPhoneOtp,
} from '../common/common';
import PolicySection from './PolicySection';

const OtpAndPassword = () => {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(1);
  const [otp, setOtp] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showCnfPassword, setShowCnfPassword] = useState(false);
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    password: yup
      .string()
      .required('Password is required')
      .min(8, t('users.resetPassword.formFields.validationMessages.passwordTooShort'))
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        t('users.resetPassword.formFields.validationMessages.passwordRequirements')
      ),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Confirm Password must match with Password'),
  });
  const { control, formState, getValues, trigger, setError } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const loginRedirect = async (loginData) => {
    if (Object.keys(loginData).length > 0) {
      if (
        loginData?.status === 'fail' ||
        loginData?.status === 'error' ||
        loginData?.status !== 200
      ) {
        enqueueSnackbar(loginData.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } else if (loginData?.status === 200 && loginData?.user) {
        const userData = loginData.user;
        if (userData?.roles?.type === 'business_user') {
          await history.push('/select-business');
        } else if (userData?.roles?.type === 'admin') {
          await history.push('/admin/dashboard');
        }
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [minutes, seconds]);

  useEffect(() => {
    if (!location?.state) {
      history.push('sign-up');
    }
  }, [location?.state]);

  const submitOtp = async () => {
    setLoading(true);
    if (location?.state?.email && !location?.state?.isEmailVerified) {
      const verifyOtp = await verifyEmailOtp(location?.state?.email, otp, enqueueSnackbar);
      setLoading(false);
      if (verifyOtp) {
        setOtp('');
        if (location?.state?.isComeFromUserInformation) {
          handleSubmitData();
        } else {
          setIsPasswordShown(true);
        }
      }
    }
    if (location?.state?.phoneNumber && !location?.state?.isPhoneNumberVerified) {
      const verifyPhoneNumberOTP = await verifyPhoneOtp(
        location?.state?.phoneNumber,
        location?.state?.countryCode,
        otp,
        enqueueSnackbar
      );
      setLoading(false);
      if (verifyPhoneNumberOTP) {
        setOtp('');
        if (location?.state?.isComeFromUserInformation) {
          handleSubmitData();
        } else {
          history.push('/userInformation', { ...location?.state, isPhoneNumberVerified: true });
        }
      }
    }
  };

  const checkPassword = async () => {
    const passwordConfirmValue = getValues('passwordConfirm');
    if (passwordConfirmValue?.length > 0) {
      const isValidPassword = await trigger(['password', 'passwordConfirm']);
      if (!isValidPassword) {
        setError('Confirm Password must match with Password');
      }
    }
  };

  const handleCheckPassword = () => {
    history.push('/userInformation', {
      ...location?.state,
      isEmailVerified: true,
      password: getValues('password'),
    });
  };

  const handleSubmitData = async () => {
    setLoading(true);
    const preparePayload = (isGoogleAuth) => {
      const commonData = {
        firstName: location?.state?.firstName,
        lastName: location?.state?.lastName,
        phoneNumber: {
          countryCode: location?.state?.countryCode,
          phoneNumber: parseInt(location?.state?.phoneNumber, 10),
          isoCode: location?.state?.isoCode,
        },
        countryId: parseInt(location?.state?.countryId, 10),
        isSocialUser: true,
        timeZone: '',
        isQuickHubProduct: true,
        userPackageIds: location?.state?.userPackageIds,
      };

      if (isGoogleAuth) {
        return {
          ...commonData,
          accessToken: location?.state?.googleResponse,
          email: location?.state?.email,
        };
      }
      return {
        ...commonData,
        e: location?.state?.email,
        isEmailRegistration: true,
        p: location?.state?.password,
        accessToken: '',
      };
    };

    try {
      const isGoogleAuth = location?.state?.isGoogle;
      const payloadData = getEncryptedData(JSON.stringify(preparePayload(isGoogleAuth)));
      const payload = {
        query: isGoogleAuth ? queryData.googleAuthV3 : queryData.signupV3,
        variables: { data: payloadData },
      };
      const result = await getDataToServerForAuth(payload);
      setLoading(false);

      if (isGoogleAuth) {
        if (result?.googleAuthV3?.status === 200) {
          if (result?.googleAuthV3?.refreshToken) {
            dispatch(setUserData(result?.googleAuthV3?.user));
            userService.setSession(result?.googleAuthV3?.accessToken);
            loginRedirect(result?.googleAuthV3);
          }
        } else {
          enqueueSnackbar(result?.googleAuthV3?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } else if (result?.signupV3?.status === 200) {
        enqueueSnackbar(result?.signupV3?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        if (result?.signupV3?.refreshToken) {
          dispatch(setUserData(result?.signupV3?.user));
          userService.setSession(result?.signupV3?.accessToken);
          loginRedirect(result?.signupV3);
        }
      } else {
        enqueueSnackbar(result?.signupV3?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setLoading(false);
      console.error('Error during handleSubmitData:', error);
    }
  };

  const handleKeyPressSignUpNext = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      history.push('/userInformation', {
        ...location?.state,
        isEmailVerified: true,
        password: getValues('password'),
      });
    }
  };

  return (
    <div className="flex items-center justify-center bg-white h-full w-full sm:px-0 px-16 sm:py-0 py-40">
      <img
        src="assets/images/background/top-round.png"
        className="absolute top-0 right-0 hidden lg:block"
        alt="..."
      />
      <img
        src="assets/images/background/bottom-round.png"
        className="absolute bottom-0 left-0 hidden lg:block"
        alt="..."
      />
      <div>
        <img
          src="assets/images/logo/quick_hub.png"
          className="mx-auto md:max-w-max max-w-80"
          alt="logo"
        />
        <Typography className="mt-10 mb-32 text-24 font-bold text-center text-black">
          {t('signUpPage.createAccount')}
        </Typography>
        <Paper className="shadow-none">
          <div className="bg-grey-50 sm:max-w-[816px] md:min-w-[816px] w-full mx-auto rounded-2xl items-center justify-center sm:shadow md:shadow-none py-20 px-20 md:py-40 md:px-80">
            <div className="flex flex-col justify-center w-full max-w-320 mx-auto">
              {!isPasswordShown ? (
                <>
                  <div>
                    <Typography className="text-20 font-bold tracking-tight leading-tight mb-32">
                      {t('signInPage.messages.verifyYourAccount')}
                    </Typography>
                    <Typography className="text-16 font-medium">
                      {t('signInPage.messages.verifyEmail')}
                    </Typography>
                    <Typography className="text-16 font-medium">
                      {location?.state?.email && !location?.state?.isEmailVerified
                        ? location?.state?.email
                        : `${location?.state?.countryCode} ${location?.state?.phoneNumber}`}
                    </Typography>
                  </div>
                  <MuiOtpInput
                    className="max-w-xs my-24"
                    TextFieldsProps={{
                      size: 'small',
                      placeholder: '-',
                      type: 'number',
                    }}
                    type="number"
                    gap={2}
                    length={6}
                    value={otp}
                    onChange={(newValue) => setOtp(newValue)}
                  />
                  <div>
                    <Button
                      className="hover:bg-white text-red px-0 min-h-0 py-0 min-w-0 max-w-fit max-h-0"
                      onClick={() =>
                        location?.state?.isComeFromUserInformation
                          ? history.push('userInformation', location?.state)
                          : history.push('sign-up')
                      }
                    >
                      {location?.state?.isComeFromUserInformation
                        ? t('signInPage.changePhoneNumber')
                        : location?.state?.email
                        ? t('signInPage.changeEmail')
                        : t('signInPage.changePhoneNumber')}
                    </Button>
                  </div>
                  <div className="countdown-text flex items-center justify-start text-14 font-medium my-10">
                    {seconds > 0 || minutes > 0 ? (
                      <p>
                        {t('signUpPage.messages.codeResend')}:{' '}
                        {minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </p>
                    ) : (
                      <p className="text-14">{t('signUpPage.messages.notReceivedCode')}</p>
                    )}
                    <Button
                      disabled={seconds > 0 || minutes > 0}
                      className="underline decoration-1 text-14 font-medium"
                      color="secondary"
                      style={{
                        color: seconds > 0 || minutes > 0 ? '#DFE3E8' : '#FF5630',
                      }}
                      onClick={() => {
                        setSeconds(59);
                        !location?.state?.isEmailVerified
                          ? sendOTPOnEmailVerification(location?.state?.email, enqueueSnackbar)
                          : sendOTPOnPhoneNumberVerification(
                              location?.state?.phoneNumber,
                              location?.state?.countryCode,
                              enqueueSnackbar
                            );
                      }}
                    >
                      {t('signUpPage.buttons.resendOTP')}
                    </Button>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      className="w-full md:text-14 font-semibold disabled:text-black rounded-md"
                      aria-label="Sign in"
                      disabled={!otp || loading}
                      onClick={() => submitOtp()}
                      type="button"
                    >
                      {t('signUpPage.buttons.confirm')}
                      {loading && (
                        <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                      )}
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <Typography className="mt-10 mb-32 text-24 font-bold text-black">
                    Set Password
                  </Typography>
                  <TextField
                    className="mb-24 rounded-md"
                    name="email"
                    control={control}
                    style={{ height: '44px' }}
                    placeholder={t('signInPage.formField.email.userName')}
                    sx={{
                      '& .MuiInputBase-input': {
                        border: '1px solid #666666',
                        textIndent: 'initial',
                        transition: 'all .2s ease-in-out',
                        borderRadius: '7px',
                        fontWeight: 'medium',
                      },
                    }}
                    autoFocus
                    value={location?.state?.email}
                    size="small"
                    variant="outlined"
                    fullWidth
                  />
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className={`${errors.password ? 'mb-48' : 'mb-24'} bg-white h-40`}
                        label={t('signUpPage.formField.password.name')}
                        type="password"
                        size="small"
                        error={!!errors.password}
                        helperText={errors?.password?.message}
                        onChange={(e) => {
                          field.onChange(e);
                          checkPassword();
                        }}
                        InputProps={{
                          className: 'pr-2',
                          type: showPassword ? 'text' : 'password',
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                className="bg-white"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                <Icon className="text-20" color="action">
                                  {showPassword ? 'visibility' : 'visibility_off'}
                                </Icon>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="passwordConfirm"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-24 bg-white h-40"
                        label={t('signUpPage.formField.passwordConfirm.name')}
                        type="password"
                        size="small"
                        error={!!errors.passwordConfirm}
                        helperText={errors?.passwordConfirm?.message}
                        onChange={(e) => {
                          field.onChange(e);
                          checkPassword();
                        }}
                        onKeyDown={(event) => {
                          handleKeyPressSignUpNext(event);
                        }}
                        InputProps={{
                          className: 'pr-2',
                          type: showCnfPassword ? 'text' : 'password',
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                className="bg-white"
                                onClick={() => setShowCnfPassword(!showCnfPassword)}
                              >
                                <Icon className="text-20" color="action">
                                  {showCnfPassword ? 'visibility' : 'visibility_off'}
                                </Icon>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />

                  <Button
                    variant="contained"
                    color="secondary"
                    className={`w-full md:text-14 font-semibold rounded-md ${
                      (formState.isDirty && formState.errors.passwordConfirm) ||
                      !getValues('password') ||
                      !getValues('passwordConfirm')
                        ? 'disabled:text-black'
                        : ''
                    }`}
                    aria-label="Sign in"
                    disabled={
                      (formState.isDirty && formState.errors.passwordConfirm) ||
                      !getValues('password') ||
                      !getValues('passwordConfirm') ||
                      loading
                    }
                    onClick={() => handleCheckPassword()}
                    type="button"
                  >
                    {t('signUpPage.buttons.confirm')}
                    {loading && (
                      <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                    )}
                  </Button>
                </>
              )}
              <PolicySection />
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default OtpAndPassword;
