import { Button, Tab, Tabs, Typography } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import businessData from '../../../query/business';
import { handleApiRequest } from '../../../common/common';
import CreateTemplate from './CreateTemplate';

const Template = () => {
  const [tabValue, setTabValue] = useState(1);
  const [category, setCategory] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [templates, setTemplates] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const fetchTemplateCategory = async () => {
      try {
        const payload = {
          query: businessData.getCampaignServicesMessagesBusinessType,
        };
        const result = await handleApiRequest(payload);
        if (result?.getCampaignServicesMessagesBusinessType?.status === 200) {
          setCategory(result.getCampaignServicesMessagesBusinessType?.data);
        }
      } catch (error) {
        console.error('Error white fetching template category', error);
      }
    };
    fetchTemplateCategory();
  }, []);

  const handleGetTemplateCategoryWise = async (id, value) => {
    setSelectedCategoryId(id);
    try {
      const payload = {
        query: businessData.getCampaignStandardMessages,
        variables: {
          campaignPlatformId: value || tabValue,
          messagesBusinessTypeId: Number(id),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getCampaignStandardMessages?.status === 200) {
        setTemplates(result.getCampaignStandardMessages?.data);
      }
    } catch (error) {
      console.error('Error white fetching template', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    handleGetTemplateCategoryWise(selectedCategoryId, newValue);
  };

  return (
    <div className="p-24">
      <div className="sm:flex justify-between sm:mb-0 mb-10">
        <Typography className="font-bold text-28">{t('quickCampaign.template.title')}</Typography>
        <Button
          variant="contained"
          color="quickCampaign"
          autoFocus
          onClick={() => setOpenDialog(true)}
          size="medium"
          className="rounded-md gap-10 md:px-20 md:py-12"
        >
          <p className="text-16 font-medium"> {t('quickCampaign.campaign.createCampaign')}</p>
        </Button>
      </div>
      <div className="border-solid border-grey sm:flex justify-between items-center border-b-2 mb-32 overflow-x-auto">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          textColor="quickCampaign"
          indicatorColor="quickCampaign"
          aria-label="secondary tabs example"
        >
          <Tab
            className={`sm:text-18 font-bold mr-20 p-0 ${
              tabValue === 2 ? 'text-quick-campaign' : 'text-black'
            }`}
            value={2}
            label={t('quickCampaign.template.sms')}
          />
          <Tab
            className={`sm:text-18 font-bold mr-20 p-0 ${
              tabValue === 1 ? 'text-quick-campaign' : 'text-black'
            }`}
            value={1}
            label={t('quickCampaign.template.email')}
          />
          <Tab
            className={`sm:text-18 font-bold p-0 ${
              tabValue === 3 ? 'text-quick-campaign' : 'text-black'
            }`}
            value={3}
            label={t('quickCampaign.template.whatsapp')}
          />
        </Tabs>
      </div>
      <div className="lg:flex md:flex gap-24 ">
        <div className="mb-10 sm:mb-10 overflow-x-auto md:overscroll-hidden  md:min-w-256 lg:w-216 w-full">
          <div className="sm:flex flex md:block lg:block">
            {category?.length > 0 &&
              category
                ?.sort((a, b) => a.id - b.id)
                ?.map((item, index) => (
                  <div
                    key={index}
                    role="button"
                    tabIndex={0}
                    className={`flex px-12 py-16 min-w-216 cursor-pointer gap-8  items-center flex-shrink-0 ${
                      selectedCategoryId === item?.id
                        ? 'bg-quick-campaign text-white '
                        : ' bg-white '
                    }`}
                    onClick={() => handleGetTemplateCategoryWise(item?.id)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        setSelectedCategoryId(item?.id);
                      }
                    }}
                  >
                    <img className="w-60" src={item?.icon} alt={item?.name} />

                    <h3 className="text-16 font-medium flex-grow">{item?.type}</h3>
                  </div>
                ))}
          </div>
        </div>
        <div className="grid grid-cols-3 gap-16 h-max w-full">
          {templates?.length > 0 &&
            templates?.map((item, i) => {
              return (
                <div className="bg-white p-16 h-max rounded-md w-full" key={i}>
                  <Typography>{item?.title}</Typography>
                  {tabValue === 2 && (
                    <Typography className="bg-gray-200 p-8 mt-16 rounded-md h-112 overflow-scroll">
                      {item?.messages}
                    </Typography>
                  )}
                  {tabValue === 1 && (
                    <div className="bg-gray-200 p-8 mt-16 rounded-md h-256 overflow-scroll">
                      <Typography className="">
                        {t('quickCampaign.template.subject')} : <span>{item?.htmlSubject}</span>
                      </Typography>
                      <Typography
                        className="mt-8"
                        dangerouslySetInnerHTML={{ __html: item?.htmlTemplate }}
                      />
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <CreateTemplate openDialog={openDialog} setOpenDialog={setOpenDialog} category={category} />
    </div>
  );
};

export default Template;
