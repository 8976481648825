import { Button, Icon, Typography } from '@mui/material';
import { selectDashboardData } from 'app/store/userSlice';
import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import history from '@history';
import { useSelector } from 'react-redux';
import businessData from '../../query/business';
import { handleApiRequest } from '../../common/common';

const DashboardAlerts = ({ loading }) => {
  const [cardData, setCardData] = useState([]);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const isFirstItem = cardData?.length === 1;
  const settings = {
    dots: false,
    infinite: !isFirstItem,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,

    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchCardData = async () => {
      try {
        const payload = {
          query: businessData.getBusinessWhatsNewCardV1,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            productTypeId: 8,
          },
        };
        const result = await handleApiRequest(payload);
        if (
          result?.getBusinessWhatsNewCardV1?.status === 200 &&
          result.getBusinessWhatsNewCardV1?.data
        ) {
          setCardData(JSON.parse(result.getBusinessWhatsNewCardV1?.data?.cardJson));
        }
      } catch (error) {
        console.error('Error fetching card data:', error);
      }
    };
    if (userCurrentBusinessDetails?.id && !loading) {
      fetchCardData();
    }
  }, [loading, userCurrentBusinessDetails?.id]);

  return (
    <>
      {cardData?.length > 0 && (
        <Slider {...settings}>
          {cardData
            ?.filter((item) => item?.cardName !== 'defaultRatingCounter')
            ?.map((item, index) => {
              return (
                <div className="" key={index}>
                  {item && (
                    <div
                      className=" rounded-md relative shadow p-20 sm:p-28 py-28 sm:py-48 w-full"
                      style={{ backgroundColor: item?.backgroundColor }}
                    >
                      <div className="flex items-center w-full max-h-200 rounded-md">
                        <div className="w-2/3 flex flex-col gap-16 justify-center">
                          <Typography className="text-white font-700 text-16 sm:text-20">
                            {item?.title}
                          </Typography>
                          <Button
                            className="rounded-md text-quick-social bg-white hover:bg-white max-w-88 min-w-88 sm:max-w-112 sm:min-w-112 mt-8"
                            style={{
                              backgroundColor: item?.buttonColor,
                              '--hover-bg': item?.buttonColor,
                            }}
                            // onClick={() => handleButtons(item?.portalUrl)}
                          >
                            {item?.ctaButton}
                          </Button>
                        </div>
                        <div className="w-1/3 flex justify-center">
                          <img className="hidden w-192 sm:block" src={item?.icon} alt="" />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </Slider>
      )}
    </>
  );
};

export default DashboardAlerts;
