import {
  Avatar,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import EditIcon from '@mui/icons-material/Edit';
import { useCallback, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import FuseLoading from '@fuse/core/FuseLoading';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import history from '@history';
import { publicIpv4 } from 'public-ip';
import Icon from '@mui/material/Icon';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getCountryData } from 'app/store/userSlice';
import userData from '../query/user';
import {
  getDataToServerForAuth,
  getDecryptData,
  getEncryptedData,
  getParamsId,
  handleApiRequest,
  imageDataToServer,
  storeCsvFileDataToServer,
} from '../common/common';
import queryData from '../query/common';
import salesData from '../query/sales';
import userService from '../../services';

const UserProfile = () => {
  const defaultValues = {};
  const { control, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
  });
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [phoneValue, setPhoneValue] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [images, setImages] = useState();
  const [showCnfPassword, setShowCnfPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [profileRemove, setProfileRemove] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [phone, setPhone] = useState();
  const [orignalPhoneData, setOrignalPhoneData] = useState();
  const [isVerifyEmail, setIsVerifyEmail] = useState(false);
  const [isVerifyPhone, setIsVerifyPhone] = useState(false);
  const [emailOtp, setEmailOtp] = useState();
  const [phoneNumberOtp, setPhoneNumberOtp] = useState();
  const [phoneNumberOtpVerified, setPhoneNumberOtpVerified] = useState(false);
  const [emailOtpVerified, setEmailOtpVerified] = useState(false);
  const [currentPassword, setCurrentPassword] = useState();
  const [password, setPassword] = useState();
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isConfirmPasswordMatch, setIsConfirmPasswordMatch] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [country, setCountry] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [resendOtpStatus, setResendOtpStatus] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [verifyPasswordOtp, setVerifyPasswordOtp] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [isDashboard, setIsDashboard] = useState(false);
  const [isPasswordAvailable, setIsPasswordAvailable] = useState(false);
  const loginUserData = userService.getUserData();
  const [countryId, setCountryId] = useState(0);
  const [countryList, setCountryList] = useState([]);
  const countriesData = useSelector(getCountryData);
  const [userEditData, setUserEditData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
  });
  useEffect(() => {
    setCountryList(countriesData);
  }, [countriesData]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        clearInterval(interval);
        setResendOtpStatus(false);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    if (!country) {
      setCountry(loginUserData?.isoCode?.toLowerCase());
    }
  }, [country, loginUserData?.isoCode]);

  const fetchUserDetails = useCallback(async () => {
    setLoading(true);
    const payload = {
      query: userData.getProfile,
    };
    const result = await handleApiRequest(payload);
    setLoading(false);
    if (result?.getProfile?.status === 200) {
      const data = result?.getProfile?.data;
      setIsPasswordAvailable(data?.isPassword);
      setUserDetails(data);
      setUserEditData({
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        email: data.email,
      });
      setOrignalPhoneData(data?.phoneNumber);
      setPhoneValue(
        `${data?.phoneNumber?.countryCode?.slice(1)} + ${data.phoneNumber?.phoneNumber}`
      );
      setPhoneNumber(data?.phoneNumber?.phoneNumber);
      setPhone({
        dialCode: data?.phoneNumber?.countryCode?.slice(1),
      });
      if (data?.phoneNumber?.phoneNumber) {
        setPhoneNumberOtpVerified(true);
      }
      if (data?.email) {
        setEmailOtpVerified(true);
      }
    }
  }, []);

  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  const handleFileChange = async (event) => {
    const file = event?.target?.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    const reader = new FileReader();
    reader.onload = async () => {
      setImageUrl(reader.result);
      setImages(file);
      setProfileRemove(false);
    };
    reader.readAsDataURL(file);
  };

  const handlePhoneChange = (event, data, value, formattedValue) => {
    setPhoneNumber(event?.slice(data?.dialCode?.length));
    setPhoneValue(event);
    setPhone(data);
  };

  const onSubmit = async (data) => {
    setRefreshIcon(true);
    let fileData = {};
    if (!profileRemove) {
      fileData = {
        file: images,
      };
    }
    const obj = {
      query: salesData.updateUserProfilePicture,
      variables: {
        ...(!profileRemove && fileData),
        isRemove: profileRemove,
      },
    };
    let phoneNumberObject = {};
    if (
      phoneNumber !== null &&
      phoneNumber !== '' &&
      parseInt(userDetails?.phoneNumber?.phoneNumber, 10) !== parseInt(phoneNumber, 10)
    ) {
      phoneNumberObject = {
        phoneNumber: {
          countryCode: `+${phone?.dialCode}`,
          phoneNumber: parseInt(phoneNumber, 10),
        },
      };
    }
    if (profileRemove) {
      setImageUrl('');
    }

    const payload = {
      query: salesData.editProfile,
      variables: {
        firstName: userEditData?.firstName,
        lastName: userEditData?.lastName,
        middleName: userEditData?.middleName,
        email: userEditData?.email,
        ...(phoneNumberObject?.phoneNumber && phoneNumberObject),
        countryId,
      },
    };
    if (typeof profileRemove === 'boolean') {
      const res = await storeCsvFileDataToServer(obj);
      setRefreshIcon(false);
      if (res?.updateUserProfilePicture?.status === 200) {
        setIsEdit(false);
        setImages();
        setProfileRemove(false);
      }
    }
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (result?.editProfile?.status === 200) {
      setIsEdit(false);
      fetchUserDetails();
      enqueueSnackbar(result?.editProfile?.message, {
        variant: 'success',
        autoHideDuration: 2000,
      });
    } else {
      enqueueSnackbar(result?.editProfile?.message, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  if (loading) {
    return <FuseLoading />;
  }

  const handleCancel = async () => {
    setIsEdit(false);
    fetchUserDetails();
    setProfileRemove(false);
  };

  const verifyPhone = async () => {
    setSeconds(59);
    setMinutes(0);
    setRefreshIcon(true);
    const payload = {
      query: queryData.checkAndVerifyPhoneNumber,
      variables: {
        phoneNumber: parseInt(phoneNumber, 10),
      },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (
      result?.checkAndVerifyPhoneNumber?.status === 200 &&
      result?.checkAndVerifyPhoneNumber?.available === true
    ) {
      setRefreshIcon(true);
      const obj = {
        query: queryData.sendOTPOnPhoneNumberVerification,
        variables: {
          countryCode: `+${phone?.dialCode}`,
          phoneNumber: parseInt(phoneNumber, 10),
        },
      };
      const res = await getDataToServerForAuth(obj);
      setRefreshIcon(false);
      if (res?.sendOTPOnPhoneNumberVerification?.status === 200) {
        setIsVerifyPhone(true);
        enqueueSnackbar(res?.sendOTPOnPhoneNumberVerification?.message, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(res?.sendOTPOnPhoneNumberVerification?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } else {
      enqueueSnackbar(result?.checkAndVerifyPhoneNumber?.message, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const verifyEmail = async () => {
    setSeconds(59);
    setMinutes(0);
    const emailTest = /\S+@\S+\.\S+/;
    if (!emailTest.test(userEditData?.email)) {
      enqueueSnackbar(t('userProfile.validEmail'), {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } else {
      setRefreshIcon(true);
      const payload = {
        query: queryData.checkEmail,
        variables: {
          email: userEditData?.email,
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.checkEmail?.status === 200 && result?.checkEmail?.available === true) {
        setIsVerifyEmail(true);
        setRefreshIcon(true);
        const obj = {
          query: queryData.sendOTPOnEmailVerification,
          variables: {
            email: userEditData?.email,
          },
        };
        const res = await getDataToServerForAuth(obj);
        setRefreshIcon(false);
        if (res?.sendOTPOnEmailVerification?.status === 200) {
          enqueueSnackbar(res?.sendOTPOnEmailVerification?.message, {
            variant: 'success',
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res?.sendOTPOnEmailVerification?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } else {
        enqueueSnackbar(result?.checkEmail?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    }
  };

  const confirmEmailOtpVerify = async () => {
    setRefreshIcon(true);
    if (verifyPasswordOtp) {
      const payload = {
        query: queryData?.verifyOtpForSetPassword,
        variables: {
          email: userDetails?.email,
          otp: parseInt(emailOtp, 10),
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.verifyOtpForSetPassword?.status === 200) {
        setVerifyPasswordOtp(false);
        setEmailOtp();
        setRefreshIcon(true);
        const obj = {
          query: queryData?.setPassword,
          variables: {
            password,
          },
        };
        const res = await handleApiRequest(obj);
        setRefreshIcon(false);
        if (res?.setPassword?.status === 200) {
          setPassword();
          setIsPasswordAvailable(true);
          setConfirmPassword();
          setIsVerifyEmail(false);
          enqueueSnackbar(res?.setPassword?.message, {
            variant: 'success',
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res?.setPassword?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } else {
        enqueueSnackbar(result?.verifyOtpForSetPassword?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } else {
      setRefreshIcon(true);
      const data = getEncryptedData(`{"email": "${userEditData?.email}", "otp": ${emailOtp}}`);
      const payload = {
        query: queryData.verifyOTP,
        variables: { data },
      };
      const result = await getDataToServerForAuth(payload);
      setRefreshIcon(false);
      if (result?.verifyOTP?.status === 200) {
        setIsVerifyEmail(false);
        setEmailOtp();
        setEmailOtpVerified(true);
        setUserDetails({
          ...userDetails,
          email: userEditData?.email,
        });
        enqueueSnackbar(result?.verifyOTP?.message, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(result?.verifyOTP?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    }
  };

  const confirmPhoneNumberOtpVerify = async () => {
    setRefreshIcon(true);
    const data = getEncryptedData(
      JSON.stringify({
        countryCode: `+${phone?.dialCode}`,
        phoneNumber: parseInt(phoneNumber, 10),
        otp: parseInt(phoneNumberOtp, 10),
      })
    );
    const payload = {
      query: queryData.verifyPhoneNumberOTP,
      variables: { data },
    };
    const result = await getDataToServerForAuth(payload);
    setRefreshIcon(false);
    if (result?.verifyPhoneNumberOTP?.status === 200) {
      setIsVerifyPhone(false);
      setPhoneNumberOtp();
      setPhoneNumberOtpVerified(true);
      setOrignalPhoneData({
        phoneNumber,
      });
      enqueueSnackbar(result?.verifyPhoneNumberOTP?.message, {
        variant: 'success',
        autoHideDuration: 2000,
      });
    } else {
      enqueueSnackbar(result?.verifyPhoneNumberOTP?.message, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const resendEmailOtp = async () => {
    setSeconds(59);
    setMinutes(0);
    const obj = {
      query: queryData.sendOTPOnEmailVerification,
      variables: {
        email: userEditData?.email,
      },
    };
    const res = await getDataToServerForAuth(obj);
    if (res?.sendOTPOnEmailVerification?.status === 200) {
      enqueueSnackbar(res?.sendOTPOnEmailVerification?.message, {
        variant: 'success',
        autoHideDuration: 2000,
      });
    } else {
      enqueueSnackbar(res?.sendOTPOnEmailVerification?.message, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const resendPhoneOtp = async () => {
    setMinutes(0);
    setSeconds(59);
    const obj = {
      query: queryData.sendOTPOnPhoneNumberVerification,
      variables: {
        countryCode: `+${phone?.dialCode}`,
        phoneNumber: parseInt(phoneNumber, 10),
      },
    };
    const res = await getDataToServerForAuth(obj);
    if (res?.sendOTPOnPhoneNumberVerification?.status === 200) {
      enqueueSnackbar(res?.sendOTPOnPhoneNumberVerification?.message, {
        variant: 'success',
        autoHideDuration: 2000,
      });
    } else {
      enqueueSnackbar(res?.sendOTPOnPhoneNumberVerification?.message, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const verifyPasswordEmail = async () => {
    setNewPassword(false);
    setSeconds(59);
    setMinutes(0);
    setIsVerifyEmail(true);
    setRefreshIcon(true);
    const payload = {
      query: queryData?.sendOtpForSetPassword,
      variables: {
        email: userDetails?.email,
      },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (result?.sendOtpForSetPassword?.status === 200) {
      setVerifyPasswordOtp(true);
      enqueueSnackbar(result?.sendOtpForSetPassword?.message, {
        variant: 'success',
        autoHideDuration: 2000,
      });
    } else {
      enqueueSnackbar(result?.sendOtpForSetPassword?.message, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const handleSelectCountry = (e, userId, userIndex) => {
    const selectedCountryId = e.target.value;
    setCountryId(parseInt(selectedCountryId, 10));
    setValue(`[${userIndex}].countries.id`, selectedCountryId);
    const selectedCountry = countryList?.find((countries) => countries?.id === selectedCountryId);
    if (selectedCountry) {
      const updatedUserDetails = { ...userDetails };
      updatedUserDetails.countries = {
        id: selectedCountry?.id,
        name: selectedCountry?.name,
      };
      setUserDetails(updatedUserDetails);
    }
  };

  const saveChanges = async () => {
    setRefreshIcon(true);
    const data = {
      oldPassword: currentPassword,
      newPassword: password,
    };
    const payload = {
      query: queryData.resetPassword,
      variables: {
        data: getEncryptedData(JSON.stringify(data)),
      },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (result?.resetPassword?.status === 200) {
      setPassword('');
      setConfirmPassword('');
      setCurrentPassword('');
      setIsPasswordAvailable(true);
      setIsDashboard(true);
      setResetPassword(false);
      enqueueSnackbar(result?.resetPassword?.message, {
        variant: 'success',
        autoHideDuration: 2000,
      });
    } else {
      enqueueSnackbar(result?.resetPassword?.message, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const backToDashboard = () => {
    if (loginUserData?.roles?.type === 'business_user') {
      const id = getParamsId();
      if (id) {
        history.push(`/business/dashboard/${id}`);
      } else {
        history.push('/business/dashboard');
      }
    } else {
      setIsDashboard(false);
    }
  };

  const handleNewPasswordCancel = () => {
    setPassword();
    setConfirmPassword();
    setNewPassword(false);
  };

  const handleCancelResetPassword = () => {
    setPassword();
    setConfirmPassword();
    setCurrentPassword();
    setResetPassword(false);
  };

  return (
    <div className="p-20 lg:p-32">
      <h1 className="font-bold text-28">{t('userProfile.myProfile')}</h1>
      {isVerifyEmail && (
        <div className="shadow bg-white mt-28 rounded-xl relative h-[90%]">
          <div className="flex flex-1  items-center justify-center h-[100%] text-center">
            <div className="text-center py-136">
              <img className="m-auto" src="/assets/images/icon/Group36579.svg" alt="" />
              <h2 className="py-32 text-28 font-bold">{t('userProfile.verifyYourEmail')}</h2>
              <p className="text-16 w-[300px] font-bold m-auto">
                {t('userProfile.emailCode')} {userEditData?.email}
              </p>
              <div className="w-[280px] m-auto">
                <MuiOtpInput
                  className="max-w-xs my-24"
                  TextFieldsProps={{
                    size: 'small',
                    placeholder: '-',
                    type: 'number',
                  }}
                  type="number"
                  gap={1}
                  length={6}
                  value={emailOtp}
                  onChange={(newValue) => setEmailOtp(newValue)}
                />
                <div className="card">
                  <div className="countdown-text flex items-center justify-center text-12 font-medium mt-4">
                    {seconds > 0 || minutes > 0 ? (
                      <p>
                        The OTP can be resent in: {minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </p>
                    ) : (
                      <p>Didn't receive the OTP?</p>
                    )}
                    {resendOtpStatus ? (
                      <CircularProgress
                        size={24}
                        className="text-14 ml-10 mt-1 text-darkgreen mx-auto"
                      />
                    ) : (
                      <Button
                        disabled={seconds > 0}
                        className="text-end hover:bg-white font-bold text-darkgreen"
                        type="button"
                        onClick={() => resendEmailOtp()}
                      >
                        Resend OTP
                      </Button>
                    )}
                  </div>
                </div>
                <Button
                  className="rounded mt-16 w-full bg-darkgreen disabled:text-black disabled:bg-gray hover:bg-darkgreen text-white text-16 font-bold mr-12"
                  variant="contained"
                  color="secondary"
                  type="button"
                  disabled={refreshIcon || !emailOtp}
                  onClick={() => confirmEmailOtpVerify()}
                >
                  {t('userProfile.confirm')}
                  {refreshIcon && (
                    <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isVerifyPhone && (
        <div className="shadow bg-white mt-28 rounded-xl relative h-[90%]">
          <div className="flex flex-1  items-center justify-center h-[100%] text-center">
            <div className="text-center py-136">
              <img className="m-auto" src="/assets/images/icon/Group036580.svg" alt="" />
              <h2 className="py-32 text-28 font-bold">{t('userProfile.verifyYourPhone')}</h2>
              <p className="text-16 w-[300px] font-bold m-auto">
                {t('userProfile.smsCode')} {phoneNumber}
              </p>
              <div className="w-[280px] m-auto">
                <MuiOtpInput
                  className="max-w-xs my-24"
                  TextFieldsProps={{
                    size: 'small',
                    placeholder: '-',
                    type: 'number',
                  }}
                  type="number"
                  gap={1}
                  length={6}
                  value={phoneNumberOtp}
                  onChange={(e) => setPhoneNumberOtp(e)}
                />
                <div className="card">
                  <div className="countdown-text flex items-center justify-center text-12 font-medium mt-4">
                    {seconds > 0 || minutes > 0 ? (
                      <p>
                        The code can be resent in: {minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </p>
                    ) : (
                      <p>Didn't receive the Otp?</p>
                    )}
                    {resendOtpStatus ? (
                      <CircularProgress
                        size={24}
                        className="text-14 ml-10 mt-1 text-darkgreen mx-auto"
                      />
                    ) : (
                      <Button
                        disabled={seconds > 0}
                        className="text-end hover:bg-white font-bold text-darkgreen"
                        type="button"
                        style={{
                          color: seconds > 0 ? '#DFE3E8' : '#0BA861',
                        }}
                        onClick={() => resendPhoneOtp()}
                      >
                        Resend OTP
                      </Button>
                    )}
                  </div>
                </div>
                <Button
                  className="rounded mt-16 w-full disabled:text-black disabled:bg-gray bg-darkgreen hover:bg-darkgreen text-white text-16 font-bold mr-12"
                  variant="contained"
                  color="secondary"
                  type="button"
                  disabled={refreshIcon || !phoneNumberOtp}
                  onClick={() => confirmPhoneNumberOtpVerify()}
                >
                  {t('userProfile.confirm')}
                  {refreshIcon && (
                    <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isVerifyEmail && !isVerifyPhone && !newPassword && !resetPassword && !isDashboard && (
        <div className="shadow bg-white mt-28 rounded-xl relative max-w-4xl">
          <div className="flex justify-between items-center p-16">
            <Typography className="font-bold text-16 sm:text-20">
              {t('userProfile.profileDetails')}
            </Typography>
            <div>
              {!isEdit ? (
                <Button
                  className="rounded-6 px-32 bg-darkgreen hover:bg-darkgreen text-white text-16"
                  variant="contained"
                  type="button"
                  onClick={() => setIsEdit(true)}
                >
                  <EditIcon className="pr-6" /> {t('userProfile.edit')}
                </Button>
              ) : (
                <div className="flex justify-between gap-5 sm:gap-10">
                  <Button
                    className="rounded px-3 sm:px-20 bg-white hover:bg-white border-1 border-solid border-darkgreen text-darkgreen text-16 font-500"
                    variant="contained"
                    color="secondary"
                    type="button"
                    onClick={() => handleCancel()}
                  >
                    {t('userProfile.cancel')}
                  </Button>

                  {(phoneNumber && !phoneNumberOtpVerified) ||
                  (orignalPhoneData?.phoneNumber &&
                    parseInt(orignalPhoneData?.phoneNumber, 10) !== parseInt(phoneNumber, 10)) ? (
                    <Button
                      className="rounded px-20 disabled:text-black disabled:bg-gray text-16 font-500 mr-12"
                      variant="contained"
                      color="secondary"
                      type="button"
                      disabled={refreshIcon || !phoneNumber}
                      onClick={() => verifyPhone()}
                    >
                      {t('userProfile.verifyPhone')}
                      {refreshIcon && (
                        <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                      )}
                    </Button>
                  ) : (userEditData?.email && !emailOtpVerified) ||
                    (userDetails?.email && userDetails?.email !== userEditData?.email) ? (
                    <Button
                      className="rounded px-20 disabled:text-black disabled:bg-gray text-16 font-500 mr-12"
                      variant="contained"
                      color="secondary"
                      type="button"
                      disabled={refreshIcon || !userEditData?.email}
                      onClick={() => verifyEmail()}
                    >
                      {t('userProfile.verifyEmail')}
                      {refreshIcon && (
                        <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                      )}
                    </Button>
                  ) : (
                    <Button
                      className="rounded px-3 sm:px-32 hover:bg-darkgreen disabled:text-black disabled:bg-gray text-16 font-500 "
                      variant="contained"
                      color="secondary"
                      type="button"
                      disabled={refreshIcon}
                      onClick={() => onSubmit()}
                    >
                      {t('userProfile.save')}
                      {refreshIcon && (
                        <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                      )}
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
          <hr />
          <div>
            <div className="grid grid-cols-2 pt-12 p-16">
              <Typography className="font-500 col-span-1">{t('userProfile.photo')}</Typography>
              <div>
                {!isEdit ? (
                  <Avatar
                    className="w-[80px] h-[80px] capitalize text-40"
                    src={userDetails?.profileUrl}
                  />
                ) : (
                  <div className="col-span-4 sm:col-span-3 lg:col-span-2 relative">
                    <label htmlFor="button-file">
                      <TextField
                        id="button-file"
                        className="hidden"
                        onChange={(event) => {
                          handleFileChange(event);
                        }}
                        type="file"
                        accept="image/*"
                      />
                      <Button
                        className="bg-darkgreen absolute left-[62px] z-10 max-w-[22px] max-h-[22px] min-w-[22px] min-h-[22px] hover:bg-darkgreen"
                        component="span"
                      >
                        <EditIcon className="text-white text-lg" />{' '}
                      </Button>
                    </label>
                    <Avatar
                      className="w-[80px] h-[80px] capitalize text-40"
                      src={!profileRemove && (imageUrl || userDetails?.profileUrl)}
                    />
                    <Button
                      className="bg-white absolute left-[62px] top-[54px] z-10 max-w-[22px] max-h-[22px] min-w-[22px] min-h-[22px] hover:bg-white"
                      onClick={() => setProfileRemove(true)}
                    >
                      <CloseIcon className="text-black text-lg" />
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <hr />
            <div className="grid grid-cols-2 p-16">
              <div>
                {!isEdit ? (
                  <Typography className="font-500 col-span-1">
                    {t('userProfile.filedName.firstName')}
                  </Typography>
                ) : (
                  <Typography className="font-500 col-span-1">
                    {t('userProfile.filedName.firstName')}{' '}
                  </Typography>
                )}
              </div>
              <div>
                {!isEdit ? (
                  <Typography className="text-grey-700 font-500">
                    {userDetails?.firstName ? userDetails?.firstName : '-'}
                  </Typography>
                ) : (
                  <div className="col-span-4 sm:col-span-3 lg:col-span-2">
                    <TextField
                      className=" max-w-sm"
                      type="text"
                      value={userEditData?.firstName}
                      variant="outlined"
                      onChange={(e) => {
                        const regex = /^[A-Za-z]+$/;
                        const { value } = e.target;
                        if (value === '' || regex.test(value)) {
                          setUserEditData({
                            ...userEditData,
                            firstName: value,
                          });
                        }
                      }}
                      fullWidth
                      size="small"
                    />
                  </div>
                )}
              </div>
            </div>
            <hr />
            {/* <div className="grid grid-cols-2 p-16">
              <Typography className="font-bold  col-span-1">
                {t('userProfile.middleName')}
              </Typography>
              {!isEdit ? (
                <Typography className='text-grey-700 font-500'>{userDetails?.middleName ? userDetails?.middleName : '-'}</Typography>
              ) : (
                <div className="col-span-4 sm:col-span-3 lg:col-span-2">
                  <TextField
                    className="mb-12 max-w-sm"
                    type="text"
                    value={userEditData?.middleName}
                    variant="outlined"
                    onChange={(e) => {
                      setUserEditData({
                        ...userEditData,
                        middleName: e.target.value,
                      });
                    }}
                    fullWidth
                    size="small"
                  />
                </div>
              )}
            </div>
            <hr /> */}
            <div className="grid grid-cols-2 p-16">
              <div>
                {!isEdit ? (
                  <Typography className="font-500 col-span-1">
                    {t('userProfile.filedName.lastName')}
                  </Typography>
                ) : (
                  <Typography className="font-500 col-span-1">
                    {t('userProfile.filedName.lastName')}{' '}
                  </Typography>
                )}
              </div>
              <div>
                {!isEdit ? (
                  <Typography className="col-span-4 text-grey-700 font-500">
                    {userDetails?.lastName ? userDetails?.lastName : '-'}
                  </Typography>
                ) : (
                  <div className="col-span-4 sm:col-span-3 lg:col-span-2">
                    <TextField
                      className="max-w-sm"
                      type="text"
                      value={userEditData?.lastName}
                      onChange={(e) => {
                        const regex = /^[A-Za-z]+$/;
                        const { value } = e.target;
                        if (value === '' || regex.test(value)) {
                          setUserEditData({
                            ...userEditData,
                            lastName: value,
                          });
                        }
                      }}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </div>
                )}
              </div>
            </div>
            <hr />
            <div className="grid grid-cols-2 p-16">
              <Typography className="font-500 col-span-1 flex items-center">
                {t('userProfile.filedName.businessPhone')}
              </Typography>
              <div>
                {!isEdit ? (
                  <Typography className="col-span-4 text-grey-700 font-500">
                    {userDetails?.phoneNumber?.phoneNumber
                      ? `${userDetails?.phoneNumber?.countryCode} ${userDetails?.phoneNumber?.phoneNumber}`
                      : '-'}
                  </Typography>
                ) : (
                  <div
                    className={`col-span-4 ${
                      userDetails?.phoneNumber?.phoneNumber !== null ? 'phone' : 'text-black'
                    } sm:col-span-3 lg:col-span-2`}
                  >
                    <PhoneInput
                      placeholder={t('signUpPage.formField.phoneNumber.placeHolder')}
                      inputStyle={{
                        width: '100%',
                        color: '#111827',
                      }}
                      className="w-full max-w-sm text-black"
                      name="phone_number"
                      required
                      value={phoneValue}
                      country={country}
                      enableSearch="true"
                      onChange={handlePhoneChange}
                    />
                  </div>
                )}
              </div>
            </div>
            <hr />
            <div className="grid grid-cols-2 p-16">
              <Typography className="font-500  col-span-1 flex items-center">
                {t('userProfile.email')}
              </Typography>
              <div>
                {!isEdit ? (
                  <div className="sm:flex gap-16 col-span-4 item-center">
                    <Typography className="text-grey-700 font-500">
                      {userDetails?.email ? userDetails?.email : '-'}
                    </Typography>
                    {userDetails?.email && loginUserData?.roles?.type !== 'admin' && (
                      <>
                        {isPasswordAvailable ? (
                          <Button
                            className="md:px-5 mt-16 text-12 sm:mt-0 bg-[#C9E7CA] hover:bg-[#C9E7CA] text-[#4CAF50] font-bold rounded min-h-[25px] h-[25px]"
                            onClick={() => setResetPassword(true)}
                          >
                            {t('userProfile.resetPassword')}
                          </Button>
                        ) : (
                          <Button
                            className="md:px-5 mt-16 text-12 sm:mt-0 bg-[#C9E7CA] hover:bg-[#C9E7CA] text-[#4CAF50] font-bold rounded min-h-[25px] h-[25px]"
                            onClick={() => setNewPassword(true)}
                          >
                            {t('userProfile.setPassword')}
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <div className="col-span-4 sm:col-span-3 lg:col-span-2">
                    <TextField
                      className="max-w-sm"
                      value={userEditData?.email}
                      type="text"
                      onChange={(e) => {
                        setUserEditData({
                          ...userEditData,
                          email: e.target.value,
                        });
                      }}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </div>
                )}
              </div>
            </div>
            <hr />
            <div className="grid grid-cols-2 p-16">
              <Typography className="font-500  col-span-1 flex items-center">
                {t('signUpPage.formField.country.name')}
              </Typography>
              <div>
                {!isEdit ? (
                  <div className="sm:flex gap-16 col-span-4 item-center">
                    <Typography className="text-grey-700 font-500">
                      {userDetails?.countries?.name ? userDetails?.countries?.name : '-'}
                    </Typography>
                  </div>
                ) : (
                  <div className="col-span-4 sm:col-span-3 lg:col-span-2">
                    <Controller
                      name={`businesses[${userDetails?.countries?.id}].countries`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          className="max-w-sm w-full"
                          id="country"
                          value={userDetails?.countries?.id}
                          displayEmpty
                          onChange={(e) =>
                            handleSelectCountry(e, userDetails?.id, userDetails?.countries?.id)
                          }
                          inputProps={{
                            sx: { padding: '5px', paddingLeft: '13px' },
                          }}
                        >
                          <MenuItem value={0} disabled>
                            {t('signUpPage.formField.country.selectCountry')}
                          </MenuItem>
                          {countryList?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {newPassword && (
        <div className="shadow bg-white mt-28 rounded-xl relative h-[100%]">
          <div className="text-center pt-80">
            <img className="m-auto" src="/assets/images/icon/Group36145.svg" alt="" />
            <h2 className="py-32 text-28 font-bold">{t('userProfile.setYourPassword')}</h2>
            <p className="text-16 w-[300px] font-bold m-auto">
              {t('userProfile.setPasswordMessage')}
            </p>
            <div className="w-[300px] m-auto">
              <TextField
                className="w-full pt-20 text-black"
                placeholder={t('userProfile.password')}
                value={password}
                onChange={(e) => {
                  const passwordValue = e.target.value.replace(/\s/g, '');
                  setPassword(passwordValue);
                  const passwordRegex =
                    /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
                  if (password?.length < 8) {
                    setIsPasswordValid(true);
                    setPasswordMessage(
                      t('users.resetPassword.formFields.validationMessages.passwordTooShort')
                    );
                  } else if (!passwordRegex.test(password)) {
                    setIsPasswordValid(true);
                    setPasswordMessage(
                      t('users.resetPassword.formFields.validationMessages.passwordRequirements')
                    );
                  } else {
                    setIsPasswordValid(false);
                    setPasswordMessage();
                  }
                }}
                size="small"
                InputProps={{
                  className: 'pr-2',
                  type: showPassword ? 'text' : 'password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        <Icon className="text-20" color="action">
                          {showPassword ? 'visibility' : 'visibility_off'}
                        </Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {isPasswordValid && (
                <Typography className="text-red text-12 pt-4">{passwordMessage}</Typography>
              )}
              <TextField
                className="w-full pt-24 text-black"
                placeholder={t('userProfile.confirmPassword')}
                value={confirmPassword}
                onChange={(e) => {
                  const confirmPasswordValue = e.target.value.replace(/\s/g, '');
                  setConfirmPassword(confirmPasswordValue);
                  if (password !== confirmPasswordValue) {
                    setIsConfirmPasswordMatch(true);
                    setPasswordMessage(
                      t('users.resetPassword.formFields.validationMessages.confirmPasswordMatch')
                    );
                  } else {
                    setIsConfirmPasswordMatch(false);
                    setPasswordMessage();
                  }
                }}
                size="small"
                InputProps={{
                  className: 'pr-2',
                  type: showCnfPassword ? 'text' : 'password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowCnfPassword(!showCnfPassword)}>
                        <Icon className="text-20" color="action">
                          {showCnfPassword ? 'visibility' : 'visibility_off'}
                        </Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {isConfirmPasswordMatch && (
                <Typography className="text-red text-12 pt-4">{passwordMessage}</Typography>
              )}
              <div className="flex mt-24">
                <Button
                  className="rounded mt-16 w-full bg-white hover:bg-white border-1 border-solid border-darkgreen text-darkgreen text-16 font-bold mr-12"
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={() => handleNewPasswordCancel()}
                >
                  {t('userProfile.cancel')}
                </Button>
                <Button
                  className="rounded mt-16 w-full bg-darkgreen hover:bg-darkgreen disabled:border-none border-1 border-solid border-darkgreen text-white text-16 font-bold mr-12"
                  variant="contained"
                  color="secondary"
                  type="button"
                  disabled={isPasswordValid || password !== confirmPassword || !password}
                  onClick={() => verifyPasswordEmail()}
                >
                  {t('userProfile.verifyEmail')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {resetPassword && (
        <div className="shadow bg-white mt-28 rounded-xl relative max-h-2xl max-w-2xl">
          <div className="flex p-16">
            <h2 className="py-10 w-1/2 text-16 sm:text-20 font-bold">
              {t('userProfile.resetYourPassword')}
            </h2>
            <div className=" w-1/2 flex gap-10 justify-end place-items-center">
              <Button
                className="rounded  px-3 sm:px-20 bg-white hover:bg-white border-1 border-solid border-darkgreen text-darkgreen text-12 sm:text-16 font-500"
                variant="contained"
                color="secondary"
                type="button"
                onClick={() => handleCancelResetPassword()}
              >
                {t('userProfile.cancel')}
              </Button>
              <Button
                className="rounded px-3 sm:px-28 disabled:bg-gray bg-darkgreen hover:bg-darkgreen text-white text-12 sm:text-16 font-500"
                variant="contained"
                color="secondary"
                type="button"
                disabled={
                  refreshIcon ||
                  isPasswordValid ||
                  !password ||
                  password !== confirmPassword ||
                  !currentPassword
                }
                onClick={() => saveChanges()}
              >
                {t('userProfile.save')}
                {refreshIcon && (
                  <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                )}
              </Button>
            </div>
          </div>
          <hr />
          <p className="text-16 pt-10 pl-16 pb-10 font-bold m-auto">
            {t('userProfile.resetPasswordMessage')}
          </p>
          <div className="text-center py-20">
            <div className="m-20 sm:m-auto">
              <div className="w-full flex flex-col  sm:items-start items-center sm:flex-row">
                <p className="text-16 text-start  w-full sm:w-1/2 pl-0 sm:pl-16 font-500 m-auto">
                  {t('userProfile.currentPassword')}
                </p>
                <TextField
                  className="w-full sm:w-1/2 flex pr-14 text-black"
                  placeholder={t('userProfile.currentPassword')}
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  size="small"
                  InputProps={{
                    className: 'pr-2',
                    type: showCurrentPassword ? 'text' : 'password',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
                          <Icon className="text-20" color="action">
                            {showCurrentPassword ? 'visibility' : 'visibility_off'}
                          </Icon>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <hr className="my-20" />
              <div className="w-full flex flex-col  sm:items-start items-center sm:flex-row">
                <p className="text-16 text-start  w-full sm:w-1/2 pl-0 sm:pl-16 font-500 m-auto">
                  {t('userProfile.newPassword')}
                </p>
                <TextField
                  className="w-full sm:w-1/2 flex pr-14 text-black"
                  placeholder={t('userProfile.newPassword')}
                  value={password}
                  onChange={(e) => {
                    const passwordValue = e.target.value;
                    setPassword(passwordValue);
                    const passwordRegex =
                      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
                    if (password?.length < 8) {
                      setIsPasswordValid(true);
                      setPasswordMessage(
                        t('users.resetPassword.formFields.validationMessages.passwordTooShort')
                      );
                    } else if (!passwordRegex.test(password)) {
                      setIsPasswordValid(true);
                      setPasswordMessage(
                        t('users.resetPassword.formFields.validationMessages.passwordRequirements')
                      );
                    } else {
                      setIsPasswordValid(false);
                      setPasswordMessage();
                    }
                  }}
                  size="small"
                  InputProps={{
                    className: 'pr-2',
                    type: showPassword ? 'text' : 'password',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)}>
                          <Icon className="text-20" color="action">
                            {showPassword ? 'visibility' : 'visibility_off'}
                          </Icon>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <hr className="my-20" />
              <div className="w-full flex flex-col  sm:items-start items-center sm:flex-row">
                <p className="text-16 text-start  w-full sm:w-1/2 pl-0 sm:pl-16 font-500 m-auto">
                  {t('userProfile.confirmNewPassword')}
                </p>
                {isPasswordValid && (
                  <Typography className="text-red text-12 pt-4">{passwordMessage}</Typography>
                )}
                <TextField
                  className="w-full sm:w-1/2 flex pr-14 text-black"
                  placeholder={t('userProfile.confirmNewPassword')}
                  value={confirmPassword}
                  onChange={(e) => {
                    const confirmPasswordValue = e.target.value;
                    setConfirmPassword(confirmPasswordValue);
                    if (password !== confirmPasswordValue) {
                      setIsConfirmPasswordMatch(true);
                      setPasswordMessage(
                        t('users.resetPassword.formFields.validationMessages.confirmPasswordMatch')
                      );
                    } else {
                      setIsConfirmPasswordMatch(false);
                      setPasswordMessage();
                    }
                  }}
                  size="small"
                  InputProps={{
                    className: 'pr-2',
                    type: showCnfPassword ? 'text' : 'password',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowCnfPassword(!showCnfPassword)}>
                          <Icon className="text-20" color="action">
                            {showCnfPassword ? 'visibility' : 'visibility_off'}
                          </Icon>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {isConfirmPasswordMatch && (
                <Typography className="text-red text-12 pt-4">{passwordMessage}</Typography>
              )}
            </div>
          </div>
        </div>
      )}
      {isDashboard && (
        <div className="shadow bg-white mt-28 rounded-xl relative h-[100%]">
          <div className="text-center py-88">
            <img className="m-auto" src="/assets/images/icon/successfully_icon.png" alt="" />
            <h2 className="py-32 text-28 font-bold">{t('userProfile.passwordChanged')}</h2>
            <p className="text-16 w-[550px] font-bold m-auto">
              {t('userProfile.passwordChangedMessage')}
            </p>
            <div className="w-[330px] m-auto">
              <div className="flex mt-24">
                <Button
                  className="rounded mt-16 w-full bg-darkgreen hover:bg-darkgreen border-1 border-solid border-darkgreen text-white text-16 font-bold mr-12"
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={() => backToDashboard()}
                >
                  {t('userProfile.backToDashboard')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
