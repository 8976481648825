import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { t } from 'i18next';
import moment from 'moment';

const AnalyticsDialogBox = ({ open, onClose, post, activeStep }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delete-confirmation-dialog"
      classes={{
        paper: `business-type-dropdown rounded-md`,
      }}
      PaperProps={{
        style: {
          width: '750px',
          maxWidth: '100%',
          height: '700px',
          maxHeight: '90vh',
          padding: '20px',
        },
      }}
    >
      <div className="flex justify-between mb-10 ">
        <Typography className="text-20 font-600">{t('quickSocial.postAnalytics')}</Typography>
        <IconButton className="" onClick={onClose} color="inherit">
          <CloseIcon className="w-20 h-20 text-grey-700" />
        </IconButton>
      </div>

      <div className="bg-indigo-50 bg-opacity-50 p-20 rounded-md mb-16 flex gap-16">
        <img src={post?.userProfile} className="max-w-144 rounded-md inline-block" alt="..." />
        <div className="w-full flex flex-col justify-between">
          <div className="flex flex-col gap-20">
            <div className="flex justify-between">
              <div className="flex gap-20">
                <img
                  src={post?.userProfile}
                  className="w-60 h-60 rounded-full inline-block"
                  alt="..."
                />
                <div>
                  <span className="block font-medium text-20 mb-4">{post?.userName}</span>
                  <span className="text-16 font-medium">
                    {moment(post?.postcreatedTime).format('DD MMM YYYY h:mm:ss A')}
                  </span>
                </div>
              </div>
            </div>
            <p className="text-16 font-500">{post?.caption}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col  ">
        <div className="flex gap-10 mb-10 ">
          <Typography className="text-20 font-600">{t('quickSocial.discovery')}</Typography>
          <Tooltip
            title="hello world"
            arrow
            classes={{
              tooltip: 'bg-quick-social',
              arrow: 'text-quick-social',
            }}
          >
            <img src="/assets/images/icon/tooltipForSocial.svg" alt="good" className="ml-6" />
          </Tooltip>
        </div>
        <div className="grid gap-20 grid-cols-2">
          {(activeStep === 10 && post?.insights?.title?.Impressions !== undefined) ||
            (activeStep === 9 && post?.insights?.post_impressions !== undefined && (
              <div className="bg-indigo-50 p-10 flex gap-20 items-center bg-opacity-50 ">
                <img
                  src="/assets/images/icon/impressionIcon.png"
                  alt="impression icon"
                  className="ml-6"
                />
                <div>
                  <p className="text-36 font-600">
                    {activeStep === 9
                      ? post.insights.post_impressions
                      : activeStep === 10
                      ? post?.insights?.Impressions
                      : null}
                  </p>
                  <p className="text-20 font-600">Impressions</p>
                </div>
              </div>
            ))}
          {(activeStep === 10 && post?.insights?.title?.['Accounts reached'] !== undefined) ||
            (activeStep === 9 && post?.insights?.post_impressions_unique !== undefined && (
              <div className="bg-indigo-50 p-10 flex gap-20 items-center bg-opacity-50 ">
                <img
                  src="assets/images/quick-hub/quick-social/engagement.png"
                  alt="impression icon"
                  className="ml-6"
                />
                <div>
                  <p className="text-36 font-600">
                    {activeStep === 9
                      ? post.insights.post_impressions_unique
                      : activeStep === 10
                      ? post?.insights?.['Accounts reached']
                      : null}
                  </p>
                  <p className="text-20 font-600">Unique Views</p>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="flex mt-10 flex-col  ">
        <div className="flex gap-10 mb-10 ">
          <Typography className="text-20 font-600">{t('quickSocial.engagements')}</Typography>
          <Tooltip
            title="hello world"
            arrow
            classes={{
              tooltip: 'bg-quick-social',
              arrow: 'text-quick-social',
            }}
          >
            <img src="/assets/images/icon/tooltipForSocial.svg" alt="good" className="ml-6" />
          </Tooltip>
        </div>
        <div className="grid gap-20 grid-cols-2">
          {(activeStep === 10 && post?.insights?.title?.Likes !== undefined) ||
            (activeStep === 9 && post?.insights?.likes !== undefined && (
              <div className="bg-indigo-50 p-10 flex gap-20 items-center bg-opacity-50 ">
                <img
                  src="/assets/images/icon/likeIcon.png"
                  alt="impression icon"
                  className="ml-6"
                />
                <div>
                  <p className="text-36 font-600">
                    {activeStep === 9
                      ? post.insights.likes
                      : activeStep === 10
                      ? post?.insights?.Likes
                      : null}
                  </p>
                  <p className="text-20 font-600">Likes</p>
                </div>
              </div>
            ))}
          {(activeStep === 10 && post?.insights?.title?.Comments !== undefined) ||
            (activeStep === 9 && post?.insights?.comments !== undefined && (
              <div className="bg-indigo-50 p-10 flex gap-20 items-center bg-opacity-50 ">
                <img
                  src="/assets/images/icon/commentsIcon.png"
                  alt="impression icon"
                  className="ml-6"
                />
                <div>
                  <p className="text-36 font-600">
                    {activeStep === 9
                      ? post.insights.comments
                      : activeStep === 10
                      ? post?.insights?.Comments
                      : null}
                  </p>
                  <p className="text-20 font-600">Comments</p>
                </div>
              </div>
            ))}
          {(activeStep === 10 && post?.insights?.title?.Shares !== undefined) ||
            (activeStep === 9 && post?.insights?.repost !== undefined && (
              <div className="bg-indigo-50 p-10 flex gap-20 items-center bg-opacity-50 ">
                <img
                  src="/assets/images/icon/repostIcon.svg"
                  alt="impression icon"
                  className="ml-6"
                />
                <div>
                  <p className="text-36 font-600">
                    {activeStep === 9
                      ? post.insights.repost
                      : activeStep === 10
                      ? post?.insights?.Comments
                      : null}
                  </p>
                  <p className="text-20 font-600">Repost</p>
                </div>
              </div>
            ))}
          {(activeStep === 10 && post?.insights?.title?.['Post interactions'] !== undefined) ||
            (activeStep === 9 && post?.insights?.reactions !== undefined && (
              <div className="bg-indigo-50 p-10 flex gap-20 items-center bg-opacity-50 ">
                <img
                  src="assets/images/quick-hub/quick-social/reach.png"
                  alt="impression icon"
                  className="ml-6"
                />
                <div>
                  <p className="text-36 font-600">
                    {activeStep === 9
                      ? post.insights.reactions
                      : activeStep === 10
                      ? post?.insights?.['Post interactions']
                      : null}
                  </p>
                  <p className="text-20 font-600">Reactions</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </Dialog>
  );
};

export default AnalyticsDialogBox;
