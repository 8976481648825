import { Dialog, DialogTitle, Icon, Typography } from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import { t } from 'i18next';
import FuseLoading from '@fuse/core/FuseLoading';
import wabaQuery from '../../query/waba';
import { handleWabaApiRequest, convertTemplateToHtml } from '../../common/common';

const PreviewMessage = (props) => {
  const { openValue, cancelCall, popUpMessage, waBroadcastId } = props;

  const [dialogOpen, setDialogOpen] = useState(openValue);
  const [getBroadCastList, setGetBroadCastList] = useState();
  const [totalBroadCastList, setTotalBroadCastList] = useState();
  const [broadCastListStatusCount, setBroadCastListStatusCount] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);

  const handleBroadCast = useCallback(async () => {
    try {
      setLoading(true);
      const payload = {
        query: wabaQuery.getBroadcastTemplate,
        variables: {
          waBroadcastId,
        },
      };
      const res = await handleWabaApiRequest(payload);
      setLoading(false);
      if (res?.getBroadcastTemplate?.status === 200) {
        setGetBroadCastList(res?.getBroadcastTemplate?.data);
        setTotalBroadCastList(res?.getWABroadCastContactList?.totalCount);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [waBroadcastId]);

  useEffect(() => {
    try {
      const BroadCast = async () => {
        const payload = {
          query: wabaQuery.getWABroadCastPcData,
          variables: {
            waBroadcastId,
          },
        };
        const res = await handleWabaApiRequest(payload);
        if (res?.getWABroadCastPcData?.status === 200) {
          setBroadCastListStatusCount(res?.getWABroadCastPcData?.data);
        }
      };

      BroadCast();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [waBroadcastId]);

  useEffect(() => {
    handleBroadCast();
  }, [handleBroadCast]);

  const handleClose = () => {
    setDialogOpen(false);
    cancelCall();
  };

  return (
    <Dialog
      classes={{
        paper:
          'm-24 width-full border-t-8 border-solid border-t-darkgreen border-darkgreen rounded-md',
      }}
      open={openValue}
      onKeyDown={(event) => {
        if (event.keyCode === 27) {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle className="p-0 py-16 px-20 border-b-1 border-solid border-grey-300">
        <Icon
          onClick={() => handleClose()}
          className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
        >
          cancel
        </Icon>
        <Typography className="text-20 font-semibold text-black w-full mb-0">
          {popUpMessage}
        </Typography>
      </DialogTitle>
      <div className="w-full p-20 text-center">
        <div className="relative inline-block mx-auto">
          <img
            src="/assets/images/business/mobile_frame_waba.png"
            className="img-fluid mx-auto"
            alt="..."
          />

          <div className="absolute top-144 left-0 right-0 bg-white rounded-md px-8 py-8 max-w-256 mx-auto">
            {getBroadCastList?.templateData &&
              JSON.parse(getBroadCastList?.templateData)?.components?.map((templateValue, i) => (
                <div className="block max-w-320 text-left" key={i}>
                  <div>
                    {templateValue?.type === 'HEADER' && (
                      <>
                        {templateValue?.format === 'TEXT' && (
                          <Typography className="mb-10 font-bold">{templateValue?.text}</Typography>
                        )}
                        {templateValue?.format === 'IMAGE' && (
                          <>
                            {JSON.parse(getBroadCastList?.dynamicData)?.header?.map(
                              (dynamicValue) => (
                                <>
                                  {dynamicValue?.type === 'image' && (
                                    <img
                                      src={dynamicValue?.value}
                                      className="mb-10 rounded-md"
                                      alt="..."
                                    />
                                  )}
                                </>
                              )
                            )}
                          </>
                        )}
                      </>
                    )}
                    {templateValue?.type === 'BODY' && (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: convertTemplateToHtml(templateValue?.text),
                          }}
                        />
                      </>
                    )}
                    {templateValue?.type === 'FOOTER' && (
                      <>
                        <Typography className="mt-6 text-grey-700">
                          {templateValue?.text}
                        </Typography>
                      </>
                    )}
                    {templateValue?.type === 'BUTTONS' &&
                      templateValue?.buttons?.map((buttonValue, b) => (
                        <div
                          className="text-center border-t-1 border-solid border-grey-300 py-4 mt-12"
                          key={b}
                        >
                          <a
                            className="font-bold text-darkgreen text-16"
                            href={`${
                              buttonValue?.phone_number ? `tel:${buttonValue?.phone_number}` : ''
                            }`}
                            rel="noreferrer"
                            target="_blank"
                            role="button"
                          >
                            <img
                              src={
                                buttonValue?.phone_number
                                  ? '/assets/images/business/telephone.png'
                                  : '/assets/images/business/view.png'
                              }
                              className="w-14 h-14 align-middle mr-4 inline-block"
                              alt="..."
                            />
                            {buttonValue?.text}
                          </a>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PreviewMessage;
