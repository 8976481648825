import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
} from '@mui/material';
import { t } from 'i18next';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import React from 'react';

const CreatePostPopUp = ({
  open,
  onClose,
  onCreatePost,
  temp,
  refresh,
  backgroundColor,
  textColor,
  buttonText,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="createPost-confirmation-dialog"
      classes={{
        paper: `m-24 p-28 business-type-dropdown border-t-8 border-solid text-center border-t-${textColor} border-${textColor} rounded-md`,
      }}
      PaperProps={{ style: { maxWidth: '460px' } }}
    >
      <IconButton className="absolute top-4 right-4" onClick={onClose} color="inherit">
        <CloseIcon className="w-32 h-32 text-grey-700" />
      </IconButton>
      <DialogContent className="p-0 mb-24">
        <div className="block w-full flex justify-center items-center">
          <img src={temp} alt={temp} className="text-16 font-500 text-black w-11/12" />
        </div>
      </DialogContent>
      <DialogActions className="p-0 flex justify-center gap-14">
        <Button
          className={`md:text-16 font-medium rounded-md bg-${backgroundColor} w-full max-w-256`}
          variant="contained"
          disabled={refresh}
          color="secondary"
          onClick={onCreatePost}
        >
          {buttonText}
          {refresh && <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePostPopUp;
