import { Button, Menu, Icon, MenuItem, Select, Typography, Tab, Tabs } from '@mui/material';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import { useCallback, useEffect, useState } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import history from '@history';
import Pagination from '../../../component/table/Pagination';
import reviewPlatformData from '../../query/reviewPlatform';
import { getParamsId, handleApiRequest } from '../../common/common';
import { selectDashboardData } from '../../../store/userSlice';
import UserProfile from '../../common/userProfile';
import manageBusinessData from '../../query/manageBusiness';

const SocialReviews = () => {
  const [reviewData, setReviewData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [selectedReviewData, setSelectedReviewData] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [placeID, setPlaceID] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [plateFormID, setPlateFormID] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [zomatoRatingType, setZomatoRatingType] = useState();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [isInitialFetchComplete, setIsInitialFetchComplete] = useState(false);
  const [totalReview, setTotalReview] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  const fetchBusinessReview = useCallback(
    async (placeIds, plateFormId, filterReview) => {
      setPageLoading(true);
      const payload = {
        query: reviewPlatformData.getAllBusinessReviews,
        variables: {
          pageNumber: 1,
          filterReviewRating: parseInt(filterReview, 10),
          placeId: placeIds,
          platformId: parseInt(plateFormId, 10),
        },
      };

      const result = await handleApiRequest(payload);
      setPageLoading(false);
      if (result?.getAllBusinessReviews?.status === 200) {
        setReviewData(result.getAllBusinessReviews.data);
        setSelectedReviewData(result.getAllBusinessReviews.data[0]);
        setTotalCount(result.getAllBusinessReviews?.totalReviews);
      } else {
        enqueueSnackbar(result?.getAllBusinessReviews?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    },
    [enqueueSnackbar]
  );

  const fetchProducts = useCallback(
    async (plateFormId, ratingTypes, filterReview) => {
      if (userCurrentBusinessDetails?.id) {
        setPageLoading(true);
        const payload = {
          query:
            parseInt(plateFormId, 10) === 5
              ? manageBusinessData.getAmazonProduct
              : parseInt(plateFormId, 10) === 6 && manageBusinessData.getFlipkartProduct,
          variables: {
            userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        setPageLoading(false);
        const res =
          parseInt(plateFormId, 10) === 5
            ? result?.getAmazonProduct
            : parseInt(plateFormId, 10) === 6 && result?.getFlipkartProduct;
        if (res?.status === 200) {
          setProducts(res?.data);
          const product = res?.data[0];
          setSelectedProduct(product);
          setPlaceID(product?.placeId);
          if (product?.placeId) {
            fetchBusinessReview(product?.placeId, plateFormId, ratingTypes, filterReview);
          }
        } else {
          enqueueSnackbar(res?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    },
    [userCurrentBusinessDetails?.id, fetchBusinessReview, enqueueSnackbar]
  );

  useEffect(() => {
    if (userCurrentBusinessDetails?.isOnlineBusiness) {
      const sortData = userCurrentBusinessDetails?.reviewplatform
        ?.slice()
        ?.sort((a, b) => a.id - b.id);
      setTotalReview(sortData[0]?.totalReview);
      setPlateFormID(sortData[0]?.reviewPlatformId);
      fetchProducts(sortData[0]?.reviewPlatformId);
    }
  }, [
    fetchProducts,
    userCurrentBusinessDetails?.isOnlineBusiness,
    userCurrentBusinessDetails?.reviewplatform,
  ]);

  useEffect(() => {
    if (!userCurrentBusinessDetails?.isOnlineBusiness) {
      const findDefaultPlateForm = userCurrentBusinessDetails?.reviewplatform.find(
        (item) => item.defaultReview === true
      );
      setPlaceID(findDefaultPlateForm?.placeId);
      setTotalReview(
        parseInt(findDefaultPlateForm?.reviewPlatformId, 10) === 3
          ? findDefaultPlateForm?.aggregateData?.aggregateTotalReview
          : findDefaultPlateForm?.totalReview
      );
      const ratingType = findDefaultPlateForm?.name === 'zomato' ? 'dining' : '';
      setPlateFormID(findDefaultPlateForm?.reviewPlatformId);
      setZomatoRatingType(ratingType);
      if (findDefaultPlateForm?.placeId) {
        fetchBusinessReview(
          findDefaultPlateForm?.placeId,
          findDefaultPlateForm?.reviewPlatformId,
          ratingType
        );
      }
    }
  }, [
    fetchBusinessReview,
    userCurrentBusinessDetails?.reviewplatform,
    userCurrentBusinessDetails?.isOnlineBusiness,
  ]);

  const fetchOnlineReview = useCallback(
    async (pageNum, pageSizes, plateFormId, filterId, isSortBy, isSortOrder) => {
      const payload = {
        query: reviewPlatformData.getOnlineBusinessReview,
        variables: {
          businessId: userCurrentBusinessDetails?.id,
          pageNumber: pageNum,
          pageSize: pageSizes,
          reviewPlatformId: parseInt(plateFormId, 10),
          filterReviewRating: parseInt(filterId, 10),
          ...(!filterId && {
            sortBy: isSortBy,
            orderBy: isSortOrder,
          }),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getOnlineBusinessReview?.status === 200) {
        setReviewData(result.getOnlineBusinessReview?.data);
        setSelectedReviewData(result.getOnlineBusinessReview?.data[0]);
        setTotalCount(result.getOnlineBusinessReview?.totalReview);
      } else {
        enqueueSnackbar(result?.getOnlineBusinessReview?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    },
    [userCurrentBusinessDetails?.id, enqueueSnackbar]
  );

  const commonGetData = async (placeIds, pageNum, pageSize, plateFormId, filterId, ratingTypes) => {
    try {
      const payload = {
        query: reviewPlatformData.getAllBusinessReviews,
        variables: {
          placeId: placeIds || placeID,
          platformId: parseInt(plateFormId, 10) || parseInt(plateFormID, 10),
          pageNumber: parseInt(pageNum, 10) || parseInt(page, 10),
          pageSize: parseInt(pageSize, 10) || parseInt(rowsPerPage, 10),
          ...(parseInt(plateFormId, 10) === 3 && {
            ratingType: ratingTypes || zomatoRatingType,
          }),
          filterReviewRating: parseInt(filterId, 10),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getAllBusinessReviews?.status === 200) {
        setReviewData(result.getAllBusinessReviews?.data);
        setSelectedReviewData(result.getAllBusinessReviews?.data[0]);
        setTotalCount(result.getAllBusinessReviews?.totalReviews);
      } else {
        enqueueSnackbar(result?.getAllBusinessReviews?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const selectReviewData = (data) => {
    setSelectedReviewData(data);
  };

  useEffect(() => {
    if (plateFormID === 7 && !isInitialFetchComplete) {
      fetchOnlineReview(page, rowsPerPage, plateFormID);
      setIsInitialFetchComplete(true);
    }
  }, [page, rowsPerPage, fetchOnlineReview, isInitialFetchComplete, plateFormID]);

  const handleChangePage = (event, value) => {
    setPage(value + 1);
    if (parseInt(plateFormID, 10) === 7) {
      fetchOnlineReview(value + 1, rowsPerPage, plateFormID);
    } else {
      commonGetData(placeID, value + 1, rowsPerPage, plateFormID, zomatoRatingType);
    }
  };

  const handleChangeRowsPerPage = (event, value) => {
    setRowsPerPage(event.target.value);
    setPage(1);
    if (parseInt(plateFormID, 10) === 7) {
      fetchOnlineReview(1, event.target.value, plateFormID);
    } else {
      commonGetData(placeID, 1, event.target.value, plateFormID, zomatoRatingType);
    }
  };

  const handleSelectProduct = (id) => {
    const findProduct = products?.find((product) => product.id === id);
    setSelectedProduct(findProduct);
    setPlaceID(findProduct?.placeId);
    commonGetData(findProduct?.placeId, 1, rowsPerPage, plateFormID, zomatoRatingType);
  };

  if (pageLoading) {
    return <FuseLoading />;
  }

  const redirectAddProduct = () => {
    const id = getParamsId();
    history.push(id ? `quick-reviews/product/${id}` : 'quick-reviews/product');
  };

  return (
    <div className="p-20 lg:p-32">
      <div className="sm:flex w-full items-center justify-between mb-36">
        <Typography
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
        >
          {t('reviews.title')}
        </Typography>
      </div>
      <>
        {parseInt(totalReview, 10) > 0 ||
        (parseInt(totalReview, 10) > 0 &&
          products?.length > 0 &&
          (parseInt(plateFormID, 10) === 5 || parseInt(plateFormID, 10) === 6)) ? (
          <>
            <div className="bg-white w-full rounded-md p-16">
              <Typography className="sm:flex text-16 md:text-20 font-semibold mb-10">
                {t('quickSocial.title')}
              </Typography>
              <div className="flex flex-col md:flex-row w-full gap-20">
                <div className="overflow-scroll max-h-640 md:w-1/3">
                  {userCurrentBusinessDetails.isOnlineBusiness &&
                    products?.length > 0 &&
                    parseInt(plateFormID, 10) !== 7 && (
                      <Select
                        className="w-full mb-8"
                        size="small"
                        value={selectedProduct?.id}
                        onChange={(e) => handleSelectProduct(e.target.value)}
                      >
                        {products?.map((product, i) => {
                          return (
                            <MenuItem key={i} value={product?.id} className="">
                              {product?.genericName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  {reviewData?.length > 0 &&
                    reviewData?.map((review, index) => {
                      return (
                        <div
                          className={`flex rounded-md mb-16 bg-gray-A300 p-12 border border-solid border-gray-A300 ${
                            parseInt(selectedReviewData?.id, 10) === parseInt(review?.id, 10)
                              ? 'bg-quick-social text-white'
                              : ''
                          }`}
                          key={index}
                          onClick={() => selectReviewData(review)}
                          onKeyDown={(e) => {}}
                          role="button"
                          tabIndex={0}
                        >
                          <div className="w-full">
                            <div className="flex w-full justify-between">
                              <div className="flex itemx-center gap-12">
                                {review?.reviewerProfileUrl ? (
                                  <img
                                    className="w-48 h-48 rounded-3xl"
                                    src={
                                      review?.reviewerProfileUrl || 'assets/images/icon/Profile.png'
                                    }
                                    alt=""
                                  />
                                ) : (
                                  <UserProfile
                                    name={review?.reviewerName}
                                    width="45px"
                                    height="45px"
                                  />
                                )}
                                <div className="">
                                  <Typography className="font-semibold">
                                    {review?.reviewerName}
                                  </Typography>
                                  {[...Array(5)].map((_, i) => (
                                    <Icon
                                      className={`text-16 align-middle mr-2 -mt-2 ${
                                        i <
                                        (parseInt(plateFormID, 10) !== 7
                                          ? review?.reviewRating
                                          : review?.rating)
                                          ? 'text-yellow-800'
                                          : 'text-gray-400'
                                      }`}
                                      key={i}
                                    >
                                      star
                                    </Icon>
                                  ))}
                                  {review?.reviewRating || review?.rating}.0
                                </div>
                              </div>
                              <Typography className="">
                                {moment(review?.reviewCts).format('MMM DD, YYYY')}
                              </Typography>
                            </div>
                            <Typography className="pt-12 text-14">
                              {review?.review && review.review.length > 190
                                ? `${review.review?.slice(0, 190)}...`
                                : review.review}
                            </Typography>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {parseInt(totalReview, 10) > 0 && reviewData?.length === 0 && (
                  <div className="h-full text-20 font-bold justify-center text-center my-192">
                    <img className="m-auto" src="/assets/images/business/Processing.png" alt="" />
                    <Typography className="font-semibold text-20 my-20">
                      {t('reviews.processing')}
                    </Typography>
                    <Typography className="text-16">{t('reviews.loadingReviewMessage')}</Typography>
                  </div>
                )}
                {parseInt(totalReview, 10) === 0 && reviewData?.length === 0 && (
                  <div className="text-center my-192">
                    <img className="m-auto" src="/assets/images/business/reviews.svg" alt="" />
                    <Typography className="font-semibold text-20 my-20">
                      {t('reviews.noReviews')}
                    </Typography>
                    <Typography className="text-16">{t('reviews.message')}</Typography>
                  </div>
                )}
                {selectedReviewData && (
                  <div className="h-max md:h-full md:overflow-scroll rounded-md md:w-2/3 bg-gray-A300 p-12">
                    <div className="flex justify-between">
                      <div className="flex items-center gap-12">
                        {selectedReviewData?.reviewerProfileUrl ? (
                          <img
                            className="w-48 h-48 rounded-3xl"
                            src={
                              selectedReviewData?.reviewerProfileUrl ||
                              'assets/images/icon/Profile.png'
                            }
                            alt=""
                          />
                        ) : (
                          <UserProfile
                            name={selectedReviewData?.reviewerName}
                            width="45px"
                            height="45px"
                          />
                        )}
                        <div className="">
                          <Typography className="font-semibold">
                            {selectedReviewData?.reviewerName}
                          </Typography>
                          {[...Array(5)].map((_, i) => (
                            <Icon
                              className={`text-16 align-middle mr-2 -mt-2 ${
                                i <
                                (parseInt(plateFormID, 10) !== 7
                                  ? selectedReviewData?.reviewRating
                                  : selectedReviewData?.rating)
                                  ? 'text-yellow-800'
                                  : 'text-gray-400'
                              }`}
                              key={i}
                            >
                              star
                            </Icon>
                          ))}{' '}
                          {selectedReviewData?.reviewRating || selectedReviewData?.rating}.0
                        </div>
                      </div>
                      <Typography className="">
                        {moment(selectedReviewData?.reviewCts).format('MMM DD, YYYY')}
                      </Typography>
                    </div>
                    <Typography className="font-medium pt-8 text-14 md:text-16">
                      {selectedReviewData?.review}
                    </Typography>
                    <div className="grid grid-cols-2 gap-8  sm:grid-cols-3 lg:grid-cols-4">
                      {selectedReviewData?.imageLinks?.length > 0 &&
                        selectedReviewData?.imageLinks?.map((item, i) => {
                          return (
                            <div className="" key={i}>
                              <img
                                className="sm:w-192 sm:h-192 w-160 h-160 m-auto rounded-md mt-24"
                                src={item}
                                alt=""
                              />
                            </div>
                          );
                        })}
                    </div>
                    <Button
                      className="rounded-md mt-20 text-14 sm:text-16 px-20 font-500"
                      size="medium"
                      variant="contained"
                      color="quickSocial"
                    >
                      <EditIcon className="text-16 sm:text-20 mr-10" />
                      {t('createPost.title')}
                    </Button>
                  </div>
                )}
              </div>
              {reviewData?.length > 0 && (
                <div className="sm:w-1/3">
                  <div className="flex justify-center">
                    <Pagination
                      totalCount={totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="text-center mt-160 sm:mt-256">
            {parseInt(totalReview, 10) > 0 ? (
              <div className="h-full text-20 font-bold justify-center">
                <img className="m-auto" src="/assets/images/business/Processing.png" alt="" />
                <Typography className="font-semibold text-20 my-20">
                  {t('reviews.processing')}
                </Typography>
                <Typography className="text-16">{t('reviews.loadingReviewMessage')}</Typography>
              </div>
            ) : (
              <div className="h-full text-20 font-bold justify-center">
                {(parseInt(plateFormID, 10) === 5 || parseInt(plateFormID, 10) === 6) &&
                products?.length === 0 ? (
                  <div>
                    <img
                      className="m-auto mb-16"
                      src="/assets/images/business/products.png"
                      alt=""
                    />
                    {parseInt(plateFormID, 10) === 5 && (
                      <div>
                        <Typography className="font-semibold text-20 my-16">
                          {t('products.noAmazonProduct')}
                        </Typography>
                        <Typography className="text-16 max-w-640 m-auto">
                          {t('products.addAmazonProductMessage')}
                        </Typography>
                        <Button
                          className="bg-darkgreen rounded-md text-white px-36 hover:bg-darkgreen mt-16"
                          onClick={() => redirectAddProduct()}
                        >
                          {t('products.addAmazonProduct')}
                        </Button>
                      </div>
                    )}
                    {parseInt(plateFormID, 10) === 6 && (
                      <div>
                        <Typography className="font-semibold text-20 my-16">
                          {t('products.noFlipKartProduct')}
                        </Typography>
                        <Typography className="text-16 max-w-640 m-auto">
                          {t('products.addFlipkartProductMessage')}
                        </Typography>
                        <Button
                          className="bg-darkgreen rounded-md text-white px-36 hover:bg-darkgreen mt-16"
                          onClick={() => redirectAddProduct()}
                        >
                          {t('products.addFlipkartProduct')}
                        </Button>
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <img className="m-auto" src="/assets/images/business/reviews.svg" alt="" />
                    <Typography className="font-semibold text-20 my-20">
                      {t('reviews.noReviews')}
                    </Typography>
                    <Typography className="text-16">{t('reviews.message')}</Typography>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
};

export default SocialReviews;
