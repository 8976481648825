import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Menu, MenuItem, Tabs, Tab, Button } from '@mui/material';
import { getAllUserBusinessSubscription, selectDashboardData } from 'src/app/store/userSlice';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import UserService from 'src/app/services';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import history from '@history';
import FuseLoading from '@fuse/core/FuseLoading';
import planQuery from 'src/app/main/query/plan';
import { t } from 'i18next';
import Pagination from 'src/app/component/table/Pagination';
import { useParams } from 'react-router-dom';
import { findExpireDay, getEncryptedData, handleApiRequest } from '../../../common/common';

const loginUserData = UserService.getUserData();

const MySubscription = () => {
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const [filteredSubscriptionData, setFilteredSubscriptionData] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [getPaymentHistory, setGetPaymentHistory] = useState('');
  const [total, setTotal] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const [isOpenBundle, setIsOpenBundle] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [cancelledSubscriptions, setCancelledSubscriptions] = useState({});
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const countryId = parseInt(loginUserData?.countryId, 10);
  const params = useParams();
  useEffect(() => {
    if (userSubscriptionData && userCurrentBusinessDetails?.id) {
      const filteredData = userSubscriptionData?.filter(
        (subscription) => subscription?.userBusinessId === userCurrentBusinessDetails?.id
      );
      setFilteredSubscriptionData(filteredData);
    } else {
      setFilteredSubscriptionData(userSubscriptionData);
    }
  }, [userSubscriptionData, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setIsLoading(true);
        if (countryId === 2) {
          const invoicePayload = {
            query: planQuery.getInvoiceHistoryForRazorpay,
            variables: {
              orderBy: 'asc',
              pageSize: rowsPerPage,
              ...(userCurrentBusinessDetails?.id && {
                businessId: parseInt(userCurrentBusinessDetails?.id, 10),
              }),
            },
            pageNumber: page,
          };
          const invoiceResult = await handleApiRequest(invoicePayload);
          if (invoiceResult?.getInvoiceHistoryForRazorpay?.status === 200) {
            setGetPaymentHistory(invoiceResult?.getInvoiceHistoryForRazorpay?.data);
            setTotal(invoiceResult?.getInvoiceHistoryForRazorpay?.totalCount);
          }
        } else {
          const paymentHistoryPayload = {
            query: planQuery.getPaymentHistory,
            variables: {
              businessId: parseInt(userCurrentBusinessDetails?.id, 10),
              orderBy: 'asc',
              pageSize: rowsPerPage,
            },
          };
          const paymentHistoryResult = await handleApiRequest(paymentHistoryPayload);
          if (paymentHistoryResult?.getPaymentHistory?.status === 200) {
            setGetPaymentHistory(paymentHistoryResult?.getPaymentHistory?.data);
            setTotal(paymentHistoryResult?.getPaymentHistory?.totalCount);
          }
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAllData();
  }, [countryId, page, rowsPerPage, userCurrentBusinessDetails?.id]);

  const handleCancelSubscription = async (Id, paymentId) => {
    setCancelledSubscriptions((prev) => ({
      ...prev,
      [Id]: true,
    }));
    if (countryId === 2) {
      const payloadData = {
        query: planQuery.cancelSubscriptionForRazorpay,

        variables: {
          data: getEncryptedData(
            JSON.stringify({
              razorpaySubscriptionId: paymentId,
            })
          ),
        },
      };
      const result = await handleApiRequest(payloadData);
      if (result?.cancelSubscriptionForRazorpay?.status === 200) {
        enqueueSnackbar(result?.cancelSubscriptionForRazorpay?.message, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(result?.cancelSubscriptionForRazorpay?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } else {
      const payloadData = {
        query: planQuery.cancelSubscription,

        variables: {
          data: getEncryptedData(
            JSON.stringify({
              stripeSubscriptionId: paymentId,
            })
          ),
        },
      };
      const result = await handleApiRequest(payloadData);
      if (result?.cancelSubscription?.status === 200) {
        enqueueSnackbar('Success', {
          variant: 'success',
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar('error', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    }
  };

  const handleClickA = (event, index) => {
    setAnchorEl(event.currentTarget);
    setOpenDropdownIndex(index);
  };

  const handleCloseA = () => {
    setAnchorEl(null);
    setOpenDropdownIndex(null);
  };

  const handleClickBundle = (index) => {
    setIsOpenBundle((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  if (isLoading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-32">
      <div className="mb-16">
        <h1 className=" text-30 font-bold">{t('plan.mySubscriptionData.mySubscription')}</h1>
      </div>
      <div className="  border-solid border-grey  flex flex-col justify-between  ">
        <div className=" border-solid border-grey  sm:flex justify-between items-center  border-b-2 ">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab
              className="sm:text-18 font-bold mr-20 text-black p-0"
              value={0}
              label={t('plan.mySubscriptionData.activePlan')}
            />
            <Tab
              label={t('plan.mySubscriptionData.paymentHistory')}
              className="sm:text-18 font-bold p-0 text-black"
              value={1}
            />
          </Tabs>
        </div>
        <div className="py-20">
          {selectedTab === 0 && (
            <div className="w-full">
              {filteredSubscriptionData?.length > 0 ? (
                filteredSubscriptionData?.map((subscription, index) => (
                  <div
                    className={`my-16 justify-between rounded-md bg-white max-w-screen-md flex-col sm:flex-row border-grey-300 border-1 ${
                      isOpenBundle ? 'h-auto' : ''
                    }`}
                    key={index}
                  >
                    {subscription?.isFreeTrial && (
                      <p className="w-full h-15 bg-amber-50 text-14 text-grey-700 font-medium pl-5 p-4">
                        {t('plan.mySubscriptionData.freeTrailWillExpireIn')}
                        {findExpireDay(subscription?.expireDate)}
                        {t('plan.mySubscriptionData.days')}
                      </p>
                    )}
                    <div className="flex flex-col sm:flex-row sm:items-center justify-center sm:justify-between md:justify-between lg:justify-between p-14 relative">
                      <div className="gap-10 flex items-center bg-white rounded-md">
                        <div>
                          {subscription?.name?.toLowerCase() === 'quick reviews' && (
                            <img
                              src="/assets/images/quick-hub/quick-review.jpg"
                              alt="Quick Review"
                              className="bg-white"
                            />
                          )}
                          {subscription?.name?.toLowerCase() === 'quick social' && (
                            <img
                              src="/assets/images/quick-hub/quick-social.jpg"
                              alt="Quick Social"
                              className="bg-white"
                            />
                          )}
                          {subscription?.name?.toLowerCase() === 'quick chat' && (
                            <img
                              src="/assets/images/quick-hub/quick-chat.jpg"
                              alt="Quick Chat"
                              className="bg-white"
                            />
                          )}
                          {subscription?.name?.toLowerCase() === 'quick bundle' && (
                            <img
                              src="/assets/images/quick-hub/quick-bundle.png"
                              alt="Quick Bundle"
                              className="bg-white"
                            />
                          )}
                          {subscription?.name?.toLowerCase() === 'quick combo' && (
                            <img
                              src="/assets/images/quick-hub/quick-chat.jpg"
                              alt="Quick Combo"
                              className="bg-white"
                            />
                          )}
                          {subscription?.name?.toLowerCase() === 'quick campaigns' && (
                            <img
                              src="/assets/images/quick-hub/quick-campaign.jpg"
                              alt="Quick Campaigns"
                              className="bg-white"
                            />
                          )}
                        </div>
                        <div>
                          <div className="flex text-left items-center">
                            <span className="text-left block mb-6 font-semibold text-20 tracking-normal text-black">
                              {subscription?.name.replace(/\b\w/g, (char) => char.toUpperCase())}
                            </span>
                            {(subscription?.name?.toLowerCase() === 'quick bundle' ||
                              subscription?.name?.toLowerCase() === 'quick combo') && (
                              <div className="ml-2 font-Quick">
                                <div className="w-full">
                                  <IconButton
                                    className="p-0"
                                    onClick={() => handleClickBundle(index)}
                                  >
                                    {isOpenBundle === index ? (
                                      <ExpandLessIcon />
                                    ) : (
                                      <ExpandMoreIcon />
                                    )}
                                  </IconButton>
                                  {isOpenBundle === index && (
                                    <div className="ml-64 left-0 right-14 bg-indigo-50 w-100 h-auto z-20 rounded-8 absolute p-7">
                                      {subscription?.comboDetails?.length > 0 ? (
                                        subscription?.comboDetails?.map((item, idx) => (
                                          <div
                                            key={idx}
                                            className="p-4 flex font-medium justify-between border-b-1 border-b-grey-300 last:border-b-0"
                                          >
                                            <p>{item?.name}</p>
                                            <p>
                                              {subscription?.currencySymbol}
                                              {
                                                subscription?.packageBundleDetails?.find(
                                                  (id) => id?.packageTypeId === item?.id
                                                )?.offerAmount
                                              }
                                              /
                                              {subscription?.packageInterval === 'year'
                                                ? 'year'
                                                : 'mon'}
                                            </p>
                                          </div>
                                        ))
                                      ) : (
                                        <div className="text-center">
                                          {t('plan.mySubscriptionData.noDataAvailable')}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                          <p
                            className={`text-left font-medium relative ${
                              findExpireDay(subscription?.expireDate) < 0
                                ? 'text-red-500'
                                : 'text-black'
                            }`}
                          >
                            {(!isOpen || subscription?.name !== 'Quick Bundle') && (
                              <>
                                {findExpireDay(subscription?.expireDate) < 0
                                  ? t('plan.mySubscriptionData.expiredOn')
                                  : subscription?.isFreeTrial || subscription?.isOneTimePayment
                                  ? t('plan.mySubscriptionData.expiresOn')
                                  : subscription?.isSubscription &&
                                    t('plan.mySubscriptionData.nextRenewOn')}
                                {subscription?.expireDate ? (
                                  <span>
                                    {moment(subscription?.expireDate).format('MMM DD, YYYY')}
                                  </span>
                                ) : null}
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="item-center pt-10">
                        <p className="text-20 justify-around font-medium text-black">
                          {parseInt(countryId, 10) === 2 ? '₹' : '$'}
                          {subscription?.offerAmount || subscription?.amount}/
                          {subscription?.packageInterval === 'year'
                            ? t('plan.mySubscriptionData.year')
                            : t('plan.mySubscriptionData.month')}
                        </p>
                        <div className="px-2 py-8">
                          {subscription?.isOneTimePayment &&
                          !(new Date(subscription.expireDate) < new Date()) ? (
                            <a
                              href="*"
                              className="font-medium text-14 rounded-md px-20"
                              onClick={(event) => {
                                event.preventDefault();
                                const packageBundle =
                                  subscription?.comboDetails?.length > 0
                                    ? subscription.comboDetails
                                        .map((item) => {
                                          switch (item?.name) {
                                            case 'Quick Social':
                                            case 'Quick Campaigns':
                                            case 'Quick Chat':
                                            case 'Quick Reviews':
                                              return item.id;
                                            default:
                                              return null;
                                          }
                                        })
                                        .filter(Boolean)
                                    : [subscription?.packageTypeId];

                                history.push(
                                  params?.id ? `/selectProduct/${params.id}` : '/selectProduct/',
                                  {
                                    packageBundle,
                                    intervalType: subscription?.packageInterval,
                                    paymentDetails: 'oneTimePayment',
                                    isBusinessAdded: false,
                                    specialOffer: false,
                                  }
                                );
                              }}
                            >
                              {t('plan.mySubscriptionData.extendPlan')}
                            </a>
                          ) : subscription?.isSubscription ? (
                            <a
                              href="*"
                              onClick={(e) => {
                                e.preventDefault();
                                handleCancelSubscription(
                                  subscription?.packageId,
                                  subscription?.stripeSubscriptionId ||
                                    subscription?.razorpaySubscriptionId
                                );
                              }}
                              className="text-14 font-medium text-black rounded-md px-20 text-center"
                              aria-label="skip"
                              style={{
                                color: 'black',
                                display: cancelledSubscriptions[subscription?.packageId]
                                  ? 'none'
                                  : 'block',
                              }}
                            >
                              {t('plan.mySubscriptionData.cancelPlan')}
                            </a>
                          ) : subscription?.isFreeTrial ? (
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              className="text-14 font-medium disabled:text-black rounded-md px-20"
                              aria-label="skip"
                              onClick={(event) => {
                                event.preventDefault();
                                const packageBundle =
                                  subscription?.comboDetails?.length > 0
                                    ? subscription.comboDetails
                                        .map((item) => {
                                          switch (item?.name) {
                                            case 'Quick Social':
                                            case 'Quick Campaigns':
                                            case 'Quick Chat':
                                            case 'Quick Reviews':
                                              return item.id;
                                            default:
                                              return null;
                                          }
                                        })
                                        .filter(Boolean)
                                    : [subscription?.packageTypeId];

                                history.push(
                                  params?.id ? `/selectProduct/${params.id}` : '/selectProduct/',
                                  {
                                    packageBundle,
                                    intervalType: subscription?.packageInterval,
                                    paymentDetails: 'subscription',
                                    isBusinessAdded: false,
                                    specialOffer: true,
                                  }
                                );
                              }}
                              type="button"
                            >
                              {t('plan.mySubscriptionData.subscribeNow')}
                            </Button>
                          ) : null}
                          {subscription?.isSubscription === false &&
                            findExpireDay(subscription?.expireDate) >= 0 &&
                            (subscription.razorpaySubscriptionId ||
                              subscription.stripeSubscriptionId) && (
                              <p className="text-14 font-medium text-black rounded-md px-20 text-center">
                                {t('plan.mySubscriptionData.planCancelled')}
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <h1 className="text-center text-black">
                  {t('plan.mySubscriptionData.noDataAvailable')}
                </h1>
              )}
            </div>
          )}

          {selectedTab === 1 && (
            <div className="w-full">
              {getPaymentHistory?.length > 0 ? (
                <div className="overflow-y-auto p-5 ">
                  <div>
                    <table className="min-w-full table-auto bg-white border-2 border-black-200 rounded-md ">
                      <thead className=" py-5 px-5 border-2  border-black-200 w-full">
                        <tr className=" p-10 bg-white-100  font-bold text-md  ">
                          <th className=" p-12 text-left text-black font-bold text-14 ">
                            {t('plan.mySubscriptionData.transactionId')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-14 ">
                            {t('plan.mySubscriptionData.product')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-14 ">
                            {t('plan.mySubscriptionData.planStartOn')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-14 ">
                            {t('plan.mySubscriptionData.nextRecurringDate')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-14 ">
                            {t('plan.mySubscriptionData.amount')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-14 ">
                            {t('plan.mySubscriptionData.billingCycle')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-14 ">
                            {t('plan.mySubscriptionData.method')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-14">
                            {t('plan.mySubscriptionData.status')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-14 ">
                            {t('plan.mySubscriptionData.autoPay')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-14">
                            {t('plan.mySubscriptionData.invoice')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {getPaymentHistory?.map((payment, index) => (
                          <tr key={index} className="border-t text-2">
                            <td className="p-6 pl-12 text-sm font-medium ">
                              {payment?.transactionId}
                            </td>
                            <td className="p-12  text-sm font-medium">
                              <div className="flex items-center ">
                                <p className="text-14">{payment?.planName}</p>
                                {countryId === 2
                                  ? (payment?.planName === 'Bundle' ||
                                      payment?.planName === 'Combo') && (
                                      <>
                                        <IconButton
                                          onClick={(event) => handleClickA(event, index)}
                                          className="ml-2"
                                        >
                                          {openDropdownIndex === index ? (
                                            <ExpandLessIcon />
                                          ) : (
                                            <ExpandMoreIcon />
                                          )}
                                        </IconButton>
                                        <Menu
                                          anchorEl={anchorEl}
                                          open={openDropdownIndex === index}
                                          onClose={handleCloseA}
                                          anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                          }}
                                          transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                          }}
                                          MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                          }}
                                        >
                                          {payment?.planName?.replace(/\b\w/g, (char) =>
                                            char.toUpperCase()
                                          ) === 'Combo' &&
                                            payment?.comboPlanName?.map((detail) => (
                                              <MenuItem
                                                key={index}
                                                onClick={handleCloseA}
                                                className="bg-grey-100"
                                              >
                                                <div className="font-medium text-12 flex items-center">
                                                  {detail?.replace(/\b\w/g, (char) =>
                                                    char.toUpperCase()
                                                  ) === 'Quick Reviews' && (
                                                    <img
                                                      src="/assets/images/quick-hub/quick-review-small.png"
                                                      alt="Quick Review"
                                                      className="h-14 w-14 mr-5"
                                                    />
                                                  )}
                                                  {detail?.replace(/\b\w/g, (char) =>
                                                    char.toUpperCase()
                                                  ) === 'Quick Social' && (
                                                    <img
                                                      src="/assets/images/quick-hub/quick-social-small.png"
                                                      alt="Quick Social"
                                                      className="h-14 w-14 mr-5"
                                                    />
                                                  )}
                                                  {detail?.replace(/\b\w/g, (char) =>
                                                    char.toUpperCase()
                                                  ) === 'Quick Chat' && (
                                                    <img
                                                      src="/assets/images/quick-hub/quick-chat-small.png"
                                                      alt="Quick Chat"
                                                      className="h-14 w-14 mr-5"
                                                    />
                                                  )}
                                                  {detail?.replace(/\b\w/g, (char) =>
                                                    char.toUpperCase()
                                                  ) === 'Quick Campaigns' && (
                                                    <img
                                                      src="/assets/images/quick-hub/quick-campaign-small.png"
                                                      alt="Quick Campaigns"
                                                      className="h-14 w-14 mr-5"
                                                    />
                                                  )}
                                                  <h4>{detail}</h4>
                                                </div>
                                              </MenuItem>
                                            ))}
                                          {payment?.planName?.replace(/\b\w/g, (char) =>
                                            char.toUpperCase()
                                          ) === 'Bundle' &&
                                            payment?.comboPlanName?.map((detail, i) => (
                                              <MenuItem key={i} onClick={handleCloseA}>
                                                <div className="font-medium text-12 flex items-center ">
                                                  <div>
                                                    {detail?.replace(/\b\w/g, (char) =>
                                                      char.toUpperCase()
                                                    ) === 'Quick Reviews' && (
                                                      <img
                                                        src="/assets/images/quick-hub/quick-review-small.png"
                                                        alt="Quick Review"
                                                        className="h-14 w-14 mr-5"
                                                      />
                                                    )}
                                                    {detail?.replace(/\b\w/g, (char) =>
                                                      char.toUpperCase()
                                                    ) === 'Quick Social' && (
                                                      <img
                                                        src="/assets/images/quick-hub/quick-social-small.png"
                                                        alt="Quick Social"
                                                        className="h-14 w-14 mr-5"
                                                      />
                                                    )}
                                                    {detail?.replace(/\b\w/g, (char) =>
                                                      char.toUpperCase()
                                                    ) === 'Quick Chat' && (
                                                      <img
                                                        src="/assets/images/quick-hub/quick-chat-small.png"
                                                        alt="Quick Chat"
                                                        className="h-14 w-14 mr-5"
                                                      />
                                                    )}
                                                    {detail?.replace(/\b\w/g, (char) =>
                                                      char.toUpperCase()
                                                    ) === 'Quick Campaigns' && (
                                                      <img
                                                        src="/assets/images/quick-hub/quick-campaign-small.png"
                                                        alt="Quick Campaigns"
                                                        className="h-14 w-14 mr-5"
                                                      />
                                                    )}
                                                  </div>
                                                  <h4>{detail}</h4>
                                                </div>
                                              </MenuItem>
                                            ))}
                                        </Menu>
                                      </>
                                    )
                                  : (payment.packages?.name === 'Quick Bundle' ||
                                      payment.packages?.name === 'Quick Combo') && (
                                      <>
                                        <IconButton
                                          onClick={(event) => handleClickA(event, index)}
                                          className="ml-2"
                                        >
                                          {openDropdownIndex === index ? (
                                            <ExpandLessIcon />
                                          ) : (
                                            <ExpandMoreIcon />
                                          )}
                                        </IconButton>
                                        <Menu
                                          anchorEl={anchorEl}
                                          open={openDropdownIndex === index}
                                          onClose={handleCloseA}
                                          MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                          }}
                                        >
                                          <MenuItem onClick={handleCloseA}>
                                            {t('plan.mySubscriptionData.noData')}
                                          </MenuItem>
                                        </Menu>
                                      </>
                                    )}
                              </div>
                            </td>
                            <td className="p-12 text-sm font-medium ">
                              {countryId === 2
                                ? payment?.startDate
                                  ? moment(payment?.startDate).format('MMM DD, YYYY')
                                  : ''
                                : payment?.startDate
                                ? moment(payment?.startDate).format('MMM DD, YYYY')
                                : ''}
                            </td>
                            <td className="p-12 text-sm font-medium ">
                              {countryId === 2
                                ? payment?.expireDate
                                  ? moment(payment?.expireDate).format('MMM DD, YYYY')
                                  : ''
                                : payment?.user_package_subscription?.expireDate
                                ? moment(payment?.user_package_subscription?.expireDates).format(
                                    'MMM DD, YYYY'
                                  )
                                : ''}
                            </td>
                            <td className="p-12 text-sm font-medium ">
                              {countryId === 2
                                ? `${!payment?.currency ? '₹' : '$'} ${payment?.invoiceAmount}`
                                : `${payment?.paymentIntentsDescTwo?.currency ? '$' : ''} ${
                                    payment?.paymentIntentsDescTwo?.amount || ''
                                  }`}
                            </td>
                            <td className="font-medium text-sm p-12">
                              {!payment?.isOneTimePayment
                                ? payment?.packageInterval === 'year'
                                  ? 'Yearly'
                                  : 'Monthly'
                                : 'One Time'}
                            </td>
                            <td className=" text-sm font-medium p-12">
                              {countryId === 2
                                ? payment?.paymentMethod
                                  ? payment?.paymentMethod.replace(/\b\w/g, (char) =>
                                      char.toUpperCase()
                                    )
                                  : ''
                                : payment?.packages?.packageInterval
                                ? payment?.packages?.packageInterval.replace(/\b\w/g, (char) =>
                                    char.toUpperCase()
                                  )
                                : ''}
                            </td>
                            <td
                              className={`p-12 text-sm font-medium  ${
                                payment?.status === 'Failed' ? 'text-red-500' : 'text-darkgreen'
                              }`}
                            >
                              {countryId === 2
                                ? payment?.status === 'Activated'
                                  ? t('plan.mySubscriptionData.failed')
                                  : t('plan.mySubscriptionData.successful')
                                : payment?.paymentIntentsDescTwo?.status === 'Activated'
                                ? t('plan.mySubscriptionData.failed')
                                : t('plan.mySubscriptionData.successful')}
                            </td>
                            <td className="p-12 text-sm font-medium ">
                              {payment.autopay ? 'Yes' : 'No'}
                            </td>
                            <td className="p-12 text-sm font-medium ">
                              {!payment?.isOneTimePayment ? (
                                <a
                                  href={payment?.invoiceRedirectLink}
                                  color="inherit"
                                  className="  font-medium"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p className="bg-darkgreen text-white px-10 py-2 rounded-sm inline-block text-center">
                                    {t('plan.mySubscriptionData.downloadReceipt')}
                                  </p>
                                </a>
                              ) : (
                                '-'
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Pagination
                      totalCount={total}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              ) : (
                <h1 className="text-center text-black">
                  {t('plan.mySubscriptionData.noDataAvailable')}
                </h1>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MySubscription;
