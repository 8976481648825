const saveWhatAppUserDetails = ` 
mutation($whatsAppBusinessAccountId: String!, $whatsAppPhoneNumberId: String!, $tokenCode: String!, $quickReviewBusinessId : Int){
	saveWhatAppUserDetails(whatsAppBusinessAccountId: $whatsAppBusinessAccountId, whatsAppPhoneNumberId: $whatsAppPhoneNumberId, tokenCode: $tokenCode, quickReviewBusinessId: $quickReviewBusinessId) {
    message
    status
    data {
      accessToken
      isStoredToken
    }
	}
}  
`;

const getAllMessage = ` 
query($waChatRoomId: Int!, $sortBy: String!, $sortOrder: String!, $pageNumber: Int, $pageSize: Int){
	getAllMessage(waChatRoomId: $waChatRoomId, sortBy: $sortBy, sortOrder: $sortOrder, pageNumber: $pageNumber, pageSize: $pageSize) {
    message
    status
    data {
      conversationId
      cts
      from
      id
      message
      messageId
      templateData
      dynamicData
      reactOnMsg{
        emoji
        message_id
      }
      replyId
      status
      template
      type
      uts
    }
	}
}  
`;

const getUserDetail = ` 
query{
	getUserDetail {
    message
    status
    data
	} 
}  
`;

const sendMessages = ` 
mutation($message: String, $name: String, $phoneNumber: String!, $templateComponents: String, $templateId: Int, $type: String, $waBroadcastId: Int, $waBusinessPhoneNumberId: String!, $tempMessageId: String){
	sendMessages(message: $message, name: $name, phoneNumber: $phoneNumber, templateComponents: $templateComponents, templateId: $templateId, type: $type, waBroadcastId: $waBroadcastId, waBusinessPhoneNumberId: $waBusinessPhoneNumberId, tempMessageId: $tempMessageId) {
    message
    status
    data {
      messageId
      tempMessageId
    }
	}
}  
`;

const getChatRoomWithLastMessage = ` 
query($isBroadcast: Boolean!, $isUnRead: Boolean!, $search: String){
	getChatRoomWithLastMessage(isBroadcast: $isBroadcast, isUnRead: $isUnRead, search: $search) {
    message
    status
    data{
      cts
      name
      phoneNumber
      id
      message
      waChatRoomId
      replyId
      reactOnMsg{
        message_id
        emoji
      }
      status
      type
      replyOnMessage
    }
	}
}  
`;

const getWhatsappTemplate = ` 
query($searchText: String, $templateId: Int){
	getWhatsappTemplate(searchText: $searchText, templateId: $templateId) {
    message
    status
    data{
      displayName
      dynamicData
      id
      templateData
      categoryName
      cts
      templateId
      templateName
    }
	}
}  
`;

const getChatRoomNote = ` 
query($chatRoomId: Int!, $pageNumber: Int, $pageSize: Int){
	getChatRoomNote(chatRoomId: $chatRoomId, pageNumber: $pageNumber, pageSize: $pageSize) {
    message
    status
    data{
      note
      uts
      id
      cts
    }
	}
}  
`;

const createChatRoomNote = ` 
mutation($chatRoomId: Int!, $note: String!){
	createChatRoomNote(chatRoomId: $chatRoomId, note: $note) {
    message
    status
	}
}  
`;

const deleteChatRoomNote = ` 
mutation($noteId: Int!, $note: String){
	deleteChatRoomNote(noteId: $noteId, note: $note) {
    message
    status
	}
}  
`;

const editChatRoomNote = ` 
mutation($noteId: Int!, $note: String){
	editChatRoomNote(noteId: $noteId, note: $note) {
    message
    status
	}
}  
`;

const getWABroadCastList = ` 
query($isScheduled: Boolean, $pageSize: Int, $pageNumber: Int, $searchName: String){
	getWABroadCastList(isScheduled: $isScheduled, pageSize: $pageSize, pageNumber: $pageNumber, searchName: $searchName) {
    data {
      cts
      id
      isScheduled
      name
      recipientsCount
      scheduledDate
      status
    }
    totalCount
    message
    status
	}
}  
`;

const getWABroadCastContactList = ` 
query($waBroadcastId: Int, $pageSize: Int, $pageNumber: Int){
	getWABroadCastContactList(waBroadcastId: $waBroadcastId, pageSize: $pageSize, pageNumber: $pageNumber) {
    data {
      customerName
      phoneNumber
      status
    }
    totalCount
    message
    status
	}
}  
`;

const getWABroadCastPcData = ` 
query($waBroadcastId: Int!){
	getWABroadCastPcData(waBroadcastId: $waBroadcastId) {
    data {
      readCount
      sentCount
      failedCount
      repliedCount
      sendingCount
      deliveredCount
    }
    message
    status
	}
}  
`;

const createBroadcast = ` 
mutation($contact: [Contact!]!, $isScheduled: Boolean!, $name: String!, $scheduledDate: String, $templateId: Int!){
	createBroadcast(contact: $contact, isScheduled: $isScheduled, name: $name, scheduledDate: $scheduledDate, templateId: $templateId) {
    message
    status
	}
}  
`;

const saveCampaignContact = ` 
mutation($callFrom: String, $countryCode: String, $email: String, $firstName: String!, $lastName: String, $phoneNumber: Float, $userBusinessId: Int){
	saveCampaignContact(callFrom: $callFrom, countryCode: $countryCode, email: $email, firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber, userBusinessId: $userBusinessId) {
    message
    status
	}
}  
`;

const searchCampaignContact = ` 
query($callFrom: String, $searchText: String!, $userBusinessId: Int){
	searchCampaignContact(callFrom: $callFrom, searchText: $searchText, userBusinessId: $userBusinessId) {
    message
    status
    data{
      countryCode
      email
      name
      phoneNumber
    }
	}
}  
`;

const deleteUserCsvContactOrCampaignContact = ` 
mutation($dataFrom: String, $email: String, $id: Int, $phoneNumber: String){
	deleteUserCsvContactOrCampaignContact(dataFrom: $dataFrom, email: $email, id: $id, phoneNumber: $phoneNumber) {
    message
    status
	}
}  
`;

const updateCsvContactOrCampaignContact = ` 
mutation($dataFrom: String, $email: String, $id: Int, $phoneNumber: String, $countryCode: String, $oldEmail: String, $oldPhoneNumber: String, $name: String, $firstName: String, $lastName: String){
	updateCsvContactOrCampaignContact(dataFrom: $dataFrom, email: $email, id: $id, phoneNumber: $phoneNumber, countryCode: $countryCode, oldEmail: $oldEmail, oldPhoneNumber: $oldPhoneNumber, name: $name, firstName: $firstName, lastName: $lastName) {
    message
    status
	}
}  
`;

const getTemplateCategories = ` 
query{
	getTemplateCategories {
    data{
      id
      name
    }
   message
   status
	}
   
}  
`;

const getTemplateVariable = ` 
query{
	getTemplateVariable {
    data{
      id
      name
    }
   message
   status
	}
   
}  
`;

const createWhatsappTemplate = ` 
mutation($bodyVariables: [String!], $templateBody: String!, $templateButton: TemplateButtonData, $templateCategoryId: Int!, $templateFooter: String, $templateImage: Upload, $templateName: String!, $templateTitle: String){
	createWhatsappTemplate(bodyVariables: $bodyVariables, templateBody: $templateBody, templateButton: $templateButton, templateCategoryId: $templateCategoryId, templateFooter: $templateFooter, templateImage: $templateImage, templateName: $templateName, templateTitle: $templateTitle) {
   message
   status
	}
}  
`;

const deleteWhatsappTemplate = ` 
mutation($templateId: String!){
	deleteWhatsappTemplate(templateId: $templateId) {
   message
   status
	}
}  
`;

const getBroadcastTemplate = ` 
query($waBroadcastId: Int!){
	getBroadcastTemplate(waBroadcastId: $waBroadcastId) {
   message
   status
   data{
    categoryTypeId
    dynamicData
    id
    isScheduled
    name
    templateData
    templateId
    templateName
    waUserId
   }
	}
}  
`;

const wabaQuery = {
  saveWhatAppUserDetails,
  getAllMessage,
  getUserDetail,
  sendMessages,
  getChatRoomWithLastMessage,
  getWhatsappTemplate,
  getChatRoomNote,
  createChatRoomNote,
  deleteChatRoomNote,
  editChatRoomNote,
  getWABroadCastList,
  getWABroadCastContactList,
  getWABroadCastPcData,
  createBroadcast,
  saveCampaignContact,
  searchCampaignContact,
  deleteUserCsvContactOrCampaignContact,
  updateCsvContactOrCampaignContact,
  getTemplateCategories,
  getTemplateVariable,
  createWhatsappTemplate,
  deleteWhatsappTemplate,
  getBroadcastTemplate,
};

export default wabaQuery;
