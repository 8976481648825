import DashboardConfig from './dashboard/DashboardConfig';
import ReviewsConfig from './reviews/ReviewsConfig';
import PrimaryDetailsConfig from './primary-details/PrimaryDetailsConfig';
import PendingTaskConfig from './pending-task/PendingTaskConfig';

const quickReviewsConfig = [
  DashboardConfig,
  ReviewsConfig,
  PrimaryDetailsConfig,
  PendingTaskConfig,
];

export default quickReviewsConfig;
