import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Icon,
  Switch,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import TimerIcon from '@mui/icons-material/Timer';
import CheckIcon from '@mui/icons-material/Check';
import { useSnackbar } from 'notistack';
import { TimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import { capitalizeFirstLetter, handleApiRequest } from '../../../common/common';
import manageBusinessData from '../../../query/manageBusiness';

const BusinessOpeningAndClosingHours = (props) => {
  const {
    fetchBusinessDetails,
    googleBusinessData,
    openDialog,
    setOpenDialog,
    userCurrentBusinessDetails,
  } = props;
  const [timeData, setTimeData] = useState();
  const daysOfWeek = useMemo(
    () => ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'],
    []
  );

  const [refreshIcon, setRefreshIcon] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const formatGoogleBusinessHours = (googleBusinessHours, days) => {
    const dayTimeData = days.reduce((acc, day) => {
      acc[day] = { start: { hour: '', minute: '' }, end: { hour: '', minute: '' } };
      return acc;
    }, {});

    if (googleBusinessHours?.regularHours?.periods) {
      const updatedTimeData = { ...dayTimeData };

      googleBusinessHours.regularHours.periods.forEach((period) => {
        const { openDay, closeDay, openTime, closeTime } = period;

        updatedTimeData[openDay] = {
          start: {
            hour: openTime?.hours ? String(openTime.hours).padStart(2, '0') : '00',
            minute: openTime?.minutes ? String(openTime.minutes).padStart(2, '0') : '00',
          },
          end: updatedTimeData[openDay]?.end,
          open:
            (openTime?.hours && Object.keys(openTime.hours).length === 0) ||
            (openTime?.minutes && Object.keys(openTime.minutes).length === 0),
        };

        updatedTimeData[closeDay] = {
          start: updatedTimeData[closeDay]?.start,
          end: {
            hour: closeTime?.hours ? String(closeTime.hours).padStart(2, '0') : '00',
            minute: closeTime?.minutes ? String(closeTime.minutes).padStart(2, '0') : '00',
          },
          open:
            (openTime?.hours && Object.keys(openTime.hours).length === 0) ||
            (openTime?.minutes && Object.keys(openTime.minutes).length === 0),
        };
      });

      return updatedTimeData;
    }
    return dayTimeData;
  };

  useEffect(() => {
    const updatedTimeData = formatGoogleBusinessHours(googleBusinessData, daysOfWeek);
    setTimeData(updatedTimeData);
  }, [googleBusinessData, daysOfWeek]);

  const formatHoursFor = (hours) => {
    return Object.entries(hours).map(([day, times]) => ({
      openDay: day,
      closeDay: day,
      openTime: {
        hours: parseInt(times.start.hour, 10) || 0,
        minutes: parseInt(times.start.minute, 10) || 0,
      },
      closeTime: {
        hours: parseInt(times.end.hour, 10) || 0,
        minutes: parseInt(times.end.minute, 10) || 0,
      },
    }));
  };

  const onSubmit = async () => {
    setRefreshIcon(true);
    try {
      const periods = formatHoursFor(timeData);
      const payload = {
        query: manageBusinessData.updateGoogleBusinessDetails,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          mask: 'regularHours',
          periods,
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.updateGoogleBusinessDetails?.status === 200) {
        fetchBusinessDetails();
        setOpenDialog({ ...openDialog, openingHoursAndHoursDialog: false });
      } else {
        enqueueSnackbar(result?.updateGoogleBusinessDetails?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error updating business hours:', error);
    }
  };

  const handleToggleDay = (day) => {
    setTimeData((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState?.[day],
        open: !prevState?.[day]?.open,
        ...(prevState?.[day]?.open && {
          start: { hour: '', minute: '' },
          end: { hour: '', minute: '' },
        }),
      },
    }));
  };

  const handleSelectStartTime = (day, data) => {
    const selectedDate = new Date(data);
    const hours = selectedDate.getHours();
    const minutes = selectedDate.getMinutes();
    setTimeData((prevData) => ({
      ...prevData,
      [day]: {
        ...prevData[day],
        start: {
          hour: hours ? String(hours).padStart(2, '0') : '00',
          minute: minutes ? String(minutes).padStart(2, '0') : '00',
        },
      },
    }));
  };

  const handleSelectCloseTime = (day, data) => {
    const selectedDate = new Date(data);
    const hours = selectedDate.getHours();
    const minutes = selectedDate.getMinutes();
    setTimeData((prevData) => ({
      ...prevData,
      [day]: {
        ...prevData[day],
        end: {
          hour: hours ? String(hours).padStart(2, '0') : '00',
          minute: minutes ? String(minutes).padStart(2, '0') : '00',
        },
      },
    }));
  };

  const formatTime = (time) => {
    if (time && time.hour && time.minute) {
      const date = new Date();
      date.setHours(parseInt(time.hour, 10), parseInt(time.minute, 10), 0);
      return date;
    }
    return null;
  };

  const handleClose = () => {
    const updatedTimeData = formatGoogleBusinessHours(googleBusinessData, daysOfWeek);
    setTimeData(updatedTimeData);
    setOpenDialog({ ...openDialog, openingHoursAndHoursDialog: false });
  };

  return (
    <div>
      <div>
        <Typography className="font-bold text-24 mt-16">
          <TimerIcon className="text-32" /> {t('listManagement.businessOpeningAndClosingHours')}
        </Typography>
        <div className="bg-gray-A500 lg:grid grid-cols-2 col-span-1 gap-16 p-28 rounded-md mt-16">
          <div
            className="bg-white p-24 rounded-md"
            onClick={() => setOpenDialog({ ...openDialog, openingHoursAndHoursDialog: true })}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                setOpenDialog({ ...openDialog, openingHoursAndHoursDialog: true });
              }
            }}
          >
            <div className="flex items-center justify-between gap-8 mt-8 mb-16">
              <Typography className="font-bold text-20">
                {t('listManagement.openingAndClosingHour')}
              </Typography>
            </div>
            <div className="font-medium grid w-full grid-cols-2 grid-rows-5 justify-between gap-x-8 gap-y-2">
              {daysOfWeek.map((day, i) => {
                const dayData = googleBusinessData?.regularHours?.periods?.find(
                  (item) => item.openDay === day
                );

                return (
                  <div key={i} className="flex w-256 text-14 font-semibold">
                    <span className="w-96">{capitalizeFirstLetter(day)}</span>
                    <span> : </span>
                    <span className="pl-4">
                      {dayData &&
                      dayData.openTime?.hours !== undefined &&
                      dayData.openTime?.minutes !== undefined
                        ? `${dayData.openTime.hours || '00'}:${
                            dayData.openTime.minutes || '00'
                          } - ${dayData.closeTime.hours || '00'}:${
                            dayData.closeTime.minutes || '00'
                          }`
                        : 'Closed'}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        classes={{
          paper: 'm-24 p-28 business-type-dropdown',
        }}
        open={openDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="p-0 mb-8">
          <div className="flex">
            <Typography className="text-20 font-bold text-black w-full">
              {t('listManagement.addOrEditOpeningAndClosingHour')}
            </Typography>
            <Icon
              onClick={() => handleClose()}
              className="text-24 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
            >
              cancel
            </Icon>
          </div>
          <div className="border-b-2 border-solid border-quick-review-100 pb-24">
            <Typography className="font-bold text-20">{googleBusinessData?.title}</Typography>
            <Typography className="text-gray text-16 font-semibold pb-8">
              {t('listManagement.address')} :{' '}
              {googleBusinessData?.storefrontAddress?.addressLines?.[0]}{' '}
              {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
              {googleBusinessData?.storefrontAddress?.locality}{' '}
              {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
              {googleBusinessData?.storefrontAddress?.postalCode}
            </Typography>
          </div>
          <div>
            <Typography className="font-bold text-16 py-20">Specify your Opening Hours</Typography>
          </div>
          {daysOfWeek?.map((day, index) => {
            return (
              <div
                key={index}
                className="border border-solid !border-gray rounded px-20 md:pt-4 pb-28 md:pb-16 mb-8"
              >
                <div className="w-full flex flex-wrap md:flex-nowrap justify-between items-center gap-6 md:gap-60">
                  <div className="flex md:w-1/2 items-center gap-32 justify-between">
                    <Typography className="font-semibold pt-8">{day}</Typography>
                    <Typography className="pt-12">
                      <Switch
                        checked={timeData?.[day]?.open}
                        onChange={() => handleToggleDay(day)}
                      />
                      {timeData?.[day]?.open ? 'Open' : 'Closed'}
                    </Typography>
                  </div>
                  {(timeData?.[day]?.open || timeData?.[day]?.start?.hour !== '00') && (
                    <div className="flex w-full items-center justify-between gap-8 md:gap-0">
                      <div>
                        <Typography className="font-bold text-gray text-14">
                          {t('listManagement.startTime')}
                        </Typography>
                        <TimePicker
                          className="h-24"
                          id={`time-${day}-start`}
                          required
                          value={formatTime(timeData?.[day]?.start)}
                          onChange={(data) => handleSelectStartTime(day, data)}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                        />
                      </div>
                      <div>
                        <Typography className="font-bold text-gray text-14">
                          {t('listManagement.endTime')}
                        </Typography>
                        <TimePicker
                          className="h-24"
                          id={`time-${day}-end`}
                          required
                          value={formatTime(timeData?.[day]?.end)}
                          onChange={(data) => handleSelectCloseTime(day, data)}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          <div className="text-center">
            <Button
              className="bg-quick-review w-1/2 text-white rounded-full mt-16 px-40 disabled:text-white font-bold text-18 disabled:bg-gray hover:bg-quick-review"
              disabled={refreshIcon}
              onClick={() => onSubmit()}
            >
              {refreshIcon && (
                <CircularProgress className="text-quick-review absolute mx-auto" size={24} />
              )}
              {t('listManagement.save')}
            </Button>
          </div>
        </DialogTitle>
      </Dialog>
    </div>
  );
};

export default BusinessOpeningAndClosingHours;
