import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Tabs } from '@mui/material';
import { selectDashboardData } from 'src/app/store/userSlice';
import { handleApiRequest } from '../../common/common';
import quickSocialQuery from '../../query/quickSocial';

const SocialPlatformTab = ({ onSelectTab }) => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [socialData, setSocialData] = useState([]);
  const [activeStep, setActiveStep] = useState(null);

  useEffect(() => {
    try {
      const fetchData = async () => {
        if (userCurrentBusinessDetails?.id) {
          const payloadData = {
            query: quickSocialQuery.getQuickSocialChannelWiseData,
            variables: {
              businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            },
          };
          const resultData = await handleApiRequest(payloadData);
          if (resultData?.getQuickSocialChannelWiseData?.status === 200) {
            const fetchedData = resultData?.getQuickSocialChannelWiseData?.data;
            setSocialData(fetchedData);
            if (!activeStep && fetchedData?.length > 0) {
              setActiveStep(fetchedData[0]?.reviewPlatformId);
              onSelectTab(fetchedData[0]?.reviewPlatformId);
            }
          }
        }
      };
      fetchData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails?.id, onSelectTab, activeStep]);

  const handleTabClick = (event, id) => {
    setActiveStep(id);
    if (onSelectTab) {
      onSelectTab(id);
    }
  };

  return (
    <div className="bg-white rounded-md mb-24">
      {socialData && socialData?.length > 0 && (
        <Tabs
          value={activeStep}
          className="sm:overflow-hidden overflow-scroll"
          sx={{
            '& .MuiTabs-scroller': {
              overflow: { xs: 'scroll !important', sm: 'hidden !important' },
            },
          }}
          onChange={handleTabClick}
          textColor=""
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          {socialData?.map((social, i) => (
            <Tab
              key={i}
              className={`sm:text-16 font-semibold px-24 py-16 ${
                social.isConnected ? 'cursor-pointer' : 'cursor-not-allowed'
              } ${
                activeStep === social?.reviewPlatformId
                  ? 'bg-quick-social-100 text-quick-social'
                  : 'bg-transparent'
              }`}
              disabled={!social?.isConnected}
              value={social?.reviewPlatformId}
              label={
                <div className="flex items-center">
                  <img
                    className="mr-12"
                    width="20"
                    height="20"
                    src={social?.logo}
                    alt={social?.name}
                  />
                  <span className="capitalize">{social?.name}</span>
                </div>
              }
            />
          ))}
        </Tabs>
      )}
    </div>
  );
};

export default SocialPlatformTab;
