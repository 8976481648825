import { Box, Typography, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectDashboardData } from 'app/store/userSlice';
import QuickChat from './QuickChat';
import BotResponse from './BotResponse';
import quickChatQuery from '../../query/quickChat';
import { handleApiRequest } from '../../common/common';

const BotDetails = () => {
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(
    location?.state?.item?.cardName === 'configureTheBotResponses' ? 1 : 0 || 0
  );
  const [pageLoading, setPageLoading] = useState(false);
  const [quickChatDetails, setQuickChatDetails] = useState({});
  const [selectAvatarUrl, setSelectAvatarUrl] = useState('');
  const [color, setColor] = useState('#0BA861');
  const [imageUrl, setImageUrl] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  useEffect(() => {
    const fetchAgentSupportStatus = async () => {
      setPageLoading(true);
      try {
        if (userCurrentBusinessDetails?.id && !userCurrentBusinessDetails?.isOnlineBusiness) {
          const payloadData = {
            query: quickChatQuery.getQuickChat,
            variables: {
              businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            },
          };
          const resultData = await handleApiRequest(payloadData);
          setPageLoading(false);
          if (
            resultData?.getQuickChat?.status === 200 &&
            resultData?.getQuickChat?.message !== 'Not found.'
          ) {
            setImageUrl(resultData?.getQuickChat?.data?.avatar);
            setQuickChatDetails(resultData?.getQuickChat?.data);
            setSelectAvatarUrl(resultData?.getQuickChat?.data?.avatar);
            setColor(resultData?.getQuickChat?.data?.headerColor);
          }
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchAgentSupportStatus();
  }, [userCurrentBusinessDetails]);

  if (pageLoading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-32 font-quicksand flex flex-col gap-20">
      {userCurrentBusinessDetails?.isOnlineBusiness ? (
        <div className="h-2xl w-full flex flex-col justify-center items-center">
          <div className="w-512 flex flex-col gap-20 items-center">
            <img className="max-w-96" src="/assets/images/icon/chatUnavailable.png" alt="" />
            <p className="text-24 text-center font-600">{t('quickChat.chatbotForOnline')}</p>
          </div>
        </div>
      ) : (
        <>
          <Typography className="text-20 sm:text-28 font-semibold">
            {t('quickChat.botDetails.title')}
          </Typography>
          <div className="border-b-3 border-solid border-grey sm:flex justify-between items-center">
            <Tabs
              value={activeStep}
              onChange={(e, newValue) => setActiveStep(newValue)}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <Tab
                className="sm:text-18 font-bold p-0 sm:mr-28 mr-14"
                value={0}
                label={t('quickChat.botDetails.botInfo')}
              />
              <Tab
                className="sm:text-18 font-bold p-0"
                value={1}
                label={t('quickChat.botDetails.botResponse')}
              />
            </Tabs>
          </div>
          <div>
            {activeStep === 0 && (
              <Box>
                <QuickChat
                  setActiveStep={setActiveStep}
                  setPageLoading={setPageLoading}
                  quickChatDetails={quickChatDetails}
                  selectAvatarUrl={selectAvatarUrl}
                  color={color}
                  imageUrl={imageUrl}
                  setSelectAvatarUrl={setSelectAvatarUrl}
                  setImageUrl={setImageUrl}
                  setQuickChatDetails={setQuickChatDetails}
                  setColor={setColor}
                />
              </Box>
            )}
            {activeStep === 1 && (
              <Box>
                <BotResponse setActiveStep={setActiveStep} />
              </Box>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default BotDetails;
