import { Button } from '@mui/material';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { selectDashboardData } from 'app/store/userSlice';
import { useSelector } from 'react-redux';
import quickPostQuery from '../../query/quickPost';
import { handleApiRequest } from '../../common/common';
import quickChatQuery from '../../query/quickChat';

const QuickChatFeatures = ({
  setActiveStep,
  surpriseMeCardData,
  allFeaturesTrialDataId,
  chatTrialDone,
}) => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [quickChatDetails, setQuickChatDetails] = useState({});

  useEffect(() => {
    surpriseMeCardData();
  }, [surpriseMeCardData]);
  const openChatBot = async () => {
    const payload = {
      query: quickPostQuery.updateSurpriseMeCardData,
      variables: {
        quickHubSurpriseCardId: parseInt(allFeaturesTrialDataId, 10),
        planType: 'chat',
      },
    };

    try {
      await handleApiRequest(payload);
      await fetchAgentSupportStatus();
      if (quickChatDetails?.domain) {
        window.open(
          `${process.env.REACT_APP_PORTAL_URL}/quickchat/${quickChatDetails.domain}`,
          '_blank'
        );
        surpriseMeCardData();
      }
    } catch (error) {
      console.error('Error in openChatBot:', error);
    }
  };

  const fetchAgentSupportStatus = useCallback(async () => {
    try {
      if (userCurrentBusinessDetails?.id && !userCurrentBusinessDetails?.isOnlineBusiness) {
        const payloadData = {
          query: quickChatQuery.getQuickChatDetails,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const resultData = await handleApiRequest(payloadData);
        if (resultData?.getQuickChatDetails?.status === 200) {
          setQuickChatDetails(resultData?.getQuickChatDetails?.data);
        }
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails?.id, userCurrentBusinessDetails?.isOnlineBusiness]);

  return (
    <div className="flex flex-col items-center bg-white p-20">
      <img
        className="w-384 h-auto m-auto border-1 border-grey-300 rounded-8"
        src="/assets/video/surpriseMeChat.gif"
        alt=""
      />
      <div className="flex flex-col sm:flex-row justify-center w-full gap-20 mt-10">
        <Button
          className="md:text-18  mt-10 px-36 sm:px-60 font-semibold disabled:text-black rounded-md"
          variant="outlined"
          color="secondary"
          type="button"
          onClick={() => setActiveStep(3)}
        >
          {t('manageBusiness.button.next')}
        </Button>
        <Button
          className="text-14 mt-10 px-14 font-medium disabled:text-black disabled:bg-gray-300 rounded-md"
          variant="contained"
          color="secondary"
          type="button"
          onClick={() => openChatBot()}
          disabled={chatTrialDone}
        >
          {t('createPost.testYourChatbot')}
        </Button>
      </div>
    </div>
  );
};

export default QuickChatFeatures;
