import { Button, Typography } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import { t } from 'i18next';
import selectedBusinessData from '../../../query/selectedBusiness';
import { handleApiRequest } from '../../../common/common';
import { selectDashboardData } from '../../../../store/userSlice';

const QrCode = () => {
  const [businessQrImg, setBusinessQrImg] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [copy, setCopy] = useState(false);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  useEffect(() => {
    const fetchQrCode = async () => {
      setLoading(true);
      const payload = {
        query: selectedBusinessData.createQRCodePdfWithInfo,
        variables: {
          businessQRId: userCurrentBusinessDetails?.businessQRId?.split('/').pop(),
        },
      };
      const result = await handleApiRequest(payload);
      setLoading(false);
      if (result?.createQRCodePdfWithInfo?.status === 200) {
        setBusinessQrImg(result?.createQRCodePdfWithInfo?.data.pdf);
      } else {
        enqueueSnackbar(result?.createQRCodePdfWithInfo?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    };
    if (userCurrentBusinessDetails?.businessQRId?.split('/').pop()) {
      fetchQrCode();
    }
  }, [userCurrentBusinessDetails?.businessQRId, enqueueSnackbar]);

  const handleDownload = () => {
    window.open(businessQrImg, '_blank');
  };

  const orderRedirect = () => {
    const url = `/qrcode-orders/${userCurrentBusinessDetails?.id}`;
    window.open(url, '_blank');
  };

  const handleCopyClick = async () => {
    navigator.clipboard.writeText('').then(() => {
      setCopy(true);
      return navigator.clipboard.writeText(businessQrImg);
    });
    await setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 h-max lg:p-40">
      <div className="flex w-full items-center justify-between mb-24">
        <div className="flex items-center">
          <Typography className="sm:flex text-20 md:text-28 font-bold mb-0">
            {t('qrCode.title')}
          </Typography>
        </div>
      </div>
      <div className="bg-white flex flex-col gap-12 py-24">
        <div className="bg-cover w-288 sm:w-400 h-[565px] m-auto bg-no-repeat rounded-6 bg-[url('https://quick-review.s3.amazonaws.com/qr-code/qrcode-bg.png')]">
          <div className="border-2 border-white pt-16 w-3/4 rounded-br-24 rounded-tl-6 rounded-t-none border-t-0 border-l-0 bg-quick-review" />

          <div className="text-center">
            <img className="pt-28 m-auto" src="/assets/images/business/logo.svg" alt="PDF Page" />
            <Typography className="text-gray-A600 text-16 md:text-20 pt-14 tracking-widest font-medium play-fair">
              {t('qrCode.reviewMessage')} <br /> {t('qrCode.us')}{' '}
              <span className="font-bold text-white text-16 md:text-20">{t('qrCode.review')}</span>
            </Typography>
            <div className="text-center m-auto bg-white max-w-160 p-14 rounded-md my-12">
              <QRCode value={userCurrentBusinessDetails?.businessQRId} />
            </div>
            <div className="flex gap-8 pt-16 pb-14 justify-center">
              {[...Array(5)].map((_, i) => (
                <img
                  key={i}
                  className="w-20"
                  src="/assets/images/icon/simple-star.svg"
                  alt={`Star ${i + 1}`}
                />
              ))}
            </div>
            <Typography className="text-white font-medium text-20">
              {userCurrentBusinessDetails?.name}
            </Typography>
            <div className="w-full text-center py-20 z-99 mt-12 bg-[url('https://quick-review.s3.amazonaws.com/qr-code/qrcode-name.png')]">
              <Typography className="text-black font-bold text-16">
                Effortlessly manage your review <br /> with quick reviews.
              </Typography>
            </div>
          </div>
          <div className="ml-auto border-2 border-white mt-[17px] border-b-0 pb-16 border-r-0 rounded-br-6 rounded-tl-24 rounded-tr-none rounded-b-none w-3/4 bg-quick-review bottom-0 right-0" />
        </div>
        <div className="md:flex justify-center gap-24 text-center">
          <Button
            className="bg-quick-review rounded md:px-28 disabled:bg-gray disabled:text-black text-white hover:bg-quick-review mx-10 md:mx-0 hidden"
            disabled={!userCurrentBusinessDetails?.id}
            onClick={orderRedirect}
          >
            <ShoppingBagOutlinedIcon className="mr-4 text-20" />
            {t('qrCode.button.orderQrCode')}
          </Button>
        </div>
        <div className="text-center w-full flex justify-center gap-10">
          <Button
            variant="outlined"
            className="rounded-md px-20 py-5"
            color="secondary"
            size="small"
            type="button"
            onClick={() => handleCopyClick('domain')}
            disabled={!userCurrentBusinessDetails?.id || !businessQrImg}
          >
            <ContentCopyIcon className="text-15 mr-8" /> {t('partners.action.copyLink')}
            {copy && (
              <span className="absolute bg-black text-white text-12 px-8 rounded -top-0 duration-300 animate-bounce">
                {t('business.selectedBusinessDetail.labels.copied')}
              </span>
            )}
          </Button>
          <Button
            variant="contained"
            className="rounded-md px-20 py-5 bg-quick-review"
            color="secondary"
            size="small"
            type="button"
            disabled={!userCurrentBusinessDetails?.id || !businessQrImg}
            onClick={handleDownload}
          >
            <FileDownloadOutlinedIcon className="mr-4 text-24" />
            {t('qrCode.button.download')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default QrCode;
