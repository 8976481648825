import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import history from '@history';
import { useSnackbar } from 'notistack';
import { getDecryptData, handleApiRequest } from '../../common/common';
import paymentData from '../../query/payment';

const OneTimePayment = () => {
  const routeParams = useLocation();
  const [razorpayInstance, setRazorPayInstance] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const routeToken = useCallback(() => {
    try {
      return JSON.parse(getDecryptData(routeParams?.search?.replace('?token=', '')));
    } catch (error) {
      enqueueSnackbar('Invalid token provided', { variant: 'error', autoHideDuration: 3000 });
      return null;
    }
  }, [routeParams, enqueueSnackbar]);

  const loadScript = (src) =>
    new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => reject(new Error('Razorpay SDK failed to load.'));
      document.body.appendChild(script);
    });

  const handleRazorpaySubscription = useCallback(async () => {
    try {
      await loadScript('https://checkout.razorpay.com/v1/checkout.js');
    } catch (error) {
      console.error(error.message);
      enqueueSnackbar('Failed to load Razorpay SDK. Please check your connection.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }

    if (!routeToken() || razorpayInstance) return;

    const options = {
      key: process.env.REACT_APP_RAZORPAY_PUBLIC_KEY,
      order_id: routeToken()?.orderId,
      name: 'Quick Reviews',
      prefill: {
        name: routeToken()?.fullName,
        email: routeToken()?.email,
      },
      theme: {
        color: '#0BA861',
      },
      handler: async (response) => {
        try {
          const payload = {
            query: paymentData.validateWebhookSignature,
            variables: {
              razorpayOrderId: response?.razorpay_order_id,
              razorpayPaymentId: response?.razorpay_payment_id,
              razorpaySignature: response?.razorpay_signature,
            },
          };
          const result = await handleApiRequest(payload);

          if (result?.validateWebhookSignature?.status === 200) {
            const isMobile = /iPhone|Android/i.test(navigator.userAgent);
            if (isMobile) {
              window.location.href = 'subscription://quickreviewsSubscriptionSuccess';
            } else {
              history.push(process.env.REACT_APP_PORTAL_URL);
            }
          } else {
            throw new Error(
              result?.validateWebhookSignature?.message || 'Payment validation failed.'
            );
          }
        } catch (error) {
          enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 3000 });
        }
      },
      modal: {
        confirm_close: true,
        ondismiss: (reason) => {
          console.log('Payment closed', reason);
          window.location.reload();
        },
      },
    };

    const paymentObject = new window.Razorpay(options);
    setRazorPayInstance(paymentObject);

    paymentObject.on('payment.failed', (response) => {
      console.error('Payment failed:', response);
      enqueueSnackbar('Payment failed. Please try again.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    });

    paymentObject.open();
  }, [razorpayInstance, enqueueSnackbar, routeToken]);

  useEffect(() => {
    handleRazorpaySubscription();
  }, [handleRazorpaySubscription]);

  return null;
};

export default OneTimePayment;
