/* global FB */
import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { getAllUserBusinessSubscription, selectDashboardData } from 'src/app/store/userSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import { useParams } from 'react-router-dom';
import './style.css';
import history from '@history';
import Slider from 'react-slick';
import { capitalize } from 'lodash';
import { Menu, MenuItem, Typography } from '@mui/material';
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { useSnackbar } from 'notistack';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  findExpireDay,
  handleFBLogin,
  handleSocialResponse,
  handleApiRequest,
  fetchSocialConnections,
} from '../../common/common';
import quickSocialQuery from '../../query/quickSocial';
import businessData from '../../query/business';
import DashboardAlerts from './DashboardAlert';
import PostDetails from './PostDetails';
import SelectPlatformBox from './SelectPlatformBox';

function Dashboards() {
  const { t } = useTranslation();
  const params = useParams();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [socialData, setSocialData] = useState([]);
  const [cardsData, setCardsData] = useState([]);
  const [facebookUserId, setFacebookUserId] = useState();
  const [accessToken, setAccessToken] = useState();
  const [connectionData, setConnectionData] = useState([]);
  const [socialPlatformId, setSocialPlatformId] = useState(null);
  const [openDialog, setOpenDialog] = useState(true);
  const [anchorEls, setAnchorEls] = useState(null);
  const [selectedPage, setSelectedPage] = useState([]);
  const [suggestionPostData, setSuggestionPostData] = useState([]);
  const appKey = process.env.REACT_APP_WABA_APP_ID;
  const fbConfigKey = process.env.REACT_APP_FB_CONFIG_ID;
  const inConfigKey = process.env.REACT_APP_IN_CONFIG_ID;

  const redirect = (type, platformType) => {
    const idPath = params?.id ? `/${params?.id}` : '/';

    switch (type) {
      case 'pending-task':
        history.push(`/pending-task${idPath}`, { platformType });
        break;
      default:
        console.warn('Unknown redirect type:', type);
    }
  };

  const settings = {
    dots: false,
    infinite: cardsData?.appSetupCard?.length > 1,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const templeteSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,

    responsive: [
      {
        breakpoint: 1524,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1392,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const fetchAgentSupportStatus = useCallback(async () => {
    try {
      setLoading(true);
      if (userCurrentBusinessDetails?.id) {
        const payloadData = {
          query: quickSocialQuery.getQuickSocialChannelWiseData,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const resultData = await handleApiRequest(payloadData);
        setLoading(false);
        if (resultData?.getQuickSocialChannelWiseData?.status === 200) {
          setSocialData(resultData?.getQuickSocialChannelWiseData?.data);
        }
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    fetchAgentSupportStatus();
  }, [fetchAgentSupportStatus]);

  useEffect(() => {
    const getCards = async () => {
      setLoading(true);
      try {
        const payloadData = {
          query: businessData.getAppSetupCard,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            type: 'quick_social',
          },
        };
        const resultData = await handleApiRequest(payloadData);

        if (resultData?.getAppSetupCard?.status === 200) {
          setCardsData(resultData?.getAppSetupCard?.data);
        } else {
          console.error('Unexpected status:', resultData?.getAppSetupCard?.status);
        }
      } catch (error) {
        console.error('Error fetching cards data:', error);
      } finally {
        setLoading(false);
      }
    };
    if (userCurrentBusinessDetails?.id) {
      getCards();
    }
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    const getSuggestionPosts = async () => {
      setLoading(true);
      try {
        const payloadData = {
          query: quickSocialQuery.suggestionPostV2,
          variables: {
            platformId: parseInt(userCurrentBusinessDetails?.reviewPlatformId, 10),
            userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const resultData = await handleApiRequest(payloadData);
        if (resultData?.suggestionPostV2?.status === 200) {
          setSuggestionPostData(resultData?.suggestionPostV2?.data);
        } else {
          console.error('Unexpected status:', resultData?.suggestionPostV2?.status);
        }
      } catch (error) {
        console.error('Error fetching suggestion posts:', error);
      } finally {
        setLoading(false);
      }
    };
    if (userCurrentBusinessDetails?.id && userCurrentBusinessDetails?.reviewPlatformId) {
      getSuggestionPosts();
    }
  }, [userCurrentBusinessDetails]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const openVideoUrl = (url) => {
    if (url) {
      window.open(url, '_blank');
    } else {
      console.error('No URL provided');
    }
  };
  const handleSocialConnection = async (social) => {
    setLoading(true);
    try {
      let authResponse;
      if (social?.reviewPlatformId === 9 || social?.reviewPlatformId === 10) {
        authResponse = await handleFBLogin(
          appKey,
          fbConfigKey,
          inConfigKey,
          social?.reviewPlatformId
        );
        if (authResponse) {
          const fbAccessToken = authResponse?.accessToken;
          setFacebookUserId(authResponse?.userID);

          if (fbAccessToken && userCurrentBusinessDetails?.id) {
            await fetchSocialConnections(
              social?.reviewPlatformId,
              userCurrentBusinessDetails,
              setConnectionData,
              setAccessToken,
              setOpenDialog,
              setSelectedPage,
              setLoading,
              fbAccessToken
            );
          } else {
            console.log('Missing Facebook access token or business details.');
          }
        } else {
          console.log('Facebook login failed or was cancelled.');
        }
      } else if (social?.reviewPlatformId === 11) {
        // handleXLogin();
      } else if (social?.reviewPlatformId === 12) {
        // handleLinkedInLogin();
      }
    } catch (error) {
      console.error('Error during social connection:', error);
    } finally {
      setLoading(false);
    }

    setSocialPlatformId(social?.reviewPlatformId);
  };

  const handleClickEventToCreatePost = (event) => {
    setAnchorEls(event.currentTarget);
  };

  const handleCloseBtn = () => {
    setAnchorEls(null);
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-32 h-full l-padding">
      <div className="flex flex-col gap-32">
        {userSubscriptionData &&
          userSubscriptionData
            .filter((subscriptionData) =>
              subscriptionData?.comboDetails?.length > 0
                ? subscriptionData.comboDetails.some((item) => item?.name === 'Quick Social')
                : subscriptionData?.name === 'Quick Social'
            )
            .map(
              (subscriptionData, i) =>
                subscriptionData?.userBusinessId === userCurrentBusinessDetails?.id &&
                subscriptionData?.isFreeTrial === true && (
                  <div key={i} className="bg-[#FFE4E1] text-16 rounded-6 font-600 p-16">
                    <div>
                      {findExpireDay(subscriptionData?.expireDate) > 0 && (
                        <div className="w-full flex justify-center">
                          <div className="w-384 sm:w-586 items-center flex">
                            <img
                              className="block rounded max-w-28 sm:max-w-32"
                              name="logo"
                              src="/assets/images/icon/reviewNotificationIcon.png"
                              alt="bell notification icon"
                            />
                            <p className="ml-10 mr-16 sm:ml-16 sm:mr-32 text-14 sm:text-16 ">
                              {t('quickHubDashboard.your')}
                              {subscriptionData?.comboDetails?.length > 0
                                ? 'Quick Social'
                                : subscriptionData?.name}{' '}
                              {t('quickHubDashboard.freeTrialExpire')}{' '}
                              {findExpireDay(subscriptionData?.expireDate)}{' '}
                              {t('business.searchBusiness.labels.days')}.
                            </p>
                            <Button
                              className="text-12 md:text-14 font-semibold bg-[#D24811] text-white hover:bg-red-500 rounded-12"
                              variant="contained"
                              size="small"
                              type="submit"
                              onClick={() => {
                                history.push(
                                  params?.id ? `/selectProduct/${params.id}` : '/selectProduct/',
                                  {
                                    packageBundle: [8],
                                    intervalType: subscriptionData?.packageInterval,
                                    isBusinessAdded: true,
                                  }
                                );
                              }}
                            >
                              {t('quickHubDashboard.upgradeNow')}
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
            )}
        <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-x-0 sm:gap-x-32 gap-y-28">
          <div className="col-span-2">
            <div className="flex flex-col gap-32">
              {cardsData?.length > 0 && (
                <div className="p-10 sm:p-16 md:px-0 w-full flex gap-10 md:gap-0 flex-col md:flex-row justify-center rounded-md bg-quick-social">
                  <div className="w-full md:w-1/6 flex flex-col justify-center">
                    <div className="w-full flex flex-col gap-5 items-start">
                      <div className="mx-auto bg-white rounded-full flex items-center justify-center w-60 h-60 lg:w-88 lg:h-88">
                        <h1 className="text-quick-social text-48 sm:text-64 font-500">
                          {cardsData?.filter((item) => !item?.isAdded).length}
                        </h1>
                      </div>
                      <span className="mx-auto text-16 lg:text-20 font-bold text-white">
                        {t('quickHubDashboard.pendingTask')}
                      </span>
                    </div>
                  </div>
                  <div className="w-full md:w-5/6 mr-0 md:mr-16">
                    <div className=" bg-white rounded-md p-10 sm:p-20">
                      <div className="flex items-center mb-10 justify-between">
                        <p className="text-14 sm:text-16 font-semibold">
                          {t('quickHubDashboard.nextTask')}
                        </p>
                        <Button
                          className="text-quick-social h-0 font-semibold cursor-pointer hover:bg-transparent text-14"
                          size="small"
                          onClick={() => redirect('pending-task', 'quick_social')}
                        >
                          {t('businessDashboard.seeAll')}
                        </Button>
                      </div>
                      <div className="border-1 border-solid !border-quick-social w-full rounded-md p-6 sm:p-10">
                        <Slider {...settings}>
                          {cardsData
                            ?.filter((task) => !task?.isAdded)
                            .map((item, index) => (
                              <div className="flex" key={index}>
                                <div className="flex flex-col gap-10 sm:gap-0 sm:flex-row justify-between items-center">
                                  <p className="text-14 sm:text-16 font-medium">{item?.title}</p>
                                  <div className="flex gap-14 items-center">
                                    {item?.videoUrl && (
                                      <Button
                                        className="text-12 sm:text-16 font-medium  disabled:text-black rounded-md"
                                        variant="outlined"
                                        color="quickSocial"
                                        type="button"
                                        size="small"
                                        onClick={() => openVideoUrl(item?.videoUrl)}
                                      >
                                        <img
                                          src="/assets/images/icon/playVideo-social.svg"
                                          alt="good"
                                          className="max-w-60 sm:max-w-80 mr-10"
                                        />
                                        {t('quickHubDashboard.watchVideo')}
                                      </Button>
                                    )}
                                    {item?.buttonColor && item?.backgroundColor && (
                                      <Button
                                        style={{
                                          backgroundColor: item?.buttonColor,
                                        }}
                                        className="text-12 sm:text-16 font-medium disabled:bg-gray disabled:text-black rounded-md"
                                        variant="contained"
                                        color="quickSocial"
                                        type="button"
                                        size="small"
                                        // onClick={() => handleClick(item?.cardName, params?.id)}
                                      >
                                        {item?.buttonAction}
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {socialData && socialData?.length > 0 && (
                <div className="p-6 sm:p-10 md:p-12 lg:p-16 w-full rounded-md bg-white">
                  <div className="flex flex-col gap-10 sm:gap-12 md:gap-14">
                    <div className="w-full">
                      <h1 className="text-18 sm:text-20 md:text-24 font-500">
                        {t('quickSocial.connectSocial')}
                      </h1>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:gap-0 gap-10  md:grid-cols-2 lg:grid-cols-4 gap-x-4 sm:gap-x-10 md:gap-x-12 lg:gap-x-16">
                      {socialData?.map((social, i) => (
                        <div
                          className="bg-gray-A500 rounded-m text-center p-6 sm:p-8 md:p-10 lg:p-12"
                          key={i}
                        >
                          <div>
                            {!social?.isConnected ? (
                              <img
                                className="mx-auto rounded-full"
                                width="65"
                                height="65"
                                src={social?.logo}
                                alt={social?.name}
                              />
                            ) : (
                              <div className="relative inline-block">
                                <img
                                  className="mx-auto rounded-full p-6 sm:p-8 border-2 border-quick-review"
                                  width="60"
                                  height="60"
                                  src={social?.logo}
                                  alt={social?.name}
                                />
                                <img
                                  src="assets/images/icon/done.png"
                                  className="h-14 w-14 absolute top-0 right-5 transform translate-x-1/2 translate-y-1/2"
                                  alt="done"
                                />
                              </div>
                            )}
                            <h3 className="my-6 sm:my-8 text-16 sm:text-18 md:text-20 font-500">
                              {capitalize(social?.name)}
                            </h3>
                            <Button
                              className="rounded-md disabled:bg-quick-review mt-20 disabled:text-white w-full sm:w-140 md:w-120 text-14 sm:text-16"
                              size="medium"
                              variant="contained"
                              color={`${social?.isConnected ? 'quickReview' : 'quickSocial'}`}
                              disabled={social?.isConnected}
                              onClick={() => handleSocialConnection(social)}
                            >
                              {social?.isConnected
                                ? `${t('quickSocial.connected')}`
                                : `${t('quickSocial.connect')}`}
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {suggestionPostData?.length > 0 && (
                <div className="relative bg-white p-10 sm:p-16">
                  <div className="flex justify-between items-center">
                    <Typography className="font-bold text-14 sm:text-20 ">
                      {t('quickSocial.suggestedForYou')}
                    </Typography>
                    <Button className="text-quick-social text-14 sm:text-20 !min-h-0 !max-h-0 hover:bg-transparent">
                      {t('quickSocial.exploreMore')}
                      <ChevronRightIcon className="text-quick-social" />
                    </Button>
                  </div>
                  <div className="relative mt-16">
                    <Slider {...templeteSettings}>
                      {suggestionPostData?.map((img, index) => (
                        <div key={index} className="relative group cursor-pointer px-4">
                          <div
                            className="w-full h-full p-0 cursor-pointer"
                            tabIndex={0}
                            role="button"
                            aria-label={`Preview ${index}`}
                          >
                            <img
                              src={img?.sharePostImage}
                              alt={`Preview ${index}`}
                              className="w-full h-320 sm:h-288 md:h-224 lg:h-256 object-cover rounded"
                            />
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-32">
            <DashboardAlerts loading={loading} />
            <div className="bg-quick-social rounded-md relative shadow p-28 py-36 w-full">
              <div className="">
                <div className="flex items-center  w-full max-h-200 rounded-md">
                  <div className="w-2/3 flex flex-col gap-16 justify-center">
                    <Typography className="text-white font-500 text-14 sm:text-16">
                      {t('quickSocial.createPostDesc')}
                    </Typography>
                    <Button
                      className="rounded-md text-quick-social text-14 sm:text16 bg-white hover:bg-white max-w-136 min-w-136 mt-8"
                      variant="contained"
                      aria-label="Edit"
                      onClick={handleClickEventToCreatePost}
                    >
                      <span className="text-20 sm:text-28 mr-10">+</span>
                      {t('quickSocial.createPost')}
                    </Button>
                    <Menu
                      anchorEl={anchorEls}
                      open={Boolean(anchorEls)}
                      onClose={handleCloseBtn}
                      classes={{ paper: 'min-w-112' }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      sx={{
                        mt: 1,
                        '& .MuiPaper-root': {
                          boxShadow: '1px 1px 3px gray',
                        },
                      }}
                    >
                      <MenuItem>
                        <BrokenImageOutlinedIcon className="text-quick-social text-24 mr-8" />
                        {t('quickSocial.selectTemplate')}
                      </MenuItem>
                      <MenuItem>
                        <CreateOutlinedIcon className="border-1 border-quick-social p-1 rounded-6 text-quick-social text-20 mr-8" />
                        {t('quickSocial.writeYourOwn')}
                      </MenuItem>
                    </Menu>
                  </div>
                  <div className="w-1/3 flex justify-center">
                    <img
                      className="block rounded w-60 sm:w-112"
                      name="logo"
                      src="/assets/images/icon/CreatePostIcon.png"
                      alt="bell notification icon"
                    />
                  </div>
                </div>
              </div>
            </div>
            <PostDetails loading={loading} />
          </div>
        </div>
      </div>
      {connectionData?.length > 0 && openDialog && (
        <SelectPlatformBox
          onClose={handleClose}
          openDialog={openDialog}
          socialPlatformId={socialPlatformId}
          connectionData={connectionData}
          setSelectedPage={setSelectedPage}
          selectedPage={selectedPage}
          setOpenDialog={setOpenDialog}
          handleSocialResponse={() =>
            handleSocialResponse(
              socialPlatformId,
              accessToken,
              facebookUserId,
              selectedPage,
              userCurrentBusinessDetails,
              setLoading,
              enqueueSnackbar,
              fetchAgentSupportStatus,
              setConnectionData,
              setOpenDialog
            )
          }
          handleClose={handleClose}
        />
      )}
    </div>
  );
}
export default Dashboards;
