import { Dialog, DialogTitle, Icon, Typography, Button } from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import { t } from 'i18next';
import FuseLoading from '@fuse/core/FuseLoading';
import wabaQuery from '../../query/waba';
import { handleWabaApiRequest, convertTemplateToHtml } from '../../common/common';

const TemplateList = (props) => {
  const { openValue, cancelCall, popUpMessage, onTemplateSelect } = props;

  const [dialogOpen, setDialogOpen] = useState(openValue);
  const [templateList, setTemplateList] = useState();
  const [loading, setLoading] = useState(false);
  const [searchValueTemplate, setSearchValueTemplate] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const handleTemplate = useCallback(async () => {
    try {
      const payload = {
        query: wabaQuery.getWhatsappTemplate,
        variables: {
          searchText: searchValueTemplate,
        },
      };
      const res = await handleWabaApiRequest(payload);
      if (res?.getWhatsappTemplate?.status === 200) {
        setTemplateList(res?.getWhatsappTemplate?.data);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [searchValueTemplate]);

  useEffect(() => {
    handleTemplate();
  }, [handleTemplate]);

  const handleClose = () => {
    setDialogOpen(false);
    cancelCall();
  };

  if (loading) {
    return <FuseLoading />;
  }

  const handleTemplateSelect = (template) => {
    if (selectedTemplate?.id === template.id) {
      setSelectedTemplate(template);
    } else {
      setSelectedTemplate(template);
    }
  };

  const handleSelectButtonClick = () => {
    if (onTemplateSelect && selectedTemplate) {
      onTemplateSelect(selectedTemplate);
    }
  };

  const renderHtmlContent = (html) => ({
    __html: html,
  });

  return (
    <Dialog
      classes={{
        paper:
          'm-24 max-w-screen-lg width-full border-t-8 border-solid border-t-darkgreen border-darkgreen rounded-md',
      }}
      open={openValue}
      onKeyDown={(event) => {
        if (event.keyCode === 27) {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle className="p-0 py-16 px-20 border-b-1 border-solid border-grey-300">
        <Icon
          onClick={() => handleClose()}
          className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
        >
          cancel
        </Icon>
        <Typography className="text-20 font-semibold text-black w-full mb-0">
          {popUpMessage}
        </Typography>
      </DialogTitle>
      <div className="w-full p-20 flex">
        <div
          className={`${selectedTemplate ? 'w-3/4' : 'w-full'} overflow-auto`}
          style={{ maxHeight: '70vh' }}
        >
          <input
            type="text"
            className="px-10 py-2 border rounded-md w-full h-36 border-darkgreen mb-20"
            placeholder={t('waba.inbox.searchTemplate')}
            onChange={(event) => setSearchValueTemplate(event?.target?.value)}
            value={searchValueTemplate}
          />
          <div
            className={`grid ${
              selectedTemplate
                ? 'grid-cols-2'
                : 'xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2'
            } grid-cols-1 gap-16`}
          >
            {templateList &&
              templateList?.length > 0 &&
              templateList?.map((template, index) => (
                <>
                  <div
                    key={index}
                    onClick={() => handleTemplateSelect(template)}
                    aria-hidden
                    className="text-14 font-medium cursor-pointer hover:bg-gray-200 bg-darkgreen-100 rounded-md p-5"
                  >
                    <div className="mb-5 font-medium text-16">
                      <span className="flex items-center justify-between">
                        {template?.displayName}
                        <Icon className="text-14">
                          {selectedTemplate?.id === template.id
                            ? 'check_circle'
                            : 'radio_button_unchecked'}
                        </Icon>
                      </span>
                    </div>

                    <div className="bg-white shadow p-4 rounded-md">
                      {JSON.parse(template?.templateData)?.components?.map((templateValue, i) => (
                        <div className="block" key={i}>
                          <div>
                            {templateValue?.type === 'HEADER' && (
                              <>
                                {templateValue?.format === 'TEXT' && (
                                  <Typography
                                    className="mb-10 font-bold"
                                    dangerouslySetInnerHTML={{ __html: templateValue?.text }}
                                  />
                                )}
                              </>
                            )}
                            {templateValue?.type === 'BODY' && (
                              <>
                                <Typography
                                  className="line-clamp-2"
                                  dangerouslySetInnerHTML={{ __html: templateValue?.text }}
                                />
                              </>
                            )}
                            {templateValue?.type === 'FOOTER' && (
                              <>
                                <Typography
                                  dangerouslySetInnerHTML={{ __html: templateValue?.text }}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>

                    <div>
                      {JSON.parse(template?.templateData)?.components?.map((templateValue, i) => (
                        <div key={i}>
                          {templateValue?.type === 'BUTTONS' && (
                            <>
                              {templateValue?.buttons?.map((buttonValue, b) => (
                                <div className="bg-white shadow p-4 rounded-md mt-4 text-center">
                                  <a
                                    className="font-bold text-darkgreen text-16"
                                    href={`${
                                      buttonValue?.phone_number
                                        ? `tel:${buttonValue?.phone_number}`
                                        : ''
                                    }`}
                                    rel="noreferrer"
                                    target="_blank"
                                    role="button"
                                    key={b}
                                  >
                                    <img
                                      src={
                                        buttonValue?.phone_number
                                          ? '/assets/images/business/telephone.png'
                                          : '/assets/images/business/view.png'
                                      }
                                      className="w-14 h-14 align-middle mr-4 inline-block"
                                      alt="..."
                                    />
                                    {buttonValue?.text}
                                  </a>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>

        {selectedTemplate && (
          <div className="w-1/4 bg-gray-50 px-12 py-12 rounded-md ml-4 rounded-md flex-col justify-between">
            <div>
              <Typography className="font-semibold mb-20 text-18">
                {selectedTemplate?.displayName}
              </Typography>
              <div className="mt-4 mb-4 bg-white rounded-md p-8 px-10">
                {JSON.parse(selectedTemplate?.templateData)?.components?.map((templateValue, i) => (
                  <div key={i}>
                    {templateValue?.type === 'HEADER' && templateValue?.format === 'TEXT' && (
                      <Typography
                        className="mb-10 font-bold"
                        dangerouslySetInnerHTML={{ __html: templateValue?.text }}
                      />
                    )}
                    {templateValue?.type === 'HEADER' && (
                      <>
                        {templateValue?.format === 'TEXT' && (
                          <Typography className="mb-10 font-bold">{templateValue?.text}</Typography>
                        )}
                        {templateValue?.format === 'IMAGE' && (
                          <>
                            {JSON.parse(selectedTemplate?.dynamicData)?.header?.map(
                              (dynamicValue) => (
                                <>
                                  {dynamicValue?.type === 'image' && (
                                    <img
                                      src={dynamicValue?.value}
                                      className="mb-10 rounded-md"
                                      alt="..."
                                    />
                                  )}
                                </>
                              )
                            )}
                          </>
                        )}
                      </>
                    )}
                    {templateValue?.type === 'BODY' && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: convertTemplateToHtml(templateValue?.text),
                        }}
                      />
                    )}
                    {templateValue?.type === 'FOOTER' && (
                      <Typography dangerouslySetInnerHTML={{ __html: templateValue?.text }} />
                    )}
                    {templateValue?.type === 'BUTTONS' &&
                      templateValue?.buttons?.map((buttonValue, b) => (
                        <div
                          className="text-center border-t-1 border-solid border-grey-300 py-4 mt-12"
                          key={b}
                        >
                          <a
                            className="font-bold text-darkgreen text-16"
                            href={`${
                              buttonValue?.phone_number ? `tel:${buttonValue?.phone_number}` : ''
                            }`}
                            rel="noreferrer"
                            target="_blank"
                            role="button"
                          >
                            <img
                              src={
                                buttonValue?.phone_number
                                  ? '/assets/images/business/telephone.png'
                                  : '/assets/images/business/view.png'
                              }
                              className="w-14 h-14 align-middle mr-4 inline-block"
                              alt="..."
                            />
                            {buttonValue?.text}
                          </a>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            </div>
            <div className="text-right">
              <Button
                variant="contained"
                onClick={handleSelectButtonClick}
                className="rounded-md mt-28"
                size="small"
                color="secondary"
              >
                {t('waba.inbox.select')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default TemplateList;
