import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  TextField,
  InputLabel,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import {
  DatePicker,
  LocalizationProvider,
  renderTimeViewClock,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const SchedulePopUp = ({
  open,
  onClose,
  onCreatePost,
  refresh,
  backgroundColor,
  textColor,
  buttonText,
  scheduledDate,
  setScheduledDate,
  scheduledTime,
  setScheduledTime,
}) => {
  const handleDateChange = (data) => {
    const originalDate = new Date(data);
    const formattedDate = originalDate?.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const [month, day, year] = formattedDate.split('/');
    const desiredFormat = `${year}-${month}-${day}`;
    setScheduledDate(desiredFormat);
  };

  const handleTimeChange = (event) => {
    setScheduledTime(event.toLocaleTimeString());
  };

  const handleCreatePost = () => {
    const combinedDateTime = new Date(`${scheduledDate}T${scheduledTime}`);
    onCreatePost(combinedDateTime);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="createPost-confirmation-dialog"
      classes={{
        paper: `m-24 p-28 business-type-dropdown border-t-8 border-solid text-center border-t-${textColor} border-${textColor} rounded-md`,
      }}
      PaperProps={{ style: { maxWidth: '460px' } }}
    >
      <h3 className="text-left font-semibold">Schedule Post</h3>
      <IconButton className="absolute top-4 right-4" onClick={onClose} color="inherit">
        <CloseIcon className="w-32 h-32 text-grey-700" />
      </IconButton>

      <DialogContent className="p-0 mb-24">
        <div className=" items-center justify-between gap-16">
          <div className="">
            <Typography className="text-14 text-black mt-4 font-medium text-left">
              {t('sendMessage.selectDate')}
            </Typography>
            <div className="mt-4">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  className="w-full"
                  id="note"
                  variant="outlined"
                  fullWidth
                  disablePast
                  format="MMM dd, yyyy"
                  value={scheduledDate}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        shrink: false,
                      }}
                      label="Select a date"
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="">
            <Typography className="text-14 text-black mt-4 font-medium text-left">
              {t('sendMessage.selectTime')}
            </Typography>
            <div className="mt-4">
              <TimePicker
                className="w-full"
                id="time"
                onChange={handleTimeChange}
                size="small"
                required
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              />
            </div>
          </div>
        </div>
      </DialogContent>

      <DialogActions className="p-0 flex justify-center gap-14">
        <Button
          className={`md:text-16 font-medium rounded-md bg-${backgroundColor} w-full max-w-256`}
          variant="contained"
          color="secondary"
          onClick={handleCreatePost}
          disabled={!scheduledDate || !scheduledTime}
        >
          {buttonText}
          {refresh && <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SchedulePopUp;
