import { io } from 'socket.io-client';
import userService from './app/services/userService';

let socket = null;
let chatSocket = null;

const connectSocket = () => {
  if (!socket) {
    const URL = process.env.REACT_APP_SOCKET;
    socket = io(URL, {
      extraHeaders: {
        Authorization: `Bearer ${userService.getAccessToken() || null}`,
      },
    });

    socket.on('connect', () => {
      console.log('Socket connected');
    });
  }
  return socket;
};

const connectChatSocket = () => {
  if (!chatSocket) {
    const chatURL = process.env.REACT_APP_CHAT_SOCKET;
    chatSocket = io(chatURL, {
      extraHeaders: {
        Authorization: `Bearer ${userService.getAccessToken() || process.env.REACT_APP_CHAT_TOKEN}`,
      },
    });

    chatSocket.on('connect', () => {
      console.log('Chat Socket connected');
    });
  }
  return chatSocket;
};

export { connectSocket, connectChatSocket };
