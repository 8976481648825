import { t } from 'i18next';
import { Button, Icon, MenuItem, Select, Typography } from '@mui/material';
import EmojiPicker from 'emoji-picker-react';
import { useEffect, useState, useRef, useCallback } from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useSelector } from 'react-redux';
import moment from 'moment';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { publicIpv4 } from 'public-ip';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSnackbar } from 'notistack';
import { connectChatSocket } from '../../../../socket';
import quickChatQuery from '../../query/quickChat';
import { handleApiRequest } from '../../common/common';
import { selectDashboardData } from '../../../store/userSlice';

const ChatInbox = () => {
  const chatScroll = useRef(null);
  const chatContainerRef = useRef(null);
  const [messageInput, setMessageInput] = useState('');
  const [chatType, setChatType] = useState(0);
  const [selectedContact, setSelectedContact] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [getChatRoom, setGetChatRoom] = useState([]);
  const [selectContactMessageList, setSelectContactMessageList] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [quickChatDetails, setQuickChatDetails] = useState({});
  const [isConnected, setIsConnected] = useState(false); // chatSocket.connected
  const ipAddress = publicIpv4();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [currPage, setCurrPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [prevPage, setPrevPage] = useState(0);
  const [wasLastList, setWasLastList] = useState(false);
  const currentTime = moment().format('h:mm A');
  const { enqueueSnackbar } = useSnackbar();
  const [timeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const chatSocket = connectChatSocket();

  useEffect(() => {
    const fetchAgentSupportStatus = async () => {
      if (userCurrentBusinessDetails?.id && !userCurrentBusinessDetails?.isOnlineBusiness) {
        const payloadData = {
          query: quickChatQuery.getQuickChat,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const resultData = await handleApiRequest(payloadData);
        if (
          resultData?.getQuickChat?.status === 200 &&
          resultData?.getQuickChat?.message !== 'Not found.'
        ) {
          setQuickChatDetails(resultData?.getQuickChat?.data);
        }
      }
    };
    fetchAgentSupportStatus();
  }, [userCurrentBusinessDetails]);

  const whatAppUserDetails = useCallback(async () => {
    try {
      if (selectedContact) {
        const previousScrollHeight = chatContainerRef.current?.scrollHeight;

        const payload = {
          query: quickChatQuery.getAllQuickChatRoomMessageUsingQuickChatId,
          variables: {
            quickChatRoomId: selectedContact?.quickChatRoomMessages?.quickChatRoomId,
            sortBy: 'cts',
            sortOrder: 'desc',
            pageNumber: currPage,
            pageSize: 20,
          },
        };
        const res = await handleApiRequest(payload);
        if (res?.getAllQuickChatRoomMessageUsingQuickChatId?.status === 200) {
          setHasMore(true);
          if (!res?.getAllQuickChatRoomMessageUsingQuickChatId?.data?.length) {
            setWasLastList(true);
            setHasMore(false);
            return;
          }
          setPrevPage(currPage);
          setSelectContactMessageList([
            ...(res?.getAllQuickChatRoomMessageUsingQuickChatId?.data.reverse() || []),
            ...selectContactMessageList,
          ]);

          setTimeout(() => {
            const newScrollHeight = chatContainerRef.current?.scrollHeight;
            chatContainerRef.current.scrollTop += newScrollHeight - previousScrollHeight;
          }, 0);
        }
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [selectedContact, currPage, selectContactMessageList]);

  useEffect(() => {
    try {
      if (quickChatDetails?.id) {
        setPageLoading(true);
        const whatAppUserChatRoom = async () => {
          const payload = {
            query: quickChatQuery.getAllQuickChatWithLastMessage,
            variables: {
              quickChatId: quickChatDetails?.id,
            },
          };
          const res = await handleApiRequest(payload);
          setPageLoading(false);
          if (res?.getAllQuickChatWithLastMessage?.status === 200) {
            setGetChatRoom(res?.getAllQuickChatWithLastMessage?.data);
          } else {
            setGetChatRoom([]);
          }
        };
        whatAppUserChatRoom();
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [quickChatDetails?.id]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id && quickChatDetails?.id) {
      const handleConnect = async () => {
        try {
          const resolvedIpAddress = await publicIpv4();

          const subscribeChatData = JSON.stringify({
            userBusinessesId: userCurrentBusinessDetails?.id,
            ipAddress: resolvedIpAddress,
            quickChatId: quickChatDetails?.id,
          });
          chatSocket.emit('subscribeChannelByBusinessUser', subscribeChatData, (ack) => {
            console.log('Event emitted, acknowledgment:', ack);
          });

          await setIsConnected(true);
        } catch (error) {
          console.error('Error fetching IP address or connecting to chat:', error);
          setIsConnected(false);
        }
      };
      handleConnect();

      const handleDisconnect = () => {
        setIsConnected(false);
      };

      const handleChatMessage = (event) => {
        if (event?.eventType === 'sendClientMessageToBusinessUser') {
          if (
            event?.data?.quickChatRoomId === selectedContact?.quickChatRoomMessages?.quickChatRoomId
          ) {
            setSelectContactMessageList((prevMessages) => [
              ...prevMessages,
              {
                ...event?.data,
              },
            ]);
            chatScroll.current?.scrollIntoView({ behavior: 'smooth' });
          }

          setGetChatRoom((prevContacts) => {
            const contactExists = prevContacts.some(
              (contact) =>
                contact?.quickChatRoomMessages?.quickChatRoomId === event?.data?.quickChatRoomId
            );

            let updatedContacts;

            if (!contactExists) {
              updatedContacts = [
                {
                  ...event?.data,
                  cts: Number(event?.data?.cts) * 1000,
                  unRead: 1,
                },
                ...prevContacts,
              ];
            } else {
              updatedContacts = prevContacts.map((contact) =>
                contact?.quickChatRoomMessages?.quickChatRoomId === event?.data?.quickChatRoomId
                  ? {
                      ...contact,
                      quickChatRoomMessages: { ...event?.data },
                      cts: Number(event?.data?.cts) * 1000,
                      unRead: 1,
                    }
                  : contact
              );
            }
            return updatedContacts.sort((a, b) => b.cts - a.cts);
          });
        }
      };

      chatSocket.on('connect', handleConnect);
      chatSocket.on('disconnect', handleDisconnect);
      chatSocket.on('QUICK_CHAT_MESSAGES', handleChatMessage);
      return () => {
        chatSocket.off('connect', handleConnect);
        chatSocket.off('disconnect', handleDisconnect);
        chatSocket.off('QUICK_CHAT_MESSAGES', handleChatMessage);
      };
    }
    return undefined;
  }, [
    currentTime,
    getChatRoom,
    ipAddress,
    quickChatDetails?.id,
    userCurrentBusinessDetails?.id,
    selectedContact?.quickChatRoomMessages?.quickChatRoomId,
    chatSocket,
  ]);

  useEffect(() => {
    if (selectedContact) {
      if (!wasLastList && prevPage !== currPage) {
        whatAppUserDetails();
      }
    }
  }, [
    selectedContact,
    currPage,
    wasLastList,
    prevPage,
    selectContactMessageList,
    whatAppUserDetails,
    hasMore,
  ]);

  const onScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = chatContainerRef.current;
      if (scrollTop <= 4 && !wasLastList && hasMore) {
        setHasMore(false);
        setCurrPage((prevPageNumber) => prevPageNumber + 1);
      }
    }
  };

  useEffect(() => {
    if (chatScroll.current) {
      chatScroll.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectContactMessageList]);

  const handleContactClick = (contact) => {
    setSelectedContact(contact);
    setSelectContactMessageList([]);
    setCurrPage(1);
    setPrevPage(0);
    setHasMore(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage('text');
    }
  };
  chatSocket.on('error', (error) => {
    console.error('Socket error:', error);
  });
  const handleSendMessage = async () => {
    if (!messageInput || messageInput.trim() === '') {
      return;
    }
    setMessageInput('');

    const newMessage = {
      message: messageInput,
      cts: moment().toISOString(),
      status: 'sent',
      messageType: 'sender',
    };

    setSelectContactMessageList((prevList) => [...prevList, newMessage]);
    chatScroll.current?.scrollIntoView({ behavior: 'smooth' });
    try {
      const payload = {
        query: quickChatQuery.sendQuickChatMessageByOwner,
        variables: {
          quickChatRoomId: selectedContact?.quickChatRoomMessages?.quickChatRoomId,
          message: messageInput,
        },
      };
      await handleApiRequest(payload);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const formattedDate = (timestamp) => {
    const date = moment(timestamp);
    const today = moment();
    const yesterday = moment().subtract(1, 'days');

    if (date.isSame(today, 'day')) {
      return date.format('h:mm A');
    }
    if (date.isSame(yesterday, 'day')) {
      return 'Yesterday';
    }
    return date.format('DD/MM/YYYY');
  };

  const handleFavorite = async () => {
    try {
      const payload = {
        query: quickChatQuery.addQuickChatFavorite,
        variables: {
          isFavorite: !selectedContact?.favourite,
          quickChatRoomId: selectedContact?.quickChatRoomMessages?.quickChatRoomId,
        },
      };
      const res = await handleApiRequest(payload);
      if (res?.addQuickChatFavorite?.status === 200) {
        enqueueSnackbar(res?.addQuickChatFavorite?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setSelectedContact((prevData) => ({
          ...prevData,
          favourite: !prevData.favourite, // Toggle the favourite value
        }));
      } else {
        enqueueSnackbar(res?.addQuickChatFavorite?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  if (pageLoading) {
    return <FuseLoading />;
  }

  const groupMessagesByDate = (messages) => {
    return messages.reduce((groups, message) => {
      const date = moment.utc(message.cts).tz(timeZone).format('YYYY-MM-DD');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});
  };

  return (
    <>
      <div className="p-20 lg:p-28">
        {userCurrentBusinessDetails?.isOnlineBusiness ? (
          <div className="h-2xl w-full flex flex-col justify-center items-center">
            <div className="w-512 flex flex-col gap-20 items-center">
              <img className="max-w-96" src="/assets/images/icon/chatUnavailable.png" alt="" />
              <p className="text-24 text-center font-600">{t('quickChat.chatbotForOnline')}</p>
            </div>
          </div>
        ) : getChatRoom?.length > 0 ? (
          <div className="bg-white rounded-md border-1 border-solid border-grey-300">
            <div className="flex h-screen chat-height">
              {/* Left Sidebar */}
              <div className="w-1/4 overflow-auto">
                <div className="border-b-1 border-solid border-grey-300 h-64 grid align-middle w-full px-16 relative">
                  <div className="flex items-center justify-between">
                    <Select
                      className="inline-block border-none text-black capitalize min-h-0 h-auto p-0 font-semibold text-16"
                      id="msgType"
                      value={chatType}
                      size="small"
                      displayEmpty
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        '& .MuiSelect-select': {
                          padding: '0px 0',
                          display: 'flex',
                          alignItems: 'center',
                          border: 'none',
                        },
                      }}
                      onChange={(e) => setChatType(e?.target?.value)}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      <MenuItem value={0} className="capitalize">
                        {t('waba.inbox.allChat')}
                      </MenuItem>
                      <MenuItem value={1} className="capitalize">
                        {t('waba.inbox.unreadChat')}
                      </MenuItem>
                      <MenuItem value={2} className="capitalize">
                        {t('waba.inbox.broadcast')}
                      </MenuItem>
                    </Select>
                  </div>
                </div>
                <div className="p-16">
                  {getChatRoom && getChatRoom?.length > 0 ? (
                    <>
                      {getChatRoom?.map((contact, index) => (
                        <div
                          key={index}
                          className={`mb-10 rounded cursor-pointer hover:border-quick-chat border-1 border-solid  rounded-md p-16 ${
                            selectedContact?.id === contact?.id
                              ? 'border-quick-chat'
                              : 'bg-white border-grey-300'
                          }`}
                          aria-hidden
                          onClick={() => handleContactClick(contact)}
                        >
                          <div className="flex items-center">
                            <img
                              src="/assets/images/business/profile.png"
                              className="img-fluid w-full max-w-48 mr-10"
                              alt="..."
                            />

                            <div className="overflow-hidden w-full">
                              <div className="flex items-center justify-between">
                                <h3 className="text-16 font-semibold w-full overflow-hidden whitespace-nowrap text-ellipsis">
                                  {contact?.ipAddress}
                                </h3>

                                <span className="text-12 font-semibold w-68 text-right">
                                  {formattedDate(contact?.quickChatRoomMessages?.cts)}
                                </span>
                              </div>
                              <div className="flex items-center justify-between">
                                <span
                                  className="font-medium text-14 whitespace-nowrap overflow-hidden text-ellipsis mt-4 mb-4 block text-grey-600"
                                  dangerouslySetInnerHTML={{
                                    __html: contact?.quickChatRoomMessages?.message,
                                  }}
                                />
                                <span
                                  className={`text-12 font-semibold w-68 text-right ${
                                    contact?.status === 'live'
                                      ? 'text-grey-800'
                                      : contact?.status === 'close'
                                      ? 'text-grey-600'
                                      : 'text-red'
                                  }`}
                                >
                                  <span
                                    className={`inline-block w-7 h-7 rounded-full mr-3 ${
                                      contact?.status === 'live'
                                        ? 'bg-green'
                                        : contact?.status === 'close'
                                        ? 'bg-grey-600'
                                        : 'bg-red'
                                    }`}
                                  />
                                  <span>{contact?.status}</span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <Typography className="text-center font-medium">
                      {t('waba.inbox.noResult')}
                    </Typography>
                  )}
                </div>
              </div>

              <div
                className={`flex-grow relative ${
                  selectedContact ? 'w-3/5' : 'w-4/5'
                } border-x-1 border-solid border-grey-300`}
              >
                {selectedContact ? (
                  <>
                    <div className="border-b-1 border-solid border-grey-300 h-64 grid align-middle w-full px-16">
                      <div className="flex items-center">
                        <img
                          src="/assets/images/business/profile.png"
                          className="img-fluid w-full max-w-40 mr-10"
                          alt="..."
                        />

                        <div className="flex items-center justify-between w-full">
                          <h3 className="text-16 font-bold">{selectedContact?.ipAddress}</h3>
                          <span
                            aria-hidden="true"
                            className="cursor-pointer"
                            onClick={() => {
                              handleFavorite();
                            }}
                          >
                            {selectedContact?.favourite ? (
                              <FavoriteIcon className="text-red" />
                            ) : (
                              <FavoriteBorderIcon />
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="inbox-height flex flex-col bg-gray-A300 relative">
                      <div
                        className="flex-grow overflow-auto p-20 pb-0"
                        onScroll={onScroll}
                        ref={chatContainerRef}
                      >
                        {Object.entries(groupMessagesByDate(selectContactMessageList)).map(
                          ([date, messages], index) => (
                            <div key={index}>
                              <div className="text-center">
                                <div className="text-center text-grey-600 text-12 font-medium mb-12 bg-white inline-block mx-auto rounded-md shadow px-10 py-4 font-medium">
                                  {moment(date).calendar(null, {
                                    sameDay: '[Today]',
                                    lastDay: '[Yesterday]',
                                    lastWeek: 'dddd',
                                    sameElse: 'DD/MM/YYYY',
                                  })}
                                </div>
                              </div>
                              {messages.map((message, idx) => (
                                <div className="mb-12 block" key={idx}>
                                  <div
                                    className={`rounded-md mb-4 inline-block px-8 py-8 text-14 font-semibold ${
                                      message?.messageType === 'sender'
                                        ? 'self-end text-right float-right bg-quick-chat text-white'
                                        : 'self-start text-left bg-white'
                                    }`}
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: message?.message,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={`text-12 text-gray-700 font-medium clear-both     ${
                                      message?.messageType === 'sender' ? 'text-right' : 'text-left'
                                    }`}
                                  >
                                    {moment.utc(message.cts).tz(timeZone).format('hh:mm A')}

                                    {message?.messageType === 'sender' &&
                                      message?.status === 'send' && (
                                        <Icon className="text-14">done</Icon>
                                      )}
                                    {message?.messageType === 'sender' &&
                                      message?.status === 'delivered' && (
                                        <DoneAllIcon className="text-14 ml-3" />
                                      )}
                                    {message?.messageType === 'sender' &&
                                      message?.status === 'read' && (
                                        <DoneAllIcon className="text-blue text-14 ml-3" />
                                      )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )
                        )}
                        <div ref={chatScroll} className="h-32" />
                      </div>
                      <div className="mt-4 flex bg-white items-center px-20">
                        <input
                          type="text"
                          className="flex-grow p-2 border-none rounded-none h-60"
                          value={messageInput}
                          onChange={(e) => setMessageInput(e.target.value)}
                          placeholder={t('waba.inbox.typeMessage')}
                          onKeyPress={handleKeyPress}
                        />
                        <div>
                          <span
                            aria-hidden
                            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                            className="bg-grey-100 rounded-md w-28 h-28 inline-block text-center pt-4 align-middle mx-10 cursor-pointer"
                          >
                            <Icon className="text-20">emoji_emotions</Icon>
                          </span>
                          {showEmojiPicker && (
                            <div className="absolute bottom-60 right-16">
                              <EmojiPicker
                                onEmojiClick={(emojiObject) => {
                                  setMessageInput((prevMsg) => prevMsg + emojiObject.emoji);
                                  setShowEmojiPicker(false);
                                }}
                              />
                            </div>
                          )}

                          <Button
                            className="rounded-md md:px-24"
                            onClick={() => handleSendMessage()}
                            size="small"
                            variant="contained"
                            color="quickChat"
                            type="button"
                          >
                            {t('quickChat.createBot.send')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="h-full flex items-center justify-center bg-gray-A300">
                    <div className="mx-auto text-center">
                      <img
                        src="/assets/images/business/no_chat.png"
                        className="img-fluid mx-auto mb-48 max-w-192"
                        alt="..."
                      />
                      <Typography className="block text-24 font-semibold mb-12">
                        {t('quickChat.createBot.noChat')}
                      </Typography>
                      <Typography className="block font-medium text-16 text-grey-600 mb-28 max-w-256 mx-auto">
                        {t('quickChat.createBot.noChatInfo')}
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="h-screen chat-height flex items-center justify-center">
            <div className="mx-auto text-center">
              <img
                src="/assets/images/business/no_chat.png"
                className="img-fluid mx-auto mb-48 max-w-192"
                alt="..."
              />
              <Typography className="block text-24 font-semibold mb-12">
                {t('quickChat.createBot.noChatStart')}
              </Typography>
              <Typography className="block font-medium text-16 text-grey-600 mb-28 max-w-480 mx-auto">
                {t('quickChat.createBot.noChatStartDesc')}
              </Typography>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ChatInbox;
