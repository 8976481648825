import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Icon, Tooltip, Typography, IconButton, Menu, MenuItem, Button } from '@mui/material';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { selectDashboardData } from 'src/app/store/userSlice';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import SendIcon from '@mui/icons-material/Send';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import ShareIcon from '@mui/icons-material/Share';
import moment from 'moment';
import SocialPlatformTab from './socialPlatformTab';
import { handleApiRequest } from '../../common/common';
import AnalyticsDialogBox from './AnalyticsDialogBox';
import quickSocialQuery from '../../query/quickSocial';

const AllSharePost = () => {
  const { t } = useTranslation();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [activeStep, setActiveStep] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [postDetails, setPostDetails] = useState([]);
  const [selectedPost, setSelectedPost] = useState({});
  const [showOptions, setShowOptions] = useState(false);
  const [expandedPosts, setExpandedPosts] = useState({});

  useEffect(() => {
    const fetchPostDetails = async () => {
      if (userCurrentBusinessDetails?.id) {
        const queryMap = {
          9: quickSocialQuery.getAllFacebookChannelData,
          10: quickSocialQuery.getInstagramPosts,
        };

        const query = queryMap[activeStep];
        if (query) {
          const payload = {
            query,
            variables: {
              userBusinessId: parseInt(userCurrentBusinessDetails.id, 10),
            },
          };

          try {
            const res = await handleApiRequest(payload);
            const responseData =
              activeStep === 9
                ? res?.getAllFacebookChannelData
                : activeStep === 10
                ? res?.getInstagramPosts
                : null;

            if (responseData?.status === 200) {
              setPostDetails(responseData.data ?? []);
            } else {
              console.error('Error fetching posts:', responseData?.message);
            }
          } catch (error) {
            console.error('API call failed:', error);
          }
        }
      }
    };

    fetchPostDetails();
  }, [activeStep, userCurrentBusinessDetails?.id]);

  const handleMenuClick = (event, post) => {
    setAnchorEl(event.currentTarget);
    setSelectedPost(post);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const toggleExpandPost = (postId) => {
    setExpandedPosts((prevExpanded) => ({
      ...prevExpanded,
      [postId]: !prevExpanded[postId],
    }));
  };

  return (
    <div className="p-20 lg:p-32">
      <div className="flex w-full items-center justify-between mb-24">
        <Typography
          component={motion.span}
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          className="sm:flex text-20 md:text-28 font-bold"
        >
          {t('quickSocial.posts')}
        </Typography>
        <Button
          className="text-16 px-14 rounded-md"
          variant="contained"
          color="quickSocial"
          onClick={(event) => handleMenuClick(event, null)}
        >
          <span className="text-20 sm:text-28 mr-10">+</span>
          {t('createPost.title')}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          classes={{ paper: 'min-w-112' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiPaper-root': {
              boxShadow: '1px 1px 3px gray',
            },
          }}
        >
          <MenuItem>
            <BrokenImageOutlinedIcon className="text-quick-social text-20 mr-8" />
            {t('quickSocial.selectTemplate')}
          </MenuItem>
          <MenuItem>
            <CreateOutlinedIcon className="border-1 border-quick-social p-1 rounded-6 text-quick-social text-20 mr-8" />
            {t('quickSocial.writeYourOwn')}
          </MenuItem>
        </Menu>
      </div>
      <SocialPlatformTab onSelectTab={setActiveStep} />
      <div>
        {postDetails.length > 0 &&
          postDetails.map((post, i) => {
            const isExpanded = expandedPosts[post.id] || false;
            const text =
              activeStep === 10 ? post.caption : activeStep === 9 ? post.description : '';
            const isTruncated = text && text.length > 100;

            return (
              <div className="bg-white rounded-md mb-16 p-16 max-w-4xl flex gap-16" key={i}>
                <img
                  src={
                    activeStep === 9
                      ? post.media?.image
                      : activeStep === 10
                      ? post.mediaUrl
                      : undefined
                  }
                  className="w-224 h-224 rounded-md inline-block"
                  alt="Post"
                />
                <div className="w-full flex flex-col justify-between">
                  <div className="flex flex-col gap-20">
                    <div className="flex justify-between">
                      <div className="flex gap-20">
                        <img
                          src={post.userProfile}
                          className="w-60 h-60 rounded-full inline-block"
                          alt="User Profile"
                        />
                        <div>
                          <span className="block font-medium text-20 mb-4">{post.userName}</span>
                          <span className="text-16 font-medium">
                            {moment(post.postcreatedTime).format('DD MMM YYYY h:mm:ss A')}
                          </span>
                        </div>
                      </div>
                      <IconButton
                        className="hover:bg-transparent !p-0 sm:!p-10"
                        onClick={(event) => handleMenuClick(event, post)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && selectedPost === post}
                        onClose={handleCloseMenu}
                        classes={{ paper: 'min-w-112' }}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        sx={{
                          '& .MuiPaper-root': {
                            boxShadow: '1px 1px 3px gray',
                          },
                        }}
                      >
                        <MenuItem>
                          <ShareIcon className="text-20 mr-8" />
                          {t('createPost.shareBtn')}
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setShowOptions(true);
                            handleCloseMenu();
                          }}
                        >
                          <AnalyticsOutlinedIcon className="text-20 mr-8" />
                          {t('quickSocial.postAnalytics')}
                        </MenuItem>
                      </Menu>
                    </div>
                    <div className="text-16 font-500">
                      <p
                        className={`${
                          isExpanded ? 'whitespace-normal text-justify' : 'line-clamp-3'
                        } text-justify`}
                      >
                        {text}
                      </p>

                      {isTruncated && (
                        <Button
                          className="text-blue-500 mt-2 hover:bg-transparent"
                          onClick={() => toggleExpandPost(post.id)}
                        >
                          {isExpanded ? 'Show Less' : 'Read More'}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {showOptions && selectedPost && (
        <AnalyticsDialogBox
          open={showOptions}
          onClose={() => setShowOptions(false)}
          post={selectedPost}
          activeStep={activeStep}
        />
      )}
    </div>
  );
};

export default AllSharePost;
