import React from 'react';
import { Button } from '@mui/material';
import { t } from 'i18next';

const PrimaryDetails = () => {
  return (
    <div className=" p-20 lg:p-32 ">
      <div className="flex justify-between items-center mb-32">
        <h1 className="text-28 font-bold font-Quicksand">{t('primaryDetails.primaryDetails')}</h1>
        <div className="flex space-x-4 gap-12">
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            className=" text-darkgreen rounded-md px-16 gap-20 text-16 "
          >
            <img
              src="/assets/images/icon/play.png"
              alt=" G "
              className="bg-white  rounded-full max-h-20 m"
            />

            {t('primaryDetails.howToConnect')}
          </Button>

          <Button
            size="small"
            variant="contained"
            className="rounded-md px-16 text-16 gap-20 "
            color="secondary"
          >
            <img
              src="/assets/images/icon/google icon.png"
              alt=" G "
              className="bg-white  rounded-full p-4 max-h-20"
            />
            {t('primaryDetails.connectBusiness')}
          </Button>
        </div>
      </div>

      <div className="flex flex-col md:flex-row bg-white p-56 rounded-6 gap-56 items-center ">
        <div className="w-1/2 flex flex-col">
          <div className="mb-16">
            <h2 className="text-32 font-bold mb-20 font-Quicksand">
              {t('primaryDetails.manageBusinessProfile')}
            </h2>
            <p className="text-18 font-medium font-Quicksand text-grey-800 mb-24">
              {t('primaryDetails.primaryDetailsPara')}
            </p>
          </div>

          <div className="flex space-x-4 gap-20 mb-40">
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              className=" text-darkgreen rounded-md px-16 gap-20 text-16 "
            >
              <img
                src="/assets/images/icon/play.png"
                alt=" G "
                className="bg-white  rounded-full max-h-20 m"
              />

              {t('primaryDetails.howToConnect')}
            </Button>

            <Button
              size="small"
              variant="contained"
              className="rounded-md px-16 gap-20 text-16 "
              color="secondary"
            >
              <img
                src="/assets/images/icon/google icon.png"
                alt=" G "
                className="bg-white  rounded-full p-4 max-h-20"
              />
              {t('primaryDetails.connectBusiness')}
            </Button>
          </div>
          <div className="font-semibold text-24 mb-24">
            <h1> {t('primaryDetails.whatYouCanDo')} </h1>
          </div>
          <div className=" flex gap-4 pr-10">
            <div className=" w-1/2 ">
              <div className="bg-gray-100  h-auto  mb-20 mr-10 pb-16 pl-10 p-16 rounded-xl pr-48">
                <img
                  src="/assets/images/icon/manage-multiple.png"
                  alt=" Manage Multiple"
                  className="mb-16  max-h-56"
                />
                <h4 className="text-16 font-semibold mb-8">
                  {t('primaryDetails.manageMultipleProfiles')}
                </h4>
                <p className="text-14 font-medium text-black ">
                  {t('primaryDetails.manageMultipleProfilesPara')}
                </p>
              </div>
              <div className="bg-gray-100  h-auto mb-10 mr-10 pr-40 p-16 rounded-xl">
                <img
                  src="/assets/images/icon/manage-service.png"
                  alt="Manage Services"
                  className="mb-16   max-h-56"
                />
                <h4 className="text-16 font-semibold mb-8">{t('primaryDetails.manageServices')}</h4>
                <p className="text-14 font-medium text-black">
                  {t('primaryDetails.manageServicesPara')}
                </p>
              </div>
            </div>
            <div className="w-1/2">
              <div className="bg-gray-100 w-full h-auto mb-20 ml-10 pl-16 py-16 rounded-xl">
                <img
                  src="/assets/images/icon/post-update.png"
                  alt="Post Update"
                  className="mb-16  max-h-56"
                />
                <h4 className="text-16 font-semibold mb-8">{t('primaryDetails.postUpdates')}</h4>
                <p className="text-14 font-medium text-black">
                  {t('primaryDetails.postUpdatesPara')}
                </p>
              </div>
              <div className="bg-gray-100 w-full h-auto mb-10 ml-10 p-16 rounded-xl">
                <img
                  src="/assets/images/icon/respondReview.png"
                  alt="Monitor Respond"
                  className="mb-16   max-h-56"
                />
                <h4 className="text-16 font-semibold mb-8">
                  {t('primaryDetails.monitorRespondToReview')}
                </h4>
                <p className="text-14 font-medium text-black">
                  {t('primaryDetails.monitorRespondToReviewPara')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className=" p-20 w-1/2">
          <img src="/assets/images/icon/Group.png" alt="Large Map" className="rounded-lg " />
        </div>
      </div>
    </div>
  );
};

export default PrimaryDetails;
