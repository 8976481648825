import PrimaryDetails from './PrimaryDetails';

const PrimaryDetailsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'primary-details/:id?',
      element: <PrimaryDetails />,
    },
  ],
};

export default PrimaryDetailsConfig;
