import withReducer from 'app/store/withReducer';
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import history from '@history';
import { useTranslation } from 'react-i18next';
import { Button, Typography, Slider as MSlider, Icon, Tooltip } from '@mui/material';
import { getAllUserBusinessSubscription, selectDashboardData } from 'src/app/store/userSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import {
  handleApiRequest,
  getParamsId,
  findExpireDay,
  redirectToDescription,
  getDecryptData,
} from '../../common/common';
import reducer from './store';
import quickHub from '../../query/quickHub';
import UserService from '../../../services/userService';
import Dashboard from './Dashboard.css';
import metricsData from '../../query/metrics';
import queryData from '../../query/common';

function Dashboards() {
  const { t } = useTranslation();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const loginUserData = UserService.getUserData();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const params = useParams();
  const [dashboardData, setDashboardData] = useState({
    surpriseMeCard: {},
    quickSummaryList: [],
    overAllFullReport: [],
    specialOffer: [],
    productSnapShotData: [],
  });

  const getImageSrc = useMemo(
    () => (type) => {
      const imageMap = {
        quick_social: 'assets/images/quick-hub/quick-social.jpg',
        quick_campaigns: 'assets/images/quick-hub/quick-campaign.jpg',
        quick_chat: 'assets/images/quick-hub/quick-chat.jpg',
        quick_reviews: 'assets/images/quick-hub/quick-review.jpg',
      };
      return imageMap[type] || '';
    },
    []
  );
  const getNotAvailable = useMemo(
    () => (type) => {
      const imageMap = {
        quick_social: {
          image: 'assets/images/quick-hub/quick-social-NDA.png',
          text: '<span style="color: #219EBC">Create</span> And <span style="color: #219EBC">Share</span> Your Post Effortlessly',
        },
        quick_campaigns: {
          image: 'assets/images/quick-hub/quick-campaign-NDA.png',
          text: `<span style="color: #8B78DC">Connect</span> With Your <span style="color: #8B78DC">Customers</span> Seamlessly`,
        },
        quick_chat: {
          image: 'assets/images/quick-hub/quick-chat-NDA.png',
          text: 'Experience Seamless Instant <span style="color: #EBB32F">Bot Conversations</span>',
        },
        quick_reviews: {
          image: 'assets/images/quick-hub/quick-reviews-NDA.png',
          text: 'Your One-Stop Solution For <span style="color: #2DB67C">Customer Reviews.</span>',
        },
      };
      return imageMap[type] || { image: '', text: '' };
    },
    []
  );

  const order = ['quick_reviews', 'quick_chat', 'quick_social', 'quick_campaigns'];

  const redirect = (type, redirectUrl) => {
    try {
      if (type === 'on-url') {
        if (params?.id) {
          history.push(`/${redirectUrl}/${params?.id}`);
        } else {
          history.push(`/${redirectUrl}/`);
        }
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const formatNumber = (num) => {
    if (typeof num !== 'number') {
      return 0;
    }

    if (num >= 1000) {
      return `${(num / 1000).toFixed(1)}k`;
    }

    if (num % 1 !== 0) {
      return num.toFixed(2);
    }

    return num;
  };

  const fetchDashboardData = async (id) => {
    const fetchPayload = (query, variables) => ({
      query,
      variables: { ...variables },
    });

    const requests = [
      handleApiRequest(
        fetchPayload(quickHub.getSurpriseMeCard, { userBusinessId: id, isHomeScreen: true })
      ).catch((err) => ({ error: err })),
      handleApiRequest(fetchPayload(quickHub.getQuickHubOverAllSummary, { businessId: id })).catch(
        (err) => ({ error: err })
      ),
      handleApiRequest(fetchPayload(quickHub.quickHubOverAllFullReport, { businessId: id })).catch(
        (err) => ({ error: err })
      ),
      handleApiRequest(
        fetchPayload(quickHub.getSpecialOfferForUserBusiness, { userBusinessId: id })
      ).catch((err) => ({ error: err })),
      handleApiRequest(
        fetchPayload(quickHub.getQuickHubProductSnapShotData, { businessId: id })
      ).catch((err) => ({ error: err })),
    ];

    try {
      setLoading(true);
      const results = await Promise.all(requests);

      const [
        surpriseMeResult,
        summaryResult,
        fullReportResult,
        specialOfferResult,
        productSnapShotResult,
      ] = results.map((result) => (result.error ? null : result));

      setDashboardData({
        surpriseMeCard: surpriseMeResult?.getSurpriseMeCard?.data || {},
        quickSummaryList: summaryResult?.getQuickHubOverAllSummary?.data?.overallSummary || [],
        overAllFullReport: fullReportResult?.quickHubOverAllFullReport?.data || [],
        specialOffer: specialOfferResult?.getSpecialOfferForUserBusiness?.data || [],
        productSnapShotData: productSnapShotResult?.getQuickHubProductSnapShotData?.data || [],
      });
    } catch (error) {
      console.error('Error fetching dashboard data', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      fetchDashboardData(userCurrentBusinessDetails?.id);
    }
  }, [userCurrentBusinessDetails?.id]);

  if (loading) {
    return <FuseLoading />;
  }

  const { surpriseMeCard, quickSummaryList, productSnapShotData, specialOffer } = dashboardData;
  const isQuickSummaryList = quickSummaryList?.length === 1;
  const isFreeTrialLength =
    userSubscriptionData &&
    userSubscriptionData.filter(
      (subscriptionData) =>
        subscriptionData?.userBusinessId === userCurrentBusinessDetails?.id &&
        subscriptionData.isFreeTrial
    ).length === 1;
  const settings = {
    dots: false,
    rows: 2,
    infinite: !isQuickSummaryList,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const whatsSettings = {
    dots: true,
    rows: 1,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const subscriptionDataSettings = {
    dots: false,
    infinite: !isFreeTrialLength,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  function CircularProgressBar(progressCount, totalProgressCount, type) {
    const radius = 30;
    const strokeWidth = 3;
    const circumference = 2 * Math.PI * radius;
    const progress = progressCount / totalProgressCount;
    const strokeDashoffset = circumference - progress * circumference;

    const colors = {
      quick_social: { bgColor: '#CEF1FC', progressColor: '#219EBC' },
      quick_chat: { bgColor: '#FFF1D1', progressColor: '#EBB32F' },
      quick_reviews: { bgColor: '#D4EBDE', progressColor: '#2DB67C' },
      quick_campaigns: { bgColor: '#E4DEF8', progressColor: '#8B78DC' },
    };

    const { bgColor = '', progressColor = '' } = colors[type] || {};

    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <svg width={radius * 2 + strokeWidth} height={radius * 2 + strokeWidth}>
          <circle
            stroke={bgColor}
            fill="transparent"
            strokeWidth={strokeWidth}
            r={radius}
            cx={radius + strokeWidth / 2}
            cy={radius + strokeWidth / 2}
            style={{
              filter: 'drop-shadow(rgba(0, 0, 0, 0.1) 0px 1px 1px)',
            }}
          />
          <circle
            stroke={progressColor}
            fill="transparent"
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset}
            strokeLinecap="round"
            r={radius}
            cx={radius + strokeWidth / 2}
            cy={radius + strokeWidth / 2}
            style={{
              transition: 'stroke-dashoffset 0.35s ease',
              transform: `rotate(-90deg)`,
              transformOrigin: '50% 50%',
            }}
          />
          <text
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize="28"
            fontWeight="bold"
            style={{
              textShadow: '0px 3px 6px rgba(0, 0, 0, 0.3)',
            }}
            fill={
              type === 'quick_social'
                ? '#219EBC'
                : type === 'quick_chat'
                ? '#EBB32F'
                : type === 'quick_reviews'
                ? '#2DB67C'
                : type === 'quick_campaigns'
                ? '#8B78DC'
                : ''
            }
          >
            {progressCount}
          </text>
        </svg>
      </div>
    );
  }

  const SliderCard = ({ productList }) => {
    let parsedCard = [];
    try {
      parsedCard = JSON.parse(productList?.whatsNewCard);
    } catch (error) {
      console.error('Invalid JSON format:', error);
    }
    return (
      parsedCard?.length > 0 && (
        <Slider {...whatsSettings}>
          {parsedCard?.map((product, i) => (
            <div className="block w-full" key={i}>
              <div
                className={`border border-solid 
                ${
                  productList?.type === 'quick_social'
                    ? '!border-quick-social'
                    : productList?.type === 'quick_campaigns'
                    ? '!border-quick-campaign'
                    : productList?.type === 'quick_chat'
                    ? '!border-quick-chat'
                    : productList?.type === 'quick_reviews'
                    ? '!border-quick-review'
                    : ''
                } rounded-md mt-16 pr-16 py-10 pl-5`}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <img src={product?.icon} className="w-36 inline-block mr-4" alt="product" />
                    <span className="font-medium text-14 inline-block">{product?.title}</span>
                  </div>
                  <Button
                    className="text-12 font-medium px-14"
                    size="small"
                    color="secondary"
                    variant="contained"
                  >
                    {product?.button}
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )
    );
  };

  const handleSurpriseMeCard = () => {
    const id = getParamsId();
    if (id) {
      history.push(`explore-features/${id}`);
    } else {
      history.push('explore-features/');
    }
  };

  return (
    <div className="p-20 lg:p-32 h-full l-padding">
      <div className="flex flex-col">
        {userSubscriptionData &&
          userSubscriptionData?.length > 0 &&
          userSubscriptionData.some(
            (subscriptionData) =>
              subscriptionData?.userBusinessId === userCurrentBusinessDetails?.id &&
              subscriptionData.isFreeTrial
          ) && (
            <div className="bg-[#FFE4E1] mb-20 sm:text-18 text-14 rounded-6 font-semibold p-10">
              <Slider {...subscriptionDataSettings}>
                {userSubscriptionData
                  ?.filter((subscriptionData) => subscriptionData.isFreeTrial)
                  ?.map((subscriptionData, i) => (
                    <div key={i}>
                      {findExpireDay(subscriptionData?.expireDate) > 0 ? (
                        <div className="w-full flex justify-center relative top-3">
                          <div className="items-center flex justify-between">
                            <img
                              className="block rounded max-w-38 mr-16"
                              name="logo"
                              src="/assets/images/icon/bellIconNotification.png"
                              alt="bell notification icon"
                            />
                            <p className="mr-28 sm:mr-48">
                              {t('quickHubDashboard.your')} {subscriptionData?.name}
                              {subscriptionData?.comboDetails?.length > 0
                                ? `[${subscriptionData.comboDetails
                                    .map((item) => item?.name)
                                    .join(', ')}]`
                                : ''}{' '}
                              {t('quickHubDashboard.freeTrialExpire')}{' '}
                              {findExpireDay(subscriptionData?.expireDate)}{' '}
                              {t('business.searchBusiness.labels.days')}.
                            </p>
                            <Button
                              className="text-14 font-medium bg-[#D07062] text-white hover:bg-red-300 rounded-12 px-16"
                              variant="contained"
                              size="small"
                              type="submit"
                              onClick={() => {
                                const packageBundle =
                                  subscriptionData?.comboDetails?.length > 0
                                    ? subscriptionData?.comboDetails?.map((item) => item?.id)
                                    : [subscriptionData?.packageTypeId] || [];
                                const intervalType = subscriptionData?.packageInterval;
                                const paymentDetails =
                                  subscriptionData?.packageInterval === 'month' ||
                                  subscriptionData?.packageInterval === 'year'
                                    ? 'subscription'
                                    : 'oneTimePayment';

                                history.push(
                                  params?.id ? `/selectProduct/${params.id}` : '/selectProduct/',
                                  {
                                    packageBundle,
                                    intervalType,
                                    paymentDetails,
                                    isBusinessAdded: true,
                                  }
                                );
                              }}
                            >
                              {t('quickHubDashboard.upgradeNow')}
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className="w-full min-h-40 max-h-40 flex items-center justify-center">
                          <div className="w-384 sm:w-586 items-center flex justify-center">
                            <p>
                              {t('quickHubDashboard.your')} {subscriptionData?.name}{' '}
                              {t('quickHubDashboard.planExpired')}.
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </Slider>
            </div>
          )}
        <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-0 sm:gap-x-32 gap-y-28">
          <div className="col-span-2">
            <div className="mb-32 bg-white shadow rounded-md py-20 px-24">
              <div className="flex flex-col-reverse md:flex-row justify-between items-center">
                <div className="sm:mt-0 mt-20">
                  <h3 className="text-16 lg:text-20 font-bold">
                    {t('quickHubDashboard.welcomeText')}
                  </h3>
                  <Typography className="text-16 lg:text-20 font-medium my-16 sm:my-24 max-w-512">
                    {' '}
                    {t('quickHubDashboard.mainDesc')}
                  </Typography>
                  <div className="flex gap-20 md:justify-start justify-center">
                    <a
                      role="button"
                      target="_blank"
                      href="https://itunes.apple.com/app/id6449814972"
                      rel="noreferrer"
                    >
                      <img
                        src="assets/images/quick-hub/app-store.png"
                        className="inline-block max-w-120 lg:max-w-160"
                        alt="..."
                      />
                    </a>
                    <a
                      role="button"
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=reviewApp.dev"
                      rel="noreferrer"
                    >
                      <img
                        src="assets/images/quick-hub/play-store.png"
                        className="inline-block max-w-120 lg:max-w-160"
                        alt="..."
                      />
                    </a>
                  </div>
                </div>
                <div>
                  <img src="assets/images/quick-hub/welcome.png" className="max-w-256" alt="..." />
                </div>
              </div>
            </div>
            {productSnapShotData && productSnapShotData.length > 0 && (
              <div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-32">
                  {order?.map((type) => {
                    const filteredProductList = productSnapShotData.filter(
                      (productList) => productList?.type === type
                    );

                    return filteredProductList.length > 0 ? (
                      filteredProductList.reverse().map((productList, index) => (
                        <div className="bg-white shadow rounded-md p-14 xl:p-24" key={index}>
                          <div className="mb-20">
                            <img
                              alt={productList.type}
                              src={getImageSrc(productList?.type)}
                              className="inline-block mr-12"
                            />
                            <h2 className="font-bold text-16 sm:text-20 capitalize inline-block">
                              {productList?.type.replace(/_/g, ' ')}
                            </h2>
                          </div>
                          <div>
                            {-(
                              Number(productList?.pendingTask?.progressCount) -
                              Number(productList?.pendingTask?.totalProgressCount)
                            ) > 0 && (
                              <div
                                className={`rounded p-16 mb-16 ${
                                  productList?.type === 'quick_social'
                                    ? 'bg-quick-social-100'
                                    : productList?.type === 'quick_campaigns'
                                    ? 'bg-quick-campaign-100'
                                    : productList?.type === 'quick_chat'
                                    ? 'bg-quick-chat-100'
                                    : productList?.type === 'quick_reviews'
                                    ? 'bg-quick-review-100'
                                    : ''
                                }`}
                              >
                                <div className="flex items-center">
                                  {CircularProgressBar(
                                    -(
                                      Number(productList?.pendingTask?.progressCount) -
                                      Number(productList?.pendingTask?.totalProgressCount)
                                    ),
                                    productList?.pendingTask?.totalProgressCount,
                                    productList?.type
                                  )}
                                  <div className="pl-12">
                                    <h3 className="text-16 font-semibold">
                                      {t('quickHubDashboard.taskPending')}
                                    </h3>
                                    <Typography className="text-14 font-medium max-w-256 mt-6 text-grey-700">
                                      {t('quickHubDashboard.desc')}
                                    </Typography>
                                  </div>
                                </div>
                                {productList?.pendingTask?.card?.length > 0 &&
                                  (() => {
                                    let sortedData = [];

                                    if (userCurrentBusinessDetails?.isOnlineBusiness) {
                                      sortedData = [...productList.pendingTask.card]
                                        .sort((a, b) => a.onlineIndex - b.onlineIndex)
                                        .filter((i) => i?.isOnlineSupport !== false);
                                    } else {
                                      sortedData = [...productList.pendingTask.card]
                                        .sort((a, b) => a.physicalIndex - b.physicalIndex)
                                        .filter((i) => i?.isOfflineSupport === null);
                                    }

                                    const taskCard = sortedData[0];

                                    return taskCard ? (
                                      <div className="bg-white rounded mt-10 px-12 py-10 font-medium text-14 flex items-center justify-between">
                                        {taskCard?.name}
                                        <Button
                                          className={`text-white h-24 min-h-24 hover:bg-black ${
                                            productList?.type === 'quick_social'
                                              ? 'bg-quick-social'
                                              : productList?.type === 'quick_campaigns'
                                              ? 'bg-quick-campaign'
                                              : productList?.type === 'quick_chat'
                                              ? 'bg-quick-chat'
                                              : productList?.type === 'quick_reviews'
                                              ? 'bg-quick-review'
                                              : ''
                                          }`}
                                          variant="contained"
                                          size="small"
                                          onClick={() => redirect('on-url', taskCard?.portalURL)}
                                        >
                                          {taskCard?.button}
                                        </Button>
                                      </div>
                                    ) : null;
                                  })()}
                              </div>
                            )}
                            <div>
                              <div
                                className={`${
                                  productList?.type === 'quick_social' ||
                                  productList?.type === 'quick_chat'
                                    ? 'grid grid-cols-2 gap-16'
                                    : 'grid grid-cols-3 gap-16'
                                }`}
                              >
                                {productList?.type === 'quick_reviews' && (
                                  <>
                                    <div className="bg-grey-100 rounded text-center px-5 py-16 md:py-40">
                                      <span className="text-28 sm:text-32 xl:text-40 block font-semibold">
                                        {productList?.dataPoint?.todayReview || 0}
                                      </span>
                                      <span className="text-12 sm:text-14 font-medium">
                                        {t('quickHubDashboard.todayReview')}
                                      </span>
                                    </div>
                                    <div className="bg-grey-100 rounded text-center px-5 py-16 md:py-40">
                                      <span className="text-28 sm:text-32 xl:text-40 block font-semibold">
                                        {productList?.dataPoint?.totalReviewsRating?.count || 0}
                                      </span>
                                      <span className="text-12 sm:text-14 font-medium">
                                        {t('quickHubDashboard.overallRating')}
                                      </span>
                                    </div>
                                    <div className="bg-grey-100 rounded text-center px-5 py-16 md:py-40">
                                      <span className="text-28 sm:text-32 xl:text-40 block font-semibold">
                                        {formatNumber(
                                          productList?.dataPoint?.totalReviews?.count || 0
                                        )}
                                      </span>
                                      <span className="text-12 sm:text-14 font-medium">
                                        {t('quickHubDashboard.totalReview')}
                                      </span>
                                    </div>
                                  </>
                                )}
                                {productList?.type === 'quick_social' && (
                                  <>
                                    <div className="bg-grey-100 rounded text-center px-5 py-16 md:py-40">
                                      <span className="text-28 sm:text-32 xl:text-40 block font-semibold">
                                        {productList?.dataPoint?.postShared || 0}
                                      </span>
                                      <span className="text-12 sm:text-14 font-medium">
                                        {t('quickHubDashboard.postShared')}
                                      </span>
                                    </div>
                                    <div className="bg-grey-100 rounded text-center px-5 py-16 md:py-40">
                                      <span className="text-28 sm:text-32 xl:text-40 block font-semibold">
                                        {formatNumber(productList?.dataPoint?.todayReview || 0)}
                                      </span>
                                      <span className="text-12 sm:text-14 font-medium">
                                        {t('quickHubDashboard.totalReview')}
                                      </span>
                                    </div>
                                  </>
                                )}
                                {productList?.type === 'quick_chat' && (
                                  <>
                                    <div className="bg-grey-100 rounded text-center px-5 py-16 md:py-[30px]">
                                      <span className="text-28 sm:text-32 xl:text-40 block font-semibold">
                                        {productList?.dataPoint?.todayConversation || 0}
                                      </span>
                                      <span className="text-12 sm:text-14 font-medium max-w-96 block mx-auto">
                                        {t('quickHubDashboard.todayConversations')}
                                      </span>
                                    </div>
                                    <div className="bg-grey-100 rounded text-center px-5 py-16 md:py-[30px]">
                                      <span className="text-28 sm:text-32 xl:text-40 block font-semibold">
                                        {formatNumber(
                                          productList?.dataPoint?.totalConversation || 0
                                        )}
                                      </span>
                                      <span className="text-12 sm:text-14 font-medium">
                                        {t('quickHubDashboard.totalConversations')}
                                      </span>
                                    </div>
                                  </>
                                )}
                                {productList?.type === 'quick_campaigns' && (
                                  <>
                                    <div className="bg-grey-100 rounded text-center px-5 py-16 md:py-40">
                                      <span className="text-28 sm:text-32 xl:text-40 block font-semibold">
                                        {productList?.dataPoint?.sendSms || 0}
                                      </span>
                                      <span className="text-12 sm:text-14 font-medium max-w-96 block mx-auto">
                                        {t('quickHubDashboard.smsSend')}
                                      </span>
                                    </div>
                                    <div className="bg-grey-100 rounded text-center px-5 py-16 md:py-40">
                                      <span className="text-28 sm:text-32 xl:text-40 block font-semibold">
                                        {productList?.dataPoint?.sendEmail || 0}
                                      </span>
                                      <span className="text-12 sm:text-14 font-medium max-w-96 block mx-auto">
                                        {t('quickHubDashboard.emailSend')}
                                      </span>
                                    </div>
                                    <div className="bg-grey-100 rounded text-center px-5 py-16 md:py-40">
                                      <span className="text-28 sm:text-32 xl:text-40 block font-semibold">
                                        {formatNumber(productList?.dataPoint?.sendWhatsapp || 0)}
                                      </span>
                                      <span className="text-12 sm:text-14 font-medium">
                                        {t('quickHubDashboard.whatsappSend')}
                                      </span>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="mb-10">
                                {productList?.whatsNewCard && (
                                  <SliderCard productList={productList} t={t} />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="bg-white shadow rounded-md p-14 sm:p-24" key={type}>
                        <div className="mb-20">
                          <div className="mb-20">
                            <img
                              alt={type}
                              src={getImageSrc(type)}
                              className="inline-block mr-12"
                            />
                            <h2 className="font-bold text-16 sm:text-20 capitalize inline-block">
                              {type.replace(/_/g, ' ')}
                            </h2>
                          </div>
                          <div>
                            <p
                              className="text-20 mb-5 font-bold"
                              dangerouslySetInnerHTML={{ __html: getNotAvailable(type).text }}
                            />
                            <img
                              alt={type}
                              src={getNotAvailable(type).image}
                              className="inline-block mr-12"
                            />
                          </div>
                        </div>
                        <div className="flex gap-10 w-full justify-center">
                          <Button
                            className="text-14 lg:text-16 mx-auto w-full font-medium rounded-md"
                            variant="outlined"
                            color={
                              type === 'quick_social'
                                ? 'quickSocial'
                                : type === 'quick_campaigns'
                                ? 'quickCampaign'
                                : type === 'quick_chat'
                                ? 'quickChat'
                                : type === 'quick_reviews'
                                ? 'quickReview'
                                : ''
                            }
                            type="button"
                            size="small"
                            onClick={() => redirectToDescription(type)}
                          >
                            {t('plan.learnMore')}
                          </Button>

                          <Button
                            className="text-14 lg:text-16 mx-auto w-full font-medium rounded-md"
                            variant="contained"
                            color={
                              type === 'quick_social'
                                ? 'quickSocial'
                                : type === 'quick_campaigns'
                                ? 'quickCampaign'
                                : type === 'quick_chat'
                                ? 'quickChat'
                                : type === 'quick_reviews'
                                ? 'quickReview'
                                : ''
                            }
                            type="button"
                            size="small"
                            onClick={() => {
                              history.push('selectProduct/', {
                                packageBundle: [
                                  type === 'quick_social'
                                    ? 8
                                    : type === 'quick_campaigns'
                                    ? 10
                                    : type === 'quick_chat'
                                    ? 9
                                    : type === 'quick_reviews'
                                    ? 7
                                    : null,
                                ],
                                intervalType: 'month',
                                paymentDetails: 'subscription',
                                isBusinessAdded: true,
                              });
                            }}
                          >
                            {t('userProfile.subscribeNow')}
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="w-full">
            {surpriseMeCard && Object?.keys(surpriseMeCard)?.length > 0 && (
              <div className="bg-white rounded-md p-12 lg:p-16 relative shadow">
                <div className="absolute top-32 left-32">
                  <Typography
                    className="text-14 lg:text-16 font-semibold mb-14 sm:mb-20 max-w-160"
                    style={{ color: surpriseMeCard?.buttonColor }}
                  >
                    {surpriseMeCard?.description}
                  </Typography>
                  <Button
                    className="rounded-full px-16"
                    color="secondary"
                    variant="contained"
                    onClick={() => handleSurpriseMeCard()}
                    size="small"
                    style={{ backgroundColor: surpriseMeCard?.buttonColor }}
                  >
                    {surpriseMeCard?.button}
                  </Button>
                </div>
                <img
                  src={surpriseMeCard?.image}
                  className="inline-block w-full rounded-md max-h-160 lg:max-h-192"
                  alt="..."
                />
              </div>
            )}
            {quickSummaryList && quickSummaryList?.length > 0 && (
              <div
                className={`bg-white rounded-md p-12 lg:p-16 relative ${
                  Object?.keys(surpriseMeCard)?.length > 0 ? 'mt-32' : 'mt-0'
                } shadow`}
              >
                <div className="flex items-center mb-16">
                  <h2 className="text-16 font-bold">{t('quickHubDashboard.overallSummary')}</h2>
                  <Tooltip
                    title={t('quickHubDashboard.overallSummaryDesc')}
                    arrow
                    classes={{
                      tooltip: 'bg-darkgreen',
                      arrow: 'text-darkgreen',
                    }}
                  >
                    <img src="/assets/images/icon/tooltipicon.svg" alt="good" className="ml-6" />
                  </Tooltip>
                </div>
                <Slider className="-mx-5 -my-5" {...settings}>
                  {quickSummaryList?.map((summary, index) => (
                    <div className="block w-full " key={index}>
                      <div
                        className="rounded-md p-10 mx-5 my-5"
                        style={{ backgroundColor: summary?.backgroundColor }}
                      >
                        <div className="flex items-center">
                          <img
                            className="w-32 h-32 inline-block align-middle"
                            src={summary?.icon}
                            alt="..."
                          />
                          <span className="text-20 lg:text-24 font-semibold inline-block ml-10 align-middle">
                            {summary?.key}
                          </span>
                        </div>
                        <span className="block text-12 font-medium text-grey-600 mt-6">
                          {summary?.value}
                        </span>
                      </div>
                    </div>
                  ))}
                </Slider>
                {/* {overAllFullReport && overAllFullReport?.length > 0 && (
            <Button
            className="rounded w-full mt-16 min-h-36 h-36 flex items-center justify-between px-16"
            variant="outlined"
            color="secondary"
            size="medium"
            type="button"
            // onClick={() => handleDownload()}
          >
            {t('quickHubDashboard.viewFullReport')} <Icon className="w-auto text-16">chevron_right</Icon>
          </Button>
          )} */}
              </div>
            )}
            {specialOffer && specialOffer?.length > 0 && (
              <div className="bg-white rounded-md p-12 lg:p-16 relative mt-32 w-full shadow">
                <h2 className="text-16 font-bold mb-16">{t('quickHubDashboard.specialOffers')}</h2>
                {specialOffer?.map((specialValue, index) => (
                  <div className="block w-full mb-16 last:mb-0" key={index}>
                    <div
                      className="rounded-md p-24 text-center"
                      style={{ backgroundColor: specialValue?.backgroundColor }}
                    >
                      <div className="flex items-center justify-center mb-14">
                        {specialValue?.icons?.map((specialIcon, i) => (
                          <img
                            className="w-56 h-56 align-middle relative first:ml-0 -ml-8 border-1 border-solid border-grey-400 rounded-full"
                            key={i}
                            src={specialIcon}
                            alt="..."
                          />
                        ))}
                      </div>
                      <span className="text-20 font-bold block mb-12 align-middle capitalize">
                        {loginUserData?.countryId === 2 ? '₹' : '$'}
                        {specialValue?.amount}/{specialValue?.packageInterval.replace(/_/g, ' ')}
                      </span>
                      <Typography className="block text-14 max-w-256 font-medium text-grey-600 mb-20 mx-auto">
                        {specialValue?.description}
                      </Typography>
                      <Button
                        className="px-20"
                        color="secondary"
                        size="small"
                        variant="contained"
                        style={{ backgroundColor: specialValue?.button?.buttonColor }}
                        onClick={() => {
                          const packageBundle = specialValue?.packageBundle || [];
                          const intervalType = specialValue?.packageInterval || 'defaultInterval';
                          const paymentDetails =
                            specialValue?.packageInterval === 'month' ||
                            specialValue?.packageInterval === 'year'
                              ? 'subscription'
                              : 'oneTimePayment';

                          history.push(
                            params?.id ? `/selectProduct/${params.id}` : '/selectProduct/',
                            {
                              packageBundle,
                              intervalType,
                              paymentDetails,
                              isBusinessAdded: true,
                              specialOffer: true,
                            }
                          );
                        }}
                      >
                        {specialValue?.button?.name}
                      </Button>
                      <div className="flex w-full justify-center mt-14">
                        <span className="flex justify-center max-w-224 rounded-8 bg-white px-8 p-5">
                          <img
                            className="mr-10 max-w-16"
                            src="/assets/images/icon/discount.svg"
                            alt=""
                          />
                          <span className="text-blue-900 text-14 font-500">
                            {t('plan.save')} {loginUserData?.countryId === 2 ? '₹' : '$'}
                            {specialValue?.discount} {t('plan.onThis')} {specialValue?.name}!
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default withReducer('businessDashboardsReducer', reducer)(Dashboards);
