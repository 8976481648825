import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import metricsData from '../../query/metrics';
import { handleApiRequest } from '../../common/common';
import { selectDashboardData } from '../../../store/userSlice';
import NoData from './noDataScreen';

const ReviewsHeatMap = (props) => {
  const { reviewPlatformId } = props;
  const [heatMapData, setHeatMapData] = useState([]);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [monthsData, setMonthData] = useState([]);
  const [open, setOpen] = useState(null);

  const handleBoxClick = (index) => {
    setOpen((prev) => (prev === index ? null : index));
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleBoxClick(index);
    }
  };

  useEffect(() => {
    const fetchHeatMapData = async () => {
      try {
        const payload = {
          query: metricsData.getReviewHeatmap,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            reviewPlatformId: parseInt(reviewPlatformId, 10),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getReviewHeatmapDataV1?.status === 200) {
          const data = result?.getReviewHeatmapDataV1?.data?.reviewsHeatmapData;
          const sortedData = data.sort((a, b) => {
            return moment(b.timestamp).unix() - moment(a.timestamp).unix();
          });
          const changeDateFormat = sortedData?.map((item) => ({
            ...item,
            timestamp: moment.unix(item?.timestamp).format('ddd, MMM D, YYYY'),
          }));

          const dateFormatChange = sortedData?.map((item) => ({
            ...item,
            timestamp: moment.unix(item?.timestamp).format('MMM YYYY'),
          }));
          const uniqueMonthsYears = [
            ...new Set(
              dateFormatChange.map((item) => {
                return item.timestamp;
              })
            ),
          ];
          setMonthData(uniqueMonthsYears);

          const chunkedData = changeDateFormat.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / 7);

            if (!resultArray[chunkIndex]) {
              resultArray[chunkIndex] = [];
            }

            resultArray[chunkIndex].push(item);

            return resultArray;
          }, []);
          setHeatMapData(chunkedData);
        }
      } catch (error) {
        console.error('Error fetching heatmap data:', error);
      }
    };
    if (userCurrentBusinessDetails?.id && reviewPlatformId) {
      fetchHeatMapData();
    }
  }, [userCurrentBusinessDetails?.id, reviewPlatformId]);

  return (
    <>
      {monthsData?.length > 0 && heatMapData?.length > 0 ? (
        <>
          <div className="overflow-scroll">
            <div className="flex gap-84 pl-16">
              {monthsData?.length > 0 &&
                monthsData?.map((item, index) => {
                  return (
                    <div key={index} className="font-semibold text-14">
                      {item}
                    </div>
                  );
                })}
            </div>
            <div className="flex gap-8 p-8">
              {heatMapData.map((column, colIndex) => (
                <div key={colIndex}>
                  {column.map((item, index) => (
                    <Tooltip
                      key={index}
                      title={
                        <div className="text-black p-2 rounded">
                          <p className="text-quick-review">
                            <strong>{item.timestamp}</strong>
                          </p>
                          <p>
                            <strong>Reviews:</strong> {item.totalReviews}
                          </p>
                          <p>
                            <strong>Rating:</strong> {item.cumulativeRating}
                          </p>
                          <p>
                            <strong>Sentiment:</strong> {item.cumulativeSentiment}
                          </p>
                          <p>
                            <strong>Score:</strong> {item.score.toFixed(2)}
                          </p>
                        </div>
                      }
                      open={open === `${colIndex}-${index}`}
                      onClose={() => setOpen(null)}
                      disableHoverListener
                      PopperProps={{
                        disablePortal: true,
                      }}
                      placement="top"
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: 'white',
                            color: 'black',
                            borderRadius: 2,
                            boxShadow: 0,
                            p: 1,
                          },
                        },
                        arrow: {
                          sx: {
                            color: 'white',
                          },
                        },
                      }}
                    >
                      <div
                        role="button"
                        tabIndex={0}
                        aria-label={`Score: ${item.score}`}
                        className={`w-20 h-20 text-center rounded ${
                          item.score < 0.2
                            ? 'bg-[#EEEEEE]'
                            : item.score > 0.2 && item.score < 0.4
                            ? 'bg-[#006B5A40]'
                            : item.score > 0.4 && item.score < 0.6
                            ? 'bg-[#006B5A80]'
                            : item.score > 0.6 && item.score < 0.8
                            ? 'bg-[#006B5ABF]'
                            : 'bg-[#0BA861]'
                        } mb-4`}
                        onClick={() => handleBoxClick(`${colIndex}-${index}`)}
                        onKeyDown={(e) => handleKeyDown(e, `${colIndex}-${index}`)}
                      />
                    </Tooltip>
                  ))}
                </div>
              ))}
            </div>
            <div className="flex gap-8 my-8 pl-8">
              <Tooltip
                title="0 to 0.2"
                placement="top"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: 'white',
                      color: 'black',
                      borderRadius: 2,
                      boxShadow: 0,
                      p: 1,
                    },
                  },
                  arrow: {
                    sx: {
                      color: 'white',
                    },
                  },
                }}
              >
                <div className="w-20 h-20 text-center rounded bg-[#EEEEEE]" />
              </Tooltip>
              <Tooltip
                title="0.2 to 0.4"
                placement="top"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: 'white',
                      color: 'black',
                      borderRadius: 2,
                      boxShadow: 0,
                      p: 1,
                    },
                  },
                  arrow: {
                    sx: {
                      color: 'white',
                    },
                  },
                }}
              >
                <div className="w-20 h-20 text-center rounded bg-[#006B5A40]" />
              </Tooltip>
              <Tooltip
                title="0.4 to 0.6"
                placement="top"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: 'white',
                      color: 'black',
                      borderRadius: 2,
                      boxShadow: 0,
                      p: 1,
                    },
                  },
                  arrow: {
                    sx: {
                      color: 'white',
                    },
                  },
                }}
              >
                <div className="w-20 h-20 text-center rounded bg-[#006B5A80]" />
              </Tooltip>
              <Tooltip
                title="0.6 to 0.8"
                placement="top"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: 'white',
                      color: 'black',
                      borderRadius: 2,
                      boxShadow: 0,
                      p: 1,
                    },
                  },
                  arrow: {
                    sx: {
                      color: 'white',
                    },
                  },
                }}
              >
                <div className="w-20 h-20 text-center rounded bg-[#006B5ABF]" />
              </Tooltip>
              <Tooltip
                title="0.8 to 1"
                placement="top"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: 'white',
                      color: 'black',
                      borderRadius: 2,
                      boxShadow: 0,
                      p: 1,
                    },
                  },
                  arrow: {
                    sx: {
                      color: 'white',
                    },
                  },
                }}
              >
                <div className="w-20 h-20 text-center rounded bg-[#0BA861]" />
              </Tooltip>
            </div>
          </div>
        </>
      ) : (
        <NoData />
      )}
    </>
  );
};

export default ReviewsHeatMap;
