import React, { useMemo } from 'react';

const getRandomColorText = (randomColorValue) => `#${randomColorValue}`;
const getRandomColor = (randomColorValue) => `#${randomColorValue}33`;

const UserProfile = ({ name, width, height, fontSize, fontWeight }) => {
  const randomColorValue = useMemo(() => Math.floor(Math.random() * 16777215).toString(16), []);
  const randomColor = getRandomColor(randomColorValue);
  const randomColorText = getRandomColorText(randomColorValue);

  return (
    <div
      style={{
        backgroundColor: randomColor,
        width: width || '30px',
        height: height || '30px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: randomColorText,
        fontSize: fontSize || '14px',
        fontWeight: fontWeight || 'semibold',
      }}
    >
      {name?.charAt(0).toUpperCase()}
    </div>
  );
};

export default UserProfile;
