import Campaign from './campaigns/Campaign';
import Template from './template/Template';

const QuickCampaignsConfigs = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'quick-campaign/campaign/:id?',
      element: <Campaign />,
    },
    {
      path: 'quick-campaign/template/:id?',
      element: <Template />,
    },
  ],
};

export default QuickCampaignsConfigs;
