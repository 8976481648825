import { Button, Menu, Icon, MenuItem, Select, Typography, Tab, Tabs } from '@mui/material';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { t } from 'i18next';
import CloseIcon from '@mui/icons-material/Close';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import history from '@history';
import { useLocation } from 'react-router-dom';
import Pagination from '../../../../component/table/Pagination';
import reviewPlatformData from '../../../query/reviewPlatform';
import {
  capitalizeFirstLetter,
  getParamsId,
  handleApiRequest,
  reviewFormate,
} from '../../../common/common';
import { selectDashboardData } from '../../../../store/userSlice';
import UserProfile from '../../../common/userProfile';
import Loading from '../../manage-business/Loading';
import manageBusinessData from '../../../query/manageBusiness';

const Reviews = () => {
  const location = useLocation();
  const { placeIdForRedirect, ratingTypeForRedirect, platformsId } = location.state || {};
  const [reviewData, setReviewData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [selectedReviewData, setSelectedReviewData] = useState();
  const [filterData, setFilterData] = useState([]);
  const [filterID, setFilterID] = useState(2);
  const [filterReviewId, setFilterReviewId] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [placeID, setPlaceID] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [plateFormID, setPlateFormID] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [zomatoRatingType, setZomatoRatingType] = useState();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [sortBy, setSortBy] = useState('newest');
  const [sortOrder, setSortOrder] = useState('desc');
  const [isInitialFetchComplete, setIsInitialFetchComplete] = useState(false);
  const [totalReview, setTotalReview] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [platFormDetails, setPlatFormDetails] = useState('');
  const [diningReview, setDiningReview] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuType, setMenuType] = useState(null);

  const sortingOptions = [
    { id: 'dateAsc', type: 'Date: Oldest First' },
    { id: 'dateDesc', type: 'Date: Newest First' },
    { id: 'ratingAsc', type: 'Rating: Low to High' },
    { id: 'ratingDesc', type: 'Rating: High to Low' },
    { id: 'reviewCountAsc', type: 'Review Count: Low to High' },
    { id: 'reviewCountDesc', type: 'Review Count: High to Low' },
  ];

  const fetchBusinessReview = useCallback(
    async (placeIds, plateFormId, ratingTypes, filterReview) => {
      setPageLoading(true);
      const payload = {
        query: reviewPlatformData.getAllBusinessReviews,
        variables: {
          pageNumber: 1,
          sortBy: 'newest',
          sortOrder: 'desc',
          filterReviewRating: parseInt(filterReview, 10),
        },
      };
      if (platformsId) {
        if (placeIdForRedirect) {
          payload.variables.placeId = placeIdForRedirect;
        } else {
          payload.variables.placeId = placeIds;
        }
        if (platformsId === plateFormId) {
          payload.variables.platformId = parseInt(platformsId, 10);
        } else {
          payload.variables.platformId = parseInt(plateFormId, 10);
        }
        if (!userCurrentBusinessDetails?.isOnlineBusiness && platformsId === 3) {
          payload.variables.platformId = parseInt(platformsId, 10);
        }
      } else {
        payload.variables.placeId = placeIds;
        payload.variables.platformId = parseInt(plateFormId, 10);
      }
      if (platformsId === 3) {
        payload.variables.ratingType = ratingTypeForRedirect;
      } else if (parseInt(plateFormId, 10) === 3) {
        payload.variables.ratingType = ratingTypes;
      }
      const result = await handleApiRequest(payload);
      setPageLoading(false);
      if (result?.getAllBusinessReviews?.status === 200) {
        setReviewData(result.getAllBusinessReviews.data);
        setSelectedReviewData(result.getAllBusinessReviews.data[0]);
        setTotalCount(result.getAllBusinessReviews?.totalReviews);
      } else {
        enqueueSnackbar(result?.getAllBusinessReviews?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    },
    [
      enqueueSnackbar,
      placeIdForRedirect,
      platformsId,
      ratingTypeForRedirect,
      userCurrentBusinessDetails?.isOnlineBusiness,
    ]
  );

  const fetchProducts = useCallback(
    async (plateFormId, ratingTypes, filterReview) => {
      if (userCurrentBusinessDetails?.id) {
        setPageLoading(true);
        const payload = {
          query:
            parseInt(plateFormId, 10) === 5
              ? manageBusinessData.getAmazonProduct
              : parseInt(plateFormId, 10) === 6 && manageBusinessData.getFlipkartProduct,
          variables: {
            userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        setPageLoading(false);
        const res =
          parseInt(plateFormId, 10) === 5
            ? result?.getAmazonProduct
            : parseInt(plateFormId, 10) === 6 && result?.getFlipkartProduct;
        if (res?.status === 200) {
          setProducts(res?.data);
          const product = res?.data[0];
          setSelectedProduct(product);
          setPlaceID(product?.placeId);
          if (product?.placeId) {
            fetchBusinessReview(product?.placeId, plateFormId, ratingTypes, filterReview);
          }
        } else {
          enqueueSnackbar(res?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    },
    [userCurrentBusinessDetails?.id, fetchBusinessReview, enqueueSnackbar]
  );

  useEffect(() => {
    if (userCurrentBusinessDetails?.isOnlineBusiness) {
      const sortData = userCurrentBusinessDetails?.reviewplatform
        ?.slice()
        ?.sort((a, b) => a.id - b.id);
      setTotalReview(sortData[0]?.totalReview);
      if (platformsId) {
        setPlateFormID(platformsId);
      } else {
        setPlateFormID(sortData[0]?.reviewPlatformId);
      }
      if (platformsId !== 7 && platformsId) {
        fetchProducts(platformsId);
      } else {
        fetchProducts(sortData[0]?.reviewPlatformId);
      }
    }
  }, [
    fetchProducts,
    platformsId,
    userCurrentBusinessDetails?.isOnlineBusiness,
    userCurrentBusinessDetails?.reviewplatform,
  ]);

  useEffect(() => {
    if (!userCurrentBusinessDetails?.isOnlineBusiness) {
      const findDefaultPlateForm = userCurrentBusinessDetails?.reviewplatform.find(
        (item) => item.defaultReview === true
      );
      setPlaceID(findDefaultPlateForm?.placeId);
      setTotalReview(
        parseInt(findDefaultPlateForm?.reviewPlatformId, 10) === 3
          ? findDefaultPlateForm?.aggregateData?.aggregateTotalReview
          : findDefaultPlateForm?.totalReview
      );
      const ratingType = findDefaultPlateForm?.name === 'zomato' ? 'dining' : '';
      if (platformsId) {
        setPlaceID(placeIdForRedirect);
        setPlateFormID(platformsId);
        setZomatoRatingType(ratingTypeForRedirect);
      } else {
        setPlateFormID(findDefaultPlateForm?.reviewPlatformId);
        setZomatoRatingType(ratingType);
      }
      if (findDefaultPlateForm?.placeId) {
        fetchBusinessReview(
          findDefaultPlateForm?.placeId,
          findDefaultPlateForm?.reviewPlatformId,
          ratingType
        );
      }
    }
  }, [
    fetchBusinessReview,
    userCurrentBusinessDetails?.reviewplatform,
    userCurrentBusinessDetails?.isOnlineBusiness,
    platformsId,
    placeIdForRedirect,
    ratingTypeForRedirect,
  ]);

  useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const fetchAllData = async (platformId) => {
      try {
        setLoading(true);
        const payload = {
          query: manageBusinessData?.getPlatformDetails,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            platformId: parseInt(plateFormID, 10),
          },
          signal: abortController.signal,
        };
        const result = await handleApiRequest(payload);
        if (isMounted && result?.getPlatformDetails?.status === 200) {
          setPlatFormDetails(result?.getPlatformDetails?.data);
          setDiningReview(result?.getPlatformDetails?.dinningData);
        }
      } catch (err) {
        if (err.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error('Error fetching data:', err);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };
    const debouncedFetchData = debounce(fetchAllData, 500);
    if (userCurrentBusinessDetails?.id) {
      debouncedFetchData(plateFormID);
    }
    return () => {
      isMounted = false;
      abortController.abort();
      debouncedFetchData.cancel();
    };
  }, [plateFormID, userCurrentBusinessDetails?.id]);

  const fetchOnlineReview = useCallback(
    async (pageNum, pageSizes, plateFormId, filterId, isSortBy, isSortOrder) => {
      const payload = {
        query: reviewPlatformData.getOnlineBusinessReview,
        variables: {
          businessId: userCurrentBusinessDetails?.id,
          pageNumber: pageNum,
          pageSize: pageSizes,
          reviewPlatformId: parseInt(plateFormId, 10),
          filterReviewRating: parseInt(filterId, 10),
          ...(!filterId && {
            sortBy: isSortBy,
            orderBy: isSortOrder,
          }),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getOnlineBusinessReview?.status === 200) {
        setReviewData(result.getOnlineBusinessReview?.data);
        setSelectedReviewData(result.getOnlineBusinessReview?.data[0]);
        setTotalCount(result.getOnlineBusinessReview?.totalReview);
      } else {
        enqueueSnackbar(result?.getOnlineBusinessReview?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    },
    [userCurrentBusinessDetails?.id, enqueueSnackbar]
  );

  const commonGetData = async (
    placeIds,
    pageNum,
    pageSize,
    plateFormId,
    filterId,
    ratingTypes,
    isSortBy,
    isSortOrder
  ) => {
    try {
      const payload = {
        query: reviewPlatformData.getAllBusinessReviews,
        variables: {
          placeId: placeIds || placeID,
          platformId: parseInt(plateFormId, 10) || parseInt(plateFormID, 10),
          pageNumber: parseInt(pageNum, 10) || parseInt(page, 10),
          pageSize: parseInt(pageSize, 10) || parseInt(rowsPerPage, 10),
          ...(parseInt(plateFormId, 10) === 3 && {
            ratingType: ratingTypes || zomatoRatingType,
          }),
          filterReviewRating: parseInt(filterId, 10),
          sortBy: isSortBy || sortBy,
          sortOrder: isSortOrder || sortOrder,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getAllBusinessReviews?.status === 200) {
        setReviewData(result.getAllBusinessReviews?.data);
        setSelectedReviewData(result.getAllBusinessReviews?.data[0]);
        setTotalCount(result.getAllBusinessReviews?.totalReviews);
      } else {
        enqueueSnackbar(result?.getAllBusinessReviews?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const fetchFilterData = async () => {
      const payload = {
        query: reviewPlatformData.getReviewFilter,
      };
      const result = await handleApiRequest(payload);
      if (result?.getReviewFilter?.status === 200) {
        const reviewsData = result?.getReviewFilter?.data;
        const data = reviewsData.sort((curr, prev) => curr.id - prev.id);
        const lastElement = data.splice(-1);
        const firstElement = data.splice(0, 2);
        const filterArr = [...firstElement, ...lastElement, ...data];
        setFilterData(filterArr);
      }
    };
    fetchFilterData();
  }, [enqueueSnackbar]);

  const selectReviewData = (data) => {
    setSelectedReviewData(data);
  };

  const changeReviewPlatForm = async (reviewPlatformId) => {
    await setLoading(true);
    await setSelectedReviewData();
    const findReviewData = userCurrentBusinessDetails?.reviewplatform?.find(
      (item) => item.reviewPlatformId === reviewPlatformId
    );
    setPage(1);
    setRowsPerPage(10);
    setProducts();
    setReviewData([]);
    await setPlaceID(findReviewData?.placeId);
    await setPlateFormID(findReviewData?.reviewPlatformId);
    setTotalReview(
      parseInt(findReviewData?.reviewPlatformId, 10) === 3
        ? findReviewData?.aggregateData?.aggregateTotalReview
        : findReviewData?.totalReview
    );
    const ratingType = findReviewData?.name === 'zomato' && 'dining';
    setZomatoRatingType(ratingType);
    if (userCurrentBusinessDetails?.isOnlineBusiness) {
      if (parseInt(findReviewData?.reviewPlatformId, 10) === 7) {
        await fetchOnlineReview(
          page,
          rowsPerPage,
          findReviewData?.reviewPlatformId,
          filterReviewId,
          sortBy,
          sortOrder
        );
      } else {
        await fetchProducts(findReviewData?.reviewPlatformId, ratingType, filterReviewId);
      }
    } else {
      await commonGetData(
        findReviewData?.placeId,
        1,
        10,
        findReviewData?.reviewPlatformId,
        filterReviewId,
        ratingType
      );
    }
    await setLoading(false);
  };
  useEffect(() => {
    if (platformsId === 7 && !isInitialFetchComplete) {
      fetchOnlineReview(page, rowsPerPage, platformsId, filterReviewId, sortBy, sortOrder);
      setIsInitialFetchComplete(true);
    }
  }, [
    filterReviewId,
    page,
    rowsPerPage,
    sortBy,
    sortOrder,
    fetchOnlineReview,
    isInitialFetchComplete,
    platformsId,
  ]);

  const changeFilter = async (id) => {
    setFilterID(id !== 'all' ? id : '');
    setPage(1);
    const findFilterId = filterData?.find((item) => item.id === id);
    const isSortOrder = findFilterId?.type === 'Happy Reviews' ? 'asc' : 'desc';
    const isSortBy = findFilterId?.type === 'Happy Reviews' ? 'order' : 'newest';
    const filterId =
      findFilterId?.type !== 'Happy Reviews' && findFilterId?.type !== 'Newest'
        ? findFilterId?.filterReviewRating
        : null;
    if (findFilterId?.type === 'Happy Reviews') {
      setSortBy(isSortBy);
      setSortOrder(isSortOrder);
      setFilterReviewId('');
    } else if (findFilterId?.type === 'Newest') {
      setSortBy(isSortBy);
      setSortOrder(isSortOrder);
      setFilterReviewId('');
    } else {
      setFilterReviewId(filterId);
    }

    if (parseInt(plateFormID, 10) !== 7) {
      commonGetData(
        placeID,
        1,
        rowsPerPage,
        plateFormID,
        filterId,
        zomatoRatingType,
        isSortBy,
        isSortOrder
      );
    } else {
      fetchOnlineReview(page, rowsPerPage, plateFormID, filterId, isSortBy, isSortOrder);
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value + 1);
    if (parseInt(plateFormID, 10) === 7) {
      fetchOnlineReview(value + 1, rowsPerPage, plateFormID, filterReviewId, sortBy, sortOrder);
    } else {
      commonGetData(
        placeID,
        value + 1,
        rowsPerPage,
        plateFormID,
        filterReviewId,
        zomatoRatingType,
        sortBy,
        sortOrder
      );
    }
  };

  const handleChangeRowsPerPage = (event, value) => {
    setRowsPerPage(event.target.value);
    setPage(1);
    if (parseInt(plateFormID, 10) === 7) {
      fetchOnlineReview(1, event.target.value, plateFormID, filterReviewId, sortBy, sortOrder);
    } else {
      commonGetData(
        placeID,
        1,
        event.target.value,
        plateFormID,
        filterReviewId,
        zomatoRatingType,
        sortBy,
        sortOrder
      );
    }
  };

  const selectZomatoRatingType = (value) => {
    setZomatoRatingType(value);
    commonGetData(placeID, 1, rowsPerPage, plateFormID, filterReviewId, value, sortBy, sortOrder);
  };

  const handleSelectProduct = (id) => {
    const findProduct = products?.find((product) => product.id === id);
    setSelectedProduct(findProduct);
    setPlaceID(findProduct?.placeId);
    commonGetData(
      findProduct?.placeId,
      1,
      rowsPerPage,
      plateFormID,
      filterReviewId,
      zomatoRatingType,
      sortBy,
      sortOrder
    );
  };

  const handleClick = (event, type) => {
    if (anchorEl && menuType === type) {
      setAnchorEl(null);
      setMenuType(null);
    } else {
      setAnchorEl(event.currentTarget);
      setMenuType(type);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuType(null);
  };

  const handleSortSelect = (sortId) => {
    handleClose();
  };

  if (pageLoading) {
    return <FuseLoading />;
  }

  if (loading) {
    return <Loading />;
  }

  const redirectAddProduct = () => {
    const id = getParamsId();
    if (id) {
      history.push(`quick-reviews/product/${id}`);
    } else {
      history.push('quick-reviews/product');
    }
  };
  return (
    <div className="p-20 lg:p-32">
      <div className="sm:flex w-full items-center justify-between mb-36">
        <Typography
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
        >
          {t('reviews.title')}
        </Typography>
        <div className="sm:flex gap-24 text-center">
          <div className="items-center justify-end md:flex mb-20 md:mb-0 text-left">
            <Select
              className="rounded-md h-40 bg-white text-16 w-full sm:min-w-256 leading-5 shadow-none capitalize"
              variant="filled"
              sx={{ '& .MuiFilledInput-input': { pt: '8px', zIndex: '10' } }}
              value={parseInt(plateFormID, 10)}
              fullWidth
              disabled={userCurrentBusinessDetails?.reviewplatform?.length === 1}
              onChange={(e) => changeReviewPlatForm(e?.target?.value)}
              IconComponent={() => (
                <ArrowDropDownOutlinedIcon className="text-28 align-middle absolute right-14 text-black ml-2" />
              )}
            >
              {!userCurrentBusinessDetails?.isOnlineBusiness &&
                userCurrentBusinessDetails?.reviewplatform
                  ?.slice()
                  .sort((a, b) => b.defaultReview - a.defaultReview)
                  .map((review, i) => (
                    <MenuItem className="capitalize" value={review?.reviewPlatformId} key={i}>
                      <div className="flex gap-8">
                        <img className="w-20" src={review?.logo} alt="" />{' '}
                        <Typography>
                          {' '}
                          {review?.name && capitalizeFirstLetter(review.name)}
                        </Typography>
                      </div>
                    </MenuItem>
                  ))}
              {userCurrentBusinessDetails?.isOnlineBusiness &&
                userCurrentBusinessDetails?.reviewplatform
                  ?.slice()
                  ?.sort((a, b) => a.id - b.id)
                  .map((item, index) => {
                    return (
                      <MenuItem className="capitalize" value={item?.reviewPlatformId} key={index}>
                        <div className="flex gap-8">
                          <img className="w-20" src={item?.logo} alt="" />{' '}
                          <Typography> {item?.name && capitalizeFirstLetter(item.name)}</Typography>
                        </div>
                      </MenuItem>
                    );
                  })}
            </Select>
          </div>
        </div>
      </div>
      <div>
        {parseInt(plateFormID, 10) === 3 && (
          <div className="mb-28">
            <Tabs
              value={zomatoRatingType === 'delivery' ? 0 : 1}
              onChange={(e, newValue) =>
                selectZomatoRatingType(newValue === 0 ? 'delivery' : 'dining')
              }
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="zomato rating tabs"
              className=" border-b-2 border-grey-500"
            >
              <Tab
                className={`text-18 p-0 ${
                  zomatoRatingType === 'delivery'
                    ? 'text-darkgreen font-bold'
                    : 'text-black font-medium'
                }`}
                label={t('reviews.deliveryRating')}
              />
              <Tab
                className={`text-18 p-0  ${
                  zomatoRatingType === 'dining'
                    ? 'text-darkgreen font-bold '
                    : 'text-black font-medium'
                }`}
                label={t('reviews.diningRating')}
              />
            </Tabs>
          </div>
        )}
      </div>
      <>
        {parseInt(totalReview, 10) > 0 ||
        (parseInt(totalReview, 10) > 0 &&
          products?.length > 0 &&
          (parseInt(plateFormID, 10) === 5 || parseInt(plateFormID, 10) === 6)) ? (
          <>
            <div className="bg-white w-full rounded-md p-16">
              <div className=" flex bg-quick-review-100 rounded-12 mb-32 p-20">
                <div>
                  <img
                    src={platFormDetails?.platformDetails?.logo}
                    alt={platFormDetails?.platformDetails?.name}
                    className={`bg-white h-120 w-120 rounded-8 mr-40 ${
                      platFormDetails?.platformDetails?.name === 'zomato' ? '' : 'p-20'
                    }`}
                  />
                </div>
                <div>
                  <div className="text-32 font-bold ">
                    {platFormDetails?.platformDetails?.name
                      ? platFormDetails.platformDetails.name.charAt(0).toUpperCase() +
                        platFormDetails.platformDetails.name.slice(1)
                      : ''}
                    {t('reviews.title')}
                  </div>
                  <div>
                    {platFormDetails && (
                      <span className="block">
                        {reviewFormate(platFormDetails.totalReviewRatings, 56)}
                      </span>
                    )}
                  </div>
                  <p className="text-20 font-medium">
                    {t('reviews.reviewCount')}
                    {zomatoRatingType === 'dining'
                      ? diningReview?.totalReview
                      : platFormDetails?.totalReview}
                    {t('reviews.title')}
                  </p>
                </div>
              </div>
              <div className="flex justify-between md:w-1/3 mb-14">
                <div className="text-24 font-semibold">
                  {platFormDetails?.platformDetails?.name
                    ? `${platFormDetails.platformDetails.name
                        .charAt(0)
                        .toUpperCase()}${platFormDetails.platformDetails.name.slice(1)}`
                    : 'Default Name'}
                  {t('reviews.title')}
                </div>
                <div className="  md:flex mb-20 md:mb-0 text-left">
                  {parseInt(totalReview, 10) > 0 && (
                    <>
                      <Button
                        className="rounded-md bg-white hover:bg-white text-14 shadow-none flex p-0"
                        onClick={(e) => handleClick(e, 'filter')}
                      >
                        <img src="/assets/images/icon/filter.png" alt="sort" />
                      </Button>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && menuType === 'filter'}
                        onClose={handleClose}
                        classes={{
                          paper: 'min-w-360',
                        }}
                        className="p-10 mt-14 shadow-none w-3"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <div className="flex justify-between items-center pl-20 text-16 font-semibold mb-10 w-full ">
                          <p>{t('reviews.filter')}</p>
                          <Button
                            className="rounded-md bg-white hover:bg-white text-18 shadow-none"
                            onClick={handleClose}
                          >
                            <CloseIcon className="text-32 text-grey-700" />
                          </Button>
                        </div>
                        <div className="flex flex-col w-full">
                          {filterData?.length > 0 &&
                            filterData.map((filter, i) => (
                              <MenuItem
                                className="capitalize text-14 font-medium py-10 px-24  cursor-pointer hover:bg-gray-100 w-full "
                                key={i}
                                onClick={() => changeFilter(filter?.id)}
                              >
                                {filter?.type}
                              </MenuItem>
                            ))}
                        </div>
                      </Menu>
                    </>
                  )}
                  {parseInt(totalReview, 10) > 0 && (
                    <>
                      <Button
                        className="rounded-md bg-white hover:bg-white shadow-none hidden"
                        onClick={(e) => handleClick(e, 'sort')}
                      >
                        <img src="/assets/images/icon/sort.png" alt="sort" />
                      </Button>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && menuType === 'sort'}
                        onClose={handleClose}
                        className="mt-14 -m-96"
                      >
                        <div className="flex justify-between items-center pl-16 text-16 font-semibold mb-10">
                          <p>{t('reviews.sort')}</p>
                          <Button
                            className="rounded-md  bg-white hover:bg-white text-16 leading-5 shadow-none capitalize "
                            onClick={handleClose}
                          >
                            <CloseIcon className="text-24 text-gray-600" />
                          </Button>
                        </div>
                        {sortingOptions?.length > 0 &&
                          sortingOptions?.map((sort, i) => (
                            <MenuItem
                              value={sort?.id}
                              className="capitalize text-14 font-medium"
                              key={i}
                              onClick={() => handleSortSelect(sort.id)}
                            >
                              <Typography>{sort?.type}</Typography>
                            </MenuItem>
                          ))}
                      </Menu>
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-col md:flex-row w-full gap-20">
                <div className="overflow-scroll max-h-640 md:w-1/3">
                  {userCurrentBusinessDetails.isOnlineBusiness &&
                    products?.length > 0 &&
                    parseInt(plateFormID, 10) !== 7 && (
                      <Select
                        className="inline-block bg-white text-black capitalize border-none pt-0 font-semibold w-full mb-12 relative"
                        id="country"
                        value={selectedProduct?.id}
                        size="small"
                        displayEmpty
                        sx={{
                          '& .MuiSelect-select': {
                            display: 'flex',
                            alignItems: 'center',
                            border: 'none',
                            maxWidth: '360px',
                          },
                        }}
                        onChange={(e) => handleSelectProduct(e?.target?.value)}
                      >
                        {products?.map((product, i) => (
                          <MenuItem
                            key={i}
                            value={product?.id}
                            className="flex capitalize whitespace-normal"
                          >
                            <div className="w-full max-w-360">{product?.genericName}</div>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  {reviewData?.length > 0 &&
                    reviewData?.map((review, index) => {
                      return (
                        <div
                          className={`flex rounded-md mb-16 bg-gray-A300 p-12 border border-solid border-gray-A300 ${
                            parseInt(selectedReviewData?.id, 10) === parseInt(review?.id, 10)
                              ? '!border-darkgreen'
                              : ''
                          }`}
                          key={index}
                          onClick={() => selectReviewData(review)}
                          onKeyDown={(e) => {}}
                          role="button"
                          tabIndex={0}
                        >
                          <div className="w-full">
                            <div className="flex w-full justify-between">
                              <div className="flex itemx-center gap-12">
                                {review?.reviewerProfileUrl ? (
                                  <img
                                    className="w-48 h-48 rounded-3xl"
                                    src={
                                      review?.reviewerProfileUrl || 'assets/images/icon/Profile.png'
                                    }
                                    alt=""
                                  />
                                ) : (
                                  <UserProfile
                                    name={review?.reviewerName}
                                    width="45px"
                                    height="45px"
                                  />
                                )}
                                <div className="">
                                  <Typography className="font-semibold">
                                    {review?.reviewerName}
                                  </Typography>
                                  {[...Array(5)].map((_, i) => (
                                    <Icon
                                      className={`text-16 align-middle mr-2 -mt-2 ${
                                        i <
                                        (parseInt(plateFormID, 10) !== 7
                                          ? review?.reviewRating
                                          : review?.rating)
                                          ? 'text-yellow-800'
                                          : 'text-gray-400'
                                      }`}
                                      key={i}
                                    >
                                      star
                                    </Icon>
                                  ))}
                                  {review?.reviewRating || review?.rating}.0
                                </div>
                              </div>
                              <Typography className="">
                                {moment(review?.reviewCts).format('MMM DD, YYYY')}
                              </Typography>
                            </div>
                            <Typography className="pt-12 text-14">
                              {review?.review && review.review.length > 190
                                ? `${review.review?.slice(0, 190)}...`
                                : review.review}
                            </Typography>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {parseInt(totalReview, 10) > 0 && reviewData?.length === 0 && (
                  <div className="h-full text-20 font-bold justify-center text-center my-192">
                    <img className="m-auto" src="/assets/images/business/Processing.png" alt="" />
                    <Typography className="font-semibold text-20 my-20">
                      {t('reviews.processing')}
                    </Typography>
                    <Typography className="text-16">{t('reviews.loadingReviewMessage')}</Typography>
                  </div>
                )}
                {parseInt(totalReview, 10) === 0 && reviewData?.length === 0 && (
                  <div className="text-center my-192">
                    <img className="m-auto" src="/assets/images/business/reviews.svg" alt="" />
                    <Typography className="font-semibold text-20 my-20">
                      {t('reviews.noReviews')}
                    </Typography>
                    <Typography className="text-16">{t('reviews.message')}</Typography>
                  </div>
                )}
                {selectedReviewData && (
                  <div className="h-max md:h-full md:overflow-scroll rounded-md md:w-2/3 bg-gray-A300 p-12">
                    <div className="flex justify-between">
                      <div className="flex items-center gap-12">
                        {selectedReviewData?.reviewerProfileUrl ? (
                          <img
                            className="w-48 h-48 rounded-3xl"
                            src={
                              selectedReviewData?.reviewerProfileUrl ||
                              'assets/images/icon/Profile.png'
                            }
                            alt=""
                          />
                        ) : (
                          <UserProfile
                            name={selectedReviewData?.reviewerName}
                            width="45px"
                            height="45px"
                          />
                        )}
                        <div className="">
                          <Typography className="font-semibold">
                            {selectedReviewData?.reviewerName}
                          </Typography>
                          {[...Array(5)].map((_, i) => (
                            <Icon
                              className={`text-16 align-middle mr-2 -mt-2 ${
                                i <
                                (parseInt(plateFormID, 10) !== 7
                                  ? selectedReviewData?.reviewRating
                                  : selectedReviewData?.rating)
                                  ? 'text-yellow-800'
                                  : 'text-gray-400'
                              }`}
                              key={i}
                            >
                              star
                            </Icon>
                          ))}{' '}
                          {selectedReviewData?.reviewRating || selectedReviewData?.rating}.0
                        </div>
                      </div>
                      <Typography className="">
                        {moment(selectedReviewData?.reviewCts).format('MMM DD, YYYY')}
                      </Typography>
                    </div>
                    <Typography className="font-medium pt-8 text-14 md:text-16">
                      {selectedReviewData?.review}
                    </Typography>
                    <div className="grid grid-cols-2 gap-8  sm:grid-cols-3 lg:grid-cols-4">
                      {selectedReviewData?.imageLinks?.length > 0 &&
                        selectedReviewData?.imageLinks?.map((item, i) => {
                          return (
                            <div className="" key={i}>
                              <img
                                className="sm:w-192 sm:h-192 w-160 h-160 m-auto rounded-md mt-24"
                                src={item}
                                alt=""
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
              {reviewData?.length > 0 && (
                <div className="sm:w-1/3">
                  <div className="flex justify-center">
                    <Pagination
                      totalCount={totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="text-center mt-160 sm:mt-256">
            {parseInt(totalReview, 10) > 0 ? (
              <div className="h-full text-20 font-bold justify-center">
                <img className="m-auto" src="/assets/images/business/Processing.png" alt="" />
                <Typography className="font-semibold text-20 my-20">
                  {t('reviews.processing')}
                </Typography>
                <Typography className="text-16">{t('reviews.loadingReviewMessage')}</Typography>
              </div>
            ) : (
              <div className="h-full text-20 font-bold justify-center">
                {(parseInt(plateFormID, 10) === 5 || parseInt(plateFormID, 10) === 6) &&
                products?.length === 0 ? (
                  <div>
                    <img
                      className="m-auto mb-16"
                      src="/assets/images/business/products.png"
                      alt=""
                    />
                    {parseInt(plateFormID, 10) === 5 && (
                      <div>
                        <Typography className="font-semibold text-20 my-16">
                          {t('products.noAmazonProduct')}
                        </Typography>
                        <Typography className="text-16 max-w-640 m-auto">
                          {t('products.addAmazonProductMessage')}
                        </Typography>
                        <Button
                          className="bg-darkgreen rounded-md text-white px-36 hover:bg-darkgreen mt-16"
                          onClick={() => redirectAddProduct()}
                        >
                          {t('products.addAmazonProduct')}
                        </Button>
                      </div>
                    )}
                    {parseInt(plateFormID, 10) === 6 && (
                      <div>
                        <Typography className="font-semibold text-20 my-16">
                          {t('products.noFlipKartProduct')}
                        </Typography>
                        <Typography className="text-16 max-w-640 m-auto">
                          {t('products.addFlipkartProductMessage')}
                        </Typography>
                        <Button
                          className="bg-darkgreen rounded-md text-white px-36 hover:bg-darkgreen mt-16"
                          onClick={() => redirectAddProduct()}
                        >
                          {t('products.addFlipkartProduct')}
                        </Button>
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <img className="m-auto" src="/assets/images/business/reviews.svg" alt="" />
                    <Typography className="font-semibold text-20 my-20">
                      {t('reviews.noReviews')}
                    </Typography>
                    <Typography className="text-16">{t('reviews.message')}</Typography>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
};

export default Reviews;
