import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Icon,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import manageBusinessData from '../../../query/manageBusiness';
import { handleApiRequest, storeCsvFileDataToServer } from '../../../common/common';

const LogoPhotoVideo = (props) => {
  const {
    fetchBusinessDetails,
    userCurrentBusinessDetails,
    googleBusinessData,
    openDialog,
    setOpenDialog,
    tabValue,
    setTabValue,
  } = props;
  const [fileName, setFileName] = useState();
  const [uploadFile, setUploadFile] = useState();
  const [mediaFormat, setMediaFormat] = useState();
  const [googleLogoPhotoVideoData, setGoogleLogoPhotoData] = useState({
    // logo: [],
    photo: [],
    video: [],
  });
  const [refreshIcon, setRefreshIcon] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const fetchGooglePhotoVideo = useCallback(async () => {
    if (userCurrentBusinessDetails?.id) {
      try {
        const payload = {
          query: manageBusinessData.getGoogleBusinessPhotosVideos,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getGoogleBusinessPhotosVideos?.status === 200) {
          const googlePhotos =
            (result.getGoogleBusinessPhotosVideos?.data?.googlePhotos &&
              JSON.parse(result.getGoogleBusinessPhotosVideos?.data?.googlePhotos)) ||
            [];
          const additionalPhotoValues = googlePhotos.find(
            (item) => item.key === 'ADDITIONAL'
          )?.value;
          const googleVideos =
            (result.getGoogleBusinessPhotosVideos?.data?.googleVideo &&
              JSON.parse(result.getGoogleBusinessPhotosVideos?.data?.googleVideo)) ||
            [];
          const additionalVideoValues = googleVideos.find(
            (item) => item.key === 'ADDITIONAL'
          )?.value;
          setGoogleLogoPhotoData({
            photo: additionalPhotoValues || [],
            video: additionalVideoValues || [],
          });
        }
      } catch (error) {
        console.error('Error fetching Google Photos/Videos:', error);
      }
    }
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    fetchGooglePhotoVideo();
  }, [fetchGooglePhotoVideo]);

  const handleTabChange = (value, newValue) => {
    setTabValue(newValue);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file?.type?.startsWith('video/') && !file?.type?.startsWith('image/')) {
      enqueueSnackbar('Please select a valid file', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    if (file?.type?.startsWith('video/')) {
      setMediaFormat('VIDEO');
    } else if (file.type.startsWith('image/')) {
      setMediaFormat('PHOTO');
    }
    const reader = new FileReader();
    reader?.readAsBinaryString(file);

    reader.onload = async () => {
      setUploadFile(file);
      setFileName(file?.name);
    };
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (!file?.type?.startsWith('video/') && !file.type.startsWith('image/')) {
      enqueueSnackbar('Please select a valid file', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    const reader = new FileReader();

    reader.readAsBinaryString(file);

    reader.onload = async () => {
      setUploadFile(file);
      setFileName(file?.name);
    };
  };

  const onSubmit = async () => {
    setRefreshIcon(true);
    try {
      const payload = {
        query: manageBusinessData.addGoogleBusinessPhotosAndVideos,
        variables: {
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          file: uploadFile,
          mediaFormat,
          ...((tabValue === 1 || tabValue === 2) && {
            category: 'ADDITIONAL',
          }),
          ...(tabValue === 3 && {
            category: 'COVER',
          }),
          ...(tabValue === 4 && {
            category: 'LOGO',
          }),
        },
      };
      const result = await storeCsvFileDataToServer(payload);
      setRefreshIcon(false);
      if (result?.addGoogleBusinessPhotosAndVideos?.status === 200) {
        fetchGooglePhotoVideo();
        setUploadFile();
        setFileName();
        setOpenDialog({ ...openDialog, phoneNumberDialog: false });
        if (tabValue === 3 || tabValue === 4) {
          fetchBusinessDetails();
        }
      } else {
        enqueueSnackbar(result?.addGoogleBusinessPhotosAndVideos?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error adding photos/videos:', error);
    }
  };

  const deleteImage = async (data) => {
    const payload = {
      query: manageBusinessData.deleteGoogleBusinessPhotosAndVideos,
      variables: {
        mediaId: data?.name?.split('/')?.pop(),
        userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
        mediaFormat: data?.mediaFormat,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.deleteGoogleBusinessPhotosAndVideos?.status === 200) {
      fetchGooglePhotoVideo();
      enqueueSnackbar(result?.deleteGoogleBusinessPhotosAndVideos?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar(result?.deleteGoogleBusinessPhotosAndVideos?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <div>
      <div className="bg-white rounded-md">
        <Typography className="font-bold text-24 mt-16">
          <AddPhotoAlternateIcon className="text-32" /> {t('listManagement.logo')},{' '}
          {t('listManagement.photo')} & {t('listManagement.video')}
        </Typography>
        <div className="bg-gray-A500 grid grid-cols-2 col-span-1 gap-16 p-28 rounded-md mt-16">
          <div className="bg-white p-24 rounded-md">
            <Typography className="font-bold text-16">
              {t('listManagement.addStoreFrontPhotos')}
            </Typography>
            <div className="flex flex-wrap gap-20 mt-16">
              {googleLogoPhotoVideoData?.photo?.length > 0 &&
                googleLogoPhotoVideoData?.photo?.map((item, index) => {
                  return (
                    <div className="border relative py-8 rounded" key={index}>
                      <img className="w-84 h-76" src={item?.thumbnailUrl} alt="" />
                      <Button
                        className="absolute -right-4 -top-8 p-0 w-12 min-w-12 h-12 min-h-12"
                        onClick={() => deleteImage(item)}
                      >
                        <DeleteIcon className="text-gray w-20" />
                      </Button>
                    </div>
                  );
                })}
              <div className="border py-8 bg-gray-300 rounded">
                <div className="w-84 h-76 flex items-center">
                  <Button
                    className="font-bold hover:bg-transparent text-14"
                    onClick={() => {
                      setOpenDialog({ ...openDialog, logoPhotoVideoDialog: true });
                      setTabValue(2);
                    }}
                  >
                    {t('listManagement.addPhoto')} <AddCircleOutlineRoundedIcon className="w-16" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-24 rounded-md">
            <Typography className="font-bold text-16">
              {t('listManagement.addStoreFrontVideos')}
            </Typography>
            <div className="flex flex-wrap gap-16 mt-16">
              {googleLogoPhotoVideoData?.video?.length > 0 &&
                googleLogoPhotoVideoData?.video?.map((item, index) => {
                  return (
                    <div className="border relative py-8 rounded" key={index}>
                      <img className="w-84 h-76" src={item?.thumbnailUrl} alt="" />
                      <Button
                        className="absolute -right-4 -top-8 p-0 w-12 min-w-12 h-12 min-h-12"
                        onClick={() => deleteImage(item)}
                      >
                        <DeleteIcon className="text-gray w-20" />
                      </Button>
                    </div>
                  );
                })}
              <div className="border py-8 bg-gray-300 rounded">
                <div className="w-84 h-76 flex items-center">
                  <Button
                    className="font-bold hover:bg-transparent text-14"
                    onClick={() => {
                      setOpenDialog({ ...openDialog, logoPhotoVideoDialog: true });
                      setTabValue(1);
                    }}
                  >
                    {t('listManagement.addVideo')} <AddCircleOutlineRoundedIcon className="w-16" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        classes={{
          paper: 'm-24 p-28 business-type-dropdown',
        }}
        open={openDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="p-0 mb-8">
          <div className="flex">
            <Icon
              onClick={() => {
                setOpenDialog({ ...openDialog, logoPhotoVideoDialog: false });
                setUploadFile();
                setFileName();
              }}
              className="text-24 text-grey-400 absolute right-24 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
            >
              cancel
            </Icon>
          </div>
          <div className="border-b-2 border-solid border-quick-review-100 pb-24">
            <Typography className="font-bold text-20">{googleBusinessData?.title}</Typography>
            <Typography className="text-gray text-16 font-semibold pb-8">
              {t('listManagement.address')} :{' '}
              {googleBusinessData?.storefrontAddress?.addressLines?.[0]}{' '}
              {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
              {googleBusinessData?.storefrontAddress?.locality}{' '}
              {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
              {googleBusinessData?.storefrontAddress?.postalCode}
            </Typography>
          </div>
          <div>
            <Tabs
              value={tabValue}
              className="rounded-tl-md rounded-tr-md overflow-scroll"
              sx={{
                '& .MuiTabs-scroller': {
                  overflow: 'scroll !important',
                },
              }}
              onChange={handleTabChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <Tab
                className="md:text-18 text-14 font-semibold px-5 md:px-16"
                value={1}
                label={t('listManagement.video')}
              />
              <Tab
                className="md:text-18 text-14 ml-24 font-semibold px-5 md:px-16"
                value={2}
                label={t('listManagement.photo')}
              />
              <Tab
                className="md:text-18 text-14 ml-24 font-semibold px-5 md:px-16"
                value={3}
                label={t('listManagement.coverPhoto')}
              />
              <Tab
                className="md:text-18 text-14 ml-24 font-semibold px-5 md:px-16"
                value={4}
                label={t('listManagement.coverLogo')}
              />
            </Tabs>
            <Paper
              className="relative w-full h-full rounded-8 shadow-none border-1 border-dashed border-quick-review p-40 bg-quick-review-100 text-center"
              role="button"
              onDrop={handleDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              <span className="block text-center">
                <img
                  src={
                    tabValue === 1
                      ? '/assets/images/admin-icon/video-icon.png'
                      : '/assets/images/admin-icon/cloud_upload.png'
                  }
                  className="mx-auto mb-12"
                  width="61"
                  height="64"
                  alt="..."
                />
              </span>
              <div className="block font-semibold text-16">
                {fileName ||
                  (tabValue === 1
                    ? 'Please select a valid video file'
                    : 'Please select a valid image file')}
              </div>
              <span className="block font-semibold text-16">or</span>
              <div className="inline-block">
                <label htmlFor="button-file">
                  <input
                    accept={
                      tabValue === 1
                        ? 'video/*'
                        : tabValue === 2 || tabValue === 3
                        ? 'image/jpg'
                        : 'image/jpg, image/jpeg'
                    }
                    className="hidden"
                    id="button-file"
                    type="file"
                    onChange={handleFileChange}
                  />
                  <Button
                    size="small"
                    className="rounded-xxl block text-14 font-medium px-24 py-5 w-auto"
                    variant="contained"
                    color="secondary"
                    component="span"
                  >
                    {t('listManagement.browserFile')}
                  </Button>
                </label>
              </div>
            </Paper>
          </div>
          <div className="text-center">
            <Button
              className="bg-quick-review w-1/2 text-white rounded-full mt-16 px-40 disabled:text-white font-bold text-18 disabled:bg-gray hover:bg-quick-review"
              disabled={refreshIcon || !uploadFile}
              onClick={() => onSubmit()}
            >
              {refreshIcon && (
                <CircularProgress className="text-quick-review absolute mx-auto" size={24} />
              )}
              {t('listManagement.save')}
            </Button>
          </div>
        </DialogTitle>
      </Dialog>
    </div>
  );
};

export default LogoPhotoVideo;
