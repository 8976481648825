import { Button, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { capitalizeFirstLetter, handleApiRequest } from 'src/app/main/common/common';
import FuseLoading from '@fuse/core/FuseLoading';
import { t } from 'i18next';
import LoopIcon from '@mui/icons-material/Loop';
import { useSnackbar } from 'notistack';
import history from '@history';
import moment from 'moment';
import manageBusinessData from '../../../query/manageBusiness';
import { selectDashboardData } from '../../../../store/userSlice';
import Pagination from '../../../../component/table/Pagination';
import ScanHistoryDetails from './ScanHistoryDetails';

const ScanHistory = () => {
  const [loading, setLoading] = useState(true);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [gridHistoryData, setGridHistoryData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [totalCount, setTotalCount] = useState();
  const [historyData, setHistoryData] = useState();
  const [isHistoryDetails, setIsHistoryDetails] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const fetchScanHistory = useCallback(async () => {
    if (userCurrentBusinessDetails?.id) {
      setLoading(true);
      const obj = {
        query: manageBusinessData.getUserBusinessRankerHistory,
        variables: {
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          pageNumber: page,
          pageSize: rowsPerPage,
        },
      };
      try {
        const result = await handleApiRequest(obj);
        setLoading(false);
        if (result?.getUserBusinessRankerHistory?.status === 200) {
          setGridHistoryData(result?.getUserBusinessRankerHistory?.data);
          setTotalCount(result?.getUserBusinessRankerHistory?.totalCount);
        } else {
          enqueueSnackbar(result?.getUserBusinessRankerHistory?.message || 'Failed to fetch data', {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } catch (error) {
        setLoading(false);
        console.error('Error fetching scan history:', error);
      }
    }
  }, [page, rowsPerPage, userCurrentBusinessDetails?.id, enqueueSnackbar]);

  useEffect(() => {
    fetchScanHistory();
  }, [fetchScanHistory]);

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleChangePage = async (event, value) => {
    setPage(value + 1);
  };

  if (loading) {
    return <FuseLoading />;
  }

  const clickOnReRerun = (data) => {
    setHistoryData(data);
    setIsHistoryDetails(true);
  };

  const clickOnHistoryDetails = (value) => {
    history.push('quick-reviews/businessCompetitor/', value);
  };

  return (
    <div className="p-12 sm:p-28">
      {!isHistoryDetails ? (
        <>
          <Typography className="font-bold text-28 mb-16">
            {t('businessCompetitor.scanHistory')}
          </Typography>
          <div className="">
            {gridHistoryData?.length > 0 ? (
              <div className="w-full sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 justify-center col-span-1 gap-20 rounded">
                {gridHistoryData?.map((value, index) => {
                  return (
                    <div
                      key={index}
                      className="bg-white rounded-md border-gray w-full text-center transform transition-transform duration-300 hover:scale-105 cursor-pointer"
                      onClick={() => clickOnReRerun(value)}
                      role="button"
                      tabIndex={0}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          clickOnReRerun(value);
                        }
                      }}
                    >
                      <div className="bg-red-100 rounded-t-lg h-40 mb-8 items-center flex justify-center">
                        <Typography className="text-red text-16 font-bold">
                          Channel: Google Map
                        </Typography>
                      </div>
                      <Typography className="font-bold text-16 mt-16">
                        {t('businessCompetitor.keyword')} :{' '}
                        {capitalizeFirstLetter(value?.queryParams?.keyword)}
                      </Typography>
                      <Typography className="my-4 text-16 text-gray font-bold pt-16">
                        {t('businessCompetitor.grid')} {value?.queryParams?.grid_size} *{' '}
                        {value?.queryParams?.grid_size}
                      </Typography>
                      <Typography className="my-4 text-16 text-gray font-bold">
                        {t('businessCompetitor.radius')}: {value?.queryParams?.radius} Kms
                      </Typography>
                      <div className="flex justify-center min-h-216 max-h-216 items-center">
                        <div>
                          {[...Array(value?.queryParams?.grid_size)].map((_, rowIndex) => (
                            <div key={rowIndex} className="flex items-center gap-8 mb-8 last:mb-0">
                              {[...Array(value?.queryParams?.grid_size)].map((item, colIndex) => (
                                <div
                                  key={colIndex}
                                  className={`bg-red ${
                                    value?.queryParams?.grid_size === 3
                                      ? 'w-32 h-32'
                                      : value?.queryParams?.grid_size === 5
                                      ? 'w-24 h-24'
                                      : value?.queryParams?.grid_size === 7
                                      ? 'w-20 h-20'
                                      : 'w-16 h-16'
                                  }`}
                                />
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>
                      <Typography className="my-20 text-16 text-gray font-bold">
                        {moment(value?.cts).format('DD MMM YYYY')}
                      </Typography>
                      <div className="px-12 pb-8">
                        <Button
                          className="bg-quick-review w-full text-white font-bold text-16 rounded-md hover:bg-quick-review"
                          onClick={() => clickOnHistoryDetails(value)}
                        >
                          <LoopIcon className="pr-4" /> Re-Run
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="bg-white text-center rounded-md py-216">
                <img className="m-auto w-144" src="assets/images/business/scan.svg" alt="" />
                <Typography className="text-center font-semibold py-8 text-20 ">
                  {t('businessCompetitor.noScanHistory')}
                </Typography>
                <Typography className="text-center font-semibold text-14 max-w-512 m-auto">
                  {t('businessCompetitor.noScanHistoryMessage')}
                </Typography>
                <Button
                  className="rounded-md font-semibold px-28 my-12"
                  color="secondary"
                  variant="contained"
                  onClick={() => history.push('quick-reviews/businessCompetitor/')}
                >
                  {t('businessCompetitor.startNewSearch')}
                </Button>
              </div>
            )}
          </div>
          {gridHistoryData?.length > 0 && (
            <Pagination
              totalCount={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </>
      ) : (
        <ScanHistoryDetails historyData={historyData} setIsHistoryDetails={setIsHistoryDetails} />
      )}
    </div>
  );
};

export default ScanHistory;
