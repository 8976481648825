import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { selectDashboardData } from 'src/app/store/userSlice';
import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';
import { capitalize } from 'lodash';
import { useSnackbar } from 'notistack';
import userService from '../../../services';
import './exploreFeatures.css';
import businessData from '../../query/business';
import { handleApiRequest, handlePhoneNumberValidationCheck } from '../../common/common';
import quickPostQuery from '../../query/quickPost';

const QuickCampaignsFeatures = ({
  surpriseMeCardData,
  allFeaturesTrialDataId,
  campaignTrialDone,
}) => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const loginUserData = userService?.getUserData();
  const [tabValue, setTabValue] = useState('1');
  const [platformId, setPlatformId] = useState(2);
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState({});
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [errorMessage, setErrorMessage] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneValue, setPhoneValue] = useState();
  const [isEmailValid, setIsEmailValid] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [phone, setPhone] = useState();
  const clearForm = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneValue(phoneValue?.slice(0, phone?.dialCode?.length));
    setPhone({ ...phone, phoneNumber: '' });
  };

  useEffect(() => {
    async function fetchData() {
      setRefreshIcon(true);
      const payload = {
        query: businessData.getCampaignStandardMessages,
        variables: {
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          campaignPlatformId: parseInt(platformId, 10),
          serviceType: 'invite_reviews',
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.getCampaignStandardMessages?.status === 200) {
        const data = result?.getCampaignStandardMessages?.data;
        const reviewScannerUrl = `${userCurrentBusinessDetails?.businessQRId}`;
        const updatedArray = data?.map((value) => {
          return {
            ...value,
            htmlTemplate: value?.htmlTemplate?.replace('[Review Link]', reviewScannerUrl),
          };
        });
        surpriseMeCardData();
        setSelectedMessage(updatedArray[0]);
        setMessages(updatedArray);
      }
    }
    fetchData();
  }, [userCurrentBusinessDetails, tabValue, platformId, surpriseMeCardData]);

  const handleRedirect = async () => {
    if (isEmailValid && (tabValue === '2' ? email : phoneValue)) {
      const finalCustomerData = {
        name: `${firstName} ${lastName}`,
      };

      if (tabValue === '1') {
        finalCustomerData.countryCode = `+${phone?.dialCode}`;
        finalCustomerData.phoneNumber = phone?.phoneNumber;
        finalCustomerData.title = selectedMessage?.messages
          ?.replace(/\{firstname\} {lastname}/g, `${firstName} ${lastName}`)
          .replace(/\{businessname\}/g, userCurrentBusinessDetails?.name)
          .replace(/\[Business Name\]/g, userCurrentBusinessDetails?.name)
          .replace(/\[Customers Name\]/g, firstName)
          .replace(/\[Review Link\]/g, userCurrentBusinessDetails?.businessQRId);
      }

      if (tabValue === '2') {
        finalCustomerData.email = email;
        finalCustomerData.htmlSubject = selectedMessage?.htmlSubject
          ?.replace(/\[Business Name\]/g, userCurrentBusinessDetails?.name)
          .replace(/\[Customers Name\]/g, `${firstName} ${lastName}`);
        finalCustomerData.htmlTemplate = selectedMessage?.htmlTemplate
          ?.replace(/\{firstname\} {lastname}/g, `${firstName} ${lastName}`)
          .replace(/\[Business Name\]/g, userCurrentBusinessDetails?.name)
          .replace(/\[Customers Name\]/g, `${firstName} ${lastName}`)
          .replace(/\{businessname\}/g, userCurrentBusinessDetails?.name)
          .replace(/\[review link\]/g, userCurrentBusinessDetails?.businessQRId);
      }

      if (tabValue === '3') {
        const payload = {
          query: businessData.sendCampaignWhatsAppMessagesV1,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            campaignPlatformId: parseInt(platformId, 10),
            whatsAppTemplateId: parseInt(selectedMessage?.id, 10),
            isSchedule: false,
            queue: [
              {
                phoneNumber: parseInt(phone?.phoneNumber, 10),
                countryCode: `+${phone?.dialCode}`,
                name: `${firstName} ${lastName}`,
              },
            ],
          },
        };

        const result = await handleApiRequest(payload);
        if (result?.sendCampaignWhatsAppMessagesV1?.status === 200) {
          enqueueSnackbar(result?.sendCampaignWhatsAppMessagesV1?.message, {
            variant: 'success',
            autoHideDuration: 3000,
          });
          clearForm();
        }
      } else {
        const variables = {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          campaignPlatformId: parseInt(platformId, 10),
          campaignServicesStandardMessageId: parseInt(selectedMessage?.id, 10),
          queue: [finalCustomerData],
          isSchedule: false,
          isFreeTrial: false,
        };
        const payload = {
          query: businessData.sendCampaignMessagesV1,
          variables,
        };
        const result = await handleApiRequest(payload);
        if (result?.sendCampaignMessagesV1?.status === 200) {
          enqueueSnackbar(result?.sendCampaignMessagesV1?.message, {
            variant: 'success',
            autoHideDuration: 3000,
          });
          const payloadData = {
            query: quickPostQuery.updateSurpriseMeCardData,
            variables: {
              quickHubSurpriseCardId: parseInt(allFeaturesTrialDataId, 10),
              planType: 'campaign',
            },
          };
          await handleApiRequest(payloadData);
          surpriseMeCardData();
          clearForm();
        } else {
          enqueueSnackbar(capitalize(result?.sendCampaignMessagesV1?.message), {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    }
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    if (!/\S+@\S+\.\S+/.test(emailValue)) {
      setIsEmailValid(false);
      setEmailErrorMessage('Invalid email address');
    } else {
      setIsEmailValid(true);
      setEmailErrorMessage('');
    }
  };

  const handlePhoneChange = (event, data, value, formattedValue) => {
    setPhone({ ...data, phoneNumber: parseInt(event?.slice(data?.dialCode?.length), 10) });
    setPhoneValue(event);
  };

  return (
    <div>
      <div className="sm:flex gap-24">
        <div className="md:min-w-400 md:max-w-400 sm:max-w-288 p-14 bg-white">
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={tabValue}
            onChange={(e) => {
              setTabValue(e?.target?.value);
              setPlatformId(e?.target?.value === '1' ? 2 : e?.target?.value === '2' ? 1 : 3);
            }}
          >
            <div className="grid grid-cols-3 border-1 border-solid border-darkgreen rounded-3xl text-center">
              <FormControlLabel
                className={`rounded-l-3xl p-10 m-0 relative text-center block ${
                  tabValue === '1' ? 'bg-darkgreen text-white' : ''
                }`}
                value="1"
                control={<Radio className="p-0 absolute right-12 top-12 w-24 h-24 opacity-0" />}
                label={
                  <span className="block text-center text-16 font-medium">
                    {t('dashboard.exploreFeatures.campaigns.sms')}
                  </span>
                }
              />

              <FormControlLabel
                className={`rounded-0 border-x-1 border-darkgreen p-10 m-0 relative text-center block ${
                  tabValue === '2' ? 'bg-darkgreen text-white' : ''
                }`}
                value="2"
                control={<Radio className="p-0 absolute right-12 top-12 w-24 h-24 opacity-0" />}
                label={
                  <span className="block text-center text-16 font-medium">
                    {t('dashboard.exploreFeatures.campaigns.email')}
                  </span>
                }
              />

              <FormControlLabel
                className={`rounded-r-3xl p-10 m-0 relative text-center block ${
                  tabValue === '3' ? 'bg-darkgreen text-white' : ''
                }`}
                value="3"
                control={<Radio className="p-0 absolute right-12 top-12 w-24 h-24 opacity-0" />}
                label={
                  <span className="block text-center text-16 font-medium">
                    {t('dashboard.exploreFeatures.campaigns.whatsapp')}
                  </span>
                }
              />
            </div>
          </RadioGroup>
          {messages && messages?.length > 0 && (
            <div className="max-h-screen overflow-y-auto">
              <h2 className="font-semibold text-20 mt-20 mb-12">
                {t('dashboard.exploreFeatures.campaigns.template')}
              </h2>

              <div>
                <div>
                  {refreshIcon ? (
                    <CircularProgress size={28} className="text-darkgreen text-center mx-auto" />
                  ) : (
                    <>
                      {messages?.map((item, i) => {
                        return (
                          <div
                            key={i}
                            className={`bg-gray-A500 p-16 rounded-md mb-16 last:mb-0 border-1 border-solid ${
                              item?.id === selectedMessage?.id
                                ? 'border-darkgreen'
                                : 'border-gray-A500'
                            }`}
                            onClick={() => setSelectedMessage(item)}
                            aria-hidden
                          >
                            <Typography className="text-14 text-black pb-4">
                              {item?.title}
                            </Typography>
                            <div role="button" tabIndex={0}>
                              {tabValue === '2' ? (
                                <div className="max-h-[400px] overflow-scroll w-full">
                                  <Typography className="font-bold pb-16">
                                    {t('inviteReviews.subject')}: {item?.htmlSubject}
                                  </Typography>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: item?.htmlTemplate,
                                    }}
                                  />
                                </div>
                              ) : tabValue === '3' ? (
                                <div>
                                  <p
                                    className="w-full templeteCss"
                                    dangerouslySetInnerHTML={{
                                      __html: item?.htmlTemplate,
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className="h-[100px] overflow-scroll w-full">
                                  <Typography>{item?.messages}</Typography>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="w-full bg-white md:px-0 px-14 py-28 mt-14 sm:mt-0">
          <div className="max-w-400 mx-auto">
            <div>
              <h2 className="text-20 font-semibold mb-16 ">
                {t('dashboard.exploreFeatures.campaigns.template')}
              </h2>
              <div className="bg-gray-A500 p-12 text-black mb-16">
                {tabValue === '2' ? (
                  <div className="max-h-[400px] overflow-scroll w-full">
                    <Typography className="font-bold pb-16">
                      {t('inviteReviews.subject')}: {selectedMessage?.htmlSubject}
                    </Typography>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: selectedMessage?.htmlTemplate,
                      }}
                    />
                  </div>
                ) : tabValue === '3' ? (
                  <div>
                    <p
                      className="w-full templeteCss"
                      dangerouslySetInnerHTML={{
                        __html: selectedMessage?.htmlTemplate,
                      }}
                    />
                  </div>
                ) : (
                  <div className="h-[100px] overflow-scroll w-full">
                    <Typography>{selectedMessage?.messages}</Typography>
                  </div>
                )}
              </div>
              <h2 className="text-20 font-semibold mb-16">
                {t('dashboard.exploreFeatures.campaigns.receiverDetails')}
              </h2>

              <div>
                <div className="mt-12">
                  <Typography className="mt-12 mb-6">{t('inviteReviews.firstName')}</Typography>
                  <TextField
                    className="w-full"
                    size="small"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <Typography className="mt-12 mb-6">{t('inviteReviews.lastName')}</Typography>
                  <TextField
                    className="w-full"
                    size="small"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  {tabValue === '2' ? (
                    <>
                      <Typography className="mt-12 mb-6">{t('inviteReviews.email')}</Typography>
                      <TextField
                        className="w-full"
                        size="small"
                        value={email}
                        onChange={handleEmailChange}
                        error={!isEmailValid}
                        helperText={!isEmailValid && emailErrorMessage}
                      />
                    </>
                  ) : (
                    <>
                      <Typography className="mt-12 mb-6">{t('inviteReviews.phone')}</Typography>

                      <PhoneInput
                        placeholder={t(
                          'manageBusiness.addBusinessSteps.addBusiness.form.phone.placeHolder'
                        )}
                        inputStyle={{
                          width: '100%',
                          height: '37px',
                        }}
                        className="w-full"
                        name="phone_number"
                        required
                        value={phoneValue}
                        size="small"
                        country={loginUserData?.isoCode?.toLowerCase()}
                        enableSearch="true"
                        onChange={(value, data) => {
                          handlePhoneChange(value, data);
                          const validPhoneNumber = handlePhoneNumberValidationCheck(value, data);
                          setErrorMessage(validPhoneNumber);
                        }}
                      />
                      {errorMessage === false && phoneValue?.length > 0 && (
                        <p className="text-red mt-5 text-16">
                          {t('productSettings.InvalidPhoneNumber')}
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="text-center mt-28 mb-16">
                <Button
                  className="bg-darkgreen rounded-md text-white hover:bg-darkgreen disabled:text-black disabled:bg-gray px-60"
                  onClick={() => handleRedirect()}
                  disabled={
                    !(
                      firstName &&
                      lastName &&
                      (tabValue === '2'
                        ? email && isEmailValid
                        : phoneValue?.length > 0 && errorMessage)
                    ) || campaignTrialDone
                  }
                >
                  {tabValue === '2'
                    ? `${t('dashboard.exploreFeatures.campaigns.sendEmail')}`
                    : tabValue === '3'
                    ? `${t('dashboard.exploreFeatures.campaigns.sendMessage')}`
                    : `${t('dashboard.exploreFeatures.campaigns.sendSms')}`}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickCampaignsFeatures;
