import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Icon,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  GoogleMap,
  Marker,
  LoadScript,
  StandaloneSearchBox,
  Autocomplete,
} from '@react-google-maps/api';
import { t } from 'i18next';
import { selectDashboardData } from 'src/app/store/userSlice';
import { useSnackbar } from 'notistack';
import metricsData from '../../query/metrics';
import ReviewSentiment from './reviewSentiment';
import { getDataToServer, handleApiRequest } from '../../common/common';
import NoData from './noDataScreen';

function CompetitorBenchmarking(props) {
  const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const lib = ['places'];
  const { reviewSentiment, title } = props;
  const [selectedReviewSentimentId, setSelectedReviewSentimentId] = useState(7);
  const [competitor, setCompetitor] = useState([]);
  const [overAll, setOverAll] = useState([]);
  const [competitorBusinessId, setCompetitorBusinessId] = useState(-1);
  const [openDialog, setOpenDialog] = useState(false);
  const [autocompleteMap, setAutocompleteMap] = useState({});
  const [dataLoading, setDataLoading] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({ lat: 34.0522342, lng: -118.2436849 });
  const [selectedMapFullAddress, setSelectedMapFullAddress] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [googleData, setGoogleData] = useState({});
  const [openProcessDialog, setOpenProcessDialog] = useState(false);
  const [isProcess, seIsProcess] = useState(false);
  const [isBusinessAdd, setIsBusinessAdd] = useState(false);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  const [newGraphData, setNewGraphData] = useState([
    {
      name: userCurrentBusinessDetails?.name,
      data: [],
    },
    {
      name: 'Other Business',
      data: [],
    },
  ]);

  const fetchData = useCallback(async () => {
    try {
      if (userCurrentBusinessDetails?.id && selectedReviewSentimentId) {
        const payload = {
          query: metricsData.getCompetitorBenchmarking,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            reviewsSentimentPeriodsId: parseInt(selectedReviewSentimentId, 10),
          },
        };
        const result = await getDataToServer(payload);

        const data = result?.getCompetitorBenchmarking?.data || [];
        if (data[0]?.competitorBenchmarkingV1) {
          const competitorDataOverAll =
            competitorBusinessId !== -1
              ? data[0].competitorBenchmarkingV1?.competitor
              : data[0].competitorBenchmarkingV1.overall;
          const userBusinessData = data[0].businessAttributeRatingsV1;
          const competitorList = data[0].competitorBenchmarkingV1?.competitor;

          setCompetitor(competitorList);
          setOverAll(userBusinessData);
          setNewGraphData([
            {
              name: userCurrentBusinessDetails?.name,
              data: userBusinessData?.map((item) => item.value),
            },
            {
              name:
                competitorBusinessId !== -1
                  ? `${competitorDataOverAll[competitorBusinessId]?.name.slice(0, 10)}...`
                  : 'Other Business',
              data:
                competitorBusinessId !== -1
                  ? competitorDataOverAll[competitorBusinessId]?.status !== 'pending' &&
                    competitorDataOverAll[competitorBusinessId]?.value?.map((item) => item?.value)
                  : competitorDataOverAll?.map((item) => item?.value),
            },
          ]);
        } else {
          setCompetitor([]);
          setOverAll([]);
          setNewGraphData([]);
        }
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails, selectedReviewSentimentId, competitorBusinessId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onPlacesChanged = async () => {
    setDataLoading(true);
    if (autocompleteMap !== null) {
      const place = autocompleteMap?.getPlace();
      setCurrentLocation({
        lat: place.geometry?.location?.lat(),
        lng: place.geometry?.location?.lng(),
      });
      if (!place?.place_id) {
        enqueueSnackbar(t('manageBusiness.addBusinessSteps.businessLocation.selectData'), {
          variant: 'error',
          autoHideDuration: 3000,
        });
        setDataLoading(false);
        return;
      }

      setSelectedMapFullAddress(place.formatted_address);
      let formattedAddress = '';

      try {
        const googleBusinessData = await axios({
          url: process.env.REACT_APP_AI_GOOGLE_PLACE,
          method: 'post',
          maxBodyLength: Infinity,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            place_id: place?.place_id,
            place_name: place.name,
          }),
        });

        const googleDetails = googleBusinessData?.data?.data?.data;
        formattedAddress = place.formatted_address;

        if (googleBusinessData?.status === 200) {
          await setGoogleData({
            address: formattedAddress,
            businessName: place?.name,
            place_id: place?.place_id,
            url: place?.url,
            businessImage: googleDetails?.profile_image,
            rating: place?.rating,
            userTotalRating: place?.user_ratings_total,
            country: googleDetails?.country,
            geometry: {
              lat: place.geometry?.location?.lat(),
              lng: place.geometry?.location?.lng(),
            },
            vicinity: place?.vicinity,
          });
        }
      } catch (error) {
        console.error('An error occurred', error);
      }
      setDataLoading(false);
    }
  };
  const onPlaceSearch = (event) => {
    setSelectedMapFullAddress(event.target.value);
  };

  const onLoad = (autocompleteData) => {
    setAutocompleteMap(autocompleteData);
  };

  const onDragChanged = async (event) => {
    if (event) {
      const newLocation = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setCurrentLocation(newLocation);
    }
  };

  const getChartSimple = (data, categories, index, color, types) => {
    const chart = {
      series: data,
      options: {
        grid: {
          strokeDashArray: 3,
          show: true,
        },
        chart: {
          events: {
            mounted: (charts) => {
              charts.windowResizeHandler();
            },
          },
          type: types,
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            columnWidth: '60px',
          },
        },
        legend: {
          onItemClick: {
            toggleDataSeries: false,
          },
          markers: {
            radius: 12,
          },
        },
        colors: color,
        fill: {
          colors: color,
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          axisTicks: {
            show: false,
          },
          categories,
          labels: {
            offsetY: 0,
          },
        },
        yaxis: {
          labels: {
            align: 'left',
            maxWidth: 350,
            position: 'left',
            offsetX: -15,
            formatter(val) {
              return `${val} ★`;
            },
            style: {
              fontSize: '14px',
            },
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: (val) => `${val.toFixed(1)} ★`,
          },
        },
      },
    };

    return (
      <div id={`chart${index}`} className="flex flex-col flex-auto w-full">
        <ReactApexChart
          options={chart.options}
          series={chart.series}
          type={chart.options.chart.type}
          height="300px"
          width="100%"
        />
      </div>
    );
  };

  const submitBusiness = async () => {
    try {
      const payload = {
        query: metricsData.addCompetitorBenchmarkingBusiness,
        variables: {
          businessName: `${googleData?.businessName}, ${
            googleData?.address || googleData?.vicinity
          }`,
          geometry: JSON.stringify({ data: googleData?.geometry }),
          placeId: googleData?.place_id,
          totalReviewRatings: parseInt(googleData?.rating, 10),
          totalReviews: parseInt(googleData?.userTotalRating, 10),
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          url: googleData?.url,
        },
      };

      const result = await handleApiRequest(payload);

      if (result.addCompetitorBenchmarkingBusiness?.status === 200) {
        setIsBusinessAdd(true);
        setOpenProcessDialog(true);
        setOpenDialog(false);
        setGoogleData('');
        setSelectedMapFullAddress('');
        fetchData();
      } else {
        enqueueSnackbar(result?.addCompetitorBenchmarkingBusiness?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('An error occurred while submitting the business:', error);
    }
  };

  const handleSelectCompetitorBusiness = (value) => {
    const findBusiness = competitor?.find((item) => item?.name === value);
    if (findBusiness?.status === 'success') {
      setCompetitorBusinessId(value);
    } else {
      seIsProcess(true);
      setOpenProcessDialog(true);
    }
  };

  return (
    <>
      <div className="flex items-center justify-between mb-16">
        <div className="flex items-center ">
          {' '}
          <h3 className="text-18 font-bold">{t('metrics.competitorBenchmarking')}</h3>
          <Tooltip
            title={title}
            arrow
            classes={{
              tooltip: 'bg-quick-review',
              arrow: 'text-quick-review',
            }}
          >
            <Icon className="text-quick-review text-20 align-middle mt-4 ml-4">info</Icon>
          </Tooltip>
        </div>
        {parseInt(props.reviewPlatformId, 10) === 2 && (
          <Button
            className="h-24 min-h-24 text-quick-review hover:bg-white"
            onClick={() => setOpenDialog(true)}
          >
            <AddIcon /> {t('metrics.compareBusiness')}
          </Button>
        )}
      </div>
      <Select
        className="inline-block bg-white text-black capitalize border-none pt-0 font-semibold w-full rounded-full mb-12 relative"
        id="country"
        value={competitorBusinessId}
        size="small"
        displayEmpty
        sx={{
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
            border: 'none',
            maxWidth: '360px',
          },
        }}
        onChange={(e) => handleSelectCompetitorBusiness(e?.target?.value)}
      >
        <MenuItem value={-1} className="capitalize">
          Overall
        </MenuItem>
        {competitor?.map((option, i) => (
          <MenuItem key={i} value={option?.name} className="flex capitalize whitespace-normal">
            <div className="w-full max-w-320">{option.name}</div>
            {option.status !== 'success' && (
              <Typography className="bg-orange text-white p-4 rounded-md">
                {' '}
                {t('metrics.inProgress')}
              </Typography>
            )}
          </MenuItem>
        ))}
      </Select>
      <div className="relative">
        <ReviewSentiment
          reviewSentiment={reviewSentiment}
          sentimentId={[7, 5, 2]}
          onSelectSentiment={setSelectedReviewSentimentId}
        />
        {overAll && overAll?.length > 0 ? (
          <>
            <>
              <div>
                {getChartSimple(
                  newGraphData,
                  overAll?.map((period) => period?.key),
                  6,
                  ['#008ffb', '#ffbd23'],
                  'bar'
                )}
              </div>
            </>
          </>
        ) : (
          <NoData />
        )}
      </div>
      <Dialog
        classes={{
          paper: 'p-28 h-512',
        }}
        open={openDialog}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenDialog(false);
            setGoogleData('');
            setSelectedMapFullAddress('');
          }
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="p-0 mb-16 text-center">
          <Icon
            onClick={() => {
              setOpenDialog(false);
              setGoogleData('');
              setSelectedMapFullAddress('');
            }}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            close
          </Icon>
        </DialogTitle>
        <DialogActions className="p-0 justify-center">
          <div className="w-full ">
            <Typography className="font-semibold text-20">Compare Business Location</Typography>
            <hr className="border border-dashed border-solid !border-black my-16" />
            <div className={`${googleData?.businessName ? 'md:flex' : ''} gap-20`}>
              <div className={`${googleData?.businessName ? 'md:w-3/4' : 'w-full'}`}>
                <LoadScript
                  id="script-loader"
                  googleMapsApiKey={key}
                  libraries={lib}
                  loadingElement={<div>Loading...</div>}
                >
                  <GoogleMap
                    mapContainerStyle={{
                      height: '320px',
                      width: '100%',
                      position: 'relative',
                    }}
                    className="custom-height rounded-lg"
                    zoom={8}
                    center={currentLocation}
                  >
                    <Marker position={currentLocation} onDragEnd={onDragChanged} draggable />
                    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlacesChanged} zIndex="999">
                      <StandaloneSearchBox zIndex="999">
                        <input
                          type="text"
                          value={selectedMapFullAddress}
                          onChange={(e) => onPlaceSearch(e)}
                          placeholder={t(
                            'manageBusiness.addBusinessSteps.businessLocation.searchYourName'
                          )}
                          style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            width: `300px`,
                            height: `32px`,
                            padding: `0 12px`,
                            borderRadius: `5px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `14px`,
                            outline: `none`,
                            textOverflow: `ellipses`,
                            position: 'absolute',
                            margin: '0 auto',
                            top: '14px',
                            left: '0',
                            right: '0',
                            zIndex: '1',
                          }}
                        />
                      </StandaloneSearchBox>
                    </Autocomplete>
                  </GoogleMap>
                </LoadScript>
              </div>
              {googleData?.businessName && (
                <div className="border-1 sm:w-320 h-full mt-20 md:mt-0 m-auto md:w-288 rounded-md p-8 border-solid !border-quick-review">
                  <img className="rounded-md h-160 w-full" src={googleData?.businessImage} alt="" />
                  <Typography className="font-medium text-16 pt-8">
                    {googleData?.businessName}
                  </Typography>
                  <Typography className="text-12 py-8">{googleData?.address}</Typography>
                  <div className="flex items-center">
                    <Typography className="font-medium text-14">
                      {googleData?.rating > 0 && (
                        <Icon className="text-16 text-yellow-800 align-middle mr-2 -mt-2">
                          star
                        </Icon>
                      )}{' '}
                      {googleData?.rating}{' '}
                      <span className="font-normal pl-4 text-14">
                        ({googleData?.userTotalRating} reviews)
                      </span>
                    </Typography>
                  </div>
                </div>
              )}
            </div>
            {googleData?.businessName && (
              <div className="my-16 text-right">
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  className="inline-block align-middle rounded-3xl md:text-16 py-1 px-20 font-medium disabled:text-black sm:min-w-160 min-h-40"
                  onClick={() => submitBusiness()}
                >
                  <span>{t('manageBusiness.button.addBusiness')}</span>
                </Button>
              </div>
            )}
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          paper: `{m-28 business-type-dropdown border-t-8 border-solid ${
            isProcess ? 'border-t-red-600' : 'border-t-quick-review'
          } }`,
        }}
        open={openProcessDialog}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenProcessDialog(false);
            seIsProcess(false);
            setIsBusinessAdd(false);
          }
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="p-0 mb-16 text-center">
          <Icon
            onClick={() => {
              setOpenProcessDialog(false);
              seIsProcess(false);
              setIsBusinessAdd(false);
            }}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            close
          </Icon>
        </DialogTitle>
        <DialogActions className="p-0 justify-center my-40">
          {isBusinessAdd && (
            <div className="text-center">
              <img className="m-auto" src="/assets/images/business/check_circles.svg" alt="" />
              <Typography className="font-semibold text-20 my-24">
                {t('metrics.businessAdd')}
              </Typography>
              <Typography className="text-14 max-w-480 m-auto">
                {t('metrics.businessAddMessage')}
              </Typography>
            </div>
          )}
          {isProcess && (
            <div className="text-center">
              <img className="m-auto" src="/assets/images/business/warning_alert.png" alt="" />
              <Typography className="font-semibold text-20 my-24">
                {t('metrics.businessProgress')}
              </Typography>
              <Typography className="text-14 max-w-480 m-auto">
                {t('metrics.businessProgressMessage')}
              </Typography>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CompetitorBenchmarking;
