import React, { useCallback, useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import ConformationDialogBox from 'src/app/main/common/ConformationDialogBox';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import history from '@history';
import { useSnackbar } from 'notistack';
import DownloadIcon from '@mui/icons-material/Download';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';
import { t } from 'i18next';
import { useLocation, useParams } from 'react-router-dom';
import { Button, IconButton, TextField } from '@mui/material';
import AddDescription from './AddDescription';
import manageBusinessData from '../../query/manageBusiness';
import { handleApiRequest } from '../../common/common';
import SchedulePopUp from './SchedulePopUp';
import SharePost from '../quick-post/SharePost';

const Post = () => {
  const params = useParams();
  const [suggestion, setSuggestion] = useState('');
  const [url, setURL] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [description, setDescription] = useState('');
  const [editableDes, setEditableDes] = useState('');
  const [openDialogueForSchedule, setOpenDialogueForSchedule] = useState(false);
  const [openTemplateChangePopup, setOpenTemplateChangePopup] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const location = useLocation();
  const { data } = location.state || {};
  const [showNextDiv, setShowNextDiv] = useState(false);
  const [editableTexts, setEditableTexts] = useState(data?.editableText || []);
  const [template, setTemplate] = useState(data?.template || '');
  const [modifiedCards, setModifiedCards] = useState(new Array(editableTexts?.length).fill(false));
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const [socialData, setSocialData] = useState([]);
  const [isChecked, setIsChecked] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [scheduledDate, setScheduledDate] = useState();
  const [scheduledTime, setScheduledTime] = useState();

  useEffect(() => {
    setIsNextEnabled(description.length > 0);
  }, [description]);

  useEffect(() => {
    const socialMediaData = async () => {
      try {
        const payload = {
          query: manageBusinessData.getQuickSocialChannelWiseData,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            pageNumber: 1,
            pageSize: 10,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getQuickSocialChannelWiseData?.status === 200) {
          setSocialData(result?.getQuickSocialChannelWiseData?.data);
        }
      } catch (error) {
        console.error('Error regenerating suggestion:', error);
      }
    };
    if (userCurrentBusinessDetails?.id) {
      socialMediaData();
    }
  }, [userCurrentBusinessDetails]);

  useEffect(() => {
    const initialCheckedState = socialData?.map((platform) => platform?.isConnected);
    setIsChecked(initialCheckedState);
  }, [socialData]);

  useEffect(() => {
    const updateTemplate = () => {
      let updatedTemplate = data?.template || '';
      editableTexts?.forEach(({ key, value }) => {
        const placeholder = `###${key}###`;
        updatedTemplate = updatedTemplate?.replace(new RegExp(placeholder, 'g'), value);
      });
      setTemplate(updatedTemplate);
    };
    updateTemplate();
  }, [editableTexts, data?.template]);

  useEffect(() => {
    if (editableDes?.summarizeReviews && editableTexts?.key === 'description') {
      const updatedTexts = editableTexts?.map((item) =>
        item?.key === 'description' ? { ...item, value: editableDes?.summarizeReviews } : item
      );
      setEditableTexts(updatedTexts);
    }
  }, [editableDes, editableTexts]);

  const saveEditableText = useCallback(
    async (editableTex) => {
      try {
        const payload = {
          query: manageBusinessData.getConvertedSharePostImage,
          variables: {
            htmlTemplate: encodeURIComponent(data?.template),
            editableText: editableTex,
          },
        };

        const result = await handleApiRequest(payload);
        if (result?.getConvertedSharePostImage?.status === 200) {
          setEditableTexts((prev) =>
            prev?.map((text, i) =>
              i === editableTexts?.indexOf(editableTex)
                ? result?.getConvertedSharePostImage?.data
                : text
            )
          );
          setURL(result?.getConvertedSharePostImage?.data?.socialPostUrl);
        }
      } catch (error) {
        console.error('Error regenerating suggestion:', error);
      }
    },
    [data?.template, editableTexts]
  );

  const handleEditableTextDes = useCallback(async (descRip) => {
    try {
      const payload = {
        query: manageBusinessData.sharePostGenerateSummarizeReviews,
        variables: {
          review: descRip,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.sharePostGenerateSummarizeReviews?.status === 200) {
        setEditableDes(result?.sharePostGenerateSummarizeReviews?.data);
      }
    } catch (error) {
      console.error('Error regenerating suggestion:', error);
    }
  }, []);

  const handleDirectPost = async () => {
    const createPayload = (type) => {
      switch (type) {
        case 'facebook':
          return {
            query: manageBusinessData.postOnFacebookChannel,
            variables: {
              image: url,
              postType: 'image',
              text: description,
              userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
            },
            responseKey: 'postOnFacebookChannel',
          };
        case 'instagram':
          return {
            query: manageBusinessData.createInstagramPost,
            variables: {
              isTemplate: true,
              templateData: url,
              mediaType: 'image',
              caption: description,
              userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
            },
            responseKey: 'createInstagramPost',
          };
        default:
          return null;
      }
    };

    try {
      if (isChecked[1] === true) {
        const facebookPayload = createPayload('facebook');
        if (facebookPayload) {
          const result = await handleApiRequest(facebookPayload);
          const response = result?.[facebookPayload.responseKey];
          enqueueSnackbar(response?.message, {
            variant: response?.status === 200 ? 'success' : 'error',
            autoHideDuration: 3000,
          });
          if (response?.status === 200)
            history.push(`quick-social/share-post/${params?.id ? params.id : ''}`);
        }
      }
      if (isChecked[0] === true) {
        const instagramPayload = createPayload('instagram');
        if (instagramPayload) {
          const result = await handleApiRequest(instagramPayload);
          const response = result?.[instagramPayload.responseKey];
          enqueueSnackbar(response?.message, {
            variant: response?.status === 200 ? 'success' : 'error',
            autoHideDuration: 3000,
          });
          if (response?.status === 200)
            history.push(`quick-social/share-post/${params?.id ? params.id : ''}`);
        }
      }
    } catch (error) {
      console.error('Error posting to platform:', error);
    }
  };

  const handleTextChange = (index, value) => {
    setEditableTexts((prevEditableTexts) => {
      const currentTextValue = prevEditableTexts[index]?.value;
      if (currentTextValue !== value) {
        const updatedTexts = prevEditableTexts.map((item, i) =>
          i === index ? { ...item, value } : item
        );
        return updatedTexts;
      }
      return prevEditableTexts;
    });
    const updatedChanges = [...modifiedCards];
    updatedChanges[index] = true;
    setModifiedCards(updatedChanges);
  };

  const handleEditableTextDesc = (descRip) => {
    handleEditableTextDes(descRip);
  };

  const handleSchedule = () => {
    setOpenDialogueForSchedule(true);
  };

  const handleSave = (index) => {
    const updatedText = editableTexts[index];
    if (modifiedCards[index]) {
      saveEditableText([updatedText]);
    }

    setModifiedCards((prev) => {
      const updated = [...prev];
      updated[index] = false;
      return updated;
    });
  };
  const handleNext = () => {
    setShowNextDiv(true);
    saveEditableText(editableTexts);
  };

  const handleCheckboxChange = (index) => {
    setIsChecked((prevState) => prevState.map((item, i) => (i === index ? !item : item)));
  };

  const formatDateTime = (date, time) => {
    const dateTimeString = `${date} ${time}`;
    const dateObj = new Date(dateTimeString);
    const offset = dateObj.getTimezoneOffset();
    const hoursOffset = String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0');
    const minutesOffset = String(Math.abs(offset) % 60).padStart(2, '0');
    const sign = offset > 0 ? '-' : '+';
    const formattedDate = dateObj
      .toISOString()
      .replace(/:\d{2}\.\d{3}Z$/, '')
      .replace('T', 'T')
      .concat(`${sign}${hoursOffset}:${minutesOffset}`);
    return formattedDate;
  };

  const setSchedule = async () => {
    try {
      if (isChecked[1]) {
        const payload = {
          query: manageBusinessData.addQuickSocialSchedulePost,
          variables: {
            sharedChannels: getSelectedReviewPlatformIds(),
            editableText: editableTexts,
            userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
            scheduleDate: formatDateTime(scheduledDate, scheduledTime),
            description,
            html: encodeURIComponent(template),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.addQuickSocialSchedulePost?.status === 200) {
          history.push(`quick-social/share-post/${params?.id ? params.id : ''}`);
          enqueueSnackbar(result?.addQuickSocialSchedulePost?.message, {
            variant: 'success',
            autoHideDuration: 3000,
          });
          setOpenDialogueForSchedule(false);
        } else {
          enqueueSnackbar(result?.addQuickSocialSchedulePost?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    } catch (error) {
      console.error('Error regenerating suggestion:', error);
    }
  };

  const getSelectedReviewPlatformIds = () => {
    return socialData
      ?.filter((_, index) => isChecked[index])
      ?.map((platform) => platform?.reviewPlatformId);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDialogueForSchedule(false);
    setOpenTemplateChangePopup(false);
    setOpenShare(false);
  };

  const handleSharePost = () => {
    setOpenShare(true);
  };

  const handleEdit = () => {
    setShowNextDiv(false);
  };

  const handleTemplateChange = () => {
    history.push(`/quick-social/template/${params?.id ? params.id : ''}`);
  };

  const templateRef = useRef(null);

  const handleDownload = async () => {
    if (templateRef.current) {
      try {
        const canvas = await html2canvas(templateRef.current, { scale: 2 });

        const dataURL = canvas.toDataURL('image/png');

        const link = document.createElement('a');
        link.href = dataURL;
        link.download = 'template.png';
        link.click();
      } catch (error) {
        console.error('Error capturing HTML to image:', error);
      }
    }
  };

  return (
    <div className={`lg:p-32 p-24 ${!showNextDiv ? 'max-w-5xl' : ''}`}>
      {!showNextDiv && (
        <div>
          <h1 className="font-bold md:text-28 sm:text-24 text-20 mb-28">
            {t('quickSocialPost.title')}
          </h1>
          {data ? (
            <>
              <div className="bg-white py-20 px-32 mb-20 rounded-md">
                <div>
                  {data?.template && (
                    <div
                      className="relative"
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      {isHovered && (
                        <div className="absolute top-10 right-10 space-x-2">
                          <IconButton
                            color="primary"
                            aria-label="add photo"
                            onClick={() => setOpenTemplateChangePopup(true)}
                          >
                            <AddPhotoAlternateIcon />
                          </IconButton>
                        </div>
                      )}

                      <div className="bg-grey-100 p-24 px-96 rounded-md items-center justify-center mb-24">
                        <div dangerouslySetInnerHTML={{ __html: template }} />
                      </div>
                    </div>
                  )}
                  <ConformationDialogBox
                    open={openTemplateChangePopup}
                    onClose={() => setOpenTemplateChangePopup(false)}
                    onDelete={() => handleTemplateChange()}
                    additionalInfo={t('quickSocialTemplate.confirmationData')}
                    confirmationText={t('quickSocialTemplate.confirmationQuestion')}
                    deleteImage="/assets/images/icon/TemplateConfirm.png"
                    backgroundColor="quick-social"
                    textColor="quick-social"
                    buttonText={t('botResponses.confirm')}
                  />
                </div>
                <h3 className="md:text-20 text-16 font-semibold mb-16">
                  {t('quickSocialPost.editText')}
                </h3>
                <div>
                  {editableTexts?.map((item, index) => (
                    <div
                      key={index}
                      className="bg-grey-100 px-14 py-16 mb-12 rounded-md flex flex-col gap-4 items-start"
                    >
                      <div className="flex items-center gap-12 w-full">
                        <img src="/assets/images/icon/eye.png" alt="eye" className="h-10" />
                        <TextField
                          placeholder={t('botResponses.typeTheBotResponse')}
                          variant="outlined"
                          fullWidth
                          value={
                            item?.key === 'description' && editableDes?.summarizeReviews
                              ? editableDes?.summarizeReviews
                              : item?.value === `###${item.key}###`
                              ? `sample${item.key}`
                              : item?.value
                          }
                          onChange={(e) => handleTextChange(index, e.target.value, item?.key)}
                          className="max-w-640 text-16 font-medium"
                          color="quick-social"
                          error={
                            (item?.key === 'description' &&
                              item?.value?.length > 0 &&
                              item?.value?.length < 20) ||
                            (item?.key === 'userName' && item?.value?.length > 30)
                          }
                          helperText={
                            item?.key === 'description'
                              ? item?.value?.length === 0
                                ? t('quickSocialPost.validation')
                                : item?.value?.length > 0 && item?.value?.length < 20
                                ? t('quickSocialPost.validation')
                                : ''
                              : item?.key === 'userName' && item?.value?.length > 30
                              ? t('quickSocialPost.userNameMaxLength')
                              : ''
                          }
                        />
                        {modifiedCards[index] ? (
                          <Button
                            className="md:text-18 font-semibold bg-quick-social border rounded-md hover:bg-quick-social"
                            variant="contained"
                            onClick={() => handleSave(index)}
                          >
                            {t('quickSocialPost.save')}
                          </Button>
                        ) : null}
                      </div>
                      {item?.key === 'description' && (
                        <Button
                          className="md:text-18 font-semibold rounded-full bg-blue-100 hover:bg-blue-100 text-quick-social px-16 border-solid mt-40"
                          variant="contained"
                          size="small"
                          color="quickSocial"
                          type="button"
                          onClick={() => handleEditableTextDesc(item?.value)}
                        >
                          {t('quickSocialPost.regenerate')}
                          <img src="/assets/images/icon/Ai.svg" alt="Ai" className="h-12 w-12" />
                        </Button>
                      )}
                    </div>
                  ))}
                </div>
                <div>
                  <AddDescription
                    suggestion={suggestion}
                    setSuggestion={setSuggestion}
                    setShowNextDiv={setShowNextDiv}
                    showNextDiv={showNextDiv}
                    description={description}
                    setDescription={setDescription}
                    setIsNextEnabled={setIsNextEnabled}
                  />
                  <div className="flex justify-end">
                    <Button
                      className="md:text-18 font-semibold bg-quick-social border border-solid rounded-md hover:text-white items-center px-32 p-10"
                      variant="contained"
                      color="quickSocial"
                      type="button"
                      onClick={handleNext}
                      disabled={!isNextEnabled}
                    >
                      {t('quickSocialPost.next')}
                    </Button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div>
              <AddDescription
                suggestion={suggestion}
                setSuggestion={setSuggestion}
                setShowNextDiv={setShowNextDiv}
                showNextDiv={showNextDiv}
                description={description}
                setDescription={setDescription}
                setIsNextEnabled={setIsNextEnabled}
              />
              <div>
                <Button
                  className="md:text-18 font-semibold bg-quick-social border border-solid rounded-md hover:text-white items-center"
                  variant="contained"
                  color="quickSocial"
                  type="button"
                  onClick={() => {
                    handleNext();
                  }}
                  disabled={!isNextEnabled}
                >
                  {t('quickSocialPost.next')}
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      {showNextDiv && (
        <>
          <div className="md:flex gap-24">
            <div className=" bg-white p-20 rounded-md md:w-3/5 w-full mb-10">
              <div className="flex justify-between mb-44">
                <div>
                  <h2 className="text-28 font-semibold">{t('quickSocialPost.yourPost')}</h2>
                </div>
                <div className="gap-20">
                  <div className="">
                    <IconButton
                      color="primary"
                      aria-label="share"
                      onClick={() => handleSharePost()}
                    >
                      <ShareIcon />
                    </IconButton>

                    <IconButton
                      color="primary"
                      aria-label="download"
                      onClick={() => handleDownload()}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </div>
                  <SharePost
                    open={openShare}
                    onClose={handleCloseDeleteDialog}
                    url={url}
                    image={url}
                  />
                </div>
              </div>
              <div className="bg-grey-100 p-20 mb-20 rounded-md ">
                <p>{description}</p>
              </div>
              <div className="relative">
                <div className="absolute top-2 right-2 space-x-2">
                  <IconButton color="primary" aria-label="edit" onClick={() => handleEdit()}>
                    <EditIcon />
                  </IconButton>
                </div>
                <div>
                  {data?.template && (
                    <div ref={templateRef} dangerouslySetInnerHTML={{ __html: template }} />
                  )}
                </div>
              </div>
            </div>
            <div className="bg-white rounded-md p-24 md:w-2/5 w-full mb-10">
              <h3 className="text-20 font-semibold mb-16 ">
                {t('quickSocialPost.selectPlatform')}
              </h3>
              {!socialData?.some((platform) => platform?.isConnected) ? (
                <div className="bg-grey-100 rounded-md p-16 flex flex-col items-center">
                  <h3 className="text-24 font-medium mb-32">
                    {t('quickSocialPost.connectToSocialAccount')}
                  </h3>

                  <div className="platform-list flex gap-16 mb-20 justify-center">
                    {socialData?.map((platform, index) => (
                      <div key={index} className="flex items-center gap-8">
                        <img
                          src={platform?.logo}
                          alt={`${platform?.name} Logo`}
                          className="w-32 h-32"
                        />
                        <span>{platform?.name}</span>
                      </div>
                    ))}
                  </div>

                  <Button
                    variant="contained"
                    color="quickSocial"
                    autoFocus
                    onClick={() =>
                      history.push(`/quick-social/dashboard/${params?.id ? params.id : ''}`)
                    }
                    className="rounded-md"
                  >
                    {t('quickSocialPost.connect')}
                  </Button>
                </div>
              ) : (
                <div className="connected-platforms-list  gap-16">
                  <div className="bg-grey-200 px-24 pt-24 mb-24 rounded-md">
                    {socialData
                      ?.filter((platform) => platform?.isConnected)
                      ?.map((platform, index) => (
                        <div key={index} className="flex items-center pb-24">
                          <input
                            type="checkbox"
                            id={`checkbox-${index}`}
                            checked={isChecked[index]}
                            onChange={() => handleCheckboxChange(index)}
                            className="hidden"
                          />
                          <label
                            htmlFor={`checkbox-${index}`}
                            className="flex items-center cursor-pointer"
                          >
                            <span
                              className={`w-16 h-16 inline-block mr-4 rounded border-2 border-gray-400 ${
                                isChecked[index] ? 'bg-green-500 border-green-500' : ''
                              }`}
                            >
                              {isChecked[index] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-14 h-14 text-white m-auto"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <polyline points="20 6 9 17 4 12" />
                                </svg>
                              )}
                            </span>
                            <img
                              src={platform?.logo}
                              alt={`${platform?.name} Logo`}
                              className="w-24 h-24 mr-8"
                            />
                            <p className="text-20 font-medium">
                              {platform?.name?.replace(/\b\w/g, (char) => char?.toUpperCase())}
                            </p>
                          </label>
                        </div>
                      ))}
                  </div>
                  <div className="gap-16 flex justify-end">
                    <div>
                      <Button
                        variant="outlined"
                        color="quickSocial"
                        autoFocus
                        onClick={() => handleSchedule('Schedule')}
                        className="rounded-md px-32"
                        disabled={!isChecked.some((item) => item === true)}
                      >
                        {t('quickSocialPost.schedule')}
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        color="quickSocial"
                        autoFocus
                        onClick={() => handleDirectPost()}
                        className="rounded-md px-48"
                        disabled={!isChecked.some((item) => item === true)}
                      >
                        {t('quickSocialPost.post')}
                      </Button>
                    </div>
                  </div>
                  <SchedulePopUp
                    open={openDialogueForSchedule}
                    onClose={handleCloseDeleteDialog}
                    onCreatePost={setSchedule}
                    backgroundColor="quick-social"
                    textColor="white"
                    buttonText="Schedule"
                    scheduledDate={scheduledDate}
                    setScheduledDate={setScheduledDate}
                    scheduledTime={scheduledTime}
                    setScheduledTime={setScheduledTime}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Post;
